import React from 'react'

import { RouteState } from '../../../../context/routeContext'


function SystemTabSidebar() {
    const { systemRoute, setSystemRoute } = RouteState('accounts')

    return (
        <ul className="nav nav-tabs responsive_tabs ps-lg-3" role="tablist">
            <li className="nav-item">
                <a id="tab-A" href="#pane-A" data-bs-toggle="tab" role="tab" onClick={() => setSystemRoute("accountType")} className={`nav-link ${systemRoute === 'accountType' ? 'active' : ''}`}>Account types
                </a>
            </li>
            <li className="nav-item">
                <a id="tab-B" href="#pane-B" data-bs-toggle="tab" role="tab" onClick={() => setSystemRoute("workstation")} className={`nav-link ${systemRoute === 'workstation' ? 'active' : ''}`}>Workstations</a>
                {/* <Link to="/accountsRequest">
                        </Link> */}
            </li>
            <li className="nav-item">
                <a id="tab-C" href="#pane-C" data-bs-toggle="tab" role="tab" onClick={() => setSystemRoute("setting")} className={`nav-link ${systemRoute === 'setting' ? 'active' : ''}`}>Settings</a>
                {/* <Link to="/company">
                        </Link> */}
            </li>
        </ul>
    )
}

export default SystemTabSidebar