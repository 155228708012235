/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { RouteState } from '../../../../context/routeContext'
// import './Delivery.css'
import './imagegallery.css'

import Processing from './Processing'
import RescanRequest from './RescanRequest'
import OriginalRequest from './OriginalRequest'
import WrongCompany from './WrongCompany'
import UnassignedMail from './UnassignedMail'
import AssignmentRules from './AssignmentRules'
import QualityControls from './QualitControls'
// import RequestDetails from './modals/RequestDetails'



const Deliver = () => 
{
	const { deliveryRoute, setDeliveryRoute } = RouteState()
	const [activeTab, setActiveTab] = useState('processing')

	useEffect(() => 
	{
		setActiveTab(deliveryRoute)
	}, [deliveryRoute])

	const user = JSON.parse(localStorage?.getItem('user'))

	const seekPermission = (link) => 
	{
		if (user?.entitlements?.includes(link)) 
		{
			return true
		} 
		else 
		{
			return false
		}
	}

	return (
		<>
			<div id="content" className="tab-content cst_tab" role="tablist">

				<div id="pane-A" className={`tab-pane fade ${activeTab === 'processing' ? 'show active' : 'fade'}`} role="tabpanel" aria-labelledby="tab-A">
					<Processing activeTab={activeTab} />
				</div>

				<div id="pane-B" className={`tab-pane fade ${activeTab === 'unassigned' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-B">
					<UnassignedMail activeTab={activeTab} />
				</div>

				{seekPermission("request") && <div id="pane-C" className={`tab-pane fade ${activeTab === 'rescan' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-C">
					<RescanRequest activeTab={activeTab} />
				</div>}

				{seekPermission("request") && <div id="pane-C" className={`tab-pane fade ${activeTab === 'original' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-C">
					<OriginalRequest activeTab={activeTab} />
				</div>}

				<div id="pane-D" className={`tab-pane fade ${activeTab === 'wrong' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-D">
					<WrongCompany activeTab={activeTab} />
				</div>

				{seekPermission("quality") && <div id="pane-E" className={`tab-pane fade ${activeTab === 'quality' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-E">
					<QualityControls activeTab={activeTab} />
				</div>}

				<div id="pane-F" className={`tab-pane fade ${activeTab === 'assignment' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-F">
					<AssignmentRules activeTab={activeTab} />
				</div>

			</div>
		</>
	)
}

export default Deliver