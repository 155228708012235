import { url } from "../environment/environment";
import { getAuthToken } from "./refreshBearerToken"

import axios from "axios";

/*===============================================================
                Settings Get API
==================================================================*/

// notify(`Error : ${err?.response?.status} ${err?.response?.data.error.desc}`);
export const Settings_Get_API = async (local_url) => 
{
	return new Promise( ( resolve, reject ) =>
	{
		getAuthToken().then( token =>
		{
			const headers = {
				headers: {
				Accept: "application/json",
				Authorization: "Bearer " + token,
				},
			};
			axios.get(url + local_url, headers, { validateStatus: status => { return status >= 200 && status < 300; } } ).then( resolve ).catch( reject );

		})
		.catch( reject );	
	});
};

/*===============================================================
                Settings Put API
==================================================================*/

// notify(`Error : ${err?.response?.status} ${err?.response?.data}`);

export const Settings_Put_API = async (local_url, data) => 
{
	return new Promise( ( resolve, reject ) =>
	{
		getAuthToken().then( token =>
		{
			const headers = {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
			};
			axios.put(url + local_url, data, headers, { validateStatus: status => { return status >= 200 && status < 300; } } ).then( resolve ).catch( reject );
		})
		.catch( reject );	
	});
};

