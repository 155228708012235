/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

const AddInputReport = ({ setInput }) => {

    const [inputlabel, setInputlabel] = useState('')
    const [inputvalue, setInputvalue] = useState('')
    const [inputtype, setInputtype] = useState('')

    // var Types = ['string', 'number', 'date', 'department']
    var Types = ['string', 'number', 'date', 'department', 'position', 'company', 'address', 'workstation', 'employee', 'sender']


    useEffect(() => {
        setInputtype(Types[0])
    }, [])

    const setInputData = () => {
        setInput({
            inputlabel, inputvalue, inputtype
        })
        document.getElementById('addreportinputmodal').click()
    }

    const resetForm = () => {
        setInputlabel('')
        setInputvalue('')
        setInputtype(Types[0])
    }

    return (
        //   <!--Modal Add input report-- >
        <div className="modal fade add_condition" id="add_input_report" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body p-4">
                        <h2 className="heading_1 mb-3">Add input report</h2>
                        <div className="row">
                            <div className="fm_grp col-md-12">
                                <label htmlFor="displaylabel" className="form-label">* Display label</label>
                                <input type="text" className="form-control" id="displaylabel" onInput={(e) => setInputlabel(e?.target?.value)} value={inputlabel} />
                            </div>
                            <div className="fm_grp col-md-12">
                                <label htmlFor="reportvar" className="form-label">* Report variable</label>
                                <input type="text" className="form-control" id="reportvar" onInput={(e) => setInputvalue(e?.target?.value)} value={inputvalue} />
                            </div>
                            <div className="fm_grp col-md-12">
                                <label htmlFor="inputtype" className="form-label">* Input type</label>
                                <select id="inputtype" className="form-select" onInput={(e) => setInputtype(e?.target?.value)} value={inputtype}>
                                    {Types?.map((data, i) => (
                                        <option key={i} value={data}>{data}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="d-flex flex-wrap align-items-center justify-content-end mt-3">
                                <div className="text-end">
                                    <button type="button" className="btn btn-link btn_link_primary" data-bs-dismiss="modal" id='addreportinputmodal' data-bs-toggle="modal" data-bs-target="#add_canned_report" onClick={resetForm}>Cancel</button>
                                    <button type="button" onClick={() => setInputData()} className="btn btn_cstm_primary px-3" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#add_canned_report">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <!--Modal Add input report-- >
    )
}

export default AddInputReport
