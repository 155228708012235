export const filterOriginalData = (requestdata) => 
{
	var options;
	if( requestdata?.requests ) 
	{
		let xlabels = [];
		let requested_data = []
		let fulfilled_data = []
		let denied_data = [];
		let average_data = []

		requestdata?.requests.forEach( (request,i ) =>
		{
			requested_data.push( request.totalRequested )
			fulfilled_data.push( request.totalFulfilled )
			denied_data.push( request.totalDenied )
			average_data.push( request.averageDays )
			xlabels.push( new Date( request.date ).toLocaleString("en-US", {
				month: 'short',
				day: 'numeric',
			  }) )
		});
		options = 
		{
			chart: {
				type: 'column',
			},
			title: {
				text: '',
			},
			xAxis: {
				categories: xlabels
			},
			yAxis: {
				title: {
					text: '',
				},
			},
			plotOptions: {
				column: {
					grouping: true,
					borderWidth: 1,
				},
			},
			series: 
			[
				{
					name: 'Requested',
					data: requested_data,
				}, 
				{
					name: 'Fulfilled',
					data: fulfilled_data,
				}, 
				{
					name: 'Denied',
					data: denied_data,
				}, 
				{
					type: 'line',
					name: 'Average Response (days)',
					data: average_data,
					marker: 
					{
						enabled: true,
					},
					states: 
					{
						hover: 
						{
							lineWidth: 0,
						},
					},
					enableMouseTracking: false,
				}
			],
		}
	} 
	else 
	{
		options = {
			title: {
				text: "",
			},
			series: [
				{
					name: 'Fulfilled',
					data: [],
				}, 
				{
					name: 'Denied',
					data: [],
				}, 
				{
					name: 'Average Response',
					data: [],
				}, 
			],
			lang: {
				noData: "No data found",
			},
			noData: {
				style: {
				fontWeight: "bold",
				fontSize: "15px",
				},
			},
		};
	}
	return options;
	};
