/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { RouteState } from '../../../../context/routeContext'
import Dashboard from './Dashboard'
import Report from './Report'
import './Report.css'

const Reports = () => {
    const { dashboardRoute, setDashboardRoute, } = RouteState()

    const [activeTab, setActiveTab] = useState('dashboard')

    useEffect(() => {
        setActiveTab(dashboardRoute)
        // console.log(dashboardRoute)
    }, [dashboardRoute])

    return (
        <>
            <div id="content" className="tab-content cst_tab" role="tablist">

                <div id="pane-A" className={`tab-pane fade ${activeTab === 'dashboard' ? 'show active' : 'fade'}`} role="tabpanel" aria-labelledby="tab-A">
                    <Dashboard activeTab={activeTab} />
                </div>
                {/* <!-- End  Accounts-- >/ */}
                {/* < !--Start  Accounts Requested-- > */}
                <div id="pane-B" className={`tab-pane fade ${activeTab === 'report' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-B">
                    <Report activeTab={activeTab} />
                </div>
                {/* <!--End  Accounts Requested-- > */}

            </div>
        </>
    )
}

export default Reports