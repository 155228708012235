/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { getService, postService, putService } from '../../../../../../services/commonApi'
import { getCompanyIdToken } from '../../../../../../services/tokenService'

export default function PositionAdd({ url, type, data, callList }) {
	const [error, setError] = useState('')

	const [submit, setSubmit] = useState(false)

	const [inputName, setInputName] = useState("")
	const [multiple, setMultiple] = useState(false)
	const [protectedUser, setProtected] = useState(false)
	var [inputAltName, setinputAltName] = useState([])
	useEffect(() => {
		if (data) {
			getData()
		}
		// console.log(data)
	}, [data])

	const checkValidate = () => {
		setSubmit(true)
		// console.log(inputName, inputAltName)
		if (inputName) {
			submit_form()
		} else {
			return setError('Please provide position name')
		}
	}


	const getData = () => {
		getService(`${url}/${data?.positionId}`, true).then((res) => {
			if (res?.request?.status === 200) {
				// console.log(res?.data);
				setInputName(res?.data?.companyPosition.label)
				setMultiple(res?.data?.companyPosition.multiple)
				setProtected(res?.data?.companyPosition.protected)
				setinputAltName(getAltNames(res?.data.companyPosition.positionNames).join('\n'))
			}
		})
	}

	const getAltNames = (data) => {
		var namesArray = []
		data.forEach((item, i) => {
			namesArray[i] = item.name
		})
		return namesArray
	}

	const submit_form = () => 
	{
		let Cdata = {
			label: inputName,
			multiple: multiple,
			protected: protectedUser,
			positionNames: inputAltName.includes('\n') ? inputAltName?.split("\n") : inputAltName ? [inputAltName] : []
		}
		type ?
			putService(`${url}/${data?.positionId}`, Cdata, true).then((res) => 
			{
				setSubmit(false)
				callList()
				setError('')
				document.getElementById('closePositionModal').click()
			})
			.catch( err =>
			{
				return setError(err?.response?.data?.error?.desc)
			})
			:
			postService(url, Cdata, true).then((res) => 
			{
				setSubmit(false)
				callList()
				setError('')
				document.getElementById('closePositionModal').click()
			})
			.catch( err =>
			{
				return setError(err?.response?.data?.error?.desc)
			})
		
		setError('')
	}


	const resetForm = () => {
		setInputName('')
		setMultiple(false)
		setProtected(false)
		setinputAltName([])
		setError('')
		setSubmit(false)
	}


	return (
		<>
			<div className="modal fade adddepartment" id="add_position" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-body py-4 px-lg-0">
							<h2 className="heading_1 ps-lg-4 text-capitalize">{type} Position</h2>
							{error &&
								<div className="alert alert-danger alert_danger ps-lg-5" role="alert">
									<span className="me-3">
										<img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" />
										{error}
									</span>
								</div>
							}
							<div className="p-lg-5">
								<div className="fm_grp">
									<label htmlFor="inputAddress" className="form-label" required>Edit Position name:</label>
									<input type="text" className={`form-control ${submit && !inputName ? 'error_input' : ''}`} onChange={(e) => setInputName(e.target.value)} value={inputName} id="inputAddress" />
								</div>
								<div className="fm_grp">
									<label htmlFor="inputAlt" className="form-label" required>Alternate names for the position (1 per line). Include alternate spellings, abbreviations, etc.</label>
									<textarea className={`form-control ${submit && !inputAltName ? 'error_input' : ''}`} onChange={(e) => setinputAltName(e.target.value)} id="inputAlt" rows={6} value={inputAltName} />
								</div>
								<div className="fm_grp">
									<div className="mb-3 form-check">
										<input type="checkbox" className="form-check-input" id="protectedUser" onChange={() => setProtected(!protectedUser)} checked={!protectedUser} />
										<label className="form-check-label ms-2" htmlFor="protectedUser">Position can be self-assign</label>
									</div>
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="multipleUser" onChange={() => setMultiple(!multiple)} checked={multiple} />
										<label className="form-check-label ms-2" htmlFor="multipleUser" >Position can be used by multiple employees</label>
									</div>
								</div>
								<div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
									<div> </div>
									<div className=" text-end">
										<button type="button" className="btn btn-link  btn_link_primary me-3" id="closePositionModal" data-bs-dismiss="modal" onClick={() => resetForm()}>Cancel</button>
										<button type="button" onClick={checkValidate} className="btn btn_cstm_primary px-4">{type ? 'Edit' : 'Save'} </button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	)
}
