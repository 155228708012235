import React, { useRef, useState } from 'react'
import { useEffect } from 'react';

const HelpCanvas = ({ url }) => 
{
	const [helpUrl,setHelpUrl] = useState()
	const objectRef = useRef();

	useEffect( () =>
	{
		setHelpUrl(url)
	}, [url] )

	return (
		<div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" >
			<div className="offcanvas-header" >
				<button type="button" id="helpcanvas_close" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
			</div >
			<div className="offcanvas-body" >
				{ url && 
					<object data={helpUrl} style={{ height: '100%', width: '100%' }} id="offcanvasRightContent" ref={objectRef} >Help Topics</object>
				}
			</div >
		</div >
	)
}

export default HelpCanvas