// Set the url as the being from the root of the current location
// or if an override is found within the 'runConfig' properties
// for the api URL, then use that.
let defaulturl = "";
let { apiUrl } = window['runConfig'];
let msal = window['msalConfig'];
let msalConfig =
{
	auth: 
	{
		clientId: "",
		authority: "", 
		redirectUri: ""
	}
};

// check to see if the MSAL config has been overriden.
if( typeof msal !== 'undefined' && msal?.auth?.clientId !== '')
{
	msalConfig = msal;
}

// check to see if there is a URL to override the default one.
if( typeof apiUrl !== 'undefined' && apiUrl !== "" )
{
	defaulturl = apiUrl;
}

let signOn =
{
	authentication: "Mercury",
	isSSO : false,
	accounts : null,
	instance: null
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
let loginRequest =
{
	 scopes: ["User.Read"]
 };
 
 // Add the endpoints here for Microsoft Graph API services you'd like to use.
let graphConfig =
{
	graphMeEndpoint: "https://graph.microsoft.com" // "Enter_the_Graph_Endpoint_Here/v1.0/me"
};

export { defaulturl as url, signOn, msalConfig, loginRequest, graphConfig };
