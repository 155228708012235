import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';

const AddressListItem = ({ data }) => {
    console.log(data)
    return (
        <>
			<div className='search_results_item' id='address_search_results'>
				<span className="d-flex">
					<p><b>Company Addresses</b></p>
				</span>
				{data?.map((ele, i) => (
					<Dropdown.Item key={i} className="help_box" as={'div'}  >
						<span className="d-flex" >
							{ele.address1 && <p><small>{ele.address1}, {ele.address2? ele.address2+',' : '' } {ele.city} {ele.state} {ele.zipcode}</small></p>}
						</span>
					</Dropdown.Item>
				))}
			</div>
        </>
    )
}

export default AddressListItem