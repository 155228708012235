/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Settings_Get_API, Settings_Put_API } from '../../../../services/SettingsAPI'

const MailNotification = ({ navLink,setNavLink,error,setError, mailNotificationInput, setMailNotificationInput, allErrorCheck }) => 
{
	const [notificationFrequency, setNotificationFrequency] = useState(mailNotificationInput?.notificationFrequency)

	const [changed, setChanged] = useState(false)
	useEffect(() => 
	{
		if (!mailNotificationInput) 
		{
			Settings_Get_API('/mercury/employee/preferences/notification').then(async (res) => {
				setNotificationFrequency(res?.data?.notification?.notificationFrequency)
			})
		}
	}, [])



	// useEffect(() => {
	//     setNotificationFrequency(mailNotificationInput.notificationFrequency)
	// }, [mailNotificationInput])

	const [submit, setSubmit] = useState(false)

	const setMailNotificationInputData = () => {
		setSubmit(true)
		Settings_Put_API('/mercury/employee/preferences/notification', { notificationFrequency }).catch( (res) => 
		{
			setError(res?.response?.data?.error.info)
		})
		setError('')
		setSubmit(false)
	}

	useEffect(() => {
		setMailNotificationInput({
			notificationFrequency
		});

		const set = setTimeout(() => {
			if (changed) {
				setMailNotificationInputData()
			}
		}, 1500);
		return () => clearTimeout(set)

	}, [notificationFrequency])


	return (
		<div
			className={`tab-pane fade ${navLink === "mailNotification" ? "show active" : ""}`}
			aria-labelledby="v-pills-mail_notification-tab"
		>
			<div className="col-lg-9 offset-lg-1 modalContentItems">
				<h2 className="heading_1">Mail notification</h2>
				<p>Please specify how often you’d like to be notified of your mail.</p>
				<form className="pt-4">
					<div className="row g-3 align-items-center">
						<div className="fm_grp col-md-6">
							<div className="form-check">
								<input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={(e) => { setChanged(true); setNotificationFrequency(e.target.value) }}
									value='D' checked={notificationFrequency === 'D'} />
								<label className="form-check-label ms-2" htmlFor="flexRadioDefault1">
									Daily mail notification
								</label>
							</div>
							<div className="form-check">
								<input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={(e) => { setChanged(true); setNotificationFrequency(e.target.value) }}
									value='M' checked={notificationFrequency === 'M'} />
								<label className="form-check-label ms-2" htmlFor="flexRadioDefault2">
									Notify for each piece of mail
								</label>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
				<div> </div>
				<div className="p-md-4 text-end">
					{/* <button
						type="button"
						className="btn btn-link  btn_link_primary"
						id="closeMailNotifModal"
						data-bs-dismiss="modal" style={{ visibility: 'hidden' }}
					>
						Cancel
					</button> */}
					<button
						type="button"
						className="btn btn_cstm_primary"
						data-bs-dismiss="modal"
						disabled={allErrorCheck}
					>
						Done
					</button>
				</div>
			</div>
		</div>
	)
}

export default MailNotification