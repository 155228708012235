/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { request_account_api } from '../../../../../services/AuthApi'
import { getService, postService, putService } from '../../../../../services/commonApi'

const AccountAddEdit = ({ url, accountTypeId, setAccountTypeId, getAccountTypeList }) => {


    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [basicPrivilege, setBasicPrivilege] = useState(false)
    const [reassignPrivilege, setReassignPrivilege] = useState(false)
    const [assignPrivilege, setAssignPrivilege] = useState(false)
    const [routePrivilege, setRoutePrivilege] = useState(false)
    const [qualityPrivilege, setQualityPrivilege] = useState(false)
    const [accountPrivilege, setAccountPrivilege] = useState(false)
    const [settingsPrivilege, setSettingsPrivilege] = useState(false)
    const [reportPrivilege, setReportPrivilege] = useState(false)
    const [restorePrivilege, setRestorePrivilege] = useState(false)
    const [requestPrivilege, setRequestPrivilege] = useState(false)
    const [logPrivilege, setLogPrivilege] = useState(false)
    const [exchangePrivilege, setExchangePrivilege] = useState(false)

    useEffect(() => {
        if (accountTypeId) {
            getAccountDetails()
        }
    }, [accountTypeId])

    const getAccountDetails = () => {
        getService(url + '/' + accountTypeId, true).then(
            (res) => {
                if (res.status === 200) {
                    var AccountDetails = res?.data?.accountType
                    setName(AccountDetails?.description)
                    setDescription(AccountDetails?.description)

                    setBasicPrivilege(AccountDetails?.basicPrivilege)
                    setReassignPrivilege(AccountDetails?.reassignPrivilege)
                    setAssignPrivilege(AccountDetails?.assignPrivilege)
                    setRoutePrivilege(AccountDetails?.routePrivilege)
                    setQualityPrivilege(AccountDetails?.qualityPrivilege)
                    setAccountPrivilege(AccountDetails?.accountPrivilege)
                    setSettingsPrivilege(AccountDetails?.settingsPrivilege)
                    setReportPrivilege(AccountDetails?.reportPrivilege)
                    setRestorePrivilege(AccountDetails?.restorePrivilege)
                    setRequestPrivilege(AccountDetails?.requestPrivilege)
                    setLogPrivilege(AccountDetails?.logPrivilege)
                    setExchangePrivilege(AccountDetails?.exchangePrivilege)
                }
            }
        )
    }


    const postAccountType = () => {
        accountTypeId ?
            putService(url + '/' + accountTypeId, {
                name, description, basicPrivilege, reassignPrivilege, assignPrivilege, routePrivilege, qualityPrivilege, accountPrivilege, settingsPrivilege, reportPrivilege, restorePrivilege, requestPrivilege, logPrivilege, exchangePrivilege
            }, true).then(
                (res) => {
                    if (res?.status === 200) {
                        document.getElementById('closeAccountTypeModal').click()
                        getAccountTypeList()
                    } else {

                    }
                }
            )
            :
            postService(url, {
                name, description, basicPrivilege, reassignPrivilege, assignPrivilege, routePrivilege, qualityPrivilege, accountPrivilege, settingsPrivilege, reportPrivilege, restorePrivilege, requestPrivilege, logPrivilege, exchangePrivilege
            }, true).then(
                (res) => {
                    if (res?.status === 200) {
                        document.getElementById('closeAccountTypeModal').click()
                        getAccountTypeList()
                    } else {

                    }
                }
            )

    }
    const resetForm = () => {
        setAccountTypeId('');
        setName('');
        setDescription('');
        setBasicPrivilege(false)
        setReassignPrivilege(false)
        setAssignPrivilege(false)
        setRoutePrivilege(false)
        setQualityPrivilege(false)
        setAccountPrivilege(false)
        setSettingsPrivilege(false)
        setReportPrivilege(false)
        setRestorePrivilege(false)
        setRequestPrivilege(false)
        setLogPrivilege(false)
        setExchangePrivilege(false)
    }

    return (
        <div className="modal fade edit_account_types" id="edit_account_types" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered  modal-lg">
                <div className="modal-content">
                    <div className="modal-body p-4">
                        <h2 className="heading_1 mb-3">{accountTypeId ? 'Edit' : 'Add'} account types</h2>

                        <div className="row">
                            <div className="col-lg-5">
                                {/* <div  className="fm_grp">
                                    <label htmlFor="name"  className="form-label">Name</label>
                                    <input type="text"  className="form-control" id="name" onInput={(e) => { setName(e.target.value) }} value={name} />
                                </div> */}
                                <div className="fm_grp">
                                    <label htmlFor="descr" className="form-label">Description</label>
                                    <input type="text" className="form-control" id="descr" onInput={(e) => { setDescription(e.target.value) }} value={description} />
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1">
                                <p className="fnt_14">Permissions</p>
                                <div className="permissions">
                                    <div className="fm_grp">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1"
                                                onChange={() => setBasicPrivilege(!basicPrivilege)}
                                                checked={basicPrivilege} />
                                            <label className="form-check-label ms-2" htmlFor="exampleCheck1">Access mail</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck2"
                                                onChange={() => setAssignPrivilege(!assignPrivilege)}
                                                checked={assignPrivilege} />
                                            <label className="form-check-label ms-2" htmlFor="exampleCheck2">Digitally assign mail</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck3"
                                                onChange={() => setReassignPrivilege(!reassignPrivilege)}
                                                checked={reassignPrivilege} />
                                            <label className="form-check-label ms-2" htmlFor="exampleCheck3">Reassign mail</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck4"
                                                onChange={() => setQualityPrivilege(!qualityPrivilege)}
                                                checked={qualityPrivilege} />
                                            <label className="form-check-label ms-2" htmlFor="exampleCheck4">Perform quality control</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck5"
                                                onChange={() => setAccountPrivilege(!accountPrivilege)}
                                                checked={accountPrivilege} />
                                            <label className="form-check-label ms-2" htmlFor="exampleCheck5">Modify accounts</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck6"
                                                onChange={() => setSettingsPrivilege(!settingsPrivilege)}
                                                checked={settingsPrivilege} />
                                            <label className="form-check-label ms-2" htmlFor="exampleCheck6">Change system settings</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck7"
                                                onChange={() => setReportPrivilege(!reportPrivilege)}
                                                checked={reportPrivilege} />
                                            <label className="form-check-label ms-2" htmlFor="exampleCheck7">Generate reports</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck8"
                                                onChange={() => setRequestPrivilege(!requestPrivilege)}
                                                checked={requestPrivilege} />
                                            <label className="form-check-label ms-2" htmlFor="exampleCheck8">Manage requests</label>
                                        </div>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck9"
                                                onChange={() => setLogPrivilege(!logPrivilege)}
                                                checked={logPrivilege} />
                                            <label className="form-check-label ms-2" htmlFor="exampleCheck9">View logs</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-end mt-5 pt-lg-5">
                            <div className="text-end">
                                <button type="button" className="btn btn-link btn_link_primary" data-bs-dismiss="modal" id="closeAccountTypeModal" onClick={() => resetForm()}>Cancel</button>
                                <button type="button" className="btn btn_cstm_primary px-3" onClick={() => { postAccountType() }} >Save</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountAddEdit