import React, { useState } from 'react'
import { postService } from '../../../../../../services/commonApi'

function SenderAdd({ senderUrl, getSenderData }) {

    const [error, setError] = useState(false)
    const [submit, setSubmit] = useState(false)

    const [label, setInputName] = useState('')
    const [address1, setInputAddress1] = useState('')
    const [address2, setaddress2] = useState('')
    const [city, setcity] = useState('')
    const [state, setstate] = useState('AL')
    const [zipcode, setzipcode] = useState('')

    // var stateList = ['AL', 'AK', 'CA', 'FL', 'HI', 'NJ', 'NY', 'TX', 'WA']

    var stateList = {
        'US': [
            {
                "name": "Alabama",
                "code": "AL"
            },
            {
                "name": "Alaska",
                "code": "AK"
            },
            {
                "name": "Arizona",
                "code": "AZ"
            },
            {
                "name": "Arkansas",
                "code": "AR"
            },
            {
                "name": "California",
                "code": "CA"
            },
            {
                "name": "Colorado",
                "code": "CO"
            },
            {
                "name": "Connecticut",
                "code": "CT"
            },
            {
                "name": "Delaware",
                "code": "DE"
            },
            {
                "name": "Florida",
                "code": "FL"
            },
            {
                "name": "Georgia",
                "code": "GA"
            },
            {
                "name": "Hawaii",
                "code": "HI"
            },
            {
                "name": "Idaho",
                "code": "ID"
            },
            {
                "name": "Illinois",
                "code": "IL"
            },
            {
                "name": "Indiana",
                "code": "IN"
            },
            {
                "name": "Iowa",
                "code": "IA"
            },
            {
                "name": "Kansas",
                "code": "KS"
            },
            {
                "name": "Kentucky",
                "code": "KY"
            },
            {
                "name": "Louisiana",
                "code": "LA"
            },
            {
                "name": "Maine",
                "code": "ME"
            },
            {
                "name": "Maryland",
                "code": "MD"
            },
            {
                "name": "Massachusetts",
                "code": "MA"
            },
            {
                "name": "Michigan",
                "code": "MI"
            },
            {
                "name": "Minnesota",
                "code": "MN"
            },
            {
                "name": "Mississippi",
                "code": "MS"
            },
            {
                "name": "Missouri",
                "code": "MO"
            },
            {
                "name": "Montana",
                "code": "MT"
            },
            {
                "name": "Nebraska",
                "code": "NE"
            },
            {
                "name": "Nevada",
                "code": "NV"
            },
            {
                "name": "New Hampshire",
                "code": "NH"
            },
            {
                "name": "New Jersey",
                "code": "NJ"
            },
            {
                "name": "New Mexico",
                "code": "NM"
            },
            {
                "name": "New York",
                "code": "NY"
            },
            {
                "name": "North Carolina",
                "code": "NC"
            },
            {
                "name": "North Dakota",
                "code": "ND"
            },
            {
                "name": "Ohio",
                "code": "OH"
            },
            {
                "name": "Oklahoma",
                "code": "OK"
            },
            {
                "name": "Oregon",
                "code": "OR"
            },
            {
                "name": "Pennsylvania",
                "code": "PA"
            },
            {
                "name": "Rhode Island",
                "code": "RI"
            },
            {
                "name": "South Carolina",
                "code": "SC"
            },
            {
                "name": "South Dakota",
                "code": "SD"
            },
            {
                "name": "Tennessee",
                "code": "TN"
            },
            {
                "name": "Texas",
                "code": "TX"
            },
            {
                "name": "Utah",
                "code": "UT"
            },
            {
                "name": "Vermont",
                "code": "VT"
            },
            {
                "name": "Virginia",
                "code": "VA"
            },
            {
                "name": "Washington",
                "code": "WA"
            },
            {
                "name": "West Virginia",
                "code": "WV"
            },
            {
                "name": "Wisconsin",
                "code": "WI"
            },
            {
                "name": "Wyoming",
                "code": "WY"
            }
        ],
        'CA': [
            {
                "name": "Alberta",
                "code": "AB"
            },
            {
                "name": "British Columbia",
                "code": "BC"
            },
            {
                "name": "Manitoba",
                "code": "MB"
            },
            {
                "name": "New Brunswick",
                "code": "NB"
            },
            {
                "name": "Newfoundland and Labrador",
                "code": "NL"
            },
            {
                "name": "Northwest Territories",
                "code": "NT"
            },
            {
                "name": "Nova Scotia",
                "code": "NS"
            },
            {
                "name": "Nunavut",
                "code": "NU"
            },
            {
                "name": "Ontario",
                "code": "ON"
            },
            {
                "name": "Prince Edward Island",
                "code": "PE"
            },
            {
                "name": "Quebec",
                "code": "QC"
            },
            {
                "name": "Saskatchewan",
                "code": "SK"
            },
            {
                "name": "Yukon",
                "code": "YT"
            }
        ]
    }

    const addSender = () => {
        setSubmit(true)

        if (!address1 || !city || !state || !zipcode || !label) {
            if (address1 && city && state && zipcode && label) {
                return setError("Address is required field");
            } else if (!city && address1 && state && zipcode && label) {
                return setError("City is required field");
            } else if (!state && address1 && city && zipcode && label) {
                return setError("State is required field");
            } else if (!zipcode && address1 && state && city && label) {
                return setError("Zip Code is required field");
            } else if (!label && address1 && state && zipcode && city) {
                return setError("Name is required field");
            }
            return setError("Required fields are missing !");
        }

        postService(senderUrl, { address1, address2, city, state, zipcode, label }, true)
            .then(
                (res) => {
                    // console.log('res', res)
                    if (res?.request?.status === 200) {
                        document.getElementById('closesenderadd').click()
                        getSenderData()
                    }
                    else {
                        return setError(res?.request.statusText)
                    }
                }, err => { return setError(err?.request.statusText) });

        setError('')


    }

    return (
        <div className="modal fade add_sender" id="add_sender" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body p-4">
                        <h2 className="heading_1 mb-3">Add sender</h2>
                        {error && <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                            <span className="me-3"><img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" /></span>{error}
                        </div>}
                        <div className="row">
                            <div className="fm_grp col-md-12">
                                <label htmlFor="name" className="form-label">Sender’s full name</label>
                                <input type="text" onChange={(e) => setInputName(e.target.value)} value={label} className={`form-control ${submit && !label ? 'error_input' : ''}`} id="name" />
                            </div>
                            <div className="fm_grp col-md-12">
                                <label htmlFor="Address" className="form-label">Address line 1</label>
                                <input type="text" onChange={(e) => setInputAddress1(e.target.value)} value={address1} className={`form-control ${submit && !address1 ? 'error_input' : ''}`} id="Address" />
                            </div>
                            <div className="fm_grp col-md-12">
                                <label htmlFor="Address" className="form-label">Address line 2</label>
                                <input type="text" onInput={(e) => setaddress2(e.target.value)} className="form-control" value={address2} id="Address" />
                            </div>
                            <div className="fm_grp col-md-12">
                                <label htmlFor="City" className="form-label">City</label>
                                <input type="text" className={`form-control ${error && !city ? 'error_input' : ''}`} id="City" onInput={(e) => setcity(e.target.value)} value={city} />
                            </div>
                            <div className="fm_grp col-md-8">
                                <label htmlFor="inputState" className="form-label">State</label>
                                <select id="St"
                                    onInput={(e) => setstate(e.target.value)} value={state} className={`form-control ${submit && !state ? 'error_input' : ''}`}>
                                    {stateList?.US?.map((data, i) => (<option key={i} value={data?.code}>{data?.name}</option>))}
                                </select>
                            </div>
                            <div className="fm_grp col-md-4">
                                <label htmlFor="inputzipcode" className="form-label">Zip Code</label>
                                <input type="text" className={`form-control ${error && !zipcode ? 'error_input' : ''}`} id="inputzipcode" onInput={(e) => setzipcode(e.target.value)} />
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-between mt-3">
                                <div> </div>
                                <div className=" text-end">
                                    <button type="button" className="btn btn-link  btn_link_primary me-3" id='closesenderadd' data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" onClick={() => addSender()} className="btn btn_cstm_primary px-4">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SenderAdd