/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { getFirstName } from '../../../../../../pipes/stringPipes';
import { deleteService, getService } from '../../../../../../services/commonApi';
import Select from 'react-select';


const AccountDelete = ({ userID, setuserID, addressUrl, deleteName, getAccountList }) => 
{
	const [employeeSearchList, setEmployeeSearchList] = useState([])
	const [employeeSearchResult, setemployeeSearchResult] = useState()
	const [replacementId, setReplacementID] = useState()
	const [notify, setNotify] = useState(true)
	const [departmentInput, setDepartmentInput] = useState([])
	const [error, setError] = useState(false)
	const [searchKey, setSearchKey] = useState("")
	const [timer, setTimer] = useState(null);

	useEffect(() => 
	{
		if( userID ) 
		{
			getDepartment()
		}
	}, [userID])

	/**
	 * Get the list of any departments the deleted employee is the POC for.
	 */
	const getDepartment = () => 
	{
		getService(`${addressUrl}/${userID}/company/department`, true).then( (res) => 
		{
			if (res?.status === 200) 
			{
				let departments = []
				res?.data?.contactsFor?.forEach((element, i) => 
				{
					const obj = 
					({
						name: element?.name,				// contains the department name for display
						key: element?.departmentId,		// contains the department Id for replacement
						pocReplacement: null,				// contains the employee details for display
						employeeSearchList: null
					})
					departments[i] = obj
				});
				setDepartmentInput( departments );
			}
		})
	}

	const searchEmployee = (name) => 
	{
		getService(`/mercury/employee/list?filter=${name}`, true).then( (res) => 
		{
			if (res?.status === 200) 
			{
				// set the search results but exclude this employee from the list.
				let employees = res.data?.employees?.reduce( ( list, item ) => 
				{
					if( userID != item?.employee?.employeeId )
					{
						list.push( item ) 
					}
					return list
				}, [] ) 
				setEmployeeSearchList( employees )
			}
		})
	}
	
	const searchDeptEmployee = ( searchterm, index ) => 
	{
		getService(`/mercury/employee/list?filter=${searchterm}`, true).then( (res) => 
		{
			if (res?.status === 200) 
			{
				let data = [...departmentInput]
				// exclude this employee from any search results.
				let list = res.data?.employees?.reduce( ( list, item ) => 
				{
					if( userID != item?.employee?.employeeId )
					{
						list.push( item ) 
					}
					return list 
				}, [])
				data[index].employeeSearchList = list
				setDepartmentInput( data )
			}
		})
	}
	
	/**
	 * Update the deparment poc list with the new employeeid to be poc for that dept
	 * @param {*} index - index into the departmentInput array
	 * @param {*} event - the event that contains value with new employee id
	 * @param {*} repacement - the search result list item with employee details that is the replacement poc for the dept
	 */
	const handleDepartmentPOCChange = ( replacement , index) => 
	{
		let data = [...departmentInput]
		data[index].pocReplacement = replacement
		data[index].employeeSearchList = null
		setDepartmentInput( data )
	}

	const deleteAccount = () => 
	{
		let err = false;
		// structure for all of the replacement department values
		var departmentData = {}
		// check for an error on the replacement id
		err = !replacementId;
		// verify that all departments have a replacement.
		departmentInput?.forEach((data, i) => 
		{
			if (data.pocReplacement !== null) 
			{
				departmentData[data.key] = data.pocReplacement?.employee?.employeeId
			}
			else
			{
				// indicate a value is missing.
				err = true;
			}
		})
		if( !err )
		{
			setError( '' )
			deleteService(`${addressUrl}/${userID}`, '', true, 
			{
				params: { replacementId, notify, ...departmentData }
			}).then((res) => 
			{
				if (res?.status === 200) 
				{
					getAccountList()
					document.getElementById('closeDeleteModal').click()
				} 
				else 
				{
					return setError(res?.data?.error?.info)
				}
			})
		}
		else
		{
			setError("Please select an employee for each field.")
		}
	}

	useEffect(() => 
	{
		// if previous timers are waiting remove them.
		if( timer ) 
		{
			clearTimeout(timer);
		}
		const search = setTimeout(() => 
		{
			if (searchKey) 
			{
				searchEmployee(searchKey)
			} 
			else 
			{
				setEmployeeSearchList([])
			}
		}, 1500);
		setTimer( search )
		return () => clearTimeout(search)
	}, [searchKey])

	const setDepartmentSearchKey = ( searchTerm, index ) =>
	{
		if( timer ) 
		{
			clearTimeout(timer);
		}
		const search = setTimeout(() => 
		{
			if( searchTerm ) 
			{
				searchDeptEmployee(searchTerm,index)
			} 
			else 
			{
				let data = [...departmentInput]
				data[index].employeeSearchList = null
				setDepartmentInput( data )
			}
		}, 1500);
		setTimer( search )
	}

	const resetFormData = () => 
	{
		setSearchKey('')
		setError('')
		setuserID('')
		setReplacementID('')
		setemployeeSearchResult()
		setEmployeeSearchList([])
		setDepartmentInput([])
	}

    return (
        <div className="modal fade Delete_replace" id="Delete_replace" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content  limit_model_body">
                    <div className="modal-body py-4">
                        <h2 className="heading_1 ps-4">Delete & replace</h2>
                        {error && <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                            <span className="me-3"><img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" /></span>{error}
                        </div>}
                        <div>
                            <div className="col-xl-9 m-auto py-4">
                                <h3 className="heading_2 mb-4">Delete Account “{deleteName}”</h3>

                                <div className="fm_grp">
									<label htmlFor="MAIL" className="form-label">Forward {getFirstName(deleteName, 1)}’s mail to:</label>

									{employeeSearchResult &&
										(
											<div className="d-flex ForwardSelectedBox">
												<div>
													{/* <p>{employeeSearchResult.fullName}</p> */}
													<p>{employeeSearchResult?.employee?.employeeName?.fullName}</p>
													<p>{employeeSearchResult?.employee?.employeeName?.suffix}</p>
													<p>{employeeSearchResult?.employee?.employeeName?.nickNames}</p>
													<p>{employeeSearchResult?.employee?.companyDetails?.address?.address1}</p>
													<p>{employeeSearchResult?.employee?.companyDetails?.department}</p>
													<p>{employeeSearchResult?.employee?.contactDetails?.emailAddress1}{employeeSearchResult?.contactDetails?.emailAddress2 ? ',' + employeeSearchResult?.contactDetails?.emailAddress2 : ''}</p>
												</div>
												<div>
													<span className='px-2'> <img src="assets/img/Delete.svg" alt="" onClick={() => { setemployeeSearchResult(null); setReplacementID(''); setEmployeeSearchList([]) }} /> </span>
												</div>
											</div>
										)
									}
									{!employeeSearchResult &&
										(
											<div className="serach_box" id="dropdownMenuButton2" data-bs-toggle="dropdown">
												<input type="text" aria-label="Search" placeholder="Search employees" onChange={(e) => { setSearchKey(e.target.value) }} className={`form-control ${error && !setReplacementID ? "error_input" : ""}`} style={{ 'borderColor': error ? 'red' : '' }} defaultValue={replacementId} />
												<a href="#"><span className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
											</div>
										)
									}
									{!employeeSearchResult && <ul className={`dropdown bg-light dropdown-menu search_dropdown employee_search_dropdown ${employeeSearchList?.length ? 'show' : ''}`} aria-labelledby="dropdownMenuButton2">
										{employeeSearchList?.length < 1 ? (
											<li>
												<a className="dropdown-item" href="#">
													<p>No Matches Found</p>
												</a>
											</li>) : (
											employeeSearchList?.map((data, i) => {
												return (
													<li key={i} onClick={() => { setReplacementID(data?.employee?.employeeId); setemployeeSearchResult(data) }}>
														<a className="dropdown-item" href="#">
															<p>{data?.employee?.employeeName?.fullName}</p>
															<p>{data?.employee?.employeeName?.suffix}</p>
															<p>{data?.employee?.employeeName?.nickNames}</p>
															<p>{data?.employee?.companyDetails?.address?.address1}</p>
															<p>{data?.employee?.companyDetails?.department}</p>
															<p>{data?.employee?.contactDetails?.emailAddress1}{data?.contactDetails?.emailAddress2 ? ',' + data?.contactDetails?.emailAddress2 : ''}</p>
														</a>
													</li>
												)
											})
										)}
										</ul>
									}

								</div>

								{ /** display a search for an employee that will be the new point of contact for the department the employee was for. */}
								{ departmentInput?.map((data, i) => 
								(
									<div className="fm_grp" key={i}>
										<label htmlFor={i} className="form-label">Forward {data.name} department mail to:</label>

										{ data?.pocReplacement &&
											(
												<div className="d-flex ForwardSelectedBox">
													<div>
														<p>{data?.pocReplacement?.employee?.employeeName?.fullName}</p>
														<p>{data?.pocReplacement?.employee?.employeeName?.suffix}</p>
														<p>{data?.pocReplacement?.employee?.employeeName?.nickNames}</p>
														<p>{data?.pocReplacement?.employee?.companyDetails?.address?.address1}</p>
														<p>{data?.pocReplacement?.employee?.companyDetails?.department}</p>
														<p>{data?.pocReplacement?.employee?.contactDetails?.emailAddress1}{data?.pocReplacement?.contactDetails?.emailAddress2 ? ',' + data?.pocReplacement?.contactDetails?.emailAddress2 : ''}</p>
													</div>
													<div>
														<span className='px-2'> <img src="assets/img/Delete.svg" alt="" onClick={() => { handleDepartmentPOCChange( null, i ) }} /></span>
													</div>
												</div>
											)
										}
										{!data?.pocReplacement &&
											(
												<div className="serach_box" id="dropdownMenuButton2" data-bs-toggle="dropdown">
													<input type="text" aria-label="Search" placeholder="Search employees" 
															className={`form-control ${error && !data?.pocReplacement?.employee?.employeeId ? "error_input" : ""}`} 
															style={{ 'borderColor': error ? 'red' : '' }} 
															onChange={(e) => { setDepartmentSearchKey( e.target.value, i ) }} />
													<a href="#"><span className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
												</div>
											)
										}
										{!data?.pocReplacement && 
											<ul className={`dropdown bg-light dropdown-menu search_dropdown employee_search_dropdown ${data?.employeeSearchList ? 'show' : ''}`} 
													aria-labelledby="dropdownMenuButton2">
											{data?.employeeSearchList?.length < 1 ? 
											(
												<li>
													<a className="dropdown-item" href="#">
														<p>No Matches Found</p>
													</a>
												</li>) : (
												data?.employeeSearchList?.map((searchitem, list_i) => 
												{ 
													return (
														<li key={list_i} onClick={() => 
																{ 
																	handleDepartmentPOCChange( searchitem, i )
																}}>
															<a className="dropdown-item" href="#">
																<p>{searchitem?.employee?.employeeName?.fullName}</p>
																<p>{searchitem?.employee?.employeeName?.suffix}</p>
																<p>{searchitem?.employee?.employeeName?.nickNames}</p>
																<p>{searchitem?.employee?.companyDetails?.address?.address1}</p>
																<p>{searchitem?.employee?.companyDetails?.department}</p>
																<p>
																	{searchitem?.employee?.contactDetails?.emailAddress1}
																	{searchitem?.contactDetails?.emailAddress2 ? ',' + searchitem?.contactDetails?.emailAddress2 : ''}
																</p>
															</a>
														</li>
													)
												})
											)}
											</ul>
										}
										
								{/* <input id={i}  className="form-control" type="text" aria-label="Search" placeholder="Search accounts" />


										
                                        <div className="serach_box">
                                            <Select
                                                id={i}
                                                onInputChange={(e) => searchEmployee(e)}
                                                onChange={(e) => { handleDepartmentPOCChange(i, e) }}
                                                isClearable={true}
                                                isSearchable={true}
                                                name={data?.departmentId}
                                                options={employeeSearchList?.map((item) => (
                                                    { 'value': item?.employeeId, 'label': item?.fullName }
                                                ))}
                                            />
                                        </div>


									<a href="#"><span  className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a> */}


                                    </div>
                                ))
                                }

                            </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 px-3">
                            <div> </div>
                            <div className=" text-end">
                                <button type="button" className="btn btn-link  btn_link_primary" data-bs-dismiss="modal" id="closeDeleteModal" onClick={() => resetFormData()}>Cancel</button>
                                <button type="submit" className="btn btn_cstm_primary" onClick={() => deleteAccount()}>Delete & forward</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AccountDelete