/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// npm install validator@13.7.0
import validator from 'validator'

const AccountInfo = ({
	navLink,
	setNavLink,
	error,
	setError,
	accountInput,
	setAccountInput,
	signup
}) => {

	const [username, setUsername] = useState(accountInput ? accountInput.username : '')
	const [password, setPassword] = useState('')
	const [challenge, setChallenge] = useState('How do you spell relief?')
	const [secretphrase, setSecretphrase] = useState('relief')

  	const [notificationMode, setNotificationMode] = useState('M')

  	const [submit, setSubmit] = useState(false)

  	useEffect(() => 
	{
    	setAccountInput({ username, password, notificationMode, challenge, secretphrase }) 
	}, [username, password, notificationMode, challenge, secretphrase] )

  	const validatePassword = ( value ) => 
	{
		setPassword(value);
		if(  value.length === 0 || ( value.length > 0 && validator.isStrongPassword( value, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 })) )
		{
			setError('');
		}
		else if( value.length > 0 )
		{
			setError('Your password must be at least 8 characters long, contain at least one number, one symbol and have a mixture of uppercase and lowercase letters.');
		}
  	}

	const setAccountInputData = () => 
	{
		setSubmit(true);
		setAccountInput( { username, password, notificationMode, challenge, secretphrase } );
    	if (!username || !password || !secretphrase || !challenge) 
		{
      		if ((!secretphrase || !challenge) && password && username) 
			{
        		return setError("Please complete your recovery challenge ");
      		} 
			else if (!password && (secretphrase && challenge) && !username) 
			{
        		return setError("A password is required");
      		}
      		else if (!username && (secretphrase && challenge) && password) 
			{
				return setError("Username is required");
      		}
      		return setError("Required fields are missing.");
    	}
		else if( !validator.isStrongPassword( password, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 }) ) 
		{
			setError('Your password must be at least 8 characters long, contain at least one number, one symbol< and have a mixture of uppercase and lowercase letters.');
		}
		else
		{
			signup({ username, password, notificationMode, challenge, secretphrase });
			setError('');
		} 		
	}

  return (
    <div
      className={`tab-pane fade ${navLink === "account" ? "show active" : ""}`}
      aria-labelledby="v-pills-settings-tab"
    >
      <div className="col-lg-9 offset-lg-1 modalContentItems">
        <h2 className="heading_1">Let’s create your account</h2>
        <p className="mb-0">
          In order to create your account, we will need certain information from
          you. Please provide all the following information so that we can
          process your request.
        </p>
        <p className="fnt_w_bold">Please be as thorough as possible.</p>
        <form className=" pt-4">
          <div className="row g-3">

            <div className="fm_grp col-md-12 ">
              <div>
                <label htmlFor="recoveryPhrase" className={`form-label ${error && !challenge ? 'error_text' : ''}`}>* Recovery Phrase</label>
                <input type="text" className={`form-control ${error && !challenge ? 'error_input' : ''}`} id="recoveryPhrase" onInput={(e) => setChallenge(e.target.value)} value={challenge} />
              </div>
            </div>

            <div className="fm_grp col-md-12 mb-3">
              <div>
                <label htmlFor="recoveryAnswer" className={`form-label ${error && !secretphrase ? 'error_text' : ''}`}>* Recovery Answer</label>
                <input type="text" className={`form-control ${error && !secretphrase ? 'error_input' : ''}`} id="recoveryAnswer" onInput={(e) => setSecretphrase(e.target.value)} value={secretphrase} />
              </div>
            </div>

            <div className="fm_grp col-md-6">
              <label htmlFor="userName" className={`form-label ${error && !username ? 'error_text' : ''}`}>
                * Username {error && !username && <span>is required</span>}
              </label>
              <input type="text" className={`form-control ${error && !username ? 'error_input' : ''}`} id="userName"
                	onInput={(e) => setUsername(e.target.value)} value={username} />
            </div>

            <div className="fm_grp col-md-6">
              <div>
                <label htmlFor="passw" className={`form-label ${error && !password ? 'error_text' : ''}`}>
                  * Password {error && !password && <span>is required</span>}
                </label>
                <input id='passw' type="password" className={`form-control ${error && !password ? 'error_input' : ''}`} 
						onInput={(e) => validatePassword(e.target.value)} value={password} />
              </div>
            </div>
            <div className="fm_grp col-md-6">
              <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                  	onChange={(e) => setNotificationMode(e.target.value)} value='D' defaultChecked={notificationMode === 'D'}/>
                <label className="form-check-label ms-2" htmlFor="flexRadioDefault1" >
                  Contact is notified daily
                </label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={(e) => setNotificationMode(e.target.value)}
                  		value='M' defaultChecked={notificationMode === 'M'} />
                <label className="form-check-label ms-2" htmlFor="flexRadioDefault2">
                  	Contact is notified for each piece
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
        <div> </div>
        <div className="p-md-4 text-end">
          <button type="button" className="btn btn-link  btn_link_primary me-3" data-bs-dismiss="modal" >
            Cancel
          </button>
          <button type="button" className="btn btn_cstm_primary px-4" onClick={() => setAccountInputData()} >
            Submit request
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
