/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { AuthState } from '../../../../context/authContext'
import { getService } from '../../../../services/commonApi'
import { getCompanyIdToken } from '../../../../services/tokenService'
import PositionAdd from './Modals/PositionModal/PositionAdd'
import PositionDelete from './Modals/PositionModal/PositionDelete'

const Position = ({ activeTab }) => {
    const { databaseRoute, setDatabaseRoute } = AuthState()
    const [data, setData] = useState()
    const [type, setType] = useState()
    const [positionList, setPositionList] = useState([])
    const [sortby, setSortby] = useState()
    const [filter, setFilter] = useState()
    var start = 1;
    var stop = 30;
    var primary = true

    var positionUrl = `/mercury/company/${getCompanyIdToken()}/position`

    useEffect(() => {
        if (databaseRoute === 'position') {
            getPositionList()
        }
    }, [databaseRoute])

    const getPositionList = () => {
        // "?start=1&stop=40&primary=true"
        getService(`${positionUrl}/name/list`, true, {
            params: {
                primary, sortby, filter
            }
        }).then((res) => {
            if (res?.status === 200) {
                setPositionList(res?.data?.list)
            }
        })
    }

    useEffect(() => {
        const search = setTimeout(() => {
            if (databaseRoute === 'position') {
                getPositionList()
            }
        }, 1500);
        return () => clearTimeout(search)
    }, [filter, sortby])

    const sortByAlpha = (alpha) => {
        let data = document.querySelectorAll(".label")
        let count = 0
        data.forEach((item, i) => {
            let name = item.innerText
            if (name.charAt(0).toLocaleLowerCase().charCodeAt(0) > (alpha.charCodeAt(0) - 1)) {
                count++
                if (count === 1) {
                    item.scrollIntoView(true)
                }
                return
            }
        })
    }


    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-h" onClick={() => { setDatabaseRoute('position') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-h" aria-expanded="true" aria-controls="collapse-h">
                        Position  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-h" className={`collapse ${databaseRoute === "position" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-d">
                <div className="head d-none d-md-block">
                    <h4 className="heading_1 text_dark mb-0">Position</h4>
                </div>
                <div className="p-4">
                    <p> Please enter all of your company’s Positions that the system will use to receive mail, and keep this list current. If the list is not kept current, Positions for mail may be inaccurate.<a className="text_dark">More…</a></p>
                </div>
                <div className="p-4  pt-0 col-lg-12">
                    <div className="row">
                        <div className="col-lg-11">
                            {/* <!-- Start search--> */}
                            <div className="col-md-5 col-xl-4">
                                <div className="serach_box mb-3">
                                    <input className="form-control" type="text" aria-label="Search" placeholder="Search Position" onInput={(e) => setFilter(e.target.value)} value={filter} />
                                    <a><span className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
                                </div>
                            </div>
                            {/* <!-- Start search --> */}

                            {/* <!-- Start Table --> */}
                            <div className="row">
                                <div className="col-md-8 col-xl-4">
                                    <div className="table-responsive cst_table departments_table">
                                        <table className="table">
                                            <tbody>
                                                {
                                                    positionList.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="label">{item.name}</td>
                                                                <td className="acction_icon">
                                                                    <a onClick={() => { setType("edit"); setData(item) }} data-bs-toggle="modal" data-bs-target="#add_position">
                                                                        <img src="assets/img/Edit.svg" alt="" />
                                                                    </a>
                                                                    <a onClick={() => setData(item)} data-bs-toggle="modal" data-bs-target="#delete_position">
                                                                        <img src="assets/img/Delete.svg" alt="" />
                                                                    </a>
                                                                </td>
                                                            </tr>

                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* Add Alphabet Filter */}
                                <div className="col-md-2 col-xl-1">
                                    <div className="alphabet pt-lg-0 pt-md-3">
                                        <div onClick={() => sortByAlpha("a")}>A - B</div>
                                        <div onClick={() => sortByAlpha("c")}>C - D</div>
                                        <div onClick={() => sortByAlpha("e")}>E - F</div>
                                        <div onClick={() => sortByAlpha("g")}>G - H</div>
                                        <div onClick={() => sortByAlpha("i")}>I - J</div>
                                        <div onClick={() => sortByAlpha("k")}>K - L</div>
                                        <div onClick={() => sortByAlpha("m")}>M - N</div>
                                        <div onClick={() => sortByAlpha("o")}>O - P</div>
                                        <div onClick={() => sortByAlpha("q")}>Q - R</div>
                                        <div onClick={() => sortByAlpha("s")}>S - T</div>
                                        <div onClick={() => sortByAlpha("u")}>U - V</div>
                                        <div onClick={() => sortByAlpha("w")}>W - X</div>
                                        <div onClick={() => sortByAlpha("y")}>Y - Z</div>
                                        <div onClick={() => sortByAlpha("0")}>0 - 3</div>
                                        <div onClick={() => sortByAlpha("4")}>4 - 6</div>
                                        <div onClick={() => sortByAlpha("7")}>7 - 9</div>
                                    </div>
                                </div>
                                {/* End of Filter */}
                            </div>

                            {/* <!-- End Table -->
                            <!-- Add an Account --> */}
                            <div className="add_account mt-4">
                                <a onClick={() => { setData(''); setType("") }} data-bs-toggle="modal" data-bs-target="#add_position"> <img src="assets/img/add_account.svg" alt="" /><span>Add a Position</span></a>
                            </div>
                            {/* <!-- Add an Account --> */}
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            <PositionDelete data={data} url={positionUrl} callList={getPositionList} />
            <PositionAdd url={positionUrl} type={type} data={data} callList={getPositionList} />
        </>
    )
}

export default Position