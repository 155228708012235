import React, { useState } from 'react'
import { postService } from '../../../../../../services/commonApi'

export default function CostCenterAdd({ url, callList }) {
    const [error, setError] = useState(false)
    const [inputValue, setInputValue] = useState()

    const checkValidation = () => {
        if (inputValue) {
            setError(false)
            submit_form()
        }
        else { setError(true) }
    }
    const reset_form = () => {
        setInputValue(" ")
    }

    const submit_form = () => {
        postService(url, { name: inputValue }, true)
            .then(
                (res) => {
                    // console.log('res', res)
                    if (res.request.status === 200) {
                        callList()
                        document.getElementById('costCenterModalClose').click()
                    }
                    else {
                        return setError(res.request.statusText)
                    }
                }, err => { return setError(err.request.statusText) });
    }
    return (
        <>
            {/* Modal Add Cost Center  confirmation  */}
            <div className="modal fade AddCostCenter" id="AddCostCenter" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body py-4 px-lg-0">
                            <h2 className="heading_1 ps-lg-4">Add Cost Center</h2>
                            {/*  Error alert box  */}
                            {error &&
                                <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                                    <span className="me-3">
                                        <img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" />
                                    </span>
                                    Cost Centers
                                </div>
                            }
                            <div className="p-lg-5 ">
                                <div className="col-xl-10 m-auto py-4">
                                    {error ||
                                        <div className="fm_grp">
                                            <div className="ms-4">
                                                <label htmlFor="inputAddress" className="form-label" required> Add the following Cost Center:</label>
                                                <input type="email" className="form-control " onChange={(e) => setInputValue(e.target.value)} value={inputValue} id="inputAddress" />
                                            </div>
                                        </div>
                                    }
                                    {/*  Error alert input box  */}
                                    {error &&
                                        <div className="fm_grp">
                                            <div className={"error_inupt_box"}>
                                                <span className="me-3"><img src="assets/img/error_icon.svg" className="img-fluid" alt="" /></span>
                                                <div className="error_inupt">
                                                    <label htmlFor="inputAddress" className="form-label" required="">Add the following Cost Center:</label>
                                                    <input type="email" className="form-control" onChange={(e) => setInputValue(e.target.value)} defaultValue={inputValue} id="inputAddress" placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 px-3">
                                <div> </div>
                                <div className=" text-end">
                                    <button id="costCenterModalClose" onClick={() => reset_form()} type="button" className="btn btn-link  btn_link_primary me-3" data-bs-dismiss="modal">Cancel</button>
                                    <button onClick={() => checkValidation()} type="button" className="btn btn_cstm_primary px-4">Add Cost Center</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Add Cost Center confirmation */}

        </>
    )
}
