/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { getService } from '../../../../services/commonApi';
import { Settings_Get_API, Settings_Put_API } from '../../../../services/SettingsAPI';
import { getCompanyIdToken } from '../../../../services/tokenService';
//import Multiselect from 'multiselect-react-dropdown';
//import makeAnimated from 'react-select/animated';
//import Select from "react-dropdown-select";
//const animatedComponents = makeAnimated();
import Select from "react-select";

const getOptionLabel = (option) => option.label;
const getOptionValue = (option) => option.id;

const CompanyInfo = ({ navLink, setNavLink, error, setError, companyInput, setCompanyInput, allErrorCheck }) => {

	const [addressId, setAddress] = useState(companyInput?.addressId)
	const [primaryMailStopId, setMailstop] = useState(companyInput?.primaryMailStopId)
	const [departmentId, setDepartment] = useState(companyInput?.departmentId)
	const [employeeNumber, setEmployee_number] = useState(companyInput?.employeeNumber?companyInput?.employeeNumber:'')
	const [costCenterId, setCostCenterId] = useState(companyInput?.costCenterId)

	const [positions, setPosition] = useState(companyInput?.positions?companyInput?.positions:[])
	const [defaultPositions, setDefaultPositions] = useState(companyInput?.positions?.map((data) => ({ 'id': data })))

	const [submit, setSubmit] = useState(false)

	const [addresslist, setAddresslist] = useState([])
	const [mailStopList, setMailStopList] = useState([])
	const [departmentList, setDepartmentList] = useState([])
	const [positionList, setPositionList] = useState([])

	const [changed, setChanged] = useState(false)

	const getAddressList = () => {
		getService(`/mercury/company/${getCompanyIdToken()}/address/list`, true, "").then((res) => {
			if (res.request.status === 200) {
				setAddresslist(res.data.list)
			}
		})
	}
	const getMailStopList = () => {
		getService(`/mercury/company/${getCompanyIdToken()}/mailstop/list`, true, "").then((res) => {
			if (res.request.status === 200) {
				setMailStopList(res.data.list)
			}
		})
	}
	const getDepartmentList = () => {
		getService(`/mercury/company/${getCompanyIdToken()}/department/name/list?start=1&stop=30&primary=true`).then((res) => {
			if (res.request.status === 200) {
				setDepartmentList(res.data.list)
			}
		})
	}
	const getPositionList = () => {
		// "?start=1&stop=40&primary=true"
		getService(`/mercury/company/${getCompanyIdToken()}/position/name/list?primary=true`, true, "").then((res) => {
			if (res.request.status === 200) {
				setPositionList(res.data.list.map((data, i) => ({
					'id': i,
					'positionId': data?.positionId,
					'label': data?.name
				})))
			}
		})
	}

	useEffect(() => 
	{
		getAddressList()
		getDepartmentList()
		// getCostCenterList()
		getMailStopList()
		getPositionList()
	}, [])

	useEffect(() => 
	{
		if (!companyInput) 
		{
			Settings_Get_API('/mercury/employee/company').then((res) => 
			{
				setAddress(res?.data?.companyDetails?.addressId);
				setMailstop(res?.data?.companyDetails?.primaryMailStopId);
				setDepartment(res?.data?.companyDetails?.departmentId);
				setEmployee_number(res?.data?.companyDetails?.employeeNumber?res?.data?.companyDetails?.employeeNumber:'');
				setPosition(res?.data?.companyDetails?.positions)
				setCostCenterId(res?.data?.companyDetails?.costCenterId)
			})
		}
	}, [])

	useEffect(() =>
	{
		let selected_position = [];
		for (let i = 0; i < positions?.length; i++) 
		{
			selected_position[i] = positionList?.find(data => data?.positionId === positions[i])
		}
		if (selected_position.includes(undefined)) 
		{
			selected_position = []
		}
		setDefaultPositions( selected_position );

	}, [positionList])

	function onSelect(e) {
		let selected_position = [];
		if( e.length > 0 )
		{
			for (let i = 0; i < e?.length; i++) 
			{
				selected_position[i] = e[i].positionId;
			}
			setPosition( selected_position );
			setDefaultPositions( e );
			setChanged( true );
		}
		else
		{
			return setError("At least one position is required.");
		}
	}


	useEffect(() =>
	{
		if( changed )
		{
			setSubmit(true)
			if (!addressId || !primaryMailStopId || !departmentId || !employeeNumber || positions?.length < 1) {
				if (!addressId && primaryMailStopId && departmentId && employeeNumber && positions) {
					return setError("Address is required field");
				} else if (!primaryMailStopId && addressId && departmentId && employeeNumber && positions) {
					return setError("Mailstop is required field");
				} else if (!departmentId && addressId && primaryMailStopId && employeeNumber && positions) {
					return setError("Department is required field");
				} else if (!employeeNumber && addressId && departmentId && primaryMailStopId && positions) {
					return setError("Employee Number is required field");
				} else if (positions.length < 1 && addressId && departmentId && employeeNumber && primaryMailStopId) {
					return setError("Position is required field");
				}
				return setError("Required fields are missing !");
			}

			Settings_Put_API('/mercury/employee/company', { addressId, primaryMailStopId, departmentId, employeeNumber, positions, costCenterId })
			.then( (res) =>
			{
				setCompanyInput({ addressId, primaryMailStopId, departmentId, employeeNumber, positions, costCenterId });
				setSubmit( false );
			})
			.catch((res) => 
			{
				setError(res?.response?.data?.error.info)
			})
			.finally( () => setChanged( false ) );
		}
		setError("");

	}, [changed,addressId, primaryMailStopId, departmentId, employeeNumber, positions, costCenterId])

	const formatAddMoreLabel = (inputValue) => 
	{
		return (
			<div><strong>Another Position</strong></div>
		);
	}

	return (
		<div
			className={`tab-pane fade ${navLink === "company" ? "show active" : ""}`}
			aria-labelledby="v-pills-Companyinfo-tab"
		>
			<div className="col-lg-9 offset-lg-1 modalContentItems">
				<h2 className="heading_1">Settings: Company info</h2>
				<p>Please ensure the following information accurately reflects your company information.</p>
				<form className="pt-4">
					<div className="row g-3">

						<div className="fm_grp col-md-6">
							<label htmlFor="inputAddress" className={`form-label ${error && !addressId ? 'error_text' : ''}`}>
								* Address {error && !addressId && <span>is required</span>}
							</label>
							<select id="inputAddress" className={`form-select ${error && !addressId ? 'error_input' : ''}`} onInput={(e) => { setChanged(true); setAddress(e.target.value) }}
								value={addressId}>
								{addresslist?.map((data, i) => {
									return (
										<option key={i} value={data.addressId}>{data.address1} {data?.address2},{data?.city} {data?.state} {data?.zipcode}</option>
									)
								})}
							</select>
						</div>

						<div className="fm_grp col-md-6">
							<label htmlFor="inputMailStop" className={`form-label ${error && !primaryMailStopId ? 'error_text' : ''}`}>
								* Mail Stop {error && !primaryMailStopId && <span>is required</span>}
							</label>
							<select id="inputMailStop" className={`form-select ${error && !primaryMailStopId ? 'error_input' : ''}`} onInput={(e) => { setChanged(true); setMailstop(e.target.value) }}
								value={primaryMailStopId}>
								{mailStopList?.map((data, i) => {
									return (
										<option key={i} value={data.mailStopId}>{data.name}</option>
									)
								})}
							</select>
						</div>

						<div className="fm_grp col-md-6">
							<label htmlFor="inputDepartment" className={`form-label ${error && !departmentId ? 'error_text' : ''}`}>
								* Department {error && !departmentId && <span>is required</span>}
							</label>
							<select id="inputDepartment" className={`form-select ${error && !departmentId ? 'error_input' : ''}`}
								onInput={(e) => { setChanged(true); setDepartment(e.target.value) }}
								value={departmentId}
							>
								{departmentList?.map((data, i) => {
									return (
										<option key={i} value={data?.departmentId}>{data?.name}</option>
									)
								})}
							</select>
						</div>

						<div className="fm_grp col-md-6 opacity_5">
							<label htmlFor="inputEmployeeNumber" className={`form-label ${error && !employeeNumber ? 'error_text' : ''}`}>
								Employee number {error && !employeeNumber && <span>is required</span>}
							</label>
							<input id="employee_number" type="text" className={`form-control ${error && !employeeNumber ? 'error_input' : ''}`} value={employeeNumber} 
									onInput={(e) => { setChanged(true); setEmployee_number(e.target.value) }}/>

						</div>

						<div className="fm_grp col-md-6">
							<label htmlFor="Position" className={`form-label ${error && !positions ? 'error_text' : ''}`}>*  Position(s) {error && !positions && <span>is required</span>}</label>
							<Select
								isMulti
								value={defaultPositions}
                                options={positionList}
								getOptionLabel={getOptionLabel}
								getOptionValue={getOptionValue}
								formatCreateLabel={formatAddMoreLabel}
                                onChange={(values) => onSelect(values)}
							/>
							
						</div>
					</div>
				</form>
			</div>
			<div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
				<div> </div>
				<div className="p-md-4 text-end">
					<button
						type="button"
						id="closeCompanyModal"
						className="btn btn-link  btn_link_primary"
						data-bs-dismiss="modal" style={{
							visibility: 'hidden'
						}}
					>
						Cancel
					</button>
					<button
						type="button"
						className="btn btn_cstm_primary"
						data-bs-dismiss="modal"
						disabled={allErrorCheck}
					>
						Done
					</button>
				</div>
			</div>
		</div>
	)
}

export default CompanyInfo