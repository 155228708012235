/* eslint-disable no-unused-vars */
import React from 'react'
import { putService } from '../../../../../services/commonApi'

const Deny = ({ refresh, getOriginalReqList, url, data }) => {

    const denyReq = () => {
        putService(`${url}/${data}`, '', true, {
            params: { 'response': 'denied' }
        }).then(
            (res) => {
                if (res.status === 200) {
                    refresh()
                    document.getElementById('dismissdenyModal').click()

                }
            }
        )
    }

    return (
        <div className="modal fade deny" id="deny" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body py-4">
                        <div className="row align-items-center">
                            <div className="col-lg-11 m-auto">
                                <h2 className="heading_1 mb-4">Deny</h2>
                                <p>Deny this request?</p>
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-end mt-4">
                                <div className="p-md-4 text-end">
                                    <button type="button" className="btn btn-link btn_link_primary" id='dismissdenyModal' onClick={() => getOriginalReqList()} data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn_cstm_primary px-4" onClick={() => denyReq()}>Deny</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Deny