/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { deleteService, getService } from '../../../../../../../services/commonApi'

export default function DeleteSenderAddress({ addressDelete, url, getSenderAddress, setAddressDelete }) {

    const [error, setError] = useState()
	const [label, setLabel] = useState('')
	const [addressId,setAddressId] = useState()
    const [NameList, setNameList] = useState()
    const [replacementId, setReplacementId] = useState('')
    useEffect(() => 
	{
		if (addressDelete?.addressId) 
		{
			console.log(addressDelete)
			getNameList()
			setAddressId(addressDelete.addressId)
			if( addressDelete.address1 )
			{
				setLabel(addressDelete.address1+","+addressDelete.city)
			}
			else
			{
				setLabel( addressDelete.city +","+addressDelete.state+" "+addressDelete.zip )
			}
		}
	}, [addressDelete?.addressId])

	const getNameList = () => 
	{
		getService(`${url}/address/list`, true, "").then((res) => 
		{
			if (res.request.status === 200) 
			{
				getSenderAddress()
				setAddressDelete('')
				setNameList(res.data.filter(el => el.addressId !== addressDelete?.addressId))
			}
		})
	}

	const deleteSenderAddress = () => 
	{
		if (!replacementId) 
		{
			return setError('Please provide replacement Id')
		}
		deleteService(`${url}/address/${addressId}?replacementId=${replacementId}`, true).then((res) => 
		{
			if (res?.request?.status === 200) 
			{
				getSenderAddress()
				document.getElementById('senderaddressdeletemodal').click()
				// getSenderData()
			} 
			else 
			{
				return setError('Something went wrong , Please try again later')
			}
		})
	}

    return (
        <div className="modal fade delete_sender_address" id="delete_sender_address" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body py-4 px-lg-0">
                        <h2 className="heading_1 ps-lg-4">Delete confirmation</h2>

                        {/* <!--  Error alert box  --> */}
                        {error && <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                            <span className="me-3"><img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" /></span>{error}
                        </div>}

                        <div className="p-lg-5">
                            <div className="col-xl-8 m-auto py-4">
                                <h3 className="heading_2 mb-4">Delete { label } ?</h3>
                                <div className="fm_grp">
                                    <label htmlFor="inputState" className="form-label">* Replace with the following Address:</label>
                                    <select id="inputState" className="form-select" onChange={(e) => setReplacementId(e.target.value)} value={replacementId}>
                                        <option value="">Select address</option>
                                        {
                                            NameList?.map((item, i) => {
                                                // console.log(item)
                                                return (<option key={i} value={item.addressId}>{item.address1},{item.city}</option>)
                                            })
                                        }
                                    </select>
                                </div>

                                {/* <!--  Error alert input box  --> */}
                                {/* <div  className="fm_grp">
                                    <div  className="error_inupt_box">
                                        <span  className="me-3"><img src="assets/img/error_icon.svg"  className="img-fluid" alt="" /></span>
                                        <div  className="error_inupt">
                                            <label htmlFor="inputAddress"  className="form-label" required="">* Email address is a required field.</label>
                                            <input type="email"  className="form-control" id="inputAddress" placeholder="" />
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 px-3">
                            <div> </div>
                            <div className="text-end">
                                <button type="button" className="btn btn-link  btn_link_primary me-3" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#sender_name" id="senderaddressdeletemodal">Cancel</button>
                                <button type="button" className="btn btn_cstm_primary px-4" onClick={() => deleteSenderAddress()}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
