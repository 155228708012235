/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { AuthState } from '../../../../context/authContext'
import { getService } from '../../../../services/commonApi'
import CompanyAdd from './Modals/CompanyModals/CompanyAdd'
import CompanyDelete from './Modals/CompanyModals/CompanyDelete'
import { getCompanyIdToken } from '../../../../services/tokenService'
import { useState, useEffect } from 'react'

const CompanyName = ({ activeTab }) => {
    const { databaseRoute, setDatabaseRoute } = AuthState()
    const [companyList, setCompnayList] = useState([])
    const [modalData, setModalData] = useState()

    const [sortby, setSortby] = useState()
    const [filter, setFilter] = useState()
    var start = 1;
    var stop = 30;

    useEffect(() => {
        if (databaseRoute === 'companyName') {
            getCompanyListFunc()
        }
    }, [databaseRoute])

    const url = `/mercury/company/${getCompanyIdToken()}/names`
    const getCompanyListFunc = () => {
        getService(url, true, {
            params: {
                sortby, filter
            }
        }).then((res) => {
            if (res?.status === 200) {
                setCompnayList(res.data.companyNames)
            }

        }, (err) => console.log(err))
    }

    useEffect(() => {
        const search = setTimeout(() => {
            if (databaseRoute === 'companyName') {
                getCompanyListFunc()
            }
        }, 1500);
        return () => clearTimeout(search)
    }, [filter, sortby])

    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-d" onClick={() => { setDatabaseRoute('companyName') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-d" aria-expanded="true" aria-controls="collapse-d">
                        Company Names  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-d" className={`bgYellow collapse ${databaseRoute === "companyName" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-d">
                <div className="head d-none d-md-block">
                    <h4 className="heading_1 text_dark mb-0">Company Names</h4>
                </div>
                <div className="p-4">
                    <p> Please enter all of your company’s Company Names that the system will use to receive mail, and keep this list current. If the list is not kept current, Company Names for mail may be inaccurate. <a className="text_dark">More…</a></p>
                </div>
                <div className="p-4  pt-0 col-lg-12">
                    <div className="row">
                        <div className="col-lg-11">
                            {/* <!-- Start search--> */}
                            <div className="col-md-5 col-xl-4">
                                <div className="serach_box mb-3">
                                    <input className="form-control" type="text" aria-label="Search" placeholder="Search Company Names" onInput={(e) => setFilter(e.target.value)} value={filter} />
                                    <a ><span className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
                                </div>
                            </div>
                            {/* <!-- Start search -->
      
                                    <!-- Start Table --> */}
                            <div className="col-md-8  col-xl-4">
                                <div className="table-responsive cst_table company_name_table">
                                    <table className="table">
                                        <tbody>
                                            {
                                                companyList?.length > 0 &&
                                                <>
                                                    {companyList?.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td style={{ width: '80%' }}>{item.name}</td>
                                                                <td><a data-bs-toggle="modal" data-bs-target="#deleteconfirmation"><img src="assets/img/Delete.svg" alt="" onClick={() => setModalData(item)} /> </a></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <!-- End Table -->
                                    <!-- Add an Account --> */}
                            <div className="add_account mt-4">
                                <a data-bs-toggle="modal" data-bs-target="#add_company"> <img src="assets/img/add_account.svg" alt="" /><span>Add Company Name</span></a>
                            </div>
                            {/* <!-- Add an Account --> */}
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            <CompanyAdd getList={getCompanyListFunc} />
            <CompanyDelete url={url} modalData={modalData} getList={getCompanyListFunc} />
        </>
    )
}

export default CompanyName