/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

const EvaluateRules = ({ rule, oldData, changedData, rulesList, receiverChange, senderChange }) => {

	useEffect(() => 
	{
		setTimeout(() => {
			// console.log('rule close')
			let elem = document.getElementById('closeEvaluateRule');
			if( elem )
			{
				elem.click()
			}
		}, 15000);
		// console.log(rule)
	}, [rule, oldData, changedData])


	return (
		<div className="offcanvas offcanvas-top fade delete_banner warn_modal evaluate_rules" id="evaluate_rules" tabIndex="-1" data-bs-scroll="true" data-bs-backdrop="false" aria-labelledby="offcanvasScrollingLabel">

			<div className="offcanvas-body">
				<div className="row align-items-center">
					<div className="col-lg-5">
						<p>You have selected <b>“{rulesList.filter((el) => el.id === rule).map((el) => el?.name)}”</b> to run.</p>
					</div>
					<div className="col-lg-6">
						<div>
							{ !receiverChange && !senderChange && <p>No changes were made based on your selected rule.</p> }
							{receiverChange && <p><b>Recipient list</b> has changed based on your selected rules.</p>}
							{senderChange && <p><b>Sender list</b> has changed based on your selected rules.</p>}
						</div>
					</div>
					<div className="col-lg-1 text-end">
						<button type="button" className="cbtn" id="closeEvaluateRule" data-bs-dismiss="offcanvas" aria-label="Close">
							<i className="bi bi-x"></i>
						</button>
					</div>
				</div>
			</div>

		</div>
	)
}

export default EvaluateRules