/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

const DeleteBanner = ({ callBackFunction, close, setClose }) => {

    useEffect(() => {
        if (close) {
            // closeFunction()
            document.getElementById('closedeleteNoteModal').click()
            setClose(false)
        }
    }, [close])


    // function closeFunction() {
    //     setClose(false)
    // }

    return (
        <div className="offcanvas offcanvas-top fade delete_banner warn_modal" id="delete_banner" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" aria-labelledby="offcanvasScrollingLabel">
            {/* <div  className="modal-dialog modal-xl">
                <div  className="modal-content"> */}
            <div className="offcanvas-body">
                <div className="row align-items-center">
                    <div className="col-lg-9">
                        <p>Are you sure you want to delete this item? This action cannot be undone.</p>
                    </div>
                    <div className="col-lg-2 text-lg-end">
                        <button type="button" className="btn btn_cstm_primary px-4 btn-sm" onClick={() => callBackFunction()}>Delete</button>
                    </div>

                    <div className="col-lg-1 text-end">
                        <button type="button" className="btn close_btn" data-bs-dismiss="offcanvas" id="closedeleteNoteModal" >
                            <i className="bi bi-x"></i>
                        </button>
                    </div>
                </div>
            </div>
            {/* </div>
            </div> */}
        </div>
    )
}

export default DeleteBanner