/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { RouteState } from '../../../../context/routeContext'
import { getService, putService } from '../../../../services/commonApi'
import { getCompanyIdToken } from '../../../../services/tokenService'
import NotificationTemplate from './modals/NotificationTemplate'


const Settings = () => {
    const { systemRoute, setSystemRoute } = RouteState()

    const [urlEnd, setUrlEnd] = useState('')

    const [emailServer, setEmailServer] = useState()
    // { emailAddress: '', hostName: '', portNumber: '', username: '', password: '', useTLS: '', useSSL: '', sslPort: '' }

    const [imageProcessing, setImageProcessing] = useState()
    // { envelopesLast: '', emailOnErrors: '', preprocessed: '', strictAddressing: '', senderCapture: '', systemEmail: '', firstBatchPage: '', soundex: '', minimumConfidence: '' }

    const [qualiyControl, setQualiyControl] = useState()
    // { check: '', sampleSize: '' }

    const [archiving, setArchiving] = useState()
    // { archive: '', maxAge: '' }

    var notificationAPI = `/mercury/company/${getCompanyIdToken()}/notifications/url`
    var emailAPI = '/mercury/system/email'
    var imageAPI = '/mercury/system/processing'
    var qualityAPI = '/mercury/system/qualitycontrol'
    var archivingAPI = '/mercury/system/archiving'

    const [emailAddress, setEmailAddress] = useState('')
    const [hostName, setHostName] = useState('')
    const [portNumber, setPortNumber] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [useTLS, setUseTLS] = useState()
    const [useSSL, setUseSSL] = useState()
    const [sslPort, setSslPort] = useState('')

    const [emailEditStatus, setEmailEditStatus] = useState(false)


    const [envelopesLast, setEnvelopesLast] = useState()
    const [emailOnErrors, setEmailOnErrors] = useState()
    const [preprocessed, setPreprocessed] = useState()
    const [strictAddressing, setStrictAddressing] = useState()
    const [senderCapture, setSenderCapture] = useState()
    const [systemEmail, setSystemEmail] = useState()
    const [firstBatchPage, setFirstBatchPage] = useState()
    const [soundex, setSoundex] = useState()
    const [minimumConfidence, setMinimumConfidence] = useState()

    const [settingsEditStatus, setSettingsEditStatus] = useState(false)


    const [check, setCheck] = useState()
    const [sampleSize, setSampleSize] = useState()

    const [qualityEditStatus, setQualityEditStatus] = useState(false)


    const [archive, setArchive] = useState()
    const [maxAge, setMaxAge] = useState()

    const [archiveEditStatus, setArchiveEditStatus] = useState(false)
    const [hidePassword, setHidePassword] = useState(true)


    const [systemUrl, setSystemUrl] = useState('')
    const [welcomeUrl, setWelcomeUrl] = useState('')
    const [notifiUrlEditStatus, setNotifiUrlEditStatus] = useState(false)

    useEffect(() => {
        if (systemRoute === 'setting') {
            getNotificationURLData()
            getEmailServerData()
            getImageProcessingData()
            getQualityControlData()
            getArchivingData()
        }
    }, [systemRoute])

    const getNotificationURLData = () => {
        setNotifiUrlEditStatus(false)
        getService(notificationAPI, true, "").then(
            (res) => {
                // console.log(res)
                if (res.status === 200) {
                    setSystemUrl(res?.data?.systemUrl)
                    setWelcomeUrl(res?.data?.welcomeUrl)
                }
            }, err => {

            }
        )
    }

    const setNotificationURLData = () => {
        putService(notificationAPI, {
            systemUrl, welcomeUrl
        }, true).then(
            (res) => {
                if (res.status === 200) {
                    setNotifiUrlEditStatus(false)
                    // setEmailServer(res?.data)
                }
            }
        )
    }

    const getEmailServerData = () => {
        setEmailEditStatus(false)
        getService(emailAPI, true, "").then(
            (res) => {
                // console.log(res)
                if (res.status === 200) {
                    setEmailServer(res?.data)
                }
            }, err => {

            }
        )
    }

    const setEmailServerData = () => {
        putService(emailAPI, {
            emailAddress, hostName, portNumber, username, password, useTLS, useSSL, sslPort
        }, true).then(
            (res) => {
                if (res.status === 200) {
                    setEmailEditStatus(false)
                    // setEmailServer(res?.data)
                }
            }
        )
    }

    const getImageProcessingData = () => {
        setSettingsEditStatus(false)
        getService(imageAPI, true, "").then(
            (res) => {
                if (res.status === 200) {
                    setImageProcessing(res?.data)
                }
            }, err => {

            }
        )
    }

    const setImageProcessingData = () => {
        putService(imageAPI, {
            envelopesLast, emailOnErrors, preprocessed, strictAddressing, senderCapture, systemEmail, firstBatchPage, soundex, minimumConfidence
        }, true).then(
            (res) => {
                if (res.status === 200) {
                    setSettingsEditStatus(false)
                    // setImageProcessing(res?.data)
                }
            }
        )
    }

    const getQualityControlData = () => {
        setQualityEditStatus(false)
        getService(qualityAPI, true, "").then(
            (res) => {
                if (res.status === 200) {
                    setQualiyControl(res?.data)
                }
            }, err => {

            }
        )
    }

    const setQualityControlData = () => {
        putService(qualityAPI, {
            check, sampleSize
        }, true).then(
            (res) => {
                if (res.status === 200) {
                    setQualityEditStatus(false)

                    // setQualiyControl(res?.data)
                }
            }
        )
    }

    const getArchivingData = () => {
        setArchiveEditStatus(false)
        getService(archivingAPI, true, "").then(
            (res) => {
                if (res.status === 200) {
                    setArchiving(res?.data)
                }
            }, err => {

            }
        )
    }

    const setArchivingData = () => {
        putService(archivingAPI, {
            archive, maxAge
        }, true).then(
            (res) => {
                setArchiveEditStatus(false)
                if (res.status === 200) {
                    // setArchiving(res?.data)
                }
            }
        )
    }

    useEffect(() => {
        setEmailAddress(emailServer?.emailAddress)
        setHostName(emailServer?.hostName)
        setPortNumber(emailServer?.portNumber)
        setUsername(emailServer?.username)
        setPassword(emailServer?.password)
        setUseTLS(emailServer?.useTLS)
        setUseSSL(emailServer?.useSSL)
        setSslPort(emailServer?.sslPort)
    }, [emailServer])

    useEffect(() => {
        setEnvelopesLast(imageProcessing?.envelopesLast)
        setEmailOnErrors(imageProcessing?.emailOnErrors)
        setPreprocessed(imageProcessing?.preprocessed)
        setStrictAddressing(imageProcessing?.strictAddressing)
        setSenderCapture(imageProcessing?.senderCapture)
        setSystemEmail(imageProcessing?.systemEmail)
        setFirstBatchPage(imageProcessing?.firstBatchPage)
        setSoundex(imageProcessing?.soundex)
        setMinimumConfidence(imageProcessing?.minimumConfidence)
    }, [imageProcessing])

    useEffect(() => {
        setCheck(qualiyControl?.check)
        setSampleSize(qualiyControl?.sampleSize)
    }, [qualiyControl])

    useEffect(() => {
        setArchive(archiving?.archive)
        setMaxAge(archiving?.maxAge)
    }, [archiving])

    // useEffect(() => {
    //     const update = setTimeout(() => {
    //         if (systemRoute === 'setting') {
    //             setEmailServerData()
    //         }
    //     }, 1500);
    //     return () => clearTimeout(update)
    // }, [emailAddress, hostName, portNumber, username, password, useTLS, useSSL, sslPort])

    // useEffect(() => {
    //     const update = setTimeout(() => {
    //         if (systemRoute === 'setting') {
    //             setImageProcessingData()
    //         }
    //     }, 1500);
    //     return () => clearTimeout(update)
    // }, [envelopesLast, emailOnErrors, preprocessed, strictAddressing, senderCapture, systemEmail, firstBatchPage, soundex, minimumConfidence])

    // useEffect(() => {
    //     const update = setTimeout(() => {
    //         if (systemRoute === 'setting') {
    //             setQualityControlData()
    //         }
    //     }, 1500);
    //     return () => clearTimeout(update)
    // }, [check, sampleSize])

    // useEffect(() => {
    //     const update = setTimeout(() => {
    //         if (systemRoute === 'setting') {
    //             setArchivingData()
    //         }
    //     }, 1500);
    //     return () => clearTimeout(update)
    // }, [archive, maxAge])

    var interval = [
        '1 of 10', '2 of 10', '3 of 10', '4 of 10', '5 of 10', '6 of 10', '7 of 10', '8 of 10', '9 of 10', '10 of 10'
    ]


    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-C" onClick={() => { setSystemRoute('setting') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-C" aria-expanded="true" aria-controls="collapse-C">
                        Settings  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-C" className={`bgYellow collapse ${systemRoute === "setting" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-C">
                <div className="head d-none d-lg-block">
                    <h4 className="heading_1 text_dark mb-0">Settings</h4>
                </div>

                <div className="p-4 system_Settings">
                    <div className="row gx-lg-5">

                        <div className="col-xl-4 bdr_right">
                            <p><b>Email Settings</b></p>
                            <div className="table-responsive cst_table account_types">
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>New Account Notification</td>
                                            <td>
                                                <a onClick={() => setUrlEnd('newaccount')} className="ms-2" data-bs-toggle="modal" data-bs-target="#account_notification"><img src="assets/img/Edit.svg" alt="" /> </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Accepted Account</td>
                                            <td>
                                                <a onClick={() => setUrlEnd('approvedaccount')} className="ms-2" data-bs-toggle="modal" data-bs-target="#account_notification"><img src="assets/img/Edit.svg" alt="" /> </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Denied Account</td>
                                            <td>
                                                <a onClick={() => setUrlEnd('deniedaccount')} className="ms-2" data-bs-toggle="modal" data-bs-target="#account_notification"><img src="assets/img/Edit.svg" alt="" /> </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Forgotten Password</td>
                                            <td>
                                                <a onClick={() => setUrlEnd('forgotten')} className="ms-2" data-bs-toggle="modal" data-bs-target="#account_notification"><img src="assets/img/Edit.svg" alt="" /> </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Daily Mail</td>
                                            <td>
                                                <a onClick={() => setUrlEnd('dailymail')} className="ms-2" data-bs-toggle="modal" data-bs-target="#account_notification"><img src="assets/img/Edit.svg" alt="" /> </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Piece Wise Mail</td>
                                            <td>
                                                <a onClick={() => setUrlEnd('piecemail')} className="ms-2" data-bs-toggle="modal" data-bs-target="#account_notification"><img src="assets/img/Edit.svg" alt="" /> </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="fm_grp col-md-6">
                                    <label htmlFor="sysurl" className="form-label">Application Url</label>
                                    <input type="text" className="form-control" id="sysurl" onInput={(e) => { setSystemUrl(e.target.value); setNotifiUrlEditStatus(true) }} value={systemUrl} />
                                </div>
                                <div className="fm_grp col-md-6">
                                    <div>
                                        <label htmlFor="welurl" className="form-label">Welcome Url</label>
                                        <input type="text" className="form-control" id="welurl" onInput={(e) => { setWelcomeUrl(e.target.value); setNotifiUrlEditStatus(true) }} value={welcomeUrl} />
                                    </div>
                                </div>
                            </div>
                            {notifiUrlEditStatus && <div className="col-md-6 col-xl-6 align-self-center">
                                <div className="d-flex flex-wrap align-items-center justify-content-end">
                                    <div className="text-end">
                                        <button type="button" className="btn btn-link btn_link_primary" id='closenotifieditmodal' onClick={() => getNotificationURLData()} data-bs-dismiss="modal">Cancel</button>
                                        <button type="button" className="btn btn_cstm_primary px-3" onClick={() => setNotificationURLData()}>Save</button>
                                    </div>
                                </div>
                            </div>}
                        </div>

                        <div className="col-xl-8">
                            <hr className="vrt_shaprater" />
                            <p><b>Email Server Settings</b></p>
                            <div className="row">
                                <div className="fm_grp col-md-6">
                                    <label htmlFor="email" className="form-label">Email address</label>
                                    <input type="email" className="form-control" id="email" onInput={(e) => { setEmailAddress(e.target.value); setEmailEditStatus(true) }} value={emailAddress} />
                                </div>
                                <div className="fm_grp col-md-6">
                                    <div>
                                        <label htmlFor="username" className="form-label">Username</label>
                                        <input type="text" className="form-control" id="username" onInput={(e) => { setUsername(e.target.value); setEmailEditStatus(true) }} value={username} />
                                    </div>
                                </div>
                                <div className="fm_grp col-md-6 col-lg-4">
                                    <div>
                                        <label htmlFor="smtpserver" className="form-label">SMTP Server Hostname</label>
                                        <input type="text" className="form-control" id="smtpserver" onInput={(e) => { setHostName(e.target.value); setEmailEditStatus(true) }} value={hostName} />
                                    </div>
                                </div>
                                <div className="fm_grp col-md-6 col-lg-2">
                                    <div>
                                        <label htmlFor="port" className="form-label">Port Number</label>
                                        <input type="text" className="form-control" id="port" onInput={(e) => { setPortNumber(e.target.value); setEmailEditStatus(true) }} value={portNumber} />
                                    </div>
                                </div>
                                <div className="fm_grp col-md-6 col-lg-6">
                                    <div>
                                        <label htmlFor="password" className="form-label">Password</label>
                                        <input type={hidePassword ? 'password' : 'text'} className="form-control" id="password" onInput={(e) => { setPassword(e.target.value); setEmailEditStatus(true) }} value={password} />
                                        <i onClick={() => setHidePassword(!hidePassword)} className={`bi ${hidePassword ? 'bi-eye-fill' : 'bi-eye-slash-fill'} pass_icon`}></i>
                                    </div>
                                </div>
                                <div className="fm_grp col-md-4 col-xl-2 align-self-end">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="useTLS"
                                            onChange={() => { setUseTLS(!useTLS); setEmailEditStatus(true) }}
                                            checked={useTLS} />
                                        <label className="form-check-label ms-2" htmlFor="useTLS">Use TLS</label>
                                    </div>
                                </div>
                                <div className="fm_grp col-md-4 col-xl-2 align-self-end">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="useSSL"
                                            onChange={() => { setUseSSL(!useSSL); setEmailEditStatus(true) }}
                                            checked={useSSL} />
                                        <label className="form-check-label ms-2" htmlFor="useSSL">Use SSL</label>
                                    </div>
                                </div>
                                <div className="fm_grp col-md-6 col-xl-2">
                                    <label htmlFor="portnum" className="form-label">SSL Port Number</label>
                                    <input type="text" className="form-control" id="portnum" onInput={(e) => { setSslPort(e.target.value); setEmailEditStatus(true) }} value={sslPort} />
                                </div>
                                {emailEditStatus && <div className="col-md-6 col-xl-6 align-self-center">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end">
                                        <div className="text-end">
                                            <button type="button" className="btn btn-link btn_link_primary" id='closenotifieditmodal' onClick={() => getEmailServerData()} data-bs-dismiss="modal">Cancel</button>
                                            <button type="button" className="btn btn_cstm_primary px-3" onClick={() => setEmailServerData()}>Save</button>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>

                        <hr className="cst_shaprater" />

                        <div>
                            <p><b>Processing Settings</b></p>
                            <div className="row">
                                <div className="col-md-9 col-lg-4 col-xl-2">
                                    <div className="fm_grp">
                                        <label htmlFor="minConfidence" className="form-label">Minimum confidence</label>
                                        <input type="text" className="form-control" id="minConfidence" onInput={(e) => { setMinimumConfidence(e.target.value); setSettingsEditStatus(true) }} value={minimumConfidence} />
                                    </div>
                                    <div className="fm_grp">
                                        <label htmlFor="soundexrange" className="form-label">Soundex Range</label>
                                        <input type="text" className="form-control" id="soundexrange" onInput={(e) => { setSoundex(e.target.value); setSettingsEditStatus(true) }} value={soundex} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-xl-3 offset-xl-1">
                                    <div className="fm_grp mb-2">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="autoroute"
                                                onChange={() => { setPreprocessed(!preprocessed); setSettingsEditStatus(true) }} defaultChecked={preprocessed} />
                                            <label className="form-check-label ms-2" htmlFor="autoroute">Auto Route</label>
                                        </div>
                                    </div>
                                    <div className="fm_grp mb-2">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="envelenwise"
                                                onChange={() => { setEmailOnErrors(!emailOnErrors); setSettingsEditStatus(true) }} defaultChecked={emailOnErrors} />
                                            <label className="form-check-label ms-2" htmlFor="envelenwise">Envelopes Lengthwise</label>
                                        </div>
                                    </div>
                                    <div className="fm_grp mb-2">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="envelast"
                                                onChange={() => { setEnvelopesLast(!envelopesLast); setSettingsEditStatus(true) }} defaultChecked={envelopesLast} />
                                            <label className="form-check-label ms-2" htmlFor="envelast">Envelopes Last</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 col-xl-2">
                                    <div className="fm_grp mb-2">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="batchpagefirst"
                                                onChange={() => { setFirstBatchPage(!firstBatchPage); setSettingsEditStatus(true) }} defaultChecked={firstBatchPage} />
                                            <label className="form-check-label ms-2" htmlFor="batchpagefirst">Batch Pages First</label>
                                        </div>
                                    </div>
                                    <div className="fm_grp mb-2">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="straddress"
                                                onChange={() => { setStrictAddressing(!strictAddressing); setSettingsEditStatus(true) }} defaultChecked={strictAddressing} />
                                            <label className="form-check-label ms-2" htmlFor="straddress">Strict Addressing</label>
                                        </div>
                                    </div>
                                </div>
                                {settingsEditStatus && <div className="col-md-6 col-xl-4 align-self-end">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end p-2">
                                        <div className="text-end">
                                            <button type="button" className="btn btn-link btn_link_primary" id='closenotifieditmodal' onClick={() => getImageProcessingData()} data-bs-dismiss="modal">Cancel</button>
                                            <button type="button" className="btn btn_cstm_primary px-3" onClick={() => setImageProcessingData()}>Save</button>
                                        </div>
                                    </div>
                                </div>}
                            </div >
                        </div >

                        <hr className="cst_shaprater mb-0" />

                        <div className="row gx-lg-5">

                            <div className="col-lg-5 bdr_right pt-3">
                                <p><b>Quality Control Settings</b></p>
                                <div className="row">
                                    <div className="fm_grp col-6 col-lg-7 align-self-end">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="persormqual"
                                                onChange={() => { setCheck(!check); setQualityEditStatus(true) }} defaultChecked={check}
                                            />
                                            <label className="form-check-label ms-2" htmlFor="persormqual">Perform Quality Control</label>
                                        </div>
                                    </div>
                                    <div className="fm_grp col-6 col-md-5">
                                        <label htmlFor="sampinterval" className="form-label">Samples Interval</label>
                                        <select className="form-select form-select-sm " onChange={(e) => {
                                            setSampleSize(e.target.value); setQualityEditStatus(true)
                                        }} value={sampleSize}>
                                            {interval?.map((data, i) => (
                                                <option key={i} value={i + 1}>{data}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {qualityEditStatus && <div className="col-md-6 col-xl-12 align-self-center">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end">
                                        <div className="text-end">
                                            <button type="button" className="btn btn-link btn_link_primary" id='closenotifieditmodal' onClick={() => getQualityControlData()} data-bs-dismiss="modal">Cancel</button>
                                            <button type="button" className="btn btn_cstm_primary px-3" onClick={() => setQualityControlData()}>Save</button>
                                        </div>
                                    </div>
                                </div>}
                            </div >

                            <div className="col-lg-7  pt-3">
                                <p><b>Archiving</b></p>

                                <div className="row">
                                    <div className="fm_grp col-lg-5 align-self-end">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="purgenarch" onChange={(e) => { setArchive(!archive); setArchiveEditStatus(true) }} defaultChecked={archive} />
                                            <label className="form-check-label ms-2" htmlFor="purgenarch">Purge and Archive</label>
                                        </div>
                                    </div>

                                    <div className="fm_grp col-lg-5">
                                        <label htmlFor="maxAge" className="form-label">Maximum Age (days)</label>
                                        <input type="text" className="form-control" id="maxAge" onInput={(e) => { setMaxAge(e.target.value); setArchiveEditStatus(true) }} value={maxAge} />
                                    </div>
                                </div>

                                {archiveEditStatus && <div className="col-md-6 col-xl-12">
                                    <div className="d-flex flex-wrap align-items-center justify-content-end">
                                        <div className="text-end">
                                            <button type="button" className="btn btn-link btn_link_primary" id='closenotifieditmodal' onClick={() => getArchivingData()} data-bs-dismiss="modal">Cancel</button>
                                            <button type="button" className="btn btn_cstm_primary px-3" onClick={() => setArchivingData()}>Save</button>
                                        </div>
                                    </div>
                                </div >}
                            </div >

                        </div >

                    </div >
                </div >

                <NotificationTemplate urlEnd={urlEnd} setUrlEnd={setUrlEnd} />

            </div >
        </>
    )
}

export default Settings