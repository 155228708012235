/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { getService, postService, putService } from '../../../../../../services/commonApi'

export default function DepartmentAdd({ type, url, data, mailStopList, getList, onClose }) 
{
	const [error, setError] = useState('')
	const [inputName, setInputName] = useState('')
	const [altInputName, setAltInputName] = useState([])
	const [forwardToId, setForwardToId] = useState('')
	const [employeeSearchList, setEmployeeSearchList] = useState([])
	const [employeeSearchResult, setemployeeSearchResult] = useState()
	const [submit, setSubmit] = useState(false)
	const [notification, setNotification] = useState("M")
	const [dropdownState, setDropdownState] = useState(false)
	const [mailStopId, setMailStopId] = useState('')
	const [searchKey, setSearchKey] = useState("")

	const checkValidate = () => 
	{
		if (inputName && altInputName.length && forwardToId) 
		{
			submit_form()
		} 
		else 
		{
			return setError('Check Missing fields')
		}
	}

	useEffect(() => 
	{
		if( data && data !== 'new' ) 
		{
			getData()
		}
	}, [data, mailStopList])

	useEffect(() =>
	{
		if( mailStopList?.length && !mailStopId )
		{
			setMailStopId( mailStopList[0].mailStopId )
		}
	}, [mailStopList] )

	const getData = () => 
	{
		getService(`${url}/${data?.departmentId}`, true).then((res) => 
		{
			if (res?.request?.status === 200) 
			{
				setInputName(res?.data?.companyDepartment?.label)
				setForwardToId(res?.data?.companyDepartment?.contactId)
				setAltInputName(getAltNames(res?.data?.companyDepartment?.departmentNames).join('\n'))
				setNotification(res?.data?.companyDepartment?.departmentPreferences?.notificationType)
				searchEmployeeById(res?.data?.companyDepartment?.contactId)
				setMailStopId(res?.data?.companyDepartment?.mailStopId)
			} 
			else 
			{
				setError(res?.statusText)
			}
		}, 
		err => 
		{
			setError("Error getting department.")
		})
	}

	const getAltNames = (data) => 
	{
		var namesArray = []
		data.forEach((item, i) => 
		{
			namesArray[i] = item.name
		})
		return namesArray
	}

	useEffect(() => 
	{
		const search = setTimeout(() => 
		{
			if (searchKey) 
			{
				searchEmployee(searchKey)
			} 
			else 
			{
				setEmployeeSearchList([])
			}
		}, 1500);
		return () => clearTimeout(search)
	}, [searchKey])


	const searchEmployee = (name) => 
	{
		getService(`/mercury/employee/list?filter=${name}`, true).then( (res) => 
		{
			if (res?.status === 200) 
			{
				setEmployeeSearchList(res.data?.employees)
			}
		})
	}

	const searchEmployeeById = (id) => 
	{
		if (id)
		{
			getService(`/mercury/employee/${id}`, true).then( (res) => 
			{
				if (res?.status === 200) 
				{
					setemployeeSearchResult(res.data)
					setEmployeeSearchList([])
				} 
			})
		}
	}

	const reset_form = () => 
	{
		setInputName("")
		setForwardToId('')
		setemployeeSearchResult()
		setEmployeeSearchList([])
		setSearchKey('')
		setAltInputName([])
		setNotification('M')
		setMailStopId( mailStopList?.length ? mailStopList[0].mailStopId : '' )
		setError('')
		setSubmit(false)
	}

    const submit_form = () => 
	{
		let Cdata = 
		{
			label: inputName,
			departmentNames: altInputName.includes('\n') ? altInputName?.split("\n") : [altInputName],
			contactId: forwardToId,
			notificationType: notification,
			mailStopId: mailStopId,
		}
		if( type )
		{
			putService(`${url}/${data?.departmentId}`, Cdata, true).then( (res) => 
			{
				if (res.request.status === 200) 
				{
					getList()
					onClose();
				}
				else 
				{
					return setError(res?.response?.data?.error?.desc)
				}
			}, 
			err => 
			{ 
				return setError(err?.response?.data?.error?.desc) 
			})
		}
		else
		{
			postService(url, Cdata, true).then( (res) => 
			{
				if (res.request.status === 200) 
				{
					getList()
					onClose();
				}
				else 
				{
					return setError(res?.response?.data?.error?.desc)
				}
			}, 
			err => 
			{ 
				return setError(err.response?.data?.error?.desc) 
			});
		}
        setError('')
    }


	const handleMailStopChange = (event) => 
	{
		setMailStopId(event.target.value);
	};	

    return (
        <>

            {/* Modal Add Department  */}
            <div className="modal fade adddepartment" id="AddDepartment" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body py-4 px-lg-0">
                            <h2 className="heading_1 ps-lg-4 text-capitalize">{type ? 'Edit' : 'Add'} Department </h2>
                            {error &&
                                <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                                    <span className="me-3">
                                        <img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" />
                                    </span>
                                    {error}
                                </div>
                            }
                            <div className="p-lg-5">
                                <div className="fm_grp ">
                                    <label htmlFor="inputAddress" className="form-label" required>Add a Department name</label>
                                    <input type="text" className={`form-control ${error && !inputName ? "error_input" : ""}`} id="inputAddress" onChange={(e) => { setInputName(e.target.value) }} value={inputName} />
                                </div>
                                <div className="fm_grp">
                                    <label htmlFor="inputDepartment" className="form-label" required>Alternate names for the department (1 per line). Include alternate spellings, abbreviations, etc.</label>
                                    <textarea className={`form-control ${error && !altInputName.length ? "error_input" : ""}`} id="inputDepartment" rows={6} onChange={(e) => setAltInputName(e.target.value)} value={altInputName} />
                                </div>
                                <div className="fm_grp">
                                    <label className="form-check-label mb-2" htmlFor="flexRadioDefault1">Point of contact</label>
                                    {/*  */}
                                    {employeeSearchResult &&
                                        (
                                            <div className="d-flex ForwardSelectedBox">
                                                <div>
                                                    {/* <p>{employeeSearchResult.fullName}</p> */}
                                                    <p>{employeeSearchResult?.employee?.employeeName?.fullName}</p>
                                                    <p>{employeeSearchResult?.employee?.employeeName?.suffix}</p>
                                                    <p>{employeeSearchResult?.employee?.employeeName?.nickNames}</p>
                                                    <p>{employeeSearchResult?.employee?.companyDetails?.address?.address1}</p>
                                                    <p>{employeeSearchResult?.employee?.companyDetails?.department}</p>
                                                    <p>{employeeSearchResult?.employee?.contactDetails?.emailAddress1}{employeeSearchResult?.contactDetails?.emailAddress2 ? ',' + employeeSearchResult?.contactDetails?.emailAddress2 : ''}</p>
                                                </div>
                                                <div>
                                                    <span className='px-2'> <img src="assets/img/Delete.svg" alt="" onClick={() => { setemployeeSearchResult(null); setForwardToId(''); setEmployeeSearchList([]) }} /> </span>
                                                </div>
                                            </div>
                                        )

                                    }
                                    {!employeeSearchResult &&
                                        (
                                            <div className="serach_box" id="dropdownMenuButton2" data-bs-toggle="dropdown">
                                                <input type="text" aria-label="Search" placeholder="Search employees" onChange={(e) => { setSearchKey(e.target.value) }} className={`form-control ${error && !forwardToId ? "error_input" : ""}`} style={{ 'borderColor': error ? 'red' : '' }} defaultValue={forwardToId} />
                                                <a href="#"><span className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
                                            </div>
                                        )
                                    }
                                    {!employeeSearchResult && <ul className={`dropdown bg-light dropdown-menu search_dropdown employee_search_dropdown ${employeeSearchList?.length ? 'show' : ''}`} aria-labelledby="dropdownMenuButton2">
                                        {employeeSearchList?.length < 1 ? (<li>
                                            <a className="dropdown-item" href="#">
                                                <p>No Matches Found</p>
                                            </a>
                                        </li>) : (
                                            employeeSearchList?.map((data, i) => {
                                                return (
                                                    <li key={i} onClick={() => { setForwardToId(data?.employee?.employeeId); setemployeeSearchResult(data); setDropdownState(false) }}>
                                                        <a className="dropdown-item" href="#">
                                                            <p>{data?.employee?.employeeName?.fullName}</p>
                                                            <p>{data?.employee?.employeeName?.suffix}</p>
                                                            <p>{data?.employee?.employeeName?.nickNames}</p>
                                                            <p>{data?.employee?.companyDetails?.address?.address1}</p>
                                                            <p>{data?.employee?.companyDetails?.department}</p>
                                                            <p>{data?.employee?.contactDetails?.emailAddress1}{data?.contactDetails?.emailAddress2 ? ',' + data?.contactDetails?.emailAddress2 : ''}</p>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        )}
                                    </ul>}
                                    {/*  */}
                                    {/* <div  className="serach_box">
                                        <input  className="form-control" type="text" aria-label="Search" placeholder="Search employees" />
                                        <a href="#"><span  className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
                                    </div> */}
                                </div>
                                <div className="fm_grp">
                                    <div className="form-check">
                                        <input className="form-check-input" onClick={() => setNotification("D")} type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label className="form-check-label ms-2" htmlFor="flexRadioDefault1">
                                            Employee is notified daily
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" onClick={() => setNotification("M")} type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                        <label className="form-check-label ms-2" htmlFor="flexRadioDefault2">
                                            Employee is notified for each piece of mail
                                        </label>
                                    </div>
                                </div>
                                <div className="fm_grp">
                                    <label htmlFor="inputMailStop" className="form-label">
                                        * Mail Stop
                                    </label>
                                    <select id="inputMailStop" className={`form-select ${error && !mailStopId ? 'error_input' : ''}`} 
											onChange={handleMailStopChange} value={mailStopId}>
                                        {
											mailStopList?.map((data, i) => 
											{
	                                            return ( <option key={i} value={data.mailStopId}>{data.name}</option> )
                                        	})
										}
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 pe-3">
                                <div> </div>
                                <div className=" text-end">
                                    <button id="closeDepartmentAddModal" onClick={reset_form} type="button" className="btn btn-link btn_link_primary me-3" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" onClick={checkValidate} className="btn btn_cstm_primary px-4">{type ? 'Edit' : 'Add'} Department</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Add Department */}

        </>
    )
}
