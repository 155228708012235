/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'

import Name from '../AccountModals/AccountAdd/Name'
import Company from '../AccountModals/AccountAdd/Company'
import Contact from '../AccountModals/AccountAdd/Contact'
import Account from '../AccountModals/AccountAdd/Account'
import { getService, putService } from '../../../../../../services/commonApi'
import { getCompanyIdToken } from '../../../../../../services/tokenService'

const AccountRequestPop = ({ userSelected, setAccountID, getAccountRequestData }) => 
{
	const [navLink, setNavLink] = useState("name");
	const [nameInput, setNameInput] = useState({});
	const [nameInputError, setNameInputError] = useState();
	const [companyInput, setCompanyInput] = useState({});
	const [companyInputError, setCompanyInputError] = useState();
	const [contactInput, setContactInput] = useState({});
	const [contactInputError, setContactInputError] = useState();
	const [accountInput, setAccountInput] = useState({});
	const [accountInputError, setAccountInputError] = useState();
	const [error, setError] = useState({ err:false, desc:'', name:{err:false,desc:""}, company:{err:false,desc:""}, contact:{err:false,desc:""}, account:{err:false,desc:""} });

	useEffect(() => 
	{
		if (userSelected) 
		{
			getService(`/mercury/company/${getCompanyIdToken()}/requests/${userSelected}`, true).then((res) => 
			{
				setNameInput(res.data?.accountRequest?.employee?.employeeName)
				setCompanyInput(res.data?.accountRequest?.employee?.companyDetails)
				setContactInput(res.data?.accountRequest?.employee?.contactDetails)
				setAccountInput(res.data?.accountRequest?.employeeAccount)
			})
			.catch( (err)  =>
			{
				onError( err?.response?.data?.error )
			})
		}
	}, [userSelected])

	const onError = ( err ) =>
	{
		let error = { err:true, desc:err?.desc + ":", name:{desc:""}, company:{desc:""}, contact:{desc:""}, account:{desc:""} }
		err.fields.forEach( (elem, idx ) =>
		{
			error.desc += "<br>" + err.info[idx]
			switch( elem )
			{
				case "firstName":
				case "title":
				case "middleName":
				case "lastName":
				case "maidenName":
				case "suffix":
				case "nickNames":
					error['name']['err'] = true;
					error['name'][elem] = true;
					error['name']['desc'] = err.info[idx]
					break;
				case "addressId":
				case "mailStopId":
				case "departmentId":
				case "employeeNumber":
				case "position":
				case "costCenterId":
					error['company']['err'] = true;
					error['company'][elem] = true;
					error['company']['desc'] = err.info[idx]
					break;
				case "emailAddress1":
				case "cellPhone":
				case "workPhone":
					error['contact']['err'] = true;
					error['contact'][elem] = true;
					error['contact']['desc'] = err.info[idx]
					break;
				case "userName":
				case "notificationMode":
				case "accountTypeId":
				case "notify":					
					error['account']['err'] = true;
					error['account'][elem] = true;
					error['account']['desc'] = err.info[idx]
					break;
				default:
					break;
			}
		})
		if( error?.name?.err )
		{
			setNameInputError( error.name )
			setNavLink('name')
		}
		if( error?.company?.err )
		{
			setCompanyInputError( error.company );
			setNavLink('company')
		}
		if( error?.contact?.err )
		{
			setContactInputError( error.contact )
			setNavLink('contact')
		}
		if( error?.account?.err )
		{
			setAccountInputError( error.account )
			setNavLink('account')
		}
		setError( error )
	}

	const approveDenyAccReq = (accountInput) => 
	{
		if ((nameInput && !nameInputError?.err) && (companyInput && !companyInputError?.err) && (contactInput && !contactInputError?.err) && (accountInput && !accountInput?.err)) 
		{
			const data = ({ ...nameInput, ...companyInput, ...contactInput, ...accountInput })
			putService(`/mercury/company/${getCompanyIdToken()}/requests/${userSelected}`, data, true).then((res) => 
			{
				getAccountRequestData()
				document.getElementById('closeAccReqButton1').click()
			})
			.catch( (err)  =>
			{
				onError( err?.response?.data?.error )
			})
		}
	}

	const resetData = () => 
	{
		setNameInput('');
		setAccountInput('');
		setCompanyInput('');
		setContactInput('');
		setNavLink('name')
		setAccountID('')
		setNameInputError({err:false,desc:""})
		setAccountInputError({err:false,desc:""});
		setCompanyInputError({err:false,desc:""})
		setContactInputError({err:false,desc:""})
		setError( { err:false, desc:"", name:{err:false,desc:""}, company:{err:false,desc:""}, contact:{err:false,desc:""}, account:{err:false,desc:""} } )
	}

	return (
		<div className="modal fade " id="accountRequestPop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="accountRequestPop" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
				<div className="modal-content">
					<div className="mdl_head">
						<div className="row gx-0">
							<div className="col-12 col-lg-3 col-xl-2">
								<div className="bg_primary">
									<div className="pstn_relative">
										<h5 className="modal-title p-4" id="staticBackdropLabel">
											Account request
										</h5>
										<button
											type="button"
											id='closeAccReqButton1'
											className="btn-close p-4 d-block d-lg-none sm_btn-close"
											data-bs-dismiss="modal"
											onClick={() => resetData()}
											aria-label="Close"
										></button>
									</div>
								</div>
								{/* start responsive */}
								{error?.err && (
									<div
										className="alert alert-danger sm_ctm_alert pl-4 d-block d-lg-none"
										role="alert"
									>
										<span>
											<img
												src="/assets/img/error_icon_white.png"
												className="img-fluid"
												alt=""
											/>
										</span>{" "}
										{error.desc}
									</div>
								)}
							</div>

							<div className="col-12 col-lg-9 col-xl-10 text-end">
								<div className="pstn_relative">
									<button
										type="button"
										className="btn-close btnClose p-2 d-none d-lg-block ms-auto"
										data-bs-dismiss="modal"
										aria-label="Close"
									></button>
									{error?.err && (
										<div className="alert alert-danger ctm_alert d-none d-lg-block" role="alert" >
											<span className="me-3">
												<img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" />
											</span>{" "}
											{error.desc}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="modal-body p-lg-0">
						<div className="row gx-0">
							{/* <!-- Start Left nav-link --> */}
							<div className="col-lg-3 col-xl-2 bg_primary mb-4 mb-lg-0">
								<div className="nav flex-column nav-pills" aria-orientation="vertical"> 
									<button onClick={() => setNavLink("name")} className={`nav-link ${navLink === "name" ? "active" : "" }`} id="v-pills-home-tab" type="button" 
									>
										Names
										{nameInputError?.err && <span className="ms-3">
												<img
													src="assets/img/error_icon.svg"
													className="img-fluid"
													alt=""
												/>
											</span>}
									</button>
									<button
										onClick={() => setNavLink("company")}
										className={`nav-link ${navLink === "company" ? "active" : ""
											}`}
										id="v-pills-profile-tab"
										type="button"
									>
										Company info
											{companyInputError?.err && <span className="ms-3">
												<img
													src="assets/img/error_icon.svg"
													className="img-fluid"
													alt=""
												/>
											</span>}
									</button>
									<button
										onClick={() => setNavLink("contact")}
										className={`nav-link ${navLink === "contact" ? "active" : ""
											}`}
										id="v-pills-messages-tab"
										type="button"
									>
										Contact info
										{contactInputError?.err && <span className="ms-3">
											<img
												src="assets/img/error_icon.svg"
												className="img-fluid"
												alt=""
											/>
										</span>}
									</button>
									<button
										onClick={() => setNavLink("account")}
										className={`nav-link ${navLink === "account" ? "active" : ""
											}`}
										id="v-pills-settings-tab"
										type="button"
									>
										Account info
											{accountInputError?.err && <span className="ms-3">
												<img
													src="assets/img/error_icon.svg"
													className="img-fluid"
													alt=""
												/>
											</span>}
									</button>
								</div>
							</div>

							{/* <!-- Start right Tab-content --> */}
							<div className="col-lg-9 col-xl-10">
								<div className="tab-content">
									{/* <!-- Start Name Tab-pane--> */}
									{navLink === "name" && (
										<Name
											navLink={navLink}
											setNavLink={setNavLink}
											error={nameInputError}
											setError={setNameInputError}
											nameInput={nameInput}
											setNameInput={setNameInput}
											mode={'request'}
											resetData={resetData}
										/>
									)}
									{/* <!-- End Name Tab-pane--> */}

									{/* <!-- Start Comany Info Tab-pane--> */}
									{navLink === "company" && (
										<Company
											navLink={navLink}
											setNavLink={setNavLink}
											error={companyInputError}
											setError={setCompanyInputError}
											companyInput={companyInput}
											setCompanyInput={setCompanyInput}
											mode={'request'}
											resetData={resetData}
										/>
									)}
									{/* <!-- End Comany Info Tab-pane--> */}

									{/* <!-- End Contact Info Tab-pane--> */}
									{navLink === "contact" && (
										<Contact
											navLink={navLink}
											setNavLink={setNavLink}
											error={contactInputError}
											setError={setContactInputError}
											contactInput={contactInput}
											setContactInput={setContactInput}
											mode={'request'}
											resetData={resetData}
										/>
									)}
									{/* <!-- End Contact Info Tab-pane--> */}

									{/* <!-- End Account Info Tab-pane--> */}
									{navLink === "account" && (
										<Account
											navLink={navLink}
											setNavLink={setNavLink}
											error={accountInputError}
											setError={setAccountInputError}
											accountInput={accountInput}
											setAccountInput={setAccountInput}
											mode={'request'}
											callbackfunction={approveDenyAccReq}
											resetData={resetData}
										/>
									)}
									{/* <!-- End Account Info Tab-pane--> */}
								</div>
							</div>
							{/* <!-- End right Tab-content --> */}

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AccountRequestPop