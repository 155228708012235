/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { AuthState } from '../../../../context/authContext'
import CostCenterDelete from './Modals/CostCenterModal/CostCenterDelete'
import CostCenterAdd from './Modals/CostCenterModal/CostCenterAdd'
import { getService } from '../../../../services/commonApi'
import { getCompanyIdToken } from '../../../../services/tokenService'


const CostCenter = ({ activeTab }) => {
    const { databaseRoute, setDatabaseRoute } = AuthState()
    const [dataToDelete, setDataToDelete] = useState("")
    const [costCenterList, setCostCenterList] = useState([])
    const [sortby, setSortby] = useState()
    const [filter, setFilter] = useState()
    var start = 1;
    var stop = 30;
    var primary = true;

    var costCenterUrl = `/mercury/company/${getCompanyIdToken()}/costcenter`
    useEffect(() => {
        if (databaseRoute === 'costCenter') {
            getCostCenterList()
        }
    }, [databaseRoute])

    const getCostCenterList = () => {
        getService(`${costCenterUrl}/list`, true, {
            params: {
                primary, sortby, filter
            }
        }).then((res) => {
            if (res?.status === 200) {
                setCostCenterList(res?.data?.list)
            }
        })
    }

    const sortByAlpha = (alpha) => {
        let data = document.querySelectorAll(".name")
        let count = 0
        data.forEach((item, i) => {
            let name = item.innerText
            if (name.charAt(0).toLocaleLowerCase().charCodeAt(0) > (alpha.charCodeAt(0) - 1)) {
                count++
                if (count === 1) {
                    item.scrollIntoView(true)
                }
                return
            }
        })
    }
    useEffect(() => {
        const search = setTimeout(() => {
            if (databaseRoute === 'costCenter') {
                getCostCenterList()
            }
        }, 1500);
        return () => clearTimeout(search)
    }, [filter, sortby])
    // const data = [
    //     { text: "Cost Center name 1" },
    //     { text: "Cost Center 2 very long nam… Cost Center 2 very long nam…" },
    //     { text: "Cost Center name 3" },
    //     { text: "Cost Center name 4" },
    //     { text: "Cost Center name 1" },
    //     { text: "Cost Center 2 very long nam…" },
    //     { text: "Cost Center name 3" },
    //     { text: "Cost Center name 4" },
    //     { text: "Cost Center name 1" },
    //     { text: "Cost Center 2 very long nam…" },
    //     { text: "Address line 1" },
    //     { text: "Address line 1" },
    // ]
    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-e" onClick={() => { setDatabaseRoute('costCenter') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-e" aria-expanded="true" aria-controls="collapse-e">
                        Cost Centers <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-e" className={`collapse ${databaseRoute === "costCenter" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-e">
                <div className="head d-none d-md-block">
                    <h4 className="heading_1 text_dark mb-0">Cost Centers</h4>
                </div>
                <div className="p-4">
                    <p> Please enter all of your company’s Cost Centers that the system will use to receive mail, and keep this list current. If the list is not kept current, Cost Centers for mail may be inaccurate.<a className="text_dark">More…</a></p>
                </div>
                <div className="p-4  pt-0 col-lg-12">
                    <div className="row">
                        <div className="col-lg-11">
                            {/* <!-- Start search--> */}
                            <div className="col-md-5 col-xl-4">
                                <div className="serach_box mb-3">
                                    <input className="form-control" type="text" aria-label="Search" placeholder="Search Cost Center" onInput={(e) => setFilter(e.target.value)} value={filter} />
                                    <a ><span className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
                                </div>
                            </div>
                            {/* <!-- Start search -->
                                    <!-- Start Table --> */}
                            <div className="row">
                                <div className="col-md-8 col-xl-4">
                                    <div className="table-responsive cst_table company_name_table">
                                        <table className="table">
                                            <tbody>
                                                {
                                                    costCenterList?.map((item, i) => {
                                                        return (

                                                            <tr key={i}>
                                                                <td className="name" style={{ width: '80%' }}>{item.name}</td>
                                                                <td><a onClick={() => setDataToDelete(item)} data-bs-toggle="modal" data-bs-target="#cost_centers_delete"><img src="assets/img/Delete.svg" alt="" /> </a></td>
                                                            </tr>

                                                        )
                                                    })
                                                }
                                                {/* <tr>
                                                <td>Cost Center name 1</td>
                                                <td><a  data-bs-toggle="modal" data-bs-target="#cost_centers_delete"><img src="assets/img/Delete.svg" alt="" /> </a></td>
                                            </tr> */}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* Add Alphabet Filter */}
                                <div className="col-md-2 col-xl-1">
                                    <div className="alphabet pt-lg-0 pt-md-3">
                                        <div onClick={() => sortByAlpha("a")}>A - B</div>
                                        <div onClick={() => sortByAlpha("c")}>C - D</div>
                                        <div onClick={() => sortByAlpha("e")}>E - F</div>
                                        <div onClick={() => sortByAlpha("g")}>G - H</div>
                                        <div onClick={() => sortByAlpha("i")}>I - J</div>
                                        <div onClick={() => sortByAlpha("k")}>K - L</div>
                                        <div onClick={() => sortByAlpha("m")}>M - N</div>
                                        <div onClick={() => sortByAlpha("o")}>O - P</div>
                                        <div onClick={() => sortByAlpha("q")}>Q - R</div>
                                        <div onClick={() => sortByAlpha("s")}>S - T</div>
                                        <div onClick={() => sortByAlpha("u")}>U - V</div>
                                        <div onClick={() => sortByAlpha("w")}>W - X</div>
                                        <div onClick={() => sortByAlpha("y")}>Y - Z</div>
                                        <div onClick={() => sortByAlpha("0")}>0 - 3</div>
                                        <div onClick={() => sortByAlpha("4")}>4 - 6</div>
                                        <div onClick={() => sortByAlpha("7")}>7 - 9</div>
                                    </div>
                                </div>
                                {/* End of Filter */}
                            </div>

                            {/* <!-- End Table -->
                                    <!-- Add an Account --> */}
                            <div className="add_account mt-4">
                                <a data-bs-toggle="modal" data-bs-target="#AddCostCenter"> <img src="assets/img/add_account.svg" alt="" /><span>Add a Cost Center</span></a>
                            </div>
                            {/* <!-- Add an Account --> */}
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            <CostCenterDelete data={dataToDelete} url={costCenterUrl} callList={getCostCenterList} />
            <CostCenterAdd url={costCenterUrl} callList={getCostCenterList} />
        </>
    )
}

export default CostCenter