import React, { useState } from 'react'
import { getService, putService } from '../../../../../../services/commonApi'
import Select from 'react-select';
import { getFirstName } from '../../../../../../pipes/stringPipes';


const ReassignMail = ({ userID, addressUrl, deleteName, setuserID }) => {

    const [reassign_to, setReplacementID] = useState()
    const [employeeSearchList, setEmployeeSearchList] = useState([])
    const [error, setError] = useState(false)


    const searchEmployee = (name) => {
        getService(`/mercury/employee/search?name=${name}`, true).then(
            (res) => {
                if (res?.status === 200) {
                    setEmployeeSearchList(res.data?.employeeNames)
                }
                // console.log(res?.data?.searchResults?.employeeNames)
            }
        )
    }

    const reassignMain = () => {
        putService(`${addressUrl}/${userID}/mail/reassign_mail`, '', true, {
            params: {
                reassign_to
            }
        }).then(
            (res) => {
                if (res?.status === 200) {
                    setuserID('')
                    document.getElementById('Closereassignmail').click()
                }
            }
        )
    }

    const resetForm = () => {
        setError('')
        setuserID('')
    }

    return (




        <>
            <div className="modal fade cost_centers" id="reassignPassword" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body py-4 px-lg-0">
                            <h2 className="heading_1 ps-lg-4">Reassign mail</h2>
                            {/*  Error alert box  */}
                            {/* <div  className="alert alert-danger alert_danger ps-lg-5" role="alert">
                          <span  className="me-3"><img src="/assests/img/error_icon_white.png"  className="img-fluid" alt="Image"></span>  A This company name already exists. Please provide another.
                      </div> */}
                            <div className="p-lg-5">
                                <div className="col-xl-9 m-auto py-4">
                                    <div className="fm_grp">
                                        <label htmlFor="MAIL" className="form-label">* Reassign {getFirstName(deleteName, 1)}’s mail to:</label>
                                        <div className="serach_box">
                                            {/* <input id="MAIL"  className="form-control" type="text" aria-label="Search" placeholder="Search accounts" />
                                        <a href="#"><span  className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a> */}

                                            <Select
                                                id='MAIL'
                                                onInputChange={(e) => searchEmployee(e)}
                                                className={`${error && !reassign_to ? 'error_inupt' : ''}`}
                                                onChange={(e) => setReplacementID(e?.value)}
                                                isClearable={true}
                                                isSearchable={true}
                                                name="label"
                                                options={employeeSearchList?.map((data) => (
                                                    { 'value': data?.employeeId, 'label': data?.fullName }
                                                ))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 px-3">
                                <div> </div>
                                <div className=" text-end">
                                    <button type="button" id="Closereassignmail" className="btn btn-link  btn_link_primary" onClick={() => resetForm()} data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn_cstm_primary" onClick={() => reassignMain()}>Reassign</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReassignMail