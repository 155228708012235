/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getService } from '../../../../../services/commonApi'


export default function TrashMailModal() {
    const [folders, setFolders] = useState()
    const [activeFolder, setActiveFolder] = useState(0)

    const url = `/mercury/mail/mailboxes/folders`

    useEffect(() => {
        getFolders()
    }, [])

    const getFolders = () => {
        getService(url, true).then((res) => {
            // console.log(res)
            setFolders(res.data.folders)
        })
    }

    return (
        <div className="w_155 offcanvas offcanvas-start trashInboxmail z-9999" tabIndex="-1" id="trashInboxmail" aria-labelledby="trashInboxmailoffcanvasExampleLabel">
            <div className="off_short_layout"></div>
            <div className="offcanvas-body pe-2">
                <div className="topinbox_title mb-3">
                    <a data-bs-toggle="offcanvas" href="#trashInboxmail" className="bi_folde">
                        <i className="bi bi-folder me-2"></i>
                        <i className="bi bi-caret-down-fill"></i>
                    </a>
                    <span>Folders</span>
                </div>
                <div>
                    <ul className="list-group">
                        {
                            folders?.map((item, i) => {
                                return (
                                    <Link key={i} to={`/mail/${item.folderId}`} style={{ textDecoration: 'none' }}>
                                        <li data-bs-toggle="offcanvas" data-bs-target="#trashInboxmail" onClick={() => { setActiveFolder(i) }} className={`list-group-item  ${activeFolder === i && "active"}`}>
                                            {item?.name} <span className="badge rounded-pill">{item?.unreadMail}</span>
                                        </li>
                                    </Link>
                                )
                            })
                        }
                    </ul>
                    <div data-bs-toggle="offcanvas" href="#trashInboxmail" className="add_items" style={{ position: 'relative', 'zIndex': '99999999999999', cursor: 'pointer' }}>
                        <a data-bs-toggle="modal" data-bs-target="#add_folder"><img src="/assets/img/add_account.svg" alt="" />
                            <span className="ms-2">Add folder</span></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
