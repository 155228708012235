import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'

const CompanyNameItem = ({ data }) => {
    console.log(data)
    return (
        <>
			<div className='search_results_item' id='companyname_search_results'>
				<span className="d-flex">
					<p><b>Company Names</b></p>
				</span>
				{data?.map((ele, i) => (
					<Dropdown.Item key={i} className="help_box" as={'div'}  >
						<span className="d-flex" >
							<b>{ele?.name}</b>
						</span>
					</Dropdown.Item>
				))}
			</div>
        </>
    )
}

export default CompanyNameItem