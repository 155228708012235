/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { RouteState } from '../../../../context/routeContext'
import { stringPipe } from '../../../../pipes/stringPipes'
import { getService } from '../../../../services/commonApi'
import RequestDetails from './modals/RequestDetails'
import Deny from './modals/Deny'
import { CommonState } from '../../../../context/commonContext'

const OriginalRequest = () => {
    const { deliveryRoute, setDeliveryRoute } = RouteState()

    const [data, setData] = useState('')
    const [originalReqList, setOriginalReqList] = useState([])

    const [sortby, setSortby] = useState()
    const [status, setStatus] = useState()

    const [type, setType] = useState('')
    const { refreshMailStats, setRefreshMailStats } = CommonState()

    var url = useRef('/mercury/mail/originalrequests')


    // var sortFilter = ['Last Name',
    //     'Email',
    //     'Department',
    //     'User Name',
    //     'Mailstop',
    //     'Address',
    //     'Position',
    //     'Cost Center',
    //     'Employee Number',
    //     'Account Type']

    useEffect(() => {
        if (deliveryRoute === 'original') {
            getOriginalReqList()
        }
    }, [deliveryRoute])

    useEffect(() => {
        if (status || sortby) {
            getOriginalReqList()
        }
    }, [status, sortby])


    const getOriginalReqList = () => {
        getService(url.current, true, {
            params: {
                status, sortby
            }
        }).then(
            (res) => {
                // console.log(res)                
                setOriginalReqList(res?.data?.requests)
            }
        )
    }

    const refreshFunc = () => {
        setRefreshMailStats(!refreshMailStats)
    }


    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-C" onClick={() => { setDeliveryRoute('original') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-C" aria-expanded="true" aria-controls="collapse-C">
                        Original Request  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-C" className={`bgYellow collapse ${deliveryRoute === "original" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-C">
                <div className="head d-none d-lg-block">
                    <h4 className="heading_1 text_dark mb-0">Requests for Original</h4>
                </div>
                <div className="p-3">
                    <p>Track all requests for original copies</p>
                </div>
                <div className="p-3 pt-0">
                    {/* <!-- Start search--> */}
                    <div className="row mb-3">
                        <div className="col-md-6 col-xl-2">
                            <label htmlFor="" className="sm_label">Status</label>
                            <select className="form-select form-select-sm w_lg_7" onChange={(e) => setStatus(e.target.value)}>
                                <option value={''}>Any</option>
                                <option value="fulfilled">Fulfilled</option>
                                <option value="denied">Denied</option>
                            </select>
                        </div>
                        {/* <div className="col-md-6 col-xl-2 offset-xl-8 align-self-end sort">
                            <select className="form-select form-select-sm w_lg_75 ms-auto select_primary" onChange={(e) => setSortby(e.target.value)}>
                                <option defaultValue={''} hidden disabled>Sort</option>
                                {sortFilter.map((data, i) => (
                                    <option key={i} value={(data).toLocaleLowerCase().replace(' ', '')}>{data}</option>
                                ))}
                            </select>
                        </div> */}
                    </div>
                    {/* <!-- Start search --> */}

                    {/* <!-- Start Table --> */}
                    <div className="table-responsive cst_border cst_table requests_original">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date requested</th>
                                    <th>Requester name</th>
                                    <th>Request ID</th>
                                    <th className='text-center'>Available?</th>
                                    <th>Date digitized</th>
                                    <th>Fulfilled?</th>
                                    <th>Deny?</th>
                                    <th>Date fulfilled</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {originalReqList?.map((data, i) => (
                                    <tr key={i}>
                                        <td>{new Date(Date.parse(data?.requestDate)).toLocaleDateString()}</td>
                                        <td>{data?.employeeName}</td>
                                        <td>{stringPipe(data?.trackingCode, 14)}</td>
                                        <td style={{ 'fontSize': '1rem' }} className='text-center fw-bold'><i className={`bi ${data?.documentStatus === 'IN' ? 'bi-check-lg text-success' : 'bi-x-lg text-danger'}`}></i></td>
                                        <td>{new Date(Date.parse(data?.batchDate)).toLocaleDateString()}</td>
                                        <td>
                                            <div className="mb-0 form-check form-check-dark">
                                                <input type="checkbox" className="form-check-input" data-bs-toggle="modal" data-bs-target="#request_details" onChange={() => { setType('fullfill'); setData(data?.requestId) }} checked={(data?.fulfilled && !data?.denied) ? true : false} disabled={data?.fulfilledDate || data?.denied} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="mb-0 form-check form-check-dark">
                                                <input type="checkbox" className="form-check-input" data-bs-toggle="modal" data-bs-target="#deny" onChange={() => setData(data?.requestId)} checked={data?.denied ? true : false} disabled={data?.fulfilledDate || data?.denied} />
                                            </div>
                                        </td>
                                        <td>{data?.fulfilledDate ? new Date(Date.parse(data?.fulfilledDate)).toLocaleDateString() : 'N/A'}</td>
                                        <td>
                                            <a onClick={() => { setType('detail'); setData(data?.requestId) }} data-bs-toggle="modal" data-bs-target="#request_details"> <img src="assets/img/Print.png" alt="" /></a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <!-- End Table --> */}
                </div>

            </div>
            <RequestDetails refresh={refreshFunc} getOriginalReqList={getOriginalReqList} url={url.current} data={data} type={type} />
            {/* <Deny /> */}
            <Deny refresh={refreshFunc} getOriginalReqList={getOriginalReqList} url={url.current} data={data} />
        </>
    )
}

export default OriginalRequest