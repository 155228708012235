/*
This code defines a React component "MailTabSidebar". The component displays a list of mail folders and allows a user to view the mails in a selected folder.
It uses the useEffect hook to fetch folder data and mails data from an API. The data is saved in the state using setFolders and setFolderMail respectively. 
The component also allows for sorting the mails in a folder by different criteria, such as date received, sender name, and page count. The sorting is 
implemented using the sortby state, which is changed based on the user's selection.

Additionally, the component has logic to display a new mail banner whenever a new mail is received. This is done by checking the difference 
between the old and new mail data, and if a change is detected, a pop-up is triggered by clicking an element with the id "popNewMailAlert". 
The pop-up will automatically close after 10 seconds.

*/

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { CommonState } from '../../../../context/commonContext'
import { getService } from '../../../../services/commonApi'
import { stringPipe } from '../../../../pipes/stringPipes'
import { Link, useParams } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';

function MailTabSidebar() 
{
	var id = useParams();
	const { setToggleMailMobilebar,
			refreshFolderData,
			refreshMailData,
			setRefreshFolderData,
			hasNewMail,
			currentFolder, setCurrentFolder,
			folderList,setFolderList, setActionFolder 
	} = CommonState()

	const [folderInfo, setFolderInfo] = useState()
	const [folderMail, setFolderMail] = useState([])
	const [folderTab, setfolderTab] = useState(false)		// toggle folder pane open or closed.
	const [sortby, setsortby] = useState('received')
	const [unreadCounts, setUnreadCounts] = useState( folderList?.map((item) => item.unreadMail ) )

	var sortList = 
	[
		{ value: 'received', text: 'Date received' }, 
		{ value: 'sender', text: 'Sender name' }, 
		{ value: 'pagecount', text: 'Page count' }
	]
	const off_short_layout = document.querySelector(".off_short_layout")

	off_short_layout && off_short_layout.addEventListener('click', () => 
	{
		document.querySelector(".topinbox_title .bi_folde").click()
	})

	useEffect(() =>
	{
		if( folderList )
		{
			const updatedUnreadCounts = new Array( folderList.length ).fill(0);
			folderList?.map((item,i) => updatedUnreadCounts[ i ] = item.unreadMail );
			setUnreadCounts(updatedUnreadCounts);
		}
	}, [folderList])


	useEffect(() => 
	{
		console.log( "refreshMailData id.id : "+id.id)
		if( id?.id )
		{
			getFolderMail(`/mercury/mail/mailboxes/folders/${id.id}`)
		}
	}, [refreshMailData])

	// detected a change in new mail from the folder info, 
	// update the folder mail list and open the notice.
	useEffect(() => 
	{
		console.log( "hasNewMail : "+hasNewMail)
		if( hasNewMail ) 
		{
			let url = `/mercury/mail/mailboxes/folders/${id.id}`
			getFolderMail(url)
		}
	}, [hasNewMail])


	useEffect(() => 
	{
		console.log( "id.id : "+id.id)
		if( id.id ) 
		{
			let url = `/mercury/mail/mailboxes/folders/${id.id}`
			getFolderMail(url)
			getFolderInfo(url)
		}
	}, [id.id, sortby, refreshMailData])

	useEffect( () =>
	{
		console.log( "mailtab : "+refreshFolderData)
		if( refreshFolderData )
		{
			let url = `/mercury/mail/mailboxes/folders/${id.id}`
			setRefreshFolderData( false )
			getFolderMail(url)
			getFolderInfo(url)
		}
	}, [refreshFolderData])
	


	const getFolderMail = (url) => 
	{
		const params = { params: { sortby } }
		getService(url + '/mail', true, params).then((res) => 
		{
			setFolderMail(res.data?.messages)
		})
	}

	const getFolderInfo = (url) => 
	{
		getService(url, true, "").then((res) => 
		{
			let updatedFolderList = [...folderList]; 
			folderList?.map((item, i) => 
			{ 
				if( item.folderId === res?.data.folderId )  
				{
					updatedFolderList[ i ] = res?.data; 
				}
			})
			setCurrentFolder(res?.data)
			setFolderInfo(res?.data)
			setFolderList( updatedFolderList)
		})
	}

	const updateMailRecord = (i, mail) => 
	{
		if( !folderMail[i].viewed )
		{
			setCurrentFolder(currentFolder)
			folderMail[i].viewed = new Date();
		}
		setFolderMail(folderMail)
	}

	return (
		<>
			<div className="ms-5-md h-100">
				<div className="trash d-flex gx-0 h-100">
					{folderTab && <div className="trashInboxmail folder-view w-100">
						<div className="ps-2 folder_box py-3 h-100">
							<div className="topinbox_title mb-3">
								<a onClick={() => setfolderTab(!folderTab)} className="bi_folde">
									<i className="bi bi-folder me-2"></i>
									<i className="bi bi-caret-down-fill"></i>
								</a>
								<span>Folders</span>
							</div>
							<div className="folder_box_list">
								<ul className="list-group">
									{
										folderList?.map((item, i) => 
										{
											return (
												<li key={i} to={`/mail/${item.folderId}`} className={`list-group-item d-flex justify-content-between  ${id.id === item.folderId && "active"}`}>
													<div className={`folder-list-item`} >
														<Link key={i} to={`/mail/${item.folderId}`} style={{ textDecoration:'none' }} onClick={ () => setCurrentFolder( item ) }>
															<div className="w-100 text-truncate folder_item">
																{item?.name}
															</div>
														</Link>
														<div className="d-flex justify-content-end align-items-center">
															<span className="badge rounded-pill">{ unreadCounts[i] }</span>
														</div>
														{
															item?.modifiable && 
																<span onClick={ () => setActionFolder( item ) } data-bs-toggle="modal" data-bs-target="#delete_folder" >
																	<i className="bi bi-x-circle-fill ms-1 moz_folder webkit_folder"></i>
																</span>
														}
													</div>
												</li>
											)
										})
									}
								</ul>
							</div>

							<div className="add_items">
								<a href="#" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#add_folder"> <img src="/assets/img/add_account.svg" alt="" /><span className="ms-2">Add folder</span></a>
							</div>

						</div>
					</div>}

					<div className="px-1 ps-md-3 py-3 inbox_box">

						<div className="topinbox_title">
							{!folderTab &&
								<a onClick={() => setfolderTab(!folderTab)} className="bi_folde">
									<i className="bi bi-folder me-2"></i>
									<i className="bi bi-caret-right-fill"></i>
								</a>
							}
							<span >{folderInfo?.unreadMail ? folderInfo?.name+'('+folderInfo?.unreadMail+')' : folderInfo?.name}</span>
						</div>

						<ul className="inbox_list my-3">
							{
								folderMail?.map((item, i) => 
								{
									return (
										<li key={i} onClick={() => {
											updateMailRecord(i, item?.mailId)
										}} className={item?.mailId === id?.mailId ? 'Mailactive' : ''}>
											<Link to={`/mail/${id?.id}/${item?.mailId}`} style={{ 'textDecoration': 'none' }} >
												<p onClick={() => { setToggleMailMobilebar(false) }}>{
													item.viewed ? <small> {stringPipe(item?.sender, folderTab ? 15 : 100)}</small> : stringPipe(item?.sender, folderTab ? 15 : 100)
												}</p>
												<p><small>{new Date(item?.received).toLocaleDateString()} at {new Date(item?.received).toLocaleTimeString('en-US', { hour12: false })}</small></p>
											</Link>
										</li>
									)
								})
							}
						</ul>
						{/* <!-- Default dropup button --> */}
						{
							id.id === 'trash' &&
							<div className="empty text-center">
								<a href="#" data-bs-toggle="modal" data-bs-target="#empty_trash"> <img src="/assets/img/trash.png" alt="" /><span className="ms-2">Empty trash</span></a>
							</div>
						}
						{id.id !== 'trash' &&
							<Dropdown className='sort_down'>
								<Dropdown.Toggle as={'button'} variant="none" className="btn" id="dropdown-basic">
									<img src="/assets/img/sort-down.png" alt="" /> <i className="bi bi-chevron-down"></i>
									{
										sortList?.filter((el) => el.value === sortby).map((el) => 
										{
											return el.text;
										})
									}
								</Dropdown.Toggle>

								<Dropdown.Menu as={'ul'} style={{ 'backgroundColor': '#fff' }}>
									{
										sortList?.map((data, i) => 
										(
											<li key={i}><Dropdown.Item >{sortby === data?.value && <i className="bi bi-check me-1"></i>}<span onClick={() => setsortby(data.value)}>{data?.text}</span></Dropdown.Item></li>
										))
									}
								</Dropdown.Menu>
							</Dropdown>

						}
						{/* <!-- Default dropup button --> */}
					</div>

				</div>
				<a data-bs-toggle="offcanvas" aria-controls="offcanvasScrolling" data-bs-target="#newMailBanner" id='popNewMailAlert' className='d-none'>o</a>
			</div>
		</>
	)
}

export default MailTabSidebar