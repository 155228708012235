import React, { useState } from 'react'
import { postService } from '../../../../../services/commonApi'

const AddWorkstation = ({ url, getWorkstationList }) => {

    const [workstationName, setWorkstationName] = useState('')
    const [hostName, setHostName] = useState('')
    const [error, setError] = useState('')


    const addWorkstation = () => {
        if (!workstationName || !hostName) {
            return setError('Please fill missing fields')
        } else {
            postService(url, {
                workstationName, hostName
            }, true).then((result) => {
                getWorkstationList()
                document.getElementById('closeWorkstationModal').click()
            }).catch((err) => {
                setError(err.error.message)
            });
        }

    }




    const resetForm = () => {
        setError(false)
        setWorkstationName('')
        setHostName('')
    }


    return (
        <div className="modal fade edit_account_types" id="add_Workstation" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body p-4">
                        <h2 className="heading_1 mb-3">Add Workstation</h2>
                        {error && (
                            <div
                                className="alert alert-danger sm_ctm_alert pl-4 d-block"
                                role="alert"
                            >
                                <span>
                                    <img
                                        src="/assets/img/error_icon_white.png"
                                        className="img-fluid"
                                        alt=""
                                    />
                                </span>{" "}
                                {error}
                            </div>
                        )}
                        <div>
                            <div>
                                <div className="fm_grp">
                                    <label htmlFor="name" className="form-label">Workstation name</label>
                                    <input type="text" className={`form-control ${error && !workstationName ? 'errorBox' : ''}`} id="name" onInput={(e) => { setWorkstationName(e.target.value) }} value={workstationName} />
                                </div>
                                <div className="fm_grp">
                                    <label htmlFor="descr" className="form-label">Workstation hostname or IP address</label>
                                    <input type="text" className={`form-control ${error && !hostName ? 'errorBox' : ''}`} id="descr" onInput={(e) => { setHostName(e.target.value) }} value={hostName} />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-end mt-5 pt-lg-5">
                            <div className="text-end">
                                <button type="button" className="btn btn-link btn_link_primary" data-bs-dismiss="modal" id="closeWorkstationModal" onClick={() => resetForm()}>Cancel</button>
                                <button type="button" className="btn btn_cstm_primary px-3" onClick={() => { addWorkstation() }} >Add Workstation</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddWorkstation
