import React, { useState } from 'react'
import { deleteService } from '../../../../../services/commonApi'

const ProcessingDelete = ({ refreshList, url }) => 
{
	const [inputtext, setInputtext] = useState('')
	const [check, setcheck] = useState(false)

	function deleteMail() 
	{
		deleteService(url, '', true).then((res) => 
		{
			refreshList()
			setInputtext('')
			setcheck(false)
			document.getElementById('closedeleteJobsMailModal').click()
		})
	}

	return (
		<div className="modal fade Processing_delete" id="Processing_delete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content">
					<div className="modal-body py-5">
						<div className="h-100">
							<div className="col-xl-8 m-auto">
								<h2 className="heading_1 mb-4">Delete</h2>
								<div className="fm_grp">
									<label htmlFor="Firstname" className="form-label">Type the word DELETE to delete this job:</label>
									<input type="text" className="form-control" id="inputAddress" placeholder="" onChange={(e) => setInputtext(e.target.value)} />
								</div>
								<div className="fm_grp">
									<div className="mb-3 form-check">
										<input type="checkbox" className="form-check-input" id="operator" onChange={() => setcheck(!check)} />
										<label htmlFor="operator" className="form-label ms-2"> Yes, I have spoken to the operator.</label>
									</div>
								</div>
							</div>
							<div className="d-flex flex-wrap align-items-center justify-content-end mt-5 pt-5">
								<div className="text-end">
									<button type="button" className="btn btn-link btn_link_primary" id='closedeleteJobsMailModal' data-bs-dismiss="modal">Cancel</button>
									<button type="button" className="btn btn_cstm_primary" disabled={!(inputtext === 'DELETE' && check)} onClick={() => deleteMail()}>Delete</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProcessingDelete