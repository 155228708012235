/* eslint-disable no-unused-vars */
import { createContext, useContext, useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom";

const RouteContext = createContext();

const RouteProvider = ({ children }) => {
  const [databaseRoute, setDatabaseRoute] = useState("accounts");
  const [deliveryRoute, setDeliveryRoute] = useState("processing");
  const [dashboardRoute, setDashboardRoute] = useState("dashboard");
  const [systemRoute, setSystemRoute] = useState("accountType");

  //   const navigate = useNavigate();
  //   const location = useLocation();

  return (
    <RouteContext.Provider
      value={{
        databaseRoute,
        setDatabaseRoute,
        deliveryRoute,
        setDeliveryRoute,
        dashboardRoute,
        setDashboardRoute,
        systemRoute,
        setSystemRoute,
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};

export const RouteState = () => {
  return useContext(RouteContext);
};

export default RouteProvider;
