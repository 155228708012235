import React from 'react'
import { AuthState } from '../../../../context/authContext'

const DatabaseTabSidebar = () => {

    const { databaseRoute, setDatabaseRoute } = AuthState('accounts')

    return (
        <ul className="nav nav-tabs responsive_tabs ps-lg-3" role="tablist">
            {/* <Routes>
                        <Route path='/' element={<Database />}>
                            <Route path='accounts' element={<Accounts />} />
                            <Route path='accountsRequest' element={<AccountsRequest />} />
                            <Route path='company' element={<CompanyName />} />
                            <Route path='address' element={<Address />} />
                        </Route>
                    </Routes> */}
            <li className="nav-item">
                <a id="tab-A" href="#pane-A" data-bs-toggle="tab" role="tab" onClick={() => setDatabaseRoute("accounts")} className={`nav-link ${databaseRoute === 'accounts' ? 'active' : ''}`}>Accounts
                </a>
            </li>
            <li className="nav-item">
                <a id="tab-B" href="#pane-B" data-bs-toggle="tab" role="tab" onClick={() => setDatabaseRoute("accountRequest")} className={`nav-link ${databaseRoute === 'accountRequest' ? 'active' : ''}`}>Accounts Requested</a>
                {/* <Link to="/accountsRequest">
                        </Link> */}
            </li>
            <li className="nav-item">
                <a id="tab-C" href="#pane-C" data-bs-toggle="tab" role="tab" onClick={() => setDatabaseRoute("address")} className={`nav-link ${databaseRoute === 'address' ? 'active' : ''}`}>Addresses</a>
                {/* <Link to="/company">
                        </Link> */}
            </li>
            <li className="nav-item">
                <a id="tab-D" href="#pane-D" data-bs-toggle="tab" role="tab" onClick={() => setDatabaseRoute("companyName")} className={`nav-link ${databaseRoute === 'companyName' ? 'active' : ''}`}>Company Names</a>
                {/* <Link to="/address">
                        </Link> */}
            </li>
            <li className="nav-item">
                <a id="tab-E" href="#pane-E" data-bs-toggle="tab" role="tab" onClick={() => setDatabaseRoute("costCenter")} className={`nav-link ${databaseRoute === 'costCenter' ? 'active' : ''}`}>Cost Center</a>
                {/* <Link to="/address">
                        </Link> */}
            </li>
            <li className="nav-item">
                <a id="tab-F" href="#pane-F" data-bs-toggle="tab" role="tab" onClick={() => setDatabaseRoute("departments")} className={`nav-link ${databaseRoute === 'departments' ? 'active' : ''}`}>Departments</a>
                {/* <Link to="/address">
                        </Link> */}
            </li>
            <li className="nav-item">
                <a id="tab-G" href="#pane-G" data-bs-toggle="tab" role="tab" onClick={() => setDatabaseRoute("mailStops")} className={`nav-link ${databaseRoute === 'mailStops' ? 'active' : ''}`}>Mail Stops</a>
                {/* <Link to="/address">
                        </Link> */}
            </li>
            <li className="nav-item">
                <a id="tab-H" href="#pane-H" data-bs-toggle="tab" role="tab" onClick={() => setDatabaseRoute("position")} className={`nav-link ${databaseRoute === 'position' ? 'active' : ''}`}>Positions</a>
                {/* <Link to="/address">
                        </Link> */}
            </li>
            <li className="nav-item">
                <a id="tab-I" href="#pane-I" data-bs-toggle="tab" role="tab" onClick={() => setDatabaseRoute("senders")} className={`nav-link ${databaseRoute === 'senders' ? 'active' : ''}`}>Senders</a>
                {/* <Link to="/address">
                        </Link> */}
            </li>
        </ul>
    )
}

export default DatabaseTabSidebar