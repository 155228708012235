/* eslint-disable no-unused-vars */

/**
 * Return the search results in a single array.  The results in
 * the data are separated by type and then line, combine the
 * data into results that are line oriented.
 */
export const filterSearch = (data) => 
{
	// These are the allowed splitting terms allowed when searching from the unassigned screen.
	const regex = /^(companyname|department|position|employee|address|workstation|sender):\s*(.*?)$/
	let retval = [];
	const filter = data?.filter;
	const filter_lines = filter?.split('\n');
	let segments = [];
	// add the structure for this line
	var line_matches = [];
	let index = 0;
	for( let x = 0; x < filter_lines?.length; x++ )
	{
		if( filter_lines[x] !== '' )
		{
			segments[index] = filter_lines[x];
			line_matches[ index ] = {
				addresses: [],
				companynames: [],
				positions: [],
				departments: [],
				receivers: [],
				senders: [],
				workstations: []
			};
			// remove any type indicator from the beginning of the segment if it is present.
			if( regex.test( filter_lines[x] ) )
			{
				segments[index] = filter_lines[x].match( regex )[2];
			}
			index++;
		}
	}
	// break down the parts.
	// go through all of the search results.  They are separated by types.
	// break them out into line order.  This will result in an array where each line
	// contains matches from different types.
	data?.matches?.forEach((ele,i) => 
	{
		let curr_line = 0;
		// what line are these matching blocks from?
		if( ele?.matchingTerm && segments?.length )
		{
			curr_line = segments.indexOf( ele?.matchingTerm );	
		}
		// get all of the blocks that match the type and 
		let type_blocks = getElementBlock( ele );
		// for the type_blocks, put them into the appropriate location in the line matches.
		switch( ele.recordType.toLowerCase() )
		{
			case "employee":
				line_matches[ curr_line ].receivers = type_blocks;
				break;
			case "position":
				line_matches[ curr_line ].positions = type_blocks;
				break;
			case "department":
				line_matches[ curr_line ].departments = type_blocks;
				break;
			case "company":
			case "companyname":
				line_matches[ curr_line ].companynames = type_blocks;
				break;
			case "address":
				line_matches[ curr_line ].addresses = type_blocks;
				break;
			case "workstation":
				line_matches[ curr_line ].workstations = type_blocks;
				break;
			case "sender":
				line_matches[ curr_line ].senders = type_blocks;
				break;
			default:
				break;
		}
	});
	// lastly, merge the lines into a single return array.  Ensure the same type is not present on more than one line.
	// Resursively build the array of matching results.
	get_line( retval, line_matches, {
		id: null,
		label: null,
		what_line: 0,
		address: null,
		addr_index: 0,
		receiver: null,
		rcvr_index: 0,
		position: null,
		pos_index: 0,
		department: null,
		dept_index: 0,
		companyName: null,
		coname_index: 0,
		confidence: 1,
		workstation: null,
		ws_index: 0,
		sender: null,
		sender_index: 0,
		blockId: null,
		block: null
	});
	return retval;
};

/**
 * For each line, find the element that isn't already present in the block
 * once an element has been found, move to the next line and recurse again
 * to pick up the element from the next line.
 * Once the last line has been reached, push the block onto the array 
 * After the recursive call returns, create a new block and increment the index for the element that
 * was just operated on.  
 * Fall through to the end of the statements and recurse to the next line.
 */
function get_line( curr_arr, line_matches, curr_block )
{
	if( curr_block.what_line < line_matches.length )
	{
		let has_more = false;
		if( !curr_block?.receiver && curr_block.rcvr_index < line_matches[ curr_block.what_line ].receivers?.length  )
		{
			get_line( curr_arr, line_matches, { ...curr_block, 
								what_line: curr_block.what_line+1, 
								receiver: line_matches[ curr_block.what_line ].receivers[ curr_block.rcvr_index ],
								id: line_matches[ curr_block.what_line ].receivers[ curr_block.rcvr_index ].id,
								label: line_matches[ curr_block.what_line ].receivers[ curr_block.rcvr_index ].name
							 } );
			if( curr_block.rcvr_index+1 < line_matches[ curr_block.what_line ].receivers?.length ||
					curr_block.pos_index+1 < line_matches[ curr_block.what_line ].positions.length ||
					curr_block.dept_index+1 < line_matches[ curr_block.what_line ].departments?.length ||
					curr_block.coname_index+1 < line_matches[ curr_block.what_line ].companynames?.length ||
					curr_block.addr_index+1 < line_matches[ curr_block.what_line ].addresses?.length || 
					curr_block.ws_index+1 < line_matches[ curr_block.what_line ].workstations?.length ) 
				{
				curr_block = { ...curr_block, receiver:null, rcvr_index: curr_block.rcvr_index+1 };
				has_more = true;
			}
		}
		else if( !curr_block?.position && curr_block.pos_index < line_matches[ curr_block.what_line ].positions?.length  )
		{
			get_line( curr_arr, line_matches, { ...curr_block, 
								what_line: curr_block.what_line+1, 
								position: line_matches[ curr_block.what_line ].positions[ curr_block.pos_index ],
								id: line_matches[ curr_block.what_line ].positions[ curr_block.pos_index ].id,
								label: line_matches[ curr_block.what_line ].positions[ curr_block.pos_index ].name  
							} )
			if( curr_block.rcvr_index+1 < line_matches[ curr_block.what_line ].receivers?.length ||
				curr_block.pos_index+1 < line_matches[ curr_block.what_line ].positions.length ||
				curr_block.dept_index+1 < line_matches[ curr_block.what_line ].departments?.length ||
				curr_block.coname_index+1 < line_matches[ curr_block.what_line ].companynames?.length ||
				curr_block.addr_index+1 < line_matches[ curr_block.what_line ].addresses?.length || 
				curr_block.ws_index+1 < line_matches[ curr_block.what_line ].workstations?.length ) 
			{
				curr_block = { ...curr_block, position:null, pos_index: curr_block.pos_index+1 };
				has_more = true;
			}
		}
		else if( !curr_block?.department && curr_block.dept_index < line_matches[ curr_block.what_line ].departments?.length  )
		{
			get_line( curr_arr, line_matches, { ...curr_block, 
							what_line: curr_block.what_line+1, 
							department: line_matches[ curr_block.what_line ].departments[ curr_block.dept_index ], 
							id: line_matches[ curr_block.what_line ].departments[ curr_block.dept_index ].id, 
							label: line_matches[ curr_block.what_line ].departments[ curr_block.dept_index ].name } )
			if( curr_block.rcvr_index+1 < line_matches[ curr_block.what_line ].receivers?.length ||
				curr_block.pos_index+1 < line_matches[ curr_block.what_line ].positions.length ||
				curr_block.dept_index+1 < line_matches[ curr_block.what_line ].departments?.length ||
				curr_block.coname_index+1 < line_matches[ curr_block.what_line ].companynames?.length ||
				curr_block.addr_index+1 < line_matches[ curr_block.what_line ].addresses?.length || 
				curr_block.ws_index+1 < line_matches[ curr_block.what_line ].workstations?.length ) 
			{
				curr_block = { ...curr_block, department:null, dept_index: curr_block.dept_index+1 };
				has_more = true;
			}
		}
		else if( !curr_block?.companyName && curr_block.coname_index < line_matches[ curr_block.what_line ].companynames?.length  )
		{
			get_line( curr_arr, line_matches, { ...curr_block, 
							what_line: curr_block.what_line+1, 
							companyName: line_matches[ curr_block.what_line ].companynames[ curr_block.coname_index ],
							id: line_matches[ curr_block.what_line ].companynames[ curr_block.coname_index ].id,
							label: line_matches[ curr_block.what_line ].companynames[ curr_block.coname_index ].name 
						} )
			if( curr_block.rcvr_index+1 < line_matches[ curr_block.what_line ].receivers?.length ||
				curr_block.pos_index+1 < line_matches[ curr_block.what_line ].positions.length ||
				curr_block.dept_index+1 < line_matches[ curr_block.what_line ].departments?.length ||
				curr_block.coname_index+1 < line_matches[ curr_block.what_line ].companynames?.length ||
				curr_block.addr_index+1 < line_matches[ curr_block.what_line ].addresses?.length || 
				curr_block.ws_index+1 < line_matches[ curr_block.what_line ].workstations?.length ) 
			{
				curr_block = { ...curr_block, companyName:null, coname_index: curr_block.coname_index+1 };
				has_more = true;
			}
		}
		else if( !curr_block?.address && curr_block.addr_index < line_matches[ curr_block.what_line ].addresses?.length  )
		{
			get_line( curr_arr, line_matches, { ...curr_block, 
							what_line: curr_block.what_line+1, 
							address: line_matches[ curr_block.what_line ].addresses[ curr_block.addr_index ],
							id: line_matches[ curr_block.what_line ].addresses[ curr_block.addr_index ].id,
							label: line_matches[ curr_block.what_line ].addresses[ curr_block.addr_index ].name
						} )
			if( curr_block.rcvr_index+1 < line_matches[ curr_block.what_line ].receivers?.length ||
				curr_block.pos_index+1 < line_matches[ curr_block.what_line ].positions.length ||
				curr_block.dept_index+1 < line_matches[ curr_block.what_line ].departments?.length ||
				curr_block.coname_index+1 < line_matches[ curr_block.what_line ].companynames?.length ||
				curr_block.addr_index+1 < line_matches[ curr_block.what_line ].addresses?.length || 
				curr_block.ws_index+1 < line_matches[ curr_block.what_line ].workstations?.length ) 
			{
				curr_block = { ...curr_block, address:null, addr_index: curr_block.addr_index+1 };
				has_more = true;
			}
		}
		else if( !curr_block?.workstation && curr_block.ws_index < line_matches[ curr_block.what_line ].workstations?.length  )
		{
			get_line( curr_arr, line_matches, { ...curr_block, 
							what_line: curr_block.what_line+1, 
							workstation: line_matches[ curr_block.what_line ].workstations[ curr_block.ws_index ],
							id: line_matches[ curr_block.what_line ].workstations[ curr_block.ws_index ].id,
							label: line_matches[ curr_block.what_line ].workstations[ curr_block.ws_index ].name 
						} )
			if( curr_block.rcvr_index+1 < line_matches[ curr_block.what_line ].receivers?.length ||
				curr_block.pos_index+1 < line_matches[ curr_block.what_line ].positions.length ||
				curr_block.dept_index+1 < line_matches[ curr_block.what_line ].departments?.length ||
				curr_block.coname_index+1 < line_matches[ curr_block.what_line ].companynames?.length ||
				curr_block.addr_index+1 < line_matches[ curr_block.what_line ].addresses?.length || 
				curr_block.ws_index+1 < line_matches[ curr_block.what_line ].workstations?.length ) 
			{
				curr_block = { ...curr_block, workstation:null, ws_index: curr_block.ws_index+1 };
				has_more = true;
			}
		}
		else if( !curr_block?.sender && curr_block.sender_index < line_matches[ curr_block.what_line ].senders?.length  )
		{
			get_line( curr_arr, line_matches, { ...curr_block, 
							what_line: curr_block.what_line+1, 
							sender: line_matches[ curr_block.what_line ].senders[ curr_block.sender_index ],
							id: line_matches[ curr_block.what_line ].senders[ curr_block.sender_index ].id,
							label: line_matches[ curr_block.what_line ].senders[ curr_block.sender_index ].name
						} )
			if( curr_block.rcvr_index+1 < line_matches[ curr_block.what_line ].receivers?.length ||
				curr_block.pos_index+1 < line_matches[ curr_block.what_line ].positions.length ||
				curr_block.dept_index+1 < line_matches[ curr_block.what_line ].departments?.length ||
				curr_block.coname_index+1 < line_matches[ curr_block.what_line ].companynames?.length ||
				curr_block.addr_index+1 < line_matches[ curr_block.what_line ].addresses?.length || 
				curr_block.sender_index+1 < line_matches[ curr_block.what_line ].senders?.length || 
				curr_block.ws_index+1 < line_matches[ curr_block.what_line ].workstations?.length ) 
			{
				curr_block = { ...curr_block, sender:null, sender_index: curr_block.sender_index+1 };
				has_more = true;
			}
		}
		else
		{
			curr_block.what_line++;
		}
		if( has_more )
		{
			get_line( curr_arr, line_matches, curr_block )
		}
	}
	else if( curr_block.workstation || curr_block.sender || curr_block.address || curr_block.companyName || curr_block.department || curr_block.position || curr_block.receiver )
	{
		curr_arr.push( curr_block );
	}
}
	
export const generateSenderList = (data) =>
{
	let retval = [];
	if( data?.senderName?.name )
	{
		retval[0] =
		{
			id: data.senderId,
			name: data.senderName.name,
			address: data.senderAddress,
			confidence: data.senderConfidence,
			blockId: data.senderBlockId,
//			block: data?.senderBlock
		}
	}
	return retval;
}
export const generateReceiverList = (data) => 
{
	let retval = [];
	// if an address is found, then there was a receipient.
	if( data?.receiverId || data?.positionId || data?.departmentId || data?.companyNameId )
	{
		retval[0] = {
			address: data.address,
			receiver: data.receiverId ? {
						id : data.receiverId,
						name : data.receiverName.fullName,
						confidence: data.receiverConfidence,
						other: [] 
					} : null,
			position: data.positionId ? {
						id : data.positionId,
						name : data.positionName.name,
						confidence: data.positionConfidence,
						other: []
					} : null,
			department: data.departmentId ? {
						id : data.departmentId,
						name : data.departmentName.name,
						confidence: data.departmentConfidence,
						other: []
					} : null,
			companyName: data.companyNameId ? {
						id : data.companyNameId,
						name : data.companyName.name,
						confidence : data.companyNameConfidence,
						other: []
					} : null,
			confidence: data.confidence,
			blockId: data?.receiverBlockId,
			block: data?.receiverBlock
		};

		if( retval[0].receiver && data?.positionName )
			retval[0].receiver.other.push( data.positionName.name )
		if( retval[0].receiver && data?.departmentName )
			retval[0].receiver.other.push( data.departmentName.name )
		if( retval[0].receiver && data.address )
		{
			retval[0].receiver.other.push( data.address.address1 )
			retval[0].receiver.other.push( data.address.city+' '+data.address.state+' '+data.address.zip )
		}
		// look at any secondary alternative choices.
		data?.receivers?.map( ( element ) =>
		{
			return retval.push( {
				address: element.address,
				receiver: element.receiverId ? {
							id : element.receiverId,
							name : element.receiverName.fullName,
							confidence: element.receiverConfidence
						} : null,
				position: element.positionId ? {
							id : element.positionId,
							name : element.positionName.name,
							confidence: element.positionConfidence
						} : null,
				department: element.departmentId ? {
							id : element.departmentId,
							name : element.departmentName.name,
							confidence: element.departmentConfidence
						} : null,
				companyName: element.companyNameId ? {
							id : element.companyNameId,
							name : element.companyName.name,
							confidence : element.companyNameConfidence
						}: null,
				blockId: data?.receiverBlockId,
				block: data?.receiverBlock
			} );
		});
	}
	return retval;
}

export const filterExistData = (data) => 
{
	// console.log(data);
	const filteredArr = data?.map((data) => 
	{
		if (data?.receiverName) 
		{
			return filterEmployee([data?.receiverName]);
		} 
		else if (data?.positionName) 
		{
			return filterPosition([data?.positionName]);
		} 
		else if (data?.departmentName) 
		{
			return filterDepartment([data?.departmentName]);
		} 
		else 
		{
			return filterCompanyName([data?.companyName]);
		}
	});
	const results = filteredArr.map((ele) => ({ ...ele[0] }));
	// console.log(results);
	return results;
};

export const filterFirstChoiceData = ( receiverName, positionName, departmentName, companyName ) => 
{
	let filteredArr = {};
	if (receiverName) 
		filteredArr = filterEmployeeName([receiverName]);
	else if (positionName) 
		filteredArr = filterPosition([positionName]);
	else if (departmentName) 
		filteredArr = filterDepartment([departmentName]);
	else if (companyName) 
		filteredArr = filterCompanyName([companyName]);
	return filteredArr;
};

function filterEmployee(data) 
{
	return data?.map((ele) => (
	{
		name: ele?.employeeName?.fullName, 
		fullName: `${ ele?.employeeName?.title ? ele?.employeeName?.title + " " : "" } ${ele?.employeeName?.fullName} ${ ele?.employeeName?.suffix ? ele?.employeeName?.suffix : "" }`,
		address: ele?.companyDetails?.address?.address1,
		id: ele?.employeeId,
		idType: "receiverId",
		positionName: ele?.companyDetails?.positionNames,
		departmentName: ele?.companyDetails?.department,
		fullAddress: `${ele?.companyDetails?.address?.city} ${ele?.companyDetails?.address?.state} ${ele?.companyDetails?.address?.zipcode}`,
	}));
}

function filterDepartment(data) 
{
	return data?.map((ele) => (
	{
		name: Array.isArray(ele?.names) ? ele?.names[0].name : ele?.name,
		id: ele?.departmentId,
		idType: "departmentId",
	}));
}

function filterPosition(data) 
{
	return data?.map((ele) => (
	{
		name: ele?.label,
		id: ele?.positionId,
		idType: "positionId",
	}));
}

function filterCompanyName(data) 
{
	return data?.map((ele) => (
	{
		name: ele?.name,
		id: ele?.id,
		idType: "companyNameId",
	}));
}

function filterEmployeeName(data) 
{
	return data?.map((ele) => ({
		name: ele?.fullName,
		fullName: `${ele?.title ? ele?.title + " " : ""}${ele?.fullName} ${ele?.suffix ? ele?.suffix : "" }`,
		id: ele?.employeeId,
		idType: "receiverId",
	}));
}

function filterWorkstationName(data) 
{
	return data?.map((ele) => ({
		name: ele?.workstationName,
		id: ele?.workstationId,
		idType: "workstationId",
	}));
}

function filterSenderName(data) 
{
	return data?.map((ele) => 
	({
		name: ele?.label,
		id: ele?.senderId,
		idType: "senderId",
		address: `${ele?.address1} ${ele?.address2 ? "," + ele?.address2 : ""}`,
		fullAddress: `${ele?.city ?? ""} ${ele?.state ?? ""} ${ele?.zipcode ?? ""}`,
	}));
}

function getElementBlock( ele )
{
	let retval = [];
	let etype = ele?.recordType.toLowerCase()
	if( etype === "employee" ) 
	{
		ele?.list.forEach(( data, j ) =>
		{
			retval[j] = {
				type: "Employee",
				id : data.employeeId,
				name : data.employeeName.fullName,
				confidence : 1,
				other: []
			};
			if( data.contactDetails?.emailAddress1 )
				retval[j].other.push( data.contactDetails?.emailAddress1 )
			if( data.companyDetails?.positionNames?.length > 0 )
				retval[j].other.push( data.companyDetails?.positionNames[0].name )
			if( data.companyDetails?.department )
				retval[j].other.push( data.companyDetails.department )
			if( data.companyDetails.address )
			{
				retval[j].other.push( data.companyDetails.address.address1 )
				retval[j].other.push( data.companyDetails.address.city+' '+data.companyDetails.address.state+' '+data.companyDetails.address.zipcode )
			}
		})
	} 
	else if( etype === "department" ) 
	{
		ele?.list.forEach( (data,j) =>
		{
			retval[j] = 
			{
				id : data.departmentId,
				name : data.label,
				confidence : 1,
				other: []
			}
		})
	} 
	else if( etype === "position" ) 
	{
		ele?.list.forEach( (data,j) =>
		{
			retval[j] = 
			{
				id : data.positionId,
				name : data.label,
				confidence : 1,
				other: []
			};
		})
	} 
	else if( etype === "companyname" || etype === "company" ) 
	{
		ele?.companyNames.forEach( (data,j) =>
		{
			retval[j] = 
			{
				id : data.id,
				name : data.name,
				confidence : 1,
				other: []
			} 
		})
	}
	else if( etype === "address" ) 
	{
		ele?.list.forEach( (data,j) =>
		{
			retval[j] = 
			{
				id: data.addressId,
				name: data.address1?.toUpperCase()+' '+data.city?.toUpperCase()+' '+data.state?.toUpperCase()+' '+data.zipcode,
				address1: data.address1,
				address2: data.address2,
				city: data.city,
				state: data.state,
				zipcode: data.zipcode,
				confidence : 1,
				other: []
			}
		})
	}
	else if( etype === "sender" ) 
	{
		ele?.list.forEach( (data,j) =>
		{
			retval[j] = 
			{
				id: data?.senderId,
				name: `${data?.label?.toUpperCase() ?? ""} ${data?.city?.toUpperCase() ?? ""} ${data?.state?.toUpperCase() ?? ""}`,
				idType: "senderId",
				address: `${data?.address1} ${data?.address2 ? "," + data?.address2 : ""}`,
				fullAddress: `${data?.city ?? ""} ${data?.state ?? ""} ${data?.zipcode ?? ""}`
			}
		})
	}
	else if( etype === "workstation" ) 
	{
		ele?.workstations.forEach( (data,j) =>
		{
			retval[j] = 
			{
				id : data.workstationId,
				name : data.workstationName,
				other: []
			} 
		})
	}
	return retval;
}

export const detectChangesInMail = (oldData, NewData) => {};
