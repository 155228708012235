/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Settings_Get_API, Settings_Put_API } from '../../../../services/SettingsAPI';
import CompanyInfo from './CompanyInfo';

const ContactInfo = ({ navLink,
    setNavLink,
    error,
    setError, contactInput, setContactInput, allErrorCheck }) => {
    const [emailAddress1, setEmailAddress] = useState(contactInput?.emailAddress1)
    const [emailAddress2, setEmailAddress2] = useState(contactInput?.emailAddress2)
    const [cellPhone, setCellPhone] = useState(contactInput?.cellPhone)
    const [workPhone, setWorkPhone] = useState(contactInput?.workPhone)
    const [faxPhone, setFaxPhone] = useState(contactInput?.faxPhone)
    const [homePhone, setHomePhone] = useState(contactInput?.homePhone)
    const [submit, setSubmit] = useState(false)
    const [validEmail, setValidEmail] = useState(true)

    const [changed, setChanged] = useState(false)

    useEffect(() => {
        if (!contactInput) {
            Settings_Get_API('/mercury/employee/contact').then((res) => {
                // setContactInput(res.data.contactDetails)
                setEmailAddress(res?.data?.contactDetails?.emailAddress1)
                setEmailAddress2(res?.data?.contactDetails?.emailAddress2)
                setCellPhone(res?.data?.contactDetails?.cellPhone)
                setWorkPhone(res?.data?.contactDetails?.workPhone)
                setFaxPhone(res?.data?.contactDetails?.faxPhone)
                setHomePhone(res?.data?.contactDetails?.homePhone)
            })
        }
    }, [])




    // useEffect(() => {
    //     setEmailAddress(contactInput.emailAddress1)
    //     setEmailAddress2(contactInput.emailAddress2)
    //     setCellPhone(contactInput.cellPhone)
    //     setWorkPhone(contactInput.workPhone)
    //     setFaxPhone(contactInput.faxPhone)
    //     setHomePhone(contactInput.homePhone)

    //     setError('')

    // }, [contactInput])


    const setContactInputData = () => {
        setSubmit(true)
        if (!emailAddress1) {
            return setError("Email address is required field");
        } else if (emailValidation(emailAddress1)) {
            setValidEmail(false)
            return setError("Invalid email")
        }
        setValidEmail(true)

        Settings_Put_API('/mercury/employee/contact', {
            emailAddress1,
            cellPhone,
            workPhone,
            emailAddress2,
            faxPhone,
            homePhone
        }).then((res) => {
            // console.log(res)
            if (res.request.status === 200) {
                // document.getElementById('closeContactModal').click()
            } else {
                setError('We got some error ! Please try again later')
            }
        })

        setError("");

    }

    function emailValidation(email) {
        const regex =
            // eslint-disable-next-line
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return !(!email || regex.test(email) === true);
    }

    useEffect(() => {
        setContactInput({
            emailAddress1,
            cellPhone,
            workPhone,
            emailAddress2,
            faxPhone,
            homePhone
        });

        const set = setTimeout(() => {
            if (changed) {
                setContactInputData()
            }
        }, 1500);
        return () => clearTimeout(set)

    }, [emailAddress1,
        cellPhone,
        workPhone,
        emailAddress2,
        faxPhone,
        homePhone])

    return (
        <div
            className={`tab-pane fade ${navLink === "contact" ? "show active" : ""}`}
            aria-labelledby="v-pills-ContactInfo-tab"
        >
            <div className="col-lg-9 offset-lg-1 modalContentItems">
                <h2 className="heading_1">Settings: Contact info</h2>
                <p>Please ensure the following contact information is accurate.</p>
                <form className="pt-4">
                    <div className="row g-3 align-items-center">
                        <div className="fm_grp col-md-6">
                            <div className="error_inupt_box">
                                {/* <span  className="me-3">
                  <img
                    src="assests/img/error_icon.svg"
                     className="img-fluid"
                    alt=""
                  />
                </span> */}
                                <div className="error_input">
                                    <label htmlFor="inputEmail" className={`form-text ${error && (!emailAddress1 || !validEmail) ? 'error_text' : ''}`}>
                                        * Email address is a required field
                                    </label>
                                    <input
                                        type="email"
                                        className={`form-control ${error && (!emailAddress1 || !validEmail) ? 'error_input' : ''}`}
                                        id="inputEmail"
                                        placeholder=""
                                        onInput={(e) => { setChanged(true); setEmailAddress(e.target.value) }}
                                        value={emailAddress1}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="fm_grp col-md-6">
                            <label htmlFor="inputCell" className="form-label">
                                Cell phone
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="inputCell"
                                onInput={(e) => { setChanged(true); setCellPhone(e.target.value) }}
                                value={cellPhone}
                            />
                        </div>
                        <div className="fm_grp col-md-6">
                            <label htmlFor="inputWorkPhone" className="form-label">
                                Work phone
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="inputWorkPhone"
                                placeholder=""
                                onInput={(e) => { setChanged(true); setWorkPhone(e.target.value) }}
                                value={workPhone}
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
                <div> </div>
                <div className="p-md-4 text-end">
                    {/* <button
                        type="button"
                        id='closeContactModal'
                         className="btn btn-link  btn_link_primary"
                        data-bs-dismiss="modal" style={{ visibility: 'hidden' }}
                    >
                        Cancel
                    </button> */}
                    <button
                        type="button"
                        className="btn btn_cstm_primary"
                        data-bs-dismiss="modal"
                        disabled={allErrorCheck}
                    >
                        Done
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo