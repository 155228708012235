/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { RouteState } from '../../../../context/routeContext'
import { getService } from '../../../../services/commonApi'
import Addreport from './modal/Addreport'
import ReportDelete from './modal/Deletereport'
import Generatereport from './modal/Generatereport'

/**
 * SHows a list of cards that allow the user to generate a report.  If the user also has the ability to 
 * modify reports, they can add, delete and edit reports that are run by the system.
 * 
 * @returns 
 */
const Report = () => 
{
	const { dashboardRoute, setDashboardRoute, } = RouteState()
	const [cannedReportData, setCannedReportData] = useState([])
	const [cannedReportid, setCannedReportid] = useState('')

	var primaryUrl = '/mercury/reports/canned'

	useEffect(() => 
	{
		if (dashboardRoute === 'report') 
		{
			getCannedReportData()
		}
	}, [dashboardRoute])

	const getCannedReportData = () => 
	{
		getService(primaryUrl, true).then((res) => 
		{
				setCannedReportData(res?.data)
		})
	}

	return (
		<>
			<div className="card-header d-md-none" role="tab" id="heading-B" onClick={() => { setDashboardRoute('report') }}>
				<h5 className="mb-0">
					<a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-B" aria-expanded="true" aria-controls="collapse-B">
						Generate Report  <span> <i className="bi bi-chevron-down"></i></span>
					</a>
				</h5>
			</div>
			<div id="collapse-B" className={`bgYellow collapse ${dashboardRoute === "report" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
				aria-labelledby="heading-B">
				<div className="p-3 pt-4 py-0 col-lg-11 text_primary_2">
					<p className="my-4 my-lg-0">View, edit, or add reports.</p>
				</div>
				<div className="p-lg-5 generate_report">
					<div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5">
						{cannedReportData?.map((data, i) => (
							<div className="col" key={i}>
								<div className="card">
									<div className="card_head">
										<h4>{data?.title}</h4>
										<a onClick={() => setCannedReportid({ 'label': data?.title, 'reportId': data?.reportId })}><img src="assets/img/Edit.svg" alt="" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#add_canned_report" /></a>
									</div>
									<div className="card-body" onClick={() => setCannedReportid({ 'label': data?.title, 'reportId': data?.reportId })} data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#generate_report">
										<p>{data?.description}</p>
									</div>
									<div className="card_footer">
										<a >Version {data?.version}</a>
										<a onClick={() => setCannedReportid({ 'label': data?.title, 'reportId': data?.reportId })} data-bs-toggle="modal" data-bs-target="#delete_report"><img src="assets/img/Delete.svg" alt="" /> </a>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className="add_account">
						<a data-bs-toggle="modal" onClick={() => setCannedReportid()} data-bs-target="#add_canned_report"> <img src="assets/img/add_account.svg" alt="" /><span>Add Report</span></a>
					</div>
				</div>

				<Addreport reportId={cannedReportid} url={primaryUrl} setReportId={setCannedReportid} getList={getCannedReportData} />

				<ReportDelete deletedata={cannedReportid} senderUrl={primaryUrl} getSenderData={getCannedReportData} />

				<Generatereport url={primaryUrl} cannedReportid={cannedReportid} setCannedReportid={setCannedReportid} />
			</div>
		</>
	)
}

export default Report