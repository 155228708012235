/**
 * 
 * All changes must adhere to the Authorization Code Flow with PKCE
 * Implicit flow is not allowed.
 * 
 * 		https://learn.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-auth-code
 * 
 * 
 * 
 */

/* eslint-disable no-unused-vars */
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { rolesToEntitlements } from "../services/tokenService";

const AuthContext = createContext();

const AuthProvider = ({ children }) => 
{
	const [user, setUser] = useState();
	const [state, setState] = useState();
	const [activeLink, setActiveLink] = useState("database");

	const [databaseRoute, setDatabaseRoute] = useState("accounts");

	const [entitlement, setEntitlement] = useState([]);

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => 
	{
		const item = localStorage.getItem("user");
		if( !item || item === undefined ) 
		{
			navigate("auth");
		} 
		else
		{
			let ls_user = JSON.parse( item ) 
			setUser( ls_user );
			if( ls_user?.idTokenClaims )
			{
				setEntitlement( rolesToEntitlements( ls_user?.idTokenClaims?.roles ));
			}
			else
			{
				setEntitlement( ls_user.entitlements );
			}
		}
	}, [navigate]);

	useEffect(() => {
		const route = location.pathname;
		setActiveLink(route.split("/")[1]);
	}, [location]);

	return (
		<AuthContext.Provider
		value={{
			user,
			setUser,
			entitlement,
			setEntitlement,
			state,
			setState,
			activeLink,
			setActiveLink,
			databaseRoute,
			setDatabaseRoute,
		}}
		>
		{children}
		</AuthContext.Provider>
	);
};

export const AuthState = () => 
{
	return useContext(AuthContext);
};

export default AuthProvider;
