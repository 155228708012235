/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { deleteService, getService } from '../../../../../../services/commonApi'

const AddressDeleted = ({ data, addressID, url, getList }) => {

    const [addressList, setAddressList] = useState([])

    const [submit, setSubmit] = useState(false)
    const [replacementId, setReplacementId] = useState('')
    const [error, setError] = useState(false)

    useEffect(() => {
        if (addressID) {
            getAddressData()
        }
    }, [addressID])

    const getAddressData = () => {
        getService(url + 'list', true).then((
            res => {
                if (res?.status === 200) {
                    // console.log(res)
                    setAddressList(res.data.list)
                    setReplacementId(res.data.list[0].addressId)
                }
            }
        ))
    }

    const resetModal = () => {
        setReplacementId('')
        setError('')
        setSubmit(false)
    }

    const deleteAddress = () => {
        setSubmit(true)
        if (!replacementId) {
            return setError('Replacement Id is required')
        } else {
            deleteService(url + addressID, '', true, {
                params: {
                    replacementId
                }
            })
                .then(
                    (res) => {
                        // console.log(res)
                        if (res.request.status === 200) {
                            getList()
                            document.getElementById('deleteAddressModal').click()
                        } else {
                            setError(res.response?.data?.error?.desc)
                        }
                    }, (err) => {
                        setError(err.response?.statusText)
                    }
                )

        }
    }

    return (
        <div className="modal fade Delete_replace" id="delete_Address" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body py-4">
                        <h2 className="heading_1 ps-lg-4">Delete & replace</h2>
                        {error && <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                            <span className="me-3"><img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" /></span>{error}
                        </div>}
                        <div>
                            <div className="col-xl-8 m-auto py-4">
                                <h3 className="heading_2 mb-4">Delete “{data?.address1}”</h3>
                                <div className="fm_grp">
                                    <label htmlFor="inputState" className="form-label">* Replace with the following Address:</label>
                                    <select id="inputState" className={`form-control ${submit && !replacementId ? 'error_input' : ''}`} onChange={(e) => setReplacementId(e.target.value)}>
                                        {addressList?.map((data, i) => (
                                            <option key={i} value={data.addressId}>{data?.address1},{data.city},{data.state},{data.zipcode}</option>
                                        ))
                                        }

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 px-3">
                            <div> </div>
                            <div className=" text-end">
                                <button type="button" className="btn btn-link  btn_link_primary me-3" onClick={() => resetModal()} data-bs-dismiss="modal" id="deleteAddressModal">Cancel</button>
                                <button type="button" className="btn btn_cstm_primary px-4" onClick={() => deleteAddress()}>Delete & replace</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddressDeleted