/**
 * 
 * All changes must adhere to the Authorization Code Flow with PKCE
 * Implicit flow is not allowed.
 * 
 * 		https://learn.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-auth-code
 * 
 * 
 * 
 */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import axios from "axios";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { url, signOn, msalConfig, loginRequest } from "../environment/environment";
// keep the use of localauthtoken here since we don't want to fail 
// logging out if the token has timed out also we don't want to call
// refresh while refreshing the token.
import {
	rolesToEntitlements,
	getLoginTimestamp,
	setLoginTimestamp,
	getLocalAuthToken,
	setExpTimestamp,
	getRefreshToken,
	removeAuthToken,
	removeRefreshToken,
	setAuthToken,
	setRefreshToken,
} from "./tokenService";

import history from "./history";
import { errorHandler } from "./ErrorHandler";


/*===============================================================
			Get_Configuration
==================================================================*/

export const getconfiguration = async () => 
{
	console.log(" config url : "+url )

	const local_url = "/mercury";

	const response = await axios.get(url + local_url).catch((err) => {
		errorHandler(err);
		return err;
	});
	return response?.data;
};


/*===============================================================
				login_api
==================================================================*/
/**
 * Log into the backend application service.  Pass in the login data that contains
 * the username and password.  
 * 
 * If logged in successfully, the access totken and refresh totken are stored
 * for use with outher API calls.
 * The expiration time is set for the access bearer token
 * The login time is set for reference
 */
export const login_api = async (Logindata) => 
{
	// do the mercury login.
	if( signOn.isSSO === false )
	{
		return handleMercuryLogin( Logindata );
	}
	else if( signOn.isSSO && signOn.authentication === 'Azure' )
	{
		return handleAzureLogin( Logindata )
	}
};

/**
 * If configured properly, the Azure AD response for a SSO request will return the following values:
 * 		accessToken
 * 		idToken
 * 		​expiresOn
 * 		roles
 * 		account
 * It will contain the following claims:
 * 	​​​email: "Christopher.Fahey@tmgofficeservices.com"
	​​​employeeNumber: "0065"
	​​​exp: 1680632299
	​​​iat: 1680628399
	roles: [ "Administrator" | "Manager" | "User" ]
 * @param {*} loginType 
 * @returns 
 */
const handleAzureLogin = async (loginType) => 
{
	return new Promise(( resolve, reject )=>
	{
		msalConfig.auth.redirectUri = window.location.href;
		if (loginType === "popup") 
		{
			signOn.instance.loginPopup({
				...msalConfig,
				...loginRequest,
				redirectUri: "/redirect"
			})
			.then( (res) =>
			{
				const idToken = res?.idToken
				const expiresOn = res?.expiresOn.getTime();
				const result = {
					status : 200,
					statusText : "",
					data :
					{ 
						id : res?.uniqueId,
						exp : expiresOn,
						userName : res?.account?.username,
						picture : "",
						email : res?.idTokenClaims?.email,
						accessToken : idToken,
						refreshToken : idToken,
						entitlements : rolesToEntitlements( res?.idTokenClaims?.roles ),
					}
				};
				// retain other values.
				signOn.accounts = signOn.instance.getAllAccounts();
				setAuthToken(result?.data?.accessToken);
				setRefreshToken(result?.data?.refreshToken);
				// set the timeout timestamp.
				setExpTimestamp(expiresOn);
				setLoginTimestamp( );
				resolve(result)
			})
			.catch(e => 
			{
				reject(e);
			});
		}
		else if (loginType === "redirect") 
		{
			signOn.instance.loginRedirect(msalConfig).then(( res ) =>
			{
				const idToken = res?.idToken
				const expiresOn = ""+res?.expiresOn.getTime();
				const result = {
					status : 200,
					statusText : "",
					data :
					{ 
						id : res?.uniqueId,
						exp : expiresOn,
						userName : res?.account?.username,
						picture : "",
						email : res?.idTokenClaims?.email,
						accessToken : idToken,
						refreshToken : idToken,
						entitlements : rolesToEntitlements( res?.idTokenClaims?.roles )
					}
				};
				// retain other values.
				signOn.accounts = signOn.instance.getAllAccounts();
				setAuthToken(result?.data?.accessToken);
				setRefreshToken(result?.data?.refreshToken);
				// set the timeout timestamp.
				setExpTimestamp(expiresOn);
				setLoginTimestamp( );
				resolve(result)
			})
			.catch(e => 
			{
				reject(e);
			});
		}
	})
}

/**
 * The response that is returned by to the user for embedded authentication by mercury has the following values:
 *    	"id" : "123467",
 *     	"exp" : expiration of the session.  Use refreshToken to renew.
 *		"userName" : user name logged in with
 *      "email" : email address of user
 *      "picture" : any profile pictures that are available for user.
 *  	"entitlements" : Same as JWT. Specific rights to the service that come from the role.  Values are:
 *					Basic,Reassign, Assign, Route, Quality, Account, Request, Settings, Report, Restore, Log, Exchange
 *    	"accessToken" : This is the signed JWT payload from above.
 *      "refreshToken" : This is an opaque encrypted structure
 */
const handleMercuryLogin = async (Logindata) => 
{
	return new Promise( (resolve,reject) =>
	{
		const local_url = "/mercury/access/login";
		const authenticate = window.btoa( Logindata.username + ":" + Logindata.password );
		const headers = 
		{
			headers: {
			Accept: "application/json",
			Authorization: "Basic " + authenticate,
			},
		};
		axios.get(url + local_url, headers, { validateStatus: status => { return status >= 200 && status < 300; } } )
		.then( (response) =>
		{
			const result = {
				status : response?.request?.status,
				statusText : response?.request?.statusText,
				data :
				{ 
					id : response?.data?.id,
					exp : response?.data?.exp,
					userName : response?.data?.userName,
					picture : response?.data?.picture,
					email : response?.data?.email,
					accessToken : response?.data?.accessToken,
					refreshToken : response?.data?.refreshToken,
					entitlements : response?.data?.entitlements
				}
			};
			if (result) 
			{
				setAuthToken(result?.data?.accessToken);
				setRefreshToken(result?.data?.refreshToken);
				// set the timeout timestamp.
				setExpTimestamp(result?.data?.exp);
				setLoginTimestamp( );
			}
			resolve( result );
		})
		.catch((err) => 
		{
			errorHandler(err);
			reject( err );
		});
	});
};

const mlog = async (Logindata) => 
{
	let result = { }
	const local_url = "/mercury/access/login";
	const authenticate = window.btoa( Logindata.username + ":" + Logindata.password );
	const headers = 
	{
		headers: {
		Accept: "application/json",
		Authorization: "Basic " + authenticate,
		},
	};
	let response = await axios.get(url + local_url, headers )
	if( response?.request?.status === 200 )
	{
		result = {
			status : response?.request?.status,
			statusText : response?.request?.statusText,
			data :
			{ 
				id : response?.data?.id,
				exp : response?.data?.exp,
				userName : response?.data?.userName,
				picture : response?.data?.picture,
				email : response?.data?.email,
				accessToken : response?.data?.accessToken,
				refreshToken : response?.data?.refreshToken,
				entitlements : response?.data?.entitlements
			}
		};
		setAuthToken(result?.data?.accessToken);
		setRefreshToken(result?.data?.refreshToken);
		// set the timeout timestamp.
		setExpTimestamp(result?.data?.exp);
		setLoginTimestamp( );
	}
	else
	{
		result = 
		{
			status : response?.request?.status,
			statusText : response?.request?.statusText
		}
	};
	return result;
};


const alog = async (loginType) => 
{
	let result = { }
	msalConfig.auth.redirectUri = window.location.href;
	if (loginType === "popup") 
	{
		let res = await signOn.instance.loginPopup(msalConfig);
		const idToken = res?.idToken
		const expiresOn = res?.expiresOn.getTime();
		result = {
			status : 200,
			statusText : "",
			data :
			{ 
				id : res?.uniqueId,
				exp : expiresOn,
				userName : res?.account?.username,
				picture : "",
				email : res?.idTokenClaims?.email,
				accessToken : idToken,
				refreshToken : idToken,
				entitlements : rolesToEntitlements( res?.idTokenClaims?.roles )
			}
		};
		setAuthToken(result?.data?.accessToken);
		setRefreshToken(result?.data?.refreshToken);
		// set the timeout timestamp.
		setExpTimestamp(expiresOn);
		setLoginTimestamp( );
	}
	else if (loginType === "redirect") 
	{
		let res = await signOn.instance.loginRedirect(msalConfig);
		const idToken = res?.idToken
		const expiresOn = ""+res?.expiresOn.getTime();
		result = {
			status : 200,
			statusText : "",
			data :
			{ 
				id : res?.uniqueId,
				exp : expiresOn,
				userName : res?.account?.username,
				picture : "",
				email : res?.idTokenClaims?.email,
				accessToken : idToken,
				refreshToken : idToken,
				entitlements : rolesToEntitlements( res?.idTokenClaims?.roles )
			}
		};
		setAuthToken(result?.data?.accessToken);
		setRefreshToken(result?.data?.refreshToken);
		// set the timeout timestamp.
		setExpTimestamp(expiresOn);
		setLoginTimestamp( );
	}
	return result;
}
/*===============================================================
			logout_api
==================================================================*/
export const logout_api = async () => 
{
	const local_url = "/mercury/access/logout";
	const headers = 
	{
		headers: {
		Accept: "application/json",
		Authorization: "Bearer " + getLocalAuthToken(),
		},
	};
	const response = await axios.put(url + local_url, "", headers).catch((err) => 
	{
		errorHandler(err);
		return err;
	});
	return true;
};

/*===============================================================
			forgot_password_api
==================================================================*/
export const forgot_password_challenge = async (data) => 
{
	const local_url = "/mercury/access/forgot_password?username=" + data;
	// console.log("data", data);
	const headers = {
		headers: {
		Accept: "application/json",
		},
	};

	const response = await axios
		.get(`${url}${local_url}`, headers)
		.catch((err) => {
		// notify(`Error : ${err?.response?.status} ${err?.response?.statusText}`);
		errorHandler(err);
		return err;
		});

	return response;
};
/*===============================================================
				reset_password_api
==================================================================*/
export const reset_password = async (data) => 
{
	const local_url = "/mercury/access/reset_password";
	// console.log("data", data);
	const headers = {
		headers: {
		Accept: "application/json",
		},
	};

	const response = await axios
		.post(`${url}${local_url}`, data, headers)
		.catch((err) => {
		errorHandler(err);
		return err;
		});

	return response;
};

/*===============================================================
			request_account
==================================================================*/

export const request_account_api = async (data) => 
{
	const local_url = "/mercury/access/request_account";
	// console.log("data", data);
	const headers = {
		headers: {
		Accept: "application/json",
		},
	};
	const response = await axios
			.post(`${url}${local_url}`, data, headers)
			.catch((err) => 
			{ 
				errorHandler(err);
				return err;
			});
	// console.log("response", response);

	return response;
};

/*===============================================================
			Get_Listings
==================================================================*/

export const getListing = async (geturl) => 
{
	const response = await axios.get(url + geturl).catch((err) => {
		errorHandler(err);
		return err;
	});
	return response;
};

/*===============================================================
			Refresh_Token
==================================================================*/

export const refresh_token = async () => 
{
	// const navigate = useNavigate();

	const local_url = "/mercury/access/refresh";
	// // console.log("data", data);
	const headers = 
	{
		headers: {
		Accept: "application/json",
		Authorization: "Bearer " + getLocalAuthToken(),
		},
	};

	const response = await axios.post( `${url}${local_url}`, { refreshToken: getRefreshToken(), }, headers).then((res) => 
	{
		setAuthToken(res?.data?.accessToken);
		setRefreshToken(res?.data?.refreshToken);
		// set the timeout time
		setExpTimestamp(res?.data?.exp);
		localStorage.setItem("user", JSON.stringify(res?.data));
	})
	.catch((err) => 
	{
		errorHandler(err);
		localStorage.removeItem("user");
		removeAuthToken();
		removeRefreshToken();
		history.push("/auth");
		window.location.reload();
		return err;
	});

	return response;
};

