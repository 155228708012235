/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { AuthState } from '../../../../context/authContext'
import { getService } from '../../../../services/commonApi'
import AccountRequestPop from './Modals/AccountRequestModals/AccountRequestPop'
import { getCompanyIdToken } from '../../../../services/tokenService'
import { stringPipe } from '../../../../pipes/stringPipes'

const AccountsRequest = ({ activeTab }) => {

    const [accountRequest, setAccountRequest] = useState([])
    const [accountID, setAccountID] = useState('')
    const [sortby, setSortby] = useState()

    useEffect(() => {
        if (activeTab === 'accountRequest') {
            getAccountRequestData()
        }
    }, [activeTab, sortby])

    // var start = 1;
    // var stop = 30;

    var sortFilter = ['Last Name',
        'Email',
        'Department',
        'User Name',
        'Mailstop',
        'Address',
        'Position',
        'Cost Center',
        'Employee Number',
        'Account Type']


    const getAccountRequestData = () => {
        getService(`/mercury/company/${getCompanyIdToken()}/requests/list`, true, {
            params: {
                sortby
            }
        }).then((
            res => {
                if (res?.status === 200) {
                    setAccountRequest(res?.data?.accountRequests)
                }
            }
        ))
    }

    const { databaseRoute, setDatabaseRoute } = AuthState()
    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-B" onClick={() => { setDatabaseRoute('accountRequest') }}>
                <h5 className="mb-0">
                    <a className="collapsed heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-B" aria-expanded="false"
                        aria-controls="collapse-B">
                        Accounts Requested  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-B" className={`bgYellow collapse ${databaseRoute === "accountRequest" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-B">
                <div className="head d-none d-md-block">
                    <h4 className="heading_1 text_dark mb-0">Accounts Requested</h4>
                </div>
                <div className="p-4 col-lg-11">
                    <p className="mb-5"> Below are listed all new account requests. Please review and approve/deny the request in order to allow new users to access the system.</p>
                    <div className="col-md-3 col-lg-2 ms-auto mb-3 ">
                        <select className="form-select form-select-sm w_lg_75 ms-auto" onChange={(e) => setSortby(e.target.value)}>
                            <option defaultValue={''} hidden disabled>Sort</option>
                            {sortFilter.map((data, i) => (
                                <option key={i} value={(data).toLocaleLowerCase().replace(' ', '')}>{data}</option>
                            ))}
                        </select>
                    </div>
                    <div className="table-responsive cst_border cst_table">
                        <table className=" table  ">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>Email address</th>
                                    <th>Work phone </th>
                                    <th>Position</th>
                                    <th>Dept</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accountRequest?.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td onClick={(e) => setAccountID(data.requestId)} href="#" data-bs-toggle="modal" data-bs-target="#accountRequestPop">{data?.fullName}</td>
                                            <td>{data?.address?.address1}, {data?.address?.city}</td>
                                            <td>{data?.emailAddress1}</td>
                                            <td>{stringPipe(data?.workPhone, 9)}</td>
                                            <td>{data?.positionName?.name}</td>
                                            <td>{data?.departmentName?.name}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <AccountRequestPop userSelected={accountID} setAccountID={setAccountID} getAccountRequestData={getAccountRequestData} />
            </div>
        </>

    )
}

export default AccountsRequest