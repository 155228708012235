/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { getService } from '../../../../../../../services/commonApi'
import { getCompanyIdToken } from '../../../../../../../services/tokenService'

const Account = ({ navLink, setNavLink, error, setError, accountInput, setAccountInput, callbackfunction, mode, resetData }) => 
{
	const [userName, setUsername] = useState(accountInput?.userName ? accountInput?.userName : '')
	const [accountTypeId, setAccountTypeId] = useState(accountInput?.accountTypeId ? accountInput?.accountTypeId : '')
	const [notificationMode, setNotificationMode] = useState(accountInput?.notificationMode ? accountInput?.notificationMode : 'M')
	const [employeeSearchList, setEmployeeSearchList] = useState([])
	const [employeeSearchResult, setemployeeSearchResult] = useState()
	const [replacementId, setReplacementId] = useState('')
	const [accountTypeList, setAccountTypeList] = useState([])
	const [submit, setSubmit] = useState(false)
    const [searchKey, setSearchKey] = useState("")

	useEffect(() => 
	{
		getAccountType()
	}, [])

	/**
	 * React to the change in the type list.  Set the default value for the account type.
	 */
	useEffect(() =>
	{
		if( accountTypeList?.length && !accountInput?.accountTypeId )
		{
			setAccountTypeId( accountTypeList[0].id )
		}
	}, [ accountTypeList ] );

	const getAccountType = () => 
	{
		getService(`/mercury/company/${getCompanyIdToken()}/accounttypes/list`, true).then( (res) => 
		{
			if (res?.status === 200 ) 
			{
				setAccountTypeList(res.data.accountTypes)
			}
		})
	}

	useEffect(() =>
	{
		if( accountInput?.accountTypeId )
		{
			setAccountTypeId( accountInput.accountTypeId )
		}
	}, [ accountInput ] )

	const setContactInputData = (val) => 
	{
		if (val && !val.approve && !replacementId) 
		{
			return setError({ ...error, err:true, desc:'Replacement Id is required', replacementId:true })
		}
		setSubmit(true)
		if (replacementId) 
		{
			setAccountInput({ replacementId, userName, notificationMode, accountTypeId, ...val, 'notify': true })
		} 
		else 
		{
			setAccountInput({ userName, notificationMode, accountTypeId, ...val, 'notify': true })
		}
		if (!userName) 
		{
			return setError({ ...error, err:true, desc:'Username is required', userName:true })
		}
		if (replacementId) 
		{
			callbackfunction({ replacementId, userName, notificationMode, accountTypeId, ...val, 'notify': true })
		} else 
		{
			callbackfunction({ userName, notificationMode, accountTypeId, ...val, 'notify': true })
		}
		setError({ err:false, desc:'' })
		setEmployeeSearchList([])
		setSearchKey('')
	}

	useEffect(() => 
	{
		const search = setTimeout(() => 
		{
			if (searchKey) 
			{
				searchEmployee(searchKey)
			}
			else 
			{
				setEmployeeSearchList([])
			}
		}, 1500);
		return () => clearTimeout(search)
	}, [searchKey])


	const searchEmployee = (name) => 
	{
		getService(`/mercury/employee/list?filter=${name}`, true).then((res) => 
		{
			setEmployeeSearchList(res.data?.employees)
		})
		.catch( err =>
		{
			setError({ ...error, desc:"Error in search", replacementId:true })
		})
	}

	const handleAccountTypeChange = (event) => 
	{
		setAccountTypeId(event.target.value);
	};	


    return (
        <div
            className={`tab-pane fade ${navLink === "account" ? "show active" : ""}`}
            aria-labelledby="v-pills-settings-tab"
        >
            <div className="col-lg-9 offset-lg-1 modalContentItems">
                {mode === 'request' && (<> <h2 className="heading_1">Account requester information</h2>
                    <p className="mb-0">
                        Please review all the following information before process your request.
                    </p>
                    <p className="fnt_w_bold">Please be as thorough as possible.</p></>)}
                <form className=" pt-4">
                    <div className="row g-3">
                        <div className="fm_grp col-md-6">
                            <label htmlFor="Firstname" className={`form-label ${error?.userName && !userName ? 'error_text' : ''}`}>
                                * Username {submit && !userName && <span>is required</span>}
                            </label>
                            <input type="text" className={`form-control ${error?.userName && !userName ? 'error_input' : ''}`} id="Firstname"
                                onInput={(e) => setUsername(e.target.value)}
                                value={userName}
                            />
                        </div>

                        <div className="fm_grp col-md-6">
                            <label htmlFor="inputMailStop" className={`form-label ${submit && !accountTypeId ? 'error_text' : ''}`}>
                                * Account Type {submit && !accountTypeId && <span>is required</span>}
                            </label>
                            <select id="inputMailStop" className={`form-select ${submit && !accountTypeId ? 'error_input' : ''}`} 
									onChange={handleAccountTypeChange} value={accountTypeId} >
                                {accountTypeList?.map((data, i) => {
                                    return (
                                        <option key={i} value={data.id}>{data.description}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className="fm_grp col-md-6 mt-2">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                    onChange={(e) => setNotificationMode(e.target.value)}
                                    value='D'
                                    checked={notificationMode === 'D'}
                                />
                                <label
                                    className="form-check-label ms-2"
                                    htmlFor="flexRadioDefault1"
                                >
                                    Contact is notified daily
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                    onChange={(e) => setNotificationMode(e.target.value)}
                                    value='M'
                                    checked={notificationMode === 'M'}
                                />
                                <label
                                    className="form-check-label ms-2"
                                    htmlFor="flexRadioDefault2"
                                >
                                    Contact is notified for each piece
                                </label>
                            </div>
                        </div>

                        <div className="col-md-6"></div>

                        {mode === 'request' && <div className="fm_grp col-md-6">
                            <label className="form-check-label mb-2" htmlFor="flexRadioDefault1">Assign mail to:</label>
                            {/*  */}
                            {employeeSearchResult &&
                                (
                                    <div className="d-flex ForwardSelectedBox">
                                        <div>
                                            {/* <p>{employeeSearchResult.fullName}</p> */}
                                            <p>{employeeSearchResult?.employee?.employeeName?.fullName}</p>
                                            <p>{employeeSearchResult?.employee?.employeeName?.suffix}</p>
                                            <p>{employeeSearchResult?.employee?.employeeName?.nickNames}</p>
                                            <p>{employeeSearchResult?.employee?.companyDetails?.address?.address1}</p>
                                            <p>{employeeSearchResult?.employee?.companyDetails?.department}</p>
                                            <p>{employeeSearchResult?.employee?.contactDetails?.emailAddress1}{employeeSearchResult?.contactDetails?.emailAddress2 ? ',' + employeeSearchResult?.contactDetails?.emailAddress2 : ''}</p>
                                        </div>
                                        <div>
                                            <span className='px-2'> <img src="assets/img/Delete.svg" alt="" onClick={() => { setemployeeSearchResult(null); setReplacementId(''); setEmployeeSearchList([]) }} /> </span>
                                        </div>
                                    </div>
                                )

                            }
                            {!employeeSearchResult &&
                                (
                                    <div className="serach_box" id="dropdownMenuButton2" data-bs-toggle="dropdown">
                                        <input type="text" aria-label="Search" placeholder="Search employees" onChange={(e) => { setSearchKey(e.target.value) }} className={`form-control ${error?.replacementId && !replacementId ? "error_input" : ""}`} style={{ 'borderColor': error?.desc ? 'red' : '' }} defaultValue={replacementId} />
                                        <a href="#"><span className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
                                    </div>
                                )
                            }
                            {!employeeSearchResult && <ul className={`dropdown bg-light dropdown-menu search_dropdown ${employeeSearchList?.length ? 'show' : ''}`} aria-labelledby="dropdownMenuButton2">
                                {employeeSearchList?.length < 1 ? (<li>
                                    <a className="dropdown-item" href="#">
                                        <p>No Matches Found</p>
                                    </a>
                                </li>) : (
                                    employeeSearchList?.map((data, i) => {
                                        return (
                                            <li key={i} onClick={() => { setReplacementId(data?.employee?.employeeId); setemployeeSearchResult(data) }}>
                                                <a className="dropdown-item" href="#">
                                                    <p>{data?.employee?.employeeName?.fullName}</p>
                                                    <p>{data?.employee?.employeeName?.suffix}</p>
                                                    <p>{data?.employee?.employeeName?.nickNames}</p>
                                                    <p>{data?.employee?.companyDetails?.address?.address1}</p>
                                                    <p>{data?.employee?.companyDetails?.department}</p>
                                                    <p>{data?.employee?.contactDetails?.emailAddress1}{data?.contactDetails?.emailAddress2 ? ',' + data?.contactDetails?.emailAddress2 : ''}</p>
                                                </a>
                                            </li>
                                        )
                                    })
                                )}
                            </ul>}
                        </div>}

                    </div>
                </form>
            </div>
            <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
                <div> </div>
                <div className="p-md-4 text-end">
                    <button
                        type="button"
                        className="btn btn-link  btn_link_primary me-3"
                        data-bs-dismiss="modal"
                        onClick={() => { setSubmit(false); setError({ err:false, desc:'' }); resetData() }}                    >
                        Cancel
                    </button>
                    {!mode && <button type="button" className="btn btn_cstm_primary  px-4" onClick={() => setContactInputData()} >
                        Add Account
                    </button>}
                    {mode === 'edit' && <button type="button" className="btn btn_cstm_primary  px-4" onClick={() => setContactInputData()} >
                        Save edits
                    </button>}
                    {mode === 'request' && (<>
                        <button type="button" className="btn btn-link  btn_link_primary  px-4 mx-2 fw-bold" onClick={() => { setContactInputData({ 'approve': false }) }}>
                            Deny
                        </button>
                        <button type="button" className="btn btn_cstm_primary px-4" onClick={() => { setContactInputData({ 'approve': true }) }} >
                            Approve
                        </button>
                    </>
                    )}
                </div>
            </div>
        </div >
    )
}

export default Account 