import { toast } from "react-toastify";

export function errorHandler(err) 
{
	var status = err?.response?.status;
	var errorCode = err?.response?.data?.error?.code;
	switch (status) 
	{
		case 400:
			if (errorCode === 6) 
			{
				notify("That has already been entered.");
			} 
			else 
			{
				notify("You must supply a value for required fields.");
			}
			break;

		case 401:
			if (errorCode === 5) 
			{
				notify(err?.response?.data?.error?.desc);
			} 
			else 
			{
				notify(err?.response?.data?.error?.desc);
			}
			break;

		case 403:
			if (errorCode === 3) 
			{
				notify("The values you entered are not valid for this request.");
			}
			else if (errorCode === 4) 
			{
				notify( "You aren't allowed to delete that record. Deleted records must be replaced by an existing record." );
			} 
			else if (errorCode === 9) 
			{
				notify("You must supply a value for required fields.");
			} 
			else 
			{
				notify("Sorry, but that operation is not allowed.");
			}
			break;

		case 404:
			notify( "Opps.  We tried to access something that doesn't exist.  Please try refreshing the current screen." );
			break;

		default:
			notify("Opps, something unexpected happened and your request was not completed.");
			break;
	}
}

const notify = (msg) => toast.error(msg, 
{
	position: "top-center",
	autoClose: 5000,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	progress: undefined,
	theme: "colored",
});
