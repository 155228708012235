/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { CommonState } from '../../../../context/commonContext'
import { RouteState } from '../../../../context/routeContext'
import { getService, putService } from '../../../../services/commonApi'
import ImageGallery from './ImageGallery'

const QualitControls = () => {

    var imageRef = useRef()
//    var url = useRef()
	const [url,setUrl] = useState('')
    var PrimaryUrl = '/mercury/mail/qualitycontrol'
    const { deliveryRoute, setDeliveryRoute } = RouteState()

    const [mailList, setMailList] = useState([])
    const [mailDetail, setMailDetail] = useState({})

    const [mailImagesList, setMailImagesList] = useState([])

    const [sender, setSender] = useState(false)
    const [recipient, setRecipient] = useState(false)

    const [skippedDetails, setSkippedDetails] = useState([])

    const [currentIndex, setCurrentIndex] = useState(0)

    const [loader, setLoader] = useState(false)
    const { refreshMailStats, setRefreshMailStats } = CommonState()


    useEffect(() => 
	{
        if (deliveryRoute === 'quality') 
		{
            getUnassignedMailList()
        }
    }, [deliveryRoute])

    const getUnassignedMailList = () => 
	{
        getService(PrimaryUrl, true, "").then((res) => 
		{
			setMailList(res?.data?.messages)
			if (res?.data?.messages.length === 0) 
			{
				setMailDetail()
				setSender()
				setRecipient()
				setMailImagesList([])
			}
		})
    }

	useEffect(() => 
	{
		if (mailList.length > 0) 
		{
			getMailDetail(currentIndex)
		}
	}, [mailList, currentIndex])

    const getMailDetail = (index) => 
	{
		let curr_url = `${PrimaryUrl}/${mailList[index]?.mailId}`
		setMailImagesList([])
		setUrl( `${PrimaryUrl}/${mailList[index]?.mailId}` )
        getService( curr_url, true, "").then( (res) => 
		{
			setMailDetail(res.data)
			setSender(res.data?.senderOk)
			setRecipient(res.data?.receiverOk)
			setMailImagesList(res.data.mailParts)
		})
    }

    const UpdateQualityControl = () => 
	{
        putService(url, '', true, {
            params: {
                sender, recipient
            }
        }).then((res) => 
		{
			setRefreshMailStats(!refreshMailStats)
			if (currentIndex + 1 < mailList.length) {
				setCurrentIndex(currentIndex + 1)
			} else {
				refreshLastIndex()
			}
		})
    }

	const refreshLastIndex = () => 
	{
		setMailDetail({})
		setSender({})
		setRecipient({})
		setMailImagesList([])
	}



    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-E" onClick={() => { setDeliveryRoute('quality') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-E" aria-expanded="true" aria-controls="collapse-E">
                        Quality Controls  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-E" className={`bgYellow collapse ${deliveryRoute === "quality" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-E">
                <div className="head mt-2 mt-lg-0 row">
                    <div className="col-auto">
                        <p className="text_dark mb-0">#{currentIndex + 1} of {mailList?.length}</p>
                    </div>
                    <div className="col-11 col-md-7 col-sm-12 align-self-center">
                        <ProgressBar variant="info" now={((currentIndex) / mailList?.length) * 100} />
                    </div>
                </div>
                <div className="p-2 mt-4">
                    <div className="row">
                        <div className="col-lg-9">
                            {/* <!-- Add doc sheet area --> */}
                            <ImageGallery imageList={mailImagesList} mailUrl={url} />
                            {/* <!-- Add doc sheet area --> */}

                        </div>
                        <div className="col-lg-3">
                            <div className="right_box mt-4 pt-1">
                                <div className="mb-3">
                                    Assigned Sender: <br />
                                    <span>
                                        <b>
                                            {mailDetail?.senderName?.name} <br />
                                        </b>
                                    </span>
                                </div>
                                <div className="mb-3 form-check form-check-dark">
                                    <input type="checkbox" className="form-check-input" id="assignedrec1" checked={sender} onChange={() => setSender(!sender)} />
                                    <label className="form-check-label" htmlFor="assignedrec1">OK</label>
                                </div>
                            </div>

                            <div className="right_box mt-4 pt-1">
                                <div className="mb-3">
                                    Assigned Receiver: <br />
									{ mailDetail?.receiverName?.fullName && <div><strong>{ mailDetail?.receiverName?.fullName}</strong></div> }
									{ mailDetail?.positionName?.name && ( mailDetail?.receiverName?.fullName ? <div>{mailDetail?.positionName?.name}</div> : <div><strong>{mailDetail?.positionName?.name}</strong></div> ) }
									{ mailDetail?.departmentName?.name && ( mailDetail?.receiverName?.fullName || mailDetail?.positionName?.name ? <div>{mailDetail?.departmentName?.name}</div> : <div><strong>{mailDetail?.departmentName?.name}</strong></div> ) }
									{ mailDetail?.companyName?.name && ( mailDetail?.receiverName?.fullName || mailDetail?.positionName?.name || mailDetail?.departmentName?.name ? <div>{mailDetail?.companyName?.name}</div> : <div><strong>{mailDetail?.companyName?.name}</strong></div> ) }
									{ mailDetail?.receiverName?.fullName && mailDetail?.positionName?.name && mailDetail?.departmentName?.name && mailDetail?.companyName?.name ? <div><strong>Nothing Identified</strong></div> : '' }
									{ mailDetail?.address?.city && <><div>{mailDetail?.address?.address1 }</div> <div>{mailDetail?.address?.city} {mailDetail?.address?.state} {mailDetail?.address?.zipcode}</div></> }
                                </div>
                                <div className="mb-3 form-check form-check-dark">
                                    <input type="checkbox" onChange={() => setRecipient(!recipient)} className="form-check-input" id="assignedrec2" checked={recipient} />
                                    <label className="form-check-label" htmlFor="assignedrec2">OK</label>
                                </div>
                            </div>

                            <div className="text-end mt-4">
                                <button type="button" className="btn btn_cstm_primary" onClick={() => UpdateQualityControl()}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QualitControls