/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { getService, putService } from '../../../../../services/commonApi';
import { getCompanyIdToken } from '../../../../../services/tokenService';

const NotificationTemplate = ({ urlEnd, setUrlEnd }) => 
{
	var primaryUrl = `/mercury/company/${getCompanyIdToken()}/notifications/`

	const [body, setBody] = useState('')
	const [content, setContent] = useState('')
	const [subject, setSubject] = useState('')
	const [ishtml, setIshtml] = useState(true)
	const [insert, setInsert] = useState("%%WELCOME_URL%%")
	const [envelope, setEnvelope] = useState('')
	const [embedding, setEmbedding] = useState('')
	const [deptSubject, setDeptSubject] = useState('')
	const [notificationname, setNotificationname] = useState('')
	const [previewImage, setPreviewImage] = useState('')
	const [error, setError] = useState('')
	const [file0, setFile0] = useState({})
    const [editorContext, setEditorContext] = useState()

	useEffect(() => 
	{
		urlEnd && getNotificationText()
		switch (urlEnd) 
		{
			case 'newaccount':
				setNotificationname('New Account Notification')
				break;

			case 'approvedaccount':
				setNotificationname('Accepted Account')
				break;

			case 'deniedaccount':
				setNotificationname('Denied Account')
				break;

			case 'forgotten':
				setNotificationname('Forgotten Password')
				break;

			case 'dailymail':
				setNotificationname('Daily Mail')
				break;

			case 'piecemail':
				setNotificationname('Piece Wise Mail')
				break;

			default:
				break;
		}
	}, [urlEnd])


	const getNotificationText = () => 
	{
		getService(primaryUrl + urlEnd, true).then((res) => 
		{
			setContent(res?.data?.content)
			setSubject(res?.data?.subject)
			setIshtml(res?.data?.ishtml)
			setDeptSubject(res?.data?.deptSubject)
			setEmbedding(res?.data?.embedding)
			setEnvelope(res?.data?.envelope)
			setPreviewImage(res?.data?.image)
			setFile0(res?.data?.image);
		})
	}

	const resetForm = () => 
	{
		setContent('')
		setSubject('')
		setDeptSubject('')
		setIshtml()
		setPreviewImage('')
		setUrlEnd('')
		setError('')
	}

	const handleChange = (event, editor) => 
	{
		setContent(editor.getData());
	}


	const imageSelect = (event) => 
	{
		let file = event.target.files[0];
		setFile0(file)
		var reader = new FileReader();
		reader.onload = e => setPreviewImage(reader.result);
		// reader.readAsDataURL(file);
		var url = reader.readAsDataURL(file);
		getBase64(file, (result) => 
		{
			setFile0(result);
		})
	}

	const getBase64 = (file, cb) => 
	{
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result)
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	const setEditorFocus = (editor) => 
	{
		setEditorContext(editor)
	}

	const addPhrase = () => 
	{
		editorContext.model.change(writer => 
		{
			writer.insertText(insert, writer.model.document.selection.getFirstPosition());
		});
	}

	const updateNotificationText = () => 
	{
		if (urlEnd !== 'piecemail') 
		{
			if (!subject || !content) 
			{
				return setError('Please fill all the fields')
			}
			putService(primaryUrl + urlEnd, {subject, ishtml, content, deptSubject }, true).then((res) => 
			{
				if (res.status === 200) 
				{
					setUrlEnd()
					document.getElementById('closenotifieditmodal').click()
				}
			})
		} 
		else 
		{
			if (!subject || !deptSubject || !content || !file0) 
			{
				return setError('Please fill all the fields')
			}
			var formData = 
			{
				subject, deptSubject, content, ishtml, image: file0, embedding
			}
			putService(primaryUrl + urlEnd, formData, true).then( (res) => 
			{
				if (res.status === 200) 
				{
					setUrlEnd()
					document.getElementById('closenotifieditmodal').click()
				}
			})
		}
	}

	useEffect(() => 
	{
		const myModal = document.getElementById('account_notification')
		myModal.addEventListener('shown.bs.modal', () => 
		{
			myModal.removeAttribute('tabindex')
		})
	}, [])

	return (

		<div className="modal fade account_notification" id="account_notification" data-bs-focus="false" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
			<div className="modal-dialog modal-dialog-centered modal-lg" >
				<div className="modal-content" >
					<div className="modal-body p-4" >
						<h2 className="heading_1 mb-3" > Edit “{notificationname}”</h2 >
						{error && (
							<div
								className="alert alert-danger sm_ctm_alert pl-4 d-block"
								role="alert"
							>
								<span>
									<img
										src="/assets/img/error_icon_white.png"
										className="img-fluid"
										alt=""
									/>
								</span>{" "}
								{error}
							</div>
						)
						}
						<div className="row">
							<div className="col-lg-6">
								<div className="fm_grp">
									<label for="subject" className="form-label">Subject</label>
									<input type="text" class={`form-control ${error && !subject ? 'error_input' : ''}`} id="subject" value={subject} onInput={(e) => setSubject(e.target.value)} />
								</div >
								{
									['dailymail', 'piecemail'].includes(urlEnd) && <div className="fm_grp">
										<label for="deptsubj" className="form-label">Dept Subject</label>
										<input type="text" class={`form-control ${error && !deptSubject ? 'error_input' : ''}`} id="deptsubj" value={deptSubject} onInput={(e) => setDeptSubject(e.target.value)} />
									</div>
								}
							</div >

							<div className="col-lg-12" >
								<div className=" d-flex justify-content-between algn-items-center fm_grp mb-2 col-lg-12 pe-3" >
									<label for="inputState" className="form-label">Email content</label>
									<span className='d-flex' >
										<select className={`form-select form-select-sm ${error && deptSubject}`}
											// onChange={(e) => {
											//     setInsert(e.target.value)
											// }}

											value={insert}
											onChange={(e) => { setInsert(e.target.value) }}
										>
											<option value="%%WELCOME_URL%%">Welcome URL</option>
											<option value="%%SYSTEM_URL%%">System URL</option>
											<option value="%%MAIL_URL%%">Mail URL</option>
											<option value="%%USERNAME%%">User Name</option>
											<option value="%%PASSWORD%%">Password</option>
											<option value="%%FIRSTNAME%%">First Name</option>
											<option value="%%LASTNAME%%">Last Name</option>
											<option value="%%PROMPT%%">Recovery Phrase</option>
											<option value="%%SECRETPHRASE%%">Recovery Answer</option>
											<option value="%%SENDERNAME%%">Sender Name</option>
											<option value="%%DEPARTMENT%%">Department Name</option>
											<option value="%%IMAGE%%">Envelope Image</option>
											<option value="%%START%% %%[1,50,50]%% %%SENDER%% %%END%%">Sender Text</option>
											<option value="%%START%% %%[1,500,500]%% %%RECEIVER%% %%END%%">Recipient Text</option>
										</select>
										<button type="button" className="btn btn-link" onClick={() => addPhrase()} >Insert</button>
									</span>
								</div >
								<CKEditor
									editor={Editor}
									// config={{
									//     plugins: [SourceEditing],
									//     toolbar: [ 'sourceEditing']
									// }}
									id="CKEDITOR"
									className={`${error && !content ? 'error_inupt' : ''}`}
									data={content ? content : ""}
									onChange={(event, editor) => { handleChange(event, editor) }}
									// onFocus={(event, editor) => { setEditorFocus(event, editor) }}
									onReady={(editor) => 
									{
										// You can store the "editor" and use when it is needed.
										// console.log("Editor is ready to use!", editor);
										setEditorFocus(editor)
										editor.editing.view.change((writer) => { writer.setStyle("height","250px",editor.editing.view.document.getRoot()); });
									}}
								/>

								{
									urlEnd === "piecemail" &&
									<div className="d-flex col-lg-12 justify-content-between pt-5">
										{/* <div  className="row "> */}
										{/* <span  className="col-lg-8"> */}
										<span className="col-lg-8 row align-items-center">
											<span className="col-lg-10">
												<label htmlFor="upload-photos">Envelope Image</label>
												<div className={`form-control ${error && !previewImage ? 'error_inupt' : ''}`} 
															style={{'padding': '0px !important', 'borderRadius': '0', }}>
													{!previewImage ? (<span>&nbsp;</span>) :
														(
															<span className="d-flex align-items-start">
																< img src={previewImage} alt="" style={{ width: '150px' }} />
																<a style={{ 'color': 'red' }} onClick={() => { setPreviewImage(''); setFile0('') }}>
																	<i className="bi bi-x-circle-fill"></i>
																</a>
															</span>
														)
													}
												</div>
											</span >
											<span className="col-lg-2 pt-3">
												<input type="file" className="form-control d_none" id="upload-photos" onChange={(e) => imageSelect(e)} />
												<label htmlFor="upload-photos" className="file_type">Browse...</label>
											</span>
										</span >
										{/* </span> */}
										< span className="col-lg-4" >


											<div >
												<label htmlFor="">Image Embedding</label>
												<select className="form-select form-select-sm" onChange={(e) => setEmbedding(e.target.value)} value={embedding}>
													<option value="base64">Place</option>
													<option value="cid">Attachment</option>
												</select>
											</div>


											{/* <div  className="fm_grp"> */}
											{/* <label htmlFor="inputState"  className="form-label">Sub-report file</label>
										<input type="text"  className="form-control" id="Firstname" /> */}
											{/* </div> */}
										</span >


										{/* </div> */}
									</div >
								}
							</div >
						</div >


						<div className="d-flex flex-wrap align-items-center justify-content-end mt-5 pt-lg-5" >
							<div className="text-end" >
								<button type="button" className="btn btn-link btn_link_primary" id='closenotifieditmodal' onClick={() => resetForm()} data-bs-dismiss="modal">Cancel</button>
								<button type="button" className="btn btn_cstm_primary px-3" onClick={() => updateNotificationText()}>Save</button>
							</div >
						</div >

					</div >
				</div >
			</div >
		</div >

	)
}


export default NotificationTemplate