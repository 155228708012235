/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { postService, putService } from '../../../../../../../services/commonApi'
import './EditSenderAddress.css'

function EditSenderAddress({ data, type, url, getSenderAddress, setSelectedAddress }) {

    const [address1, setAddress1] = useState('')
    const [address2, setaddress2] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('AL')
    const [zipcode, setZipcode] = useState('')
	const [primary,setPrimary] = useState(false)

    const [error, setError] = useState('')

    // var stateList = ['AL', 'AK', 'CA', 'FL', 'HI', 'NJ', 'NY', 'TX', 'WA']

    var stateList = {
        'US': [
            {
                "name": "Alabama",
                "code": "AL"
            },
            {
                "name": "Alaska",
                "code": "AK"
            },
            {
                "name": "Arizona",
                "code": "AZ"
            },
            {
                "name": "Arkansas",
                "code": "AR"
            },
            {
                "name": "California",
                "code": "CA"
            },
            {
                "name": "Colorado",
                "code": "CO"
            },
            {
                "name": "Connecticut",
                "code": "CT"
            },
            {
                "name": "Delaware",
                "code": "DE"
            },
            {
                "name": "Florida",
                "code": "FL"
            },
            {
                "name": "Georgia",
                "code": "GA"
            },
            {
                "name": "Hawaii",
                "code": "HI"
            },
            {
                "name": "Idaho",
                "code": "ID"
            },
            {
                "name": "Illinois",
                "code": "IL"
            },
            {
                "name": "Indiana",
                "code": "IN"
            },
            {
                "name": "Iowa",
                "code": "IA"
            },
            {
                "name": "Kansas",
                "code": "KS"
            },
            {
                "name": "Kentucky",
                "code": "KY"
            },
            {
                "name": "Louisiana",
                "code": "LA"
            },
            {
                "name": "Maine",
                "code": "ME"
            },
            {
                "name": "Maryland",
                "code": "MD"
            },
            {
                "name": "Massachusetts",
                "code": "MA"
            },
            {
                "name": "Michigan",
                "code": "MI"
            },
            {
                "name": "Minnesota",
                "code": "MN"
            },
            {
                "name": "Mississippi",
                "code": "MS"
            },
            {
                "name": "Missouri",
                "code": "MO"
            },
            {
                "name": "Montana",
                "code": "MT"
            },
            {
                "name": "Nebraska",
                "code": "NE"
            },
            {
                "name": "Nevada",
                "code": "NV"
            },
            {
                "name": "New Hampshire",
                "code": "NH"
            },
            {
                "name": "New Jersey",
                "code": "NJ"
            },
            {
                "name": "New Mexico",
                "code": "NM"
            },
            {
                "name": "New York",
                "code": "NY"
            },
            {
                "name": "North Carolina",
                "code": "NC"
            },
            {
                "name": "North Dakota",
                "code": "ND"
            },
            {
                "name": "Ohio",
                "code": "OH"
            },
            {
                "name": "Oklahoma",
                "code": "OK"
            },
            {
                "name": "Oregon",
                "code": "OR"
            },
            {
                "name": "Pennsylvania",
                "code": "PA"
            },
            {
                "name": "Rhode Island",
                "code": "RI"
            },
            {
                "name": "South Carolina",
                "code": "SC"
            },
            {
                "name": "South Dakota",
                "code": "SD"
            },
            {
                "name": "Tennessee",
                "code": "TN"
            },
            {
                "name": "Texas",
                "code": "TX"
            },
            {
                "name": "Utah",
                "code": "UT"
            },
            {
                "name": "Vermont",
                "code": "VT"
            },
            {
                "name": "Virginia",
                "code": "VA"
            },
            {
                "name": "Washington",
                "code": "WA"
            },
            {
                "name": "West Virginia",
                "code": "WV"
            },
            {
                "name": "Wisconsin",
                "code": "WI"
            },
            {
                "name": "Wyoming",
                "code": "WY"
            }
        ],
        'CA': [
            {
                "name": "Alberta",
                "code": "AB"
            },
            {
                "name": "British Columbia",
                "code": "BC"
            },
            {
                "name": "Manitoba",
                "code": "MB"
            },
            {
                "name": "New Brunswick",
                "code": "NB"
            },
            {
                "name": "Newfoundland and Labrador",
                "code": "NL"
            },
            {
                "name": "Northwest Territories",
                "code": "NT"
            },
            {
                "name": "Nova Scotia",
                "code": "NS"
            },
            {
                "name": "Nunavut",
                "code": "NU"
            },
            {
                "name": "Ontario",
                "code": "ON"
            },
            {
                "name": "Prince Edward Island",
                "code": "PE"
            },
            {
                "name": "Quebec",
                "code": "QC"
            },
            {
                "name": "Saskatchewan",
                "code": "SK"
            },
            {
                "name": "Yukon",
                "code": "YT"
            }
        ]
    }

    useEffect(() => {
        if (type === 'edit') {
            setAddress1(data?.address1?data?.address1:'')
            setaddress2(data?.address2?data?.address2:'')
            setCity(data?.city?data?.city:'')
            setState(data?.state?data?.state:'')
            setZipcode(data?.zip)
			setPrimary( data?.primary ? true : false );
        } else {
            setAddress1('')
            setaddress2('')
            setCity('')
            setState('')
            setZipcode('')
			setPrimary( false )
        }
    }, [type, data?.addressId])


    const submitSenderAddress = () => 
	{
		if (!address1 || !city || !state || !zipcode) {
			if (address1 && city && state && zipcode) {
				return setError("Address is required field");
			} else if (!city && address1 && state && zipcode) {
				return setError("City is required field");
			} else if (!state && address1 && city && zipcode) {
				return setError("State is required field");
			} else if (!zipcode && address1 && state && city) {
				return setError("Zip Code is required field");
			}
			return setError("Required fields are missing !");
		}

		const formdata = {
			address1, address2, city, state, zipcode, primary
		}
		type ?
			putService(`${url}/address/${data?.addressId}`, formdata, true).then(
				(res) => {
					// console.log('res', res)
					if (res?.request?.status === 200) {
						getSenderAddress()
						document.getElementById('editsenderAddress').click()
					} else {
						return setError(res.request.statusText)
					}
				}, 
				err => 
				{ 
					return setError( err?.response?.data?.error?.desc )
				}
			).catch( (err) =>
			{
				console.log("")
			}) :
			postService(`${url}/address`, formdata, true).then(
				(res) => {
					// console.log('res', res)
					if (res?.request?.status === 200) {
						getSenderAddress()
						document.getElementById('editsenderAddress').click()
					}
					else {
						return setError(res?.request?.statusText)
					}
				}, err => { return setError(err?.request?.statusText) })


    }

    const resetForm = () => {
        setError('')
        setAddress1('')
        setaddress2('')
        setCity('')
        setState('')
        setZipcode('')
        setSelectedAddress('')
		setPrimary( false )
    }

    return (
        <div className="modal fade do_edit_sender_address" id="do_edit_sender_address" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body p-4">
                        <div className="col-lg-8 m-auto">
                            <h2 className="heading_1 mb-3 text-capitalize">{type ? 'Edit' : 'Add'} sender address</h2>
                            {error && <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                                <span className="me-3"><img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" /></span>{error}
                            </div>}
                            <div className="row">
                                <div className="fm_grp col-md-12">
                                    <label htmlFor="Address" className="form-label">Address line 1</label>
                                    <input type="text" className={`form-control ${error && !address1 ? 'error_input' : ''}`} id="Address" onInput={(e) => setAddress1(e.target.value)} value={address1} />
                                </div>
                                <div className="fm_grp col-md-12">
                                    <label htmlFor="Address2" className="form-label">Address line 2</label>
                                    <input type="text" className={`form-control`} id="Address2" onInput={(e) => setaddress2(e.target.value)} value={address2} />
                                </div>
                                <div className="fm_grp col-md-12">
                                    <label htmlFor="City" className="form-label">City</label>
                                    <input type="text" className={`form-control ${error && !city ? 'error_input' : ''}`} id="City" onInput={(e) => setCity(e.target.value)} value={city} />
                                </div>
                                <div className="fm_grp col-md-8">
                                    <label htmlFor="inputState" className="form-label">State</label>
                                    <select id="inputState"
                                        onInput={(e) => setState(e.target.value)} value={state} className={`form-control ${error && !state ? 'error_input' : ''}`}>
                                        {stateList?.US?.map((data, i) => (<option key={i} value={data?.code}>{data?.name}</option>))}
                                    </select>
                                </div>
                                <div className="fm_grp col-md-4">
                                    <label htmlFor="inputZip" className="form-label">Zip Code</label>
                                    <input type="text" className={`form-control ${error && !zipcode ? 'error_input' : ''}`} id="inputZip" placeholder="00000" onInput={(e) => setZipcode(e.target.value)} value={zipcode} />
                                </div>
                                <div className="fm_grp col-md-8 checkbox-container">
                                    <label htmlFor="checkPrimary" className="checkbox-label">Is Primary Address? </label>
                                    <input type="checkbox" className={`form-check-input`} 
											id="checkPrimary" 
											onChange={(e) => setPrimary( e?.target?.checked ? true : false )} 
											checked={primary} />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap align-items-center justify-content-between mt-3">
                            <div> </div>
                            <div className=" text-end">
                                <button type="button" onClick={resetForm} id="editsenderAddress" className="btn btn-link  btn_link_primary me-3" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#sender_name">Cancel</button>
                                <button type="button" className="btn btn_cstm_primary px-4" onClick={() => submitSenderAddress()}>Save  </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditSenderAddress