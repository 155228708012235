import React, { useEffect, useState } from 'react'
import { deleteService } from '../../../../../services/commonApi'

const DeleteAccountType = ({ accountTypeList, setAccountTypeId, accountTypeId, url, getAccountTypeList }) => {

    var accountList = accountTypeList?.filter((el) => el?.id !== accountTypeId)
    const [replacementId, setReplacementId] = useState()

    useEffect(() => {
        setReplacementId(accountList[0]?.id)
    }, [accountList])


    const deleteAccount = () => {
        deleteService(url + '/' + accountTypeId, '', true, {
            params: { replacementId }
        }).then(
            (res) => {
                if (res?.status === 200) {
                    document.getElementById('closedeleteAccountModal').click()
                    setAccountTypeId('')
                    getAccountTypeList()
                }
            }
        )
    }

    return (

        <div className="modal fade Delete_replace" id="delete_accountType_replace" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body py-4">
                        <h2 className="heading_1 ps-lg-4">Delete & replace</h2>
                        <div>
                            <div className="col-xl-8 m-auto py-4">
                                <h3 className="heading_2 mb-4">Delete “{accountTypeList.filter((el) => el.id === accountTypeId).map((el) => { return el.description })}”</h3>
                                <div className="fm_grp">
                                    <label htmlFor="inputState" className="form-label">* Replace with the following Account type:</label>
                                    <select id="inputState" className="form-select" onChange={(e) => setReplacementId(e.target.value)}>
                                        {accountList?.map((data, i) => (
                                            <option key={i} value={data?.id}>{data.description}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 mt-5 pt-lg-5">
                                <div> </div>
                                <div className=" text-end">
                                    <button type="button" className="btn btn-link  btn_link_primary" id="closedeleteAccountModal" onClick={() => setAccountTypeId('')} data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn_cstm_primary" onClick={() => deleteAccount()}>Delete & replace</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DeleteAccountType