/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { getService, postService, putService } from '../../../../../services/commonApi'
import { getCompanyIdToken } from '../../../../../services/tokenService'
import AddAction from './AddAction'
import AddCondition from './AddCondition'
import { getComparisonValue, getConditionElementValue, getRuleElementValue } from './AddRuleLogics'

const AddRule = ({ operationType, url, ruleID, setRuleID, getList }) => {

    const [rule, setRule] = useState('')
    const [description, setDescription] = useState('')

    const [conditionData, setConditionData] = useState({})
    const [conditions, setConditions] = useState([])

    const [actionData, setActionData] = useState({})
    const [actions, setActions] = useState([])

    const [error, setError] = useState(false)

    const [addressList, setAddressList] = useState([])
    const [departmentList, setDepartmentList] = useState([])
    const [companyNameList, setCompanyNameList] = useState([])
    const [mailStopList, setMailStopList] = useState([])
    const [senderList, setSenderList] = useState([])
    const [employeeList, setEmployeeList] = useState([])
    const [positionList, setPositionList] = useState([])


    const [oprationMode, setOprationMode] = useState('add')

    useEffect(() => {
        getAddressList();
        getDepartmentList();
        getCompanyNameList();
        getMailStopList();
        getPositionList();
        getEmployeeList();
        getSenderList();
    }, [])

    useEffect(() => {
        if (ruleID) {
            getRuleDetails()
        }
    }, [ruleID])

    const getRuleDetails = () => {
        getService(url + '/' + ruleID, true, "").then(
            (res) => {
                if (res?.status === 200) {
                    setRule(res?.data?.name)
                    setDescription(res?.data?.description)
                    setConditions(res?.data?.conditions)
                    setActions(res?.data?.actions)
                }
            }
        )
    }

    const getAddressList = () => {
        getService(`/mercury/company/${getCompanyIdToken()}/address/list`, true).then((
            res => {
                setAddressList(res?.data?.list)
            }
        ))
    }
    const getDepartmentList = () => {
        getService(`/mercury/company/${getCompanyIdToken()}/department/list`, true).then((res) => {
            if (res.request.status === 200) {
                setDepartmentList(res?.data?.list)
            }
        })
    }
    const getCompanyNameList = () => {
        getService(`/mercury/company/${getCompanyIdToken()}/names`, true).then((res) => {
            setCompanyNameList(res?.data?.companyNames)
        }, (err) => console.log(err))
    }
    const getMailStopList = () => {
        getService(`/mercury/company/${getCompanyIdToken()}/mailstop/list`, true).then((res) => {
            setMailStopList(res?.data?.list)
        })

    }
    const getSenderList = () => {
        getService(`/mercury/sender/list`, true).then((res) => {
            setSenderList(res?.data?.senders)
        })
    }
    const getEmployeeList = () => {
        getService('/mercury/employee/list', true).then((res) => {
            setEmployeeList(res?.data?.employees)
        })
    }
    const getPositionList = () => {
        getService(`/mercury/company/${getCompanyIdToken()}/position/list`, true).then((res) => {
            setPositionList(res?.data?.list)
        })
    }
    const getTextValue = (val, searchingCat) => {
        var returnValue;
        if (searchingCat === "B") {
            var testArr = [
                { code: "N", text: "Insufficient match information(nomatch)" },
                { code: "D", text: "Ambiguous choices available(duplicate)" },
                { code: "Z", text: "No company addresses found" },
                { code: "I", text: "Incorrect Assignment.Sent back for reassignment" },
                { code: "E", text: "Match found but poor choice." },
            ];
            returnValue = testArr
                .filter((el) => el?.code === val)
                .map((el) => {
                    return el?.text;
                });
        } else if (searchingCat === "A") {
            returnValue = addressList.filter((el) => el.addressId === val)?.map((el) => {
                return (
                    el?.address1 +
                    " " +
                    el?.city +
                    "," +
                    el?.state +
                    " " +
                    el?.zipcode
                );
            })
        } else if (searchingCat === "M") {
            returnValue = mailStopList
                ?.filter((data) => data.mailStopId === val)
                ?.map((data) => {
                    return data?.name;
                });
        } else if (searchingCat === "D") {
            returnValue = departmentList
                ?.filter((data) => data.departmentId === val)
                ?.map((data) => {
                    return data?.label;
                });
        } else if (searchingCat === "P") {
            returnValue = positionList
                ?.filter((data) => data.positionId === val)
                ?.map((data) => {
                    return data?.label;
                });
        } else if (searchingCat === "Z") {
            returnValue = companyNameList
                ?.filter((data) => data.id === val)
                ?.map((data) => {
                    return data?.name;
                });
        } else if (searchingCat === "E") {
            returnValue = employeeList
                ?.filter((data) => data?.employee?.employeeId === val)
                ?.map((data) => {
                    return (
                        data?.employee?.employeeName?.firstName +
                        " " +
                        data?.employee?.employeeName?.lastName
                    );
                });
        } else if (searchingCat === "S") {
            returnValue = senderList
                ?.filter((data) => data?.senderId === val)
                ?.map((data) => {
                    return data?.label;
                });
        } else {
            returnValue = val
        }
        return returnValue;
    }

    const [conditionForm, setConditionForm] = useState({})
    const [selectedIndex, setSelectedIndex] = useState()

    useEffect(() => {
        console.log(conditionData)
        if (conditionData?.element) {
            if (oprationMode === 'edit') {
                conditions[selectedIndex - 1] = conditionData
                setConditionData([...conditions])
                // setOprationMode('add')
            } else {
                setConditions([...conditions, conditionData])
            }
            setConditionData({})
        }
        // console.log('conditions', conditions)
    }, [conditionData])

    useEffect(() => {
        // console.log(actionData)
        if (actionData?.element) {
            setActions([...actions, actionData])
            setActionData({})

        }
        // console.log('actions', actions)
    }, [actionData])

    const saveRule = () => {

        if (!validateData()) {
            return setError('Please check all fields')
        }

        ruleID ?
            putService(url + '/' + ruleID, { name: rule, description, conditions, actions }, true)
                .then(
                    (res) => {
                        // console.log('res', res)
                        if (res.request.status === 200) {
                            getList()
                            document.getElementById('closeRuleModal').click()
                        } else {
                            return setError(res.request.statusText)
                        }
                    }, err => { return setError(err.request.statusText) }
                )
            :
            postService(url, { name: rule, description, conditions, actions }, true)
                .then(
                    (res) => {
                        // console.log('res', res)
                        if (res.request.status === 200) {
                            getList()
                            document.getElementById('closeRuleModal').click()
                        }
                        else {
                            return setError(res.request.statusText)
                        }
                    }, err => { return setError(err.request.statusText) });

        setError('')
        // console.log({
        //     rule, description, conditions, actions
        // })
    }

    const deleteConditions = (i) => {
        conditions.splice(i, 1)
        setConditions([...conditions])
    };

    const deleteActions = (e) => {
        actions.splice(e, 1)
        setActions([...actions])
    };

    const resetForm = () => {
        setRule('')
        setDescription('')
        setConditions([])
        setActions([])
        setRuleID('')
    };

    const validateData = () => {
        if (!rule || !description || !conditions.length || !actions.length) {
            return false
        } else {
            return true
        }
    }

    return (
        <>
            <div className="modal fade add_rule" id="add_rule" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body p-4">
                            <h2 className="heading_1 mb-3">{ruleID ? 'Edit' : 'Add'} rule</h2>
                            {error && <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                                <span className="me-3"><img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" /></span>{error}
                            </div>}
                            <div className="row">
                                <div className="fm_grp">
                                    <label htmlFor="rulename" className="form-label">Rule name</label>
                                    <input type="text" className="form-control" id="rulename" onInput={(e) => setRule(e.target.value)} value={rule} />
                                </div>
                                <div className="fm_grp ">
                                    <label htmlFor="descr" className="form-label">Description</label>
                                    <input type="text" className="form-control" id="descr" onInput={(e) => setDescription(e.target.value)} value={description} />
                                </div>

                                <div className="mt-2">
                                    <h3 className="fnt_14 text_primary fnt_w_bold">Conditions</h3>
                                    <div className="add_rule_table">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Elements</th>
                                                    <th>Comparison</th>
                                                    <th>Value</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {conditions?.length>0 && conditions?.map((data, i) => (
                                                    <tr key={i}>
                                                        <td>{getConditionElementValue(data?.element)}</td>
                                                        <td>{getComparisonValue(data?.comparison)}</td>
                                                        <td>{getTextValue(data?.text, data?.element)}</td>
                                                        <td className="acction_icon">
                                                            <a data-bs-toggle="modal" onClick={() => { setConditionForm(data); setSelectedIndex(i + 1); setOprationMode('edit') }} data-bs-target="#add_condition">
                                                                <img src="assets/img/Edit.svg" alt="" />
                                                            </a>
                                                            <a onClick={() => deleteConditions(i)}>
                                                                <img src="assets/img/Delete.svg" alt="" />
                                                                {/* <img src="assets/img/Delete.svg" alt=""  /> */}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
												{ ( !conditions || conditions?.length === 0 ) &&
													<tr><td></td></tr>
												 }
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <!-- Add an Account --> */}
                                    <div className="add_account">
                                        <a data-bs-dismiss="modal" onClick={() => { setConditionForm(); setOprationMode('add') }} data-bs-toggle="modal" data-bs-target="#add_condition"> <img src="assets/img/add_account.svg" alt="" /><span>Add condition</span></a>
                                    </div>
                                    {/* <!-- Add an Account --> */}
                                </div>

                                <div className="mt-4">
                                    <h3 className="fnt_14 text_primary fnt_w_bold">Actions</h3>
                                    <div className="add_rule_table">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Elements</th>
                                                    <th>Value</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {actions?.length>0 && actions?.map((data, i) => (
                                                    <tr key={i}>
                                                        <td>{getRuleElementValue(data?.element)}</td>
                                                        <td>{getTextValue(data?.text, data?.element)}</td>
                                                        <td className="acction_icon">
                                                            <a onClick={() => deleteActions(i)}>
                                                                <img src="assets/img/Delete.svg" alt="" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
												{ ( !actions || actions?.length === 0 ) &&
													<tr><td></td></tr>
												 }
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <!-- Add an Account --> */}
                                    <div className="add_account">
                                        <a data-bs-dismiss="modal" onClick={() => { setActionData(); setOprationMode('add') }} data-bs-toggle="modal" data-bs-target="#add_action"> <img src="assets/img/add_account.svg" alt="" /><span>Add action</span></a>
                                    </div>
                                    {/* <!-- Add an Account --> */}
                                </div>
                                <div className="d-flex flex-wrap align-items-center justify-content-end mt-3">
                                    <div className="text-end">
                                        <button type="button" className="btn btn-link btn_link_primary" onClick={() => resetForm()} data-bs-dismiss="modal" id='closeRuleModal'>Cancel</button>
                                        <button type="button" className="btn btn_cstm_primary px-3" onClick={() => saveRule()}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddCondition operationType={oprationMode} url={url} conditionInput={conditionData} conditionForm={conditionForm} setConditionInput={setConditionData} setConditionForm={setConditionForm} />
            <AddAction operationType={oprationMode} url={url} actionInput={actionData} setActionInput={setActionData} />
        </>

    )
}

export default AddRule