/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { RouteState } from '../../../../context/routeContext'
import { stringPipe } from '../../../../pipes/stringPipes'
import { getService } from '../../../../services/commonApi'
import RescanRequestDetails from './modals/RescanRequestDetails'
import Deny from './modals/Deny'
import { CommonState } from '../../../../context/commonContext'

const RescanRequest = () => 
{
	const { deliveryRoute, setDeliveryRoute } = RouteState()
	const [data, setData] = useState('')
	const [rescanReqList, setRescanReqList] = useState([])
	const [sortby, setSortby] = useState()
	const [status, setStatus] = useState("pending")
	const [type, setType] = useState('')
	const { refreshMailStats, setRefreshMailStats } = CommonState()

	var url = useRef('/mercury/mail/rescanrequests')

	useEffect(() => 
	{
		if (deliveryRoute === 'rescan') 
		{
			getRescanReqList()
		}
	}, [deliveryRoute])

	useEffect(() => 
	{
		getRescanReqList()
	}, [status, sortby])


	const getRescanReqList = () => 
	{
		getService(url.current, true, { params: { status, sortby } }).then( (res) => 
		{
			setRescanReqList(res?.data?.requests)
		})
	}

	const refreshFunc = () => 
	{
		setRefreshMailStats(!refreshMailStats)
	}


    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-C" onClick={() => { setDeliveryRoute('rescan') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-C" aria-expanded="true" aria-controls="collapse-C">
                        Rescan Request  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-C" className={`bgYellow collapse ${deliveryRoute === "rescan" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-C">
                <div className="head d-none d-lg-block">
                    <h4 className="heading_1 text_dark mb-0">Requests for Rescan</h4>
                </div>
                <div className="p-3">
                    <p>List employee requests to rescan mail</p>
                </div>
                <div className="p-3 pt-0">
                    {/* <!-- Start search--> */}
                    <div className="row mb-3">
                        <div className="col-md-6 col-xl-2">
                            <label htmlFor="" className="sm_label">Status</label>
                            <select className="form-select form-select-sm w_lg_7" onChange={(e) => setStatus(e.target.value)}>
                                <option value="pending">Pending</option>
                                <option value="inprogress">In Progress</option>
                                <option value="fulfilled">Fulfilled</option>
                                <option value={''}>All</option>
                            </select>
                        </div>
                    </div>
                    {/* <!-- Start Table --> */}
                    <div className="table-responsive cst_border cst_table requests_rescan">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className='text-center'>Date requested</th>
                                    <th className='text-center'>Requester name</th>
                                    <th className='text-center'>Available?</th>
                                    <th className='text-center'>Request Code</th>
                                    <th className='text-center'>Date Started</th>
                                    <th className='text-center'>Date Fulfilled</th>
                                    <th className='text-center'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {rescanReqList?.map((data, i) => (
                                    <tr key={i}>
                                        <td className='text-center'>{new Date(Date.parse(data?.requestDate)).toLocaleDateString()}</td>
                                        <td className='text-center'>{data?.employeeName}</td>
                                        <td style={{ 'fontSize': '1rem' }} className='text-center fw-bold'>
											<i className={`bi ${data?.available === true ? 'bi-check-lg text-success' : 'bi-x-lg text-danger'}`}></i>
										</td>
                                        <td className='text-center'>{stringPipe(data?.rescanCode, 20)}</td>
                                        <td className='text-center'>{data?.startDate ? new Date(Date.parse(data?.startDate)).toLocaleDateString() : ''}</td>
                                        <td className='text-center'>{data?.fulfilledDate ? new Date(Date.parse(data?.fulfilledDate)).toLocaleDateString() : ''}</td>
                                        <td className='text-center'>
                                            <a onClick={() => { setType( !data?.startDate ? 'pending' : '' ); setData(data?.requestId) }} data-bs-toggle="modal" data-bs-target="#rescanrequest_details"> <img src="assets/img/Print.png" alt="" /></a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <!-- End Table --> */}
                </div>

            </div>
            <RescanRequestDetails refresh={refreshFunc} getRescanReqList={getRescanReqList} url={url.current} data={data} type={type} />
            {/* <Deny /> */}
            <Deny refresh={refreshFunc} getRescanReqList={getRescanReqList} url={url.current} data={data} />
        </>
    )
}

export default RescanRequest