// import { getService } from "../../../../../services/commonApi";
// import { getCompanyIdToken } from "../../../../../services/tokenService";

var conditionList = [
  { code: "c", text: "Assignment confidence" },
  { code: "z", text: "Company name confidence" },
  { code: "d", text: "Department name confidence" },
  { code: "e", text: "Employee name confidence" },
  { code: "m", text: "Mail Stop confidence" },
  { code: "p", text: "Position Name confidence" },
  { code: "s", text: "Sender Name confidence" },
  { code: "B", text: "Assignment Decision" },
  { code: "X", text: "Recipient Text" },
  { code: "Y", text: "Sender Text" },
  { code: "V", text: "Envelope Text" },
  { code: "T", text: "Letter Text" },
  { code: "A", text: " Company Address" },
  { code: "Z", text: " Company Name" },
  { code: "M", text: " Mail Stop" },
  { code: "D", text: "Department Name" },
  { code: "P", text: "Position Name" },
  { code: "E", text: " Employee Name" },
  { code: "S", text: " Sender" },
];

var actionList = [
  { code: "D", text: "Set/Override Department Choice" },
  { code: "P", text: "Set/Override Position Choice" },
  { code: "E", text: "Set/Override Employee Choice" },
  { code: "S", text: "Set/Override Sender Choice" },
  { code: "I", text: "No Further Rule Actions" },
];

var comparisonList = [
  { code: "=", text: "Equals" },
  { code: "<", text: "Less Than" },
  { code: "L", text: "Less Than Or Equal" },
  { code: ">", text: "Greater Than" },
  { code: "G", text: "Greater Than Or Equal" },
  { code: "C", text: " Contains" },
  { code: "X", text: "Does Not Contain" },
  { code: "N", text: "No Value" },
  { code: "n", text: "Has A Value" },
  { code: "A", text: "Matches Recipients" },
  { code: "a", text: "Doesn't Match Recipients" },
];

// const getListData = async (url) => {
//   const data = await getService(url, true);
//   console.log(data.data);
//   return data.data;
// };

// var addressList = getListData(
//   `/mercury/company/${getCompanyIdToken()}/address/list`
// ).then((res) => {
//   return res;
// });
// // console.log(addressList);
// var departmentList = getListData(
//   `/mercury/company/${getCompanyIdToken()}/department/list`
// );
// var mailStopList = getListData(
//   `/mercury/company/${getCompanyIdToken()}/mailstop/list`
// );
// var namesList = getListData(`/mercury/company/${getCompanyIdToken()}/names`);
// var positionList = getListData(
//   `/mercury/company/${getCompanyIdToken()}/position/list`
// );
// var employeeList = getListData(`/mercury/employee/list`);
// var senderist = getListData(`/mercury/sender/list`);

export function getConditionElementValue(val) {
  return conditionList
    .filter((el) => el.code === val)
    .map((el) => {
      return el.text;
    });
}

export function getRuleElementValue(val) {
  return actionList
    .filter((el) => el.code === val)
    .map((el) => {
      return el.text;
    });
}

export function getComparisonValue(val) {
  return comparisonList
    .filter((el) => el.code === val)
    .map((el) => {
      return el.text;
    });
}

// export const getTextValue = (val, searchingCat) => {
//   var returnValue;
//   var dataVal;

//   if (searchingCat === "B") {
//     var testArr = [
//       { code: "N", text: "Insufficient match information(nomatch)" },
//       { code: "D", text: "Ambiguous choices available(duplicate)" },
//       { code: "Z", text: "No company addresses found" },
//       { code: "I", text: "Incorrect Assignment.Sent back for reassignment" },
//       { code: "E", text: "Match found but poor choice." },
//     ];
//     returnValue = testArr
//       .filter((el) => el?.code === val)
//       .map((el) => {
//         return el?.text;
//       });
//   } else if (searchingCat === "A") {
//     addressList.then(
//       (res) => (returnValue = addressValue(res.list, "message1"))
//     );
// addressList.then((res) => {
//   res.list
//     ?.filter((data) => data.addressId === val)
//     ?.map((data) => {
//       return (
//         data?.address1 +
//         " " +
//         data?.city +
//         "," +
//         data?.state +
//         " " +
//         data?.zipcode
//       );
//     });
//   return returnValue;
//   //   return returnValue;
// });
// returnValue = dataVal[0];
// } else if (searchingCat === "M") {
// } else if (searchingCat === "D") {
// } else if (searchingCat === "P") {
// } else if (searchingCat === "Z") {
// } else if (searchingCat === "E") {
// } else if (searchingCat === "S") {
// } else if (searchingCat === "I") {
// } else {
//   returnValue = val;
// }
// console.log(returnValue);
//   switch (searchingCat) {
//     case "B":
//       var testArr = [
//         { code: "N", text: "Insufficient match information(nomatch)" },
//         { code: "D", text: "Ambiguous choices available(duplicate)" },
//         { code: "Z", text: "No company addresses found" },
//         { code: "I", text: "Incorrect Assignment.Sent back for reassignment" },
//         { code: "E", text: "Match found but poor choice." },
//       ];
//       returnValue = testArr
//         .filter((el) => el?.code === val)
//         .map((el) => {
//           return el?.text;
//         });
//       break;
//     // case "A":
//     //   url = `/mercury/company/${getCompanyIdToken()}/address/list`;
//     //   var addresses = getListData(url);

//     //   console.log("fgghfgfjgfjf", addresses);

//     //   returnValue = addresses?.list
//     //     ?.filter((data) => data.addressId === val)
//     //     ?.map((data) => {
//     //       return (
//     //         data?.address1 +
//     //         " " +
//     //         data?.city +
//     //         "," +
//     //         data?.state +
//     //         " " +
//     //         data?.zipcode
//     //       );
//     //     });
//     //   console.log(returnValue);
//     //   break;
//     // case "M":
//     //   url = `/mercury/company/${getCompanyIdToken()}/mailstop/list`;
//     //   var mailstops = await getListData(url);

//     //   returnValue = mailstops?.list
//     //     ?.filter((data) => data.mailStopId === val)
//     //     ?.map((data) => {
//     //       return data?.name;
//     //     });

//     //   break;
//     // case "D":
//     //   url = `/mercury/company/${getCompanyIdToken()}/department/list`;
//     //   var departments = await getListData(url);

//     //   returnValue = departments?.list
//     //     ?.filter((data) => data.departmentId === val)
//     //     ?.map((data) => {
//     //       return data?.label;
//     //     });

//     //   break;
//     // case "P":
//     //   url = `/mercury/company/${getCompanyIdToken()}/position/list`;
//     //   var positions = await getListData(url);

//     //   returnValue = positions?.list
//     //     ?.filter((data) => data.positionId === val)
//     //     ?.map((data) => {
//     //       return data?.label;
//     //     });

//     //   break;
//     // case "Z":
//     //   url = `/mercury/company/${getCompanyIdToken()}/names`;
//     //   var names = await getListData(url);

//     //   returnValue = names?.companyNames
//     //     ?.filter((data) => data.nameId === val)
//     //     ?.map((data) => {
//     //       return data?.name;
//     //     });

//     //   break;
//     // case "E":
//     //   url = `/mercury/employee/list`;
//     //   var employees = await getListData(url);

//     //   returnValue = employees?.employees
//     //     ?.filter((data) => data?.employee?.employeeId === val)
//     //     ?.map((data) => {
//     //       return (
//     //         data?.employee?.employeeName?.firstName +
//     //         " " +
//     //         data?.employee?.employeeName?.lastName
//     //       );
//     //     });

//     //   break;
//     // case "S":
//     //   url = `/mercury/sender/list`;
//     //   var senders = await getListData(url);
//     //   console.log(senders.senders);

//     //   returnValue = senders?.senders
//     //     ?.filter((data) => data?.senderId === val)
//     //     ?.map((data) => {
//     //       return data?.label;
//     //     });

//     //   break;

//     default:
//       returnValue = val;
//       break;
//   }
//   return returnValue;
// };

// function addressValue(arr, id) {
//   console.log(id, arr);
//   const val = arr
//     ?.filter((data) => data.addressId === id)
//     ?.map((data) => {
//       return (
//         data?.address1 +
//         " " +
//         data?.city +
//         "," +
//         data?.state +
//         " " +
//         data?.zipcode
//       );
//     });
//   return val;
// }
