/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { getService, postService, putService } from '../../../../../../services/commonApi'

const AddressModal = ({ addressID, setAddressID, setData, url, getList }) => {



    var stateJSON = {
        'US': [
            {
                "name": "Alabama",
                "code": "AL"
            },
            {
                "name": "Alaska",
                "code": "AK"
            },
            {
                "name": "Arizona",
                "code": "AZ"
            },
            {
                "name": "Arkansas",
                "code": "AR"
            },
            {
                "name": "California",
                "code": "CA"
            },
            {
                "name": "Colorado",
                "code": "CO"
            },
            {
                "name": "Connecticut",
                "code": "CT"
            },
            {
                "name": "Delaware",
                "code": "DE"
            },
            {
                "name": "Florida",
                "code": "FL"
            },
            {
                "name": "Georgia",
                "code": "GA"
            },
            {
                "name": "Hawaii",
                "code": "HI"
            },
            {
                "name": "Idaho",
                "code": "ID"
            },
            {
                "name": "Illinois",
                "code": "IL"
            },
            {
                "name": "Indiana",
                "code": "IN"
            },
            {
                "name": "Iowa",
                "code": "IA"
            },
            {
                "name": "Kansas",
                "code": "KS"
            },
            {
                "name": "Kentucky",
                "code": "KY"
            },
            {
                "name": "Louisiana",
                "code": "LA"
            },
            {
                "name": "Maine",
                "code": "ME"
            },
            {
                "name": "Maryland",
                "code": "MD"
            },
            {
                "name": "Massachusetts",
                "code": "MA"
            },
            {
                "name": "Michigan",
                "code": "MI"
            },
            {
                "name": "Minnesota",
                "code": "MN"
            },
            {
                "name": "Mississippi",
                "code": "MS"
            },
            {
                "name": "Missouri",
                "code": "MO"
            },
            {
                "name": "Montana",
                "code": "MT"
            },
            {
                "name": "Nebraska",
                "code": "NE"
            },
            {
                "name": "Nevada",
                "code": "NV"
            },
            {
                "name": "New Hampshire",
                "code": "NH"
            },
            {
                "name": "New Jersey",
                "code": "NJ"
            },
            {
                "name": "New Mexico",
                "code": "NM"
            },
            {
                "name": "New York",
                "code": "NY"
            },
            {
                "name": "North Carolina",
                "code": "NC"
            },
            {
                "name": "North Dakota",
                "code": "ND"
            },
            {
                "name": "Ohio",
                "code": "OH"
            },
            {
                "name": "Oklahoma",
                "code": "OK"
            },
            {
                "name": "Oregon",
                "code": "OR"
            },
            {
                "name": "Pennsylvania",
                "code": "PA"
            },
            {
                "name": "Rhode Island",
                "code": "RI"
            },
            {
                "name": "South Carolina",
                "code": "SC"
            },
            {
                "name": "South Dakota",
                "code": "SD"
            },
            {
                "name": "Tennessee",
                "code": "TN"
            },
            {
                "name": "Texas",
                "code": "TX"
            },
            {
                "name": "Utah",
                "code": "UT"
            },
            {
                "name": "Vermont",
                "code": "VT"
            },
            {
                "name": "Virginia",
                "code": "VA"
            },
            {
                "name": "Washington",
                "code": "WA"
            },
            {
                "name": "West Virginia",
                "code": "WV"
            },
            {
                "name": "Wisconsin",
                "code": "WI"
            },
            {
                "name": "Wyoming",
                "code": "WY"
            }
        ],
        'CA': [
            {
                "name": "Alberta",
                "code": "AB"
            },
            {
                "name": "British Columbia",
                "code": "BC"
            },
            {
                "name": "Manitoba",
                "code": "MB"
            },
            {
                "name": "New Brunswick",
                "code": "NB"
            },
            {
                "name": "Newfoundland and Labrador",
                "code": "NL"
            },
            {
                "name": "Northwest Territories",
                "code": "NT"
            },
            {
                "name": "Nova Scotia",
                "code": "NS"
            },
            {
                "name": "Nunavut",
                "code": "NU"
            },
            {
                "name": "Ontario",
                "code": "ON"
            },
            {
                "name": "Prince Edward Island",
                "code": "PE"
            },
            {
                "name": "Quebec",
                "code": "QC"
            },
            {
                "name": "Saskatchewan",
                "code": "SK"
            },
            {
                "name": "Yukon",
                "code": "YT"
            }
        ]
    }
    var countryList = [{ name: 'United States', code: 'US' }, { name: 'Canada', code: 'CA' }]


    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('AL')
    const [zipcode, setZip] = useState('')
    const [country, setCountry] = useState('US')

    const [error, setError] = useState(false)
    const [submit, setSubmit] = useState(false)

    // console.log(stateList)

    const resetForm = () => {
        setAddress1('')
        setAddress2('')
        setCountry('US')
        setCity('')
        setState('AL')
        setZip('')
        setError('')
        setSubmit(false)
        setData('')
    }

    useEffect(() => {
        if (addressID) {
            getService(url + addressID, true, "").then((res) => {
                if (res?.status === 200) {
                    var addressData = res?.data?.companyAddress
                    setAddress1(addressData.address1)
                    setAddress2(addressData.address2 ? addressData.address2 : '')
                    setCity(addressData.city)
                    setState(addressData.state)
                    setZip(addressData.zipcode)
                    setCountry(addressData.country ?? 'US')
                }
            })
        }
    }, [addressID])

    const [stateList, setStateList] = useState([])

    useEffect(() => {
        setStateList(stateJSON?.[country])
        console.log(stateList, country, stateJSON?.[country])
    }, [country])


    const addAddress = () => {
        setSubmit(true)

        if (!address1 || !city || !state || !zipcode || !country) {
            if (address1 && city && state && zipcode && country) {
                return setError("Address is required field");
            } else if (!city && address1 && state && zipcode && country) {
                return setError("City is required field");
            } else if (!state && address1 && city && zipcode && country) {
                return setError("State is required field");
            } else if (!zipcode && address1 && state && city && country) {
                return setError("Zip Code is required field");
            } else if (!country && address1 && state && zipcode && city) {
                return setError("Country is required field");
            }
            return setError("Required fields are missing !");
        }

        addressID ?
            putService(url + addressID, { addressID, address1, address2, city, state, zipcode, country }, true)
                .then(
                    (res) => {
                        // console.log('res', res)
                        if (res.request.status === 200) {
                            getList()
                            document.getElementById('closeAddressModal').click()
                        } else {
                            return setError(res.request.statusText)
                        }
                    }, err => { return setError(err.request.statusText) }
                )
            :
            postService(url, { address1, address2, city, state, zipcode, country }, true)
                .then(
                    (res) => {
                        // console.log('res', res)
                        if (res.request.status === 200) {
                            getList()
                            document.getElementById('closeAddressModal').click()
                        }
                        else {
                            return setError(res.response?.data?.error?.desc)
                        }
                    }, err => {
                        return setError(err?.response?.data?.error?.desc)
                    });

        setError('')


    }


    return (
        <div className="modal fade add_address" id="Save_Address" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
                <div className="modal-content">
                    <div className="modal-body p-0">
                        <h2 className="heading_1 mb-3 px-4 pt-4">{!addressID ? 'Add' : 'Edit'} Address</h2>
                        {error && <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                            <span className="me-3"><img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" /></span>{error}
                        </div>}
                        <div className='px-4 pb-4'>

                            <div className="row">
                                <div className="fm_grp col-md-12">
                                    <label htmlFor="addressLine" className={`form-label ${submit && !address1 ? 'error_text' : ''}`}>* Address line 1</label>
                                    <input type="text" id="addressLine" onInput={(e) => setAddress1(e.target.value)} value={address1} className={`form-control ${submit && !address1 ? 'error_input' : ''}`} />
                                </div>
                                <div className="fm_grp col-md-12">
                                    <label htmlFor="address2" >Address line 2</label>
                                    <input type="text" id="address2" className='form-control' onInput={(e) => setAddress2(e.target.value)} value={address2} />
                                </div>
                                <div className="fm_grp col-md-12">
                                    <label htmlFor="city" className={`form-label ${submit && !city ? 'error_text' : ''}`}>*City</label>
                                    <input type="text" id="city"
                                        onInput={(e) => setCity(e.target.value)} value={city} className={`form-control ${submit && !city ? 'error_input' : ''}`} />
                                </div>
                                <div className="fm_grp col-md-8">
                                    <label htmlFor="St" className={`form-label ${submit && !state ? 'error_text' : ''}`}>*ST</label>
                                    <select id="St"
                                        onInput={(e) => setState(e.target.value)} value={state} className={`form-control ${submit && !state ? 'error_input' : ''}`}>
                                        {stateList?.map((data, i) => (<option key={i} value={data?.code}>{data?.name}</option>))}
                                    </select>
                                </div>
                                <div className="fm_grp col-md-4">
                                    <label htmlFor="zip" className={`form-label ${submit && !zipcode ? 'error_text' : ''}`}>*Zip</label>
                                    <input type="text" id="zip"
                                        onInput={(e) => setZip(e.target.value)} value={zipcode} className={`form-control ${submit && !zipcode ? 'error_input' : ''}`} />
                                </div>
                                <div className="fm_grp col-md-8">
                                    <label htmlFor="country" className={`form-label ${submit && !country ? 'error_text' : ''}`}>*Country</label>
                                    <select id="country"
                                        onInput={(e) => setCountry(e.target.value)} value={country} className={`form-control ${submit && !country ? 'error_input' : ''}`}>
                                        {countryList?.map((data, i) => (<option value={data?.code} key={i} >{data?.name}</option>))}
                                    </select>
                                </div>
                                <div className="d-flex flex-wrap align-items-center justify-content-between mt-3">
                                    <div> </div>
                                    <div className=" text-end">
                                        <button type="button" id="closeAddressModal" onClick={() => resetForm()} className="btn btn-link btn_link_primary me-3" data-bs-dismiss="modal">Cancel</button>
                                        {!addressID && <button type="button" className="btn btn_cstm_primary px-4" onClick={() => addAddress()}>Add Address</button>}
                                        {addressID && <button type="button" className="btn btn_cstm_primary px-4" onClick={() => addAddress()}>Save Edits</button>}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddressModal
