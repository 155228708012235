/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Settings_Get_API, Settings_Put_API } from '../../../../services/SettingsAPI';
import { getconfiguration } from '../../../../services/AuthApi';
import { setCompanyIdToken } from '../../../../services/tokenService';
import Select from 'react-select';
import Combobox from "react-widgets/Combobox";


const NameInfo = ({ navLink,setNavLink,error,setError, nameInput, setNameInput, allErrorCheck }) => 
{
	const [firstName, setfirstName] = useState(nameInput?.firstName?nameInput.firstName:'');
	const [title, setTitle] = useState(nameInput?.title?nameInput?.title:'');
	const [middleName, setmiddleName] = useState(nameInput?.middleName?nameInput?.middleName:'');
	const [lastName, setlastName] = useState(nameInput?.lastName?nameInput?.lastName:'');
	const [maiden_name, setMaiden_name] = useState(nameInput?.maiden_name?nameInput?.maiden_name:'');
	const [suffix, setSuffix] = useState(nameInput?.suffix?nameInput?.suffix:'');
	const [nickNames, setAlternate] = useState(nameInput?.nickNames?.join('\n'));

	const [titlesList, setTitlesList] = useState([])
	const [suffixList, setSuffixList] = useState([])

	const getConfigurationData = () => {
		getconfiguration().then((result) => {
			setCompanyIdToken(result?.companyId)
			setTitlesList(result?.displaySettings?.titles)
			setSuffixList(result?.displaySettings?.suffixes)
		})
	}

	useEffect(() => {
		getConfigurationData()
	}, [])

	useEffect(() => {
		if (!nameInput) {
			Settings_Get_API('/mercury/employee/name').then(async (res) => {
				setfirstName(res.data?.employeeName?.firstName?res.data?.employeeName?.firstName:'')
				setTitle(res.data?.employeeName?.title);
				setmiddleName(res.data?.employeeName?.middleName?res.data?.employeeName?.middleName:'');
				setlastName(res.data?.employeeName?.lastName?res.data?.employeeName?.lastName:'');
				setMaiden_name(res.data?.employeeName?.maidenName?res.data?.employeeName?.maidenName:'');
				setSuffix(res.data?.employeeName?.suffix);
				setAlternate(res.data?.employeeName?.nickNames?.join('\n'));
			})
		}
	}, [])

	const [submit, setSubmit] = useState(false)
	const [changed, setChanged] = useState(false)

	const setNameInputData = () => {
		setSubmit(true)

		if (!firstName || !lastName) {
			if (!firstName && lastName) {
				return setError("First name is required");
			} else if (!lastName && firstName) {
				return setError("Last name is required");
			}
			return setError("Required fields are missing !");
		}
		Settings_Put_API('/mercury/employee/name', {firstName,title,middleName,lastName,maiden_name,suffix,'nickNames': nickNames?.split('\n'),}).catch( (res) => 
		{
			setError('We recieved an error! Please try again later')
		})
		setError("");
	};


	useEffect(() => {
		setNameInput({
			firstName,
			title,
			middleName,
			lastName,
			maiden_name,
			suffix,
			'nickNames': nickNames?.split('\n'),
		});

		const set = setTimeout(() => {
			if (changed) {
				setNameInputData()
			}
		}, 1500);
		return () => clearTimeout(set)

	}, [firstName, middleName, maiden_name, lastName, nickNames, title, suffix])


	// useEffect(() => {
	//     console.log(nickNames.split('\n'))
	// }, [nickNames])


	return (
		<div className={`tab-pane fade ${navLink === "name" ? "show active" : ""}`}
			aria-labelledby="v-pills-home-tab"
		>
			<div className="col-lg-9 offset-lg-1 modalContentItems">
				<h2 className="heading_1">Names settings</h2>
				<p>Please ensure the following name information is correct and as complete as possible.</p>
				<form className=" pt-4">
					<div className="row g-3">

						<div className="fm_grp col-md-6">
							<label htmlFor="Firstname" className={`form-label ${error && !firstName ? 'error_text' : ''}`}>
								* First name {error && !firstName && <span>is required</span>}
							</label>
							<input
								type="text"
								className={`form-control ${error && !firstName ? 'error_input' : ''}`}
								id="Firstname"
								onInput={(e) => { setChanged(true); setfirstName(e.target.value) }}
								value={firstName}
							/>
						</div>

						<div className="fm_grp col-md-6">
							<label htmlFor="inputTitle" className="form-label">
								Title
							</label>

							<Combobox
								// value={title}
								value={title}
								className="form-control"
								id="inputTitle"
								onChange={(e) => { setChanged(true); setTitle(e) }}
								data={titlesList}
							/>

							{/* {addNewTitle &&
								<div className='d-flex align-items-center'>
									<input type="text" className='form-control' onInput={(e) => setTitle(e.target.value)} value={title} />
									<span  className="btn-close" onClick={() => { setAddNewTitle(false) }}></span>
								</div>
							} */}
							{/* <Select
								className="basic-select"
								classNamePrefix="select"
								// defaultValue={colourOptions[0]}
								// isDisabled={isDisabled}
								onInput={(e) => setTitle(e.target.value)}
								value={title}
								// onChange={(e) => setTitle(e.target.value)}
								isClearable="false"
								isSearchable="true"
								name="label"
								options={titlesList.map((data) => ({ 'value': data, 'label': data }))}
							/> */}

							{/* {!addNewTitle && <select
								id="inputTitle"
								className="form-select"
								onInput={(e) => {
									if (e.target.value === 'addNew') { return setAddNewTitle(true) }
									else {
										setTitle(e.target.value)
									}
								}}
								value={title}
							>
								{titlesList?.map((data, i) => {
									return (<option key={i} value={data}>{data}</option>)
								})
								}
								<option value='addNew'>Add New</option>
							</select>} */}
						</div>

						<div className="fm_grp col-md-6">
							<div className="mb-3">
								<label htmlFor="Middlename" className="form-label">
									Middle name
								</label>
								<input
									type="text"
									className="form-control"
									id="Middlename"
									onInput={(e) => { setChanged(true); setmiddleName(e.target.value) }}
									value={middleName}
								/>
							</div>

							<div>
								<label htmlFor="inputLastName" className={`form-label ${error && !lastName ? 'error_text' : ''}`}>
									* Last name {error && !lastName && <span>is required</span>}
								</label>
								<input
									type="text"
									className={`form-control ${error && !lastName ? 'error_input' : ''}`}
									id="inputLastName"
									onInput={(e) => { setChanged(true); setlastName(e.target.value) }}
									value={lastName}
								/>
							</div>
						</div>

						<div className="fm_grp col-md-6">
							<label htmlFor="inputAltFirstName" className="form-label">
								Alternate first names (1 per line)
							</label>
							<textarea
								className="form-control"
								id="inputAltFirstName"
								rows="5"
								onInput={(e) => { setChanged(true); setAlternate(e.target.value) }}
								value={nickNames}
							></textarea>
						</div>

						<div className="fm_grp col-md-6">
							<label htmlFor="inputMaiden" className="form-label">
								Previous Last Name
							</label>
							<input
								type="text"
								className="form-control"
								id="inputMaiden"
								onInput={(e) => { setChanged(true); setMaiden_name(e.target.value) }}
								value={maiden_name}
							/>
						</div>

						<div className="fm_grp col-md-6">
							<label htmlFor="inputSuffix" className="form-label">
								Suffix
							</label>
							<Combobox
								value={suffix}
								id="inputTitle"
								className="form-control"
								onChange={(e) => { setChanged(true); setSuffix(e) }}
								data={suffixList}
							/>
							{/* {addNewSuffix &&
								<div className='d-flex align-items-center'>
									<input type="text" className='form-control' onInput={(e) => setSuffix(e.target.value)} value={suffix} />
									<span  className="btn-close" onClick={() => { setAddNewSuffix(false) }}></span>
								</div>
							} */}

							{/* {!addNewSuffix && <select
								id="inputSuffix"
								className="form-select"
								onInput={(e) => {
									if (e.target.value === 'addNew') { return setAddNewSuffix(true) }
									else {
										setSuffix(e.target.value)
									}
								}}
								value={suffix}
							>
								{suffixList?.map((data, i) => {
									return (
										<option value={data} key={i} >{data}</option>
									)
								})}
								<option value='addNew'>Add New</option>
							</select>} */}
						</div>
					</div>
				</form>
			</div>
			<div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 positionBottom">
				<div className="">* Required field</div>
				<div className="p-md-4 text-end">
					{/* <button
						type="button"
						id="closeNameModal"
						className="btn btn-link  btn_link_primary"
						data-bs-dismiss="modal" style={{
							visibility: 'hidden'
						}}
					>
						Cancel
					</button> */}
					<button
						type="button"
						className="btn btn_cstm_primary"
						data-bs-dismiss="modal"
						disabled={allErrorCheck}
					>
						Done
					</button>
				</div>
			</div>
		</div>
	)
}

export default NameInfo