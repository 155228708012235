/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Settings_Get_API, Settings_Put_API } from '../../../../services/SettingsAPI'
// npm install validator@13.7.0
import validator from 'validator'
//import { toast } from "react-toastify";


const ChangePassword = ({ navLink, setNavLink, error, setError, changePasswordInput, setChangePasswordInput, allErrorCheck }) => 
{ 
	const user = JSON.parse(localStorage.getItem('user'))
    const [username, setUsername] = useState(user?.userName)
    const [settingPassword, setSettingPassword] = useState()
    const [recoveryPhrase, setRecoveryPhrase] = useState(changePasswordInput?.recoveryPhrase)
    const [recoveryAnswer, setRecoveryAnswer] = useState(changePasswordInput?.recoveryAnswer)
	const [passwordError, setPasswordError] = useState(false)
	const [confirmError, setConfirmError] = useState(false)
	const [phraseError, setPhraseError] = useState(false)
	const [responseError, setResponseError] = useState(false)

	useEffect(() => 
	{
		if (!changePasswordInput) 
		{
			Settings_Get_API('/mercury/employee/access/security').then((res) => 
			{
				setRecoveryPhrase(res.data.employeeAccount.recoveryPhrase)
				setRecoveryAnswer(res.data.employeeAccount.recoveryAnswer)
			})
		}
	}, []);

	const validatePassword = ( value ) => 
	{
		setSettingPassword(value);
		if( value.length > 0 && !validator.isStrongPassword( value, { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1 })) 
		{
			setPasswordError(true);
		}
		else  
		{
			setPasswordError(false);
		}
		updatePasswordErrorMessage();
	}

	const validateConfirmPassword = ( value ) => 
	{
		setConfirmPass(value);
		if(( settingPassword?.length > 0 || value?.length > 0 ) && settingPassword !== value ) 
		{
			setConfirmError(true);
		}
		else if( confirmError && !passwordError )
		{
			setConfirmError(false);
		}
		updatePasswordErrorMessage();
	}

	const updatePasswordErrorMessage = () =>
	{
		if( passwordError ) 
		{
			setError('Your password must be at least 8 characters long, contain at least one number, one symbol and have a mixture of uppercase and lowercase letters.');
		}
		else if( confirmError )
		{
			setError('The Confirm password does not match Password.')
		}
		else
		{
			setError('');
			setConfirmError(false);
		}
	}

    const [confirmPass, setConfirmPass] = useState()
    const [submit, setSubmit] = useState(false)
    const [passSubmit, setPassSubmit] = useState(false)
    const [phraseSubmit, setPhraseSubmit] = useState(false)
    const [changed, setChanged] = useState(false)

    const setPhraseInputData = () => 
	{
        setSubmit(true)
		setPhraseError(false);
		setResponseError(false);
		Settings_Put_API('/mercury/employee/access/challenge', { recoveryPhrase, recoveryAnswer }).then( (res) => 
		{
			setPhraseSubmit(true)
			setSubmit(false)
		} )
		.catch( (err) =>
		{
			setPhraseError(true);
			setResponseError(true);
			setError(err?.response?.data?.error.info)
		});
		setError("");
	}

	const setPasswordInputData = () => 
	{
		if( settingPassword.length > 0 && !validator.isStrongPassword( settingPassword )) 
		{
			setPasswordError(true);
			setConfirmError(false);
			setError('Your password must be at least 8 characters long, contain at least one number, one symbol and have a mixture of uppercase and lowercase letters.');
		}
		else if(( settingPassword.length > 0 || confirmPass.length > 0 ) && settingPassword !== confirmPass ) 
		{
			setPasswordError(true);
			setConfirmError(true);
			return setError('The Confirm password does not match Password.')
		}
		else if( settingPassword.length > 0 ) 
		{
			setPasswordError(false);
			setConfirmError(false);
			Settings_Put_API('/mercury/employee/access/password', { 'password':settingPassword }).then( (res) => 
			{
				setPassSubmit(true)
				setSubmit(false)
			})
			.catch( (err) => 
			{
				setError(err?.response?.data.error.info)
			});
			setError('');
		}
		else
		{
			setPasswordError(false);
			setConfirmError(false);
			setError('');
		}
	}

/*	
	const notify = (msg) => toast.warn(msg, 
	{
		position: "top-center",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "colored",
		transition: "slide",
	});
*/

	useEffect(() => 
	{
		setChangePasswordInput( { recoveryPhrase, recoveryAnswer } );
		const set = setTimeout(() => 
		{
			if( changed )  
			{
				setPhraseInputData();
			}
		}, 1500);
		return () => clearTimeout(set)
	}, [recoveryPhrase, recoveryAnswer])


    useEffect(() => 
	{
		const set = setTimeout(() => 
		{ 
			settingPassword && setPasswordInputData()
		}, 1500);
		return () => clearTimeout(set);
	}, [settingPassword, confirmPass])

    return (
        <div className={`tab-pane fade ${navLink === "changePassword" ? "show active" : ""}`} aria-labelledby="v-pills-messages-tab">
            <div className="col-lg-10 offset-lg-1 modalContentItems">
                <h2 className="heading_1">Change password</h2>
                <p>Your new password needs at least 8 characters, uppercase and lowercase letters, and one or more numbers.</p>
                <form className="pt-5">
                    <div className="row g-3 align-items-center">
                        <div className="fm_grp col-md-12 ">
                            <div>
                                <label htmlFor="recoveryPhrase" className={`form-label ${error && phraseError && !recoveryPhrase ? 'error_text' : ''}`}>*Recovery Phrase</label>
                                <input type="text" className={`form-control ${error && phraseError && !recoveryPhrase ? 'error_input' : ''}`} id="recoveryPhrase" onInput={(e) => { setChanged(true); setRecoveryPhrase(e.target.value) }} value={recoveryPhrase} />
                            </div>
                        </div>
                        <div className="fm_grp col-md-12 mb-3">
                            <div>
                                <label htmlFor="recoveryAnswer" className={`form-label ${error && responseError && !recoveryAnswer ? 'error_text' : ''}`}>*Recovery Answer</label>
                                <input type="text" className={`form-control ${error && responseError && !recoveryAnswer ? 'error_input' : ''}`} id="recoveryAnswer" onInput={(e) => { setChanged(true); setRecoveryAnswer(e.target.value) }} value={recoveryAnswer} />
                            </div>
                        </div>

                        <div className="fm_grp col-md-6 opacity_5">
                            <label htmlFor="Firstname" className={`form-label`}>* Username</label>
                            <input type="text" className={`form-control`} id="userName" disabled value={username} />
                        </div>
                        <div className="fm_grp col-md-6">
                            <div>
                                <label htmlFor="settingPasswordFld" className={`form-label ${error && ( passwordError || confirmError ) ? 'error_text' : ''}`}>* Password</label>
                                <input autoComplete='new-password' type="password" className={`form-control ${error && ( passwordError || confirmError ) ? 'error_input' : ''}`} id="settingPasswordFld" 
										onInput={(e) => validatePassword(e.target.value)} value={settingPassword} />
                            </div>
                        </div>
                        <div className="fm_grp col-md-6 ms-auto">
                            <div>
                                <label htmlFor="confirm" className={`form-label ${error && confirmError ? 'error_text' : ''}`}>* Confirm password </label>
                                <input type="password" className={`form-control ${error && confirmError ? 'error_input' : ''}`} 
										id="confirm" onInput={(e) => validateConfirmPassword(e.target.value)} value={confirmPass} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
                <div> </div>
                <div className="p-md-4 text-end">
                    <button type="button" className="btn btn_cstm_primary" data-bs-dismiss="modal" disabled={allErrorCheck} >
                        Done
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword