/**
 * 
 * All changes must adhere to the Authorization Code Flow with PKCE
 * Implicit flow is not allowed.
 * 
 * 		https://learn.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-auth-code
 * 
 * 		npm install @azure/msal-browser @azure/msal-react @azure/msal-common
 * 
 */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Signup from "../signup/Signup";
import "./Login.css";
import { setAuthToken } from '../../services/tokenService'
import { useNavigate } from "react-router-dom";
import { AuthState } from "../../context/authContext";
import { getLocalAuthToken } from "../../services/tokenService";
import { login_api } from "../../services/AuthApi";
import EnterUserName from "../ResetPassword/Enterusername";
import SolveChallenge from "../ResetPassword/SolveChallenge";
import ResetPass from "../ResetPassword/ResetPass";

const Login = ( { useSSO } ) => 
{
	const navigate = useNavigate()

	const [ForgotPassdata, setForgotPassdata] = useState()
	const [userData, setUserData] = useState()
	const { user, setUser, setEntitlement } = AuthState()
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [rememberMe, setRememberMe] = useState(false)
	const [error, setError] = useState(false)
	const [submit, setSubmit] = useState(false)
	const [userRem, setUserRem] = useState([])

	useEffect(() => 
	{
		// Mercury login check.
		const user_token = getLocalAuthToken()
		// Already logged in so navigate.
		if( user_token ) 
		{
			navigate('../mail')
		}
		// is not logged in.  Get last info from login screen.
		else 
		{
			const userref = localStorage.getItem('userRef')
			if (userref) 
			{
				setRememberMe(true)
				const userRem = window.atob(userref).split(':')
				setUserRem(userRem)
				setUsername(userRem[0])
				setPassword(userRem[1])
			}
		}
	}, [])

	const doLogin = () => 
	{
		let logindata = {};
		setSubmit(true)
		if( !useSSO && (!username || !password) )
		{
			return
		}
		if( !useSSO )
		{
			logindata = { username, password };
			if (rememberMe) 
			{
				localStorage.setItem('userRef', window.btoa( logindata.username + ":" + logindata.password ))
			} 
		}
		else 
		{
			logindata = "popup"
			if (username === userRem[0])
				localStorage.removeItem('userRef')
		}
		// call the login.
		login_api(logindata).then((res) => 
		{
			if (res?.status === 200) 
			{
				localStorage.setItem('user', JSON.stringify(res?.data))
				sessionStorage.setItem('user', JSON.stringify(res?.data))
				setUser(res.data)
				setEntitlement(res?.data?.entitlements);
				//THERE SHOULD BE A BETTER WAY BUT THE REACT ROUTING IS MESSED UP
				// AFTER A CALL TO LOGIN USING MSAL.  THIS SOLUTION WHILE NOT IDEAL
				// IS A HACK THAT WORKS.
				window.location.assign('/mail/inbox')
			}
			setError(res?.statusText)
			setSubmit(false)
		})
	}

	return (<>

		<header className="authHeader">

		</header>

		<section className="login_form pt-4 my-5">
		<div className="container py-3">
			<div className="row">
			<div className="col-lg-10 m-auto">
				<div className="row d-flex justify-content-center align-items-center  ">
				<div className="col-xl-9">
					<div className="card text-black">
					<div className="row g-0">
						<div className="col-lg-7">
						<div className="card-body p-md-4 mx-md-3 px-md-5">
							<div className="mb-5 login_head">
								<h4 className="lgn_title mb-2">
									Welcome, please sign in
								</h4>
								<p>
									Please sign in with the information you registered
									with or was provided for you.
								</p>
							</div>

							{error && <span style={{ color: 'red' }}>{error}</span>}
							<form >
								{ !useSSO && <>
									<div className="mb-3">
										<label
										htmlFor="loginUserName"
										className={`form-label ${submit && !username ? 'error_text' : ''}`}
										>
										Username
										</label>
										<input
										type="text"
										className={`form-control ${(submit && !username) || error ? 'error_input' : ''}`}
										id="loginUserName"
										aria-describedby="emailHelp"
										onInput={(e) => setUsername(e.target.value)}
										value={username}
										/>
									</div>
									<div className="mb-3">
										<label
										htmlFor="loginPassword"
										className={`form-label ${submit && !password ? 'error_text' : ''}`}
										>
										Password
										</label>
										<input
										type="password"
										className={`form-control ${(submit && !password) || error ? 'error_input' : ''}`}
										id="loginPassword"
										onInput={(e) => setPassword(e.target.value)}
										value={password}
										/>
									</div>
									<div className="mb-2 form-check">
										<input
										type="checkbox"
										className="form-check-input"
										id="exampleCheck1"
										onChange={(e) => { setRememberMe(!rememberMe) }}
										checked={rememberMe}
										/>
										<label
										className="form-check-label ms-3"
										htmlFor="exampleCheck1"
										>
										Remember me
										</label>
									</div>
								</> }
								<div className="d-flex flex-wrap align-items-center justify-content-center pb-4 mt-5 pt-5">
									{ !useSSO && <a className="mb-3 mb-lg-0 me-2 btm_link" data-bs-toggle="modal" data-bs-target="#forgotpassModal">Need help with your login?</a> }
									<button type="button" className="btn btn-primary btn_cstm_primary px-3 ms3" onClick={() => doLogin()} > Sign in </button>
								</div>
							</form>
						</div>
						</div>
						<div className="col-lg-5 bg_dark_blue form_cnt_r">
						{!userData ? (
							<div className="h-100 px-3 py-3 p-md-4  ">
								{ !useSSO ? 
									<>
										<div className=" h-75">
											<div>
												<h4 className="mb-5 bd-highligh">Don’t have <br /> an account?</h4>
												<Signup setUserData={setUserData}>
													<button type="button" className="btn btn-outline-light w-100" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >
														Request an account
													</button>
												</Signup>
											</div>
										</div>
										<div className="mt-5 mb-5 mb-lg-0 pb-5 pb-lg-0">
											<p className="mb-0 fnt_16">
												Or please contact your admin or HR for access to the system.
											</p>
										</div>
									</>
								:
									<div className=" h-75">
										<div>
											<h4 className="mb-5 bd-highligh">Don’t have an account?</h4>
										</div>
										<div className="mt-5 mb-5 mb-lg-0 pb-5 pb-lg-0">
											<p className="mb-0 fnt_16">
												Please contact your admin or HR for access to the system.
											</p>
										</div>
									</div>
								}
							</div>
						) 
						: 
						(
							<div className="h-100 px-3 py-3 p-md-4  ">
								<div className=" h-75">
									<div>
									<h4 className="mb-5 bd-highligh">
										Thank you. We will process your request and notify you shortly via email.
									</h4>
									</div>
								</div>
							</div>
						)}
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
		</section>

		<EnterUserName setData={setForgotPassdata} />
		<SolveChallenge data={ForgotPassdata} setData={setForgotPassdata} />
		<ResetPass />
	</>
	);
};

	export default Login;
