/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { CommonState } from '../../../../../context/commonContext';
import { putService, getService } from '../../../../../services/commonApi'

const NoteEditModal = ({ url, onNoteEdit }) => 
{
    const { refreshMailData, setRefreshMailData } = CommonState()
    const [note, setNote] = useState('')
    const [noteDetail, setNoteDetail] = useState()
    const _url = useRef()
    const [lastMailOpen, setLastMailOpen] = useState('')
    var params = useParams();

    useEffect(() => {
        setLastMailOpen(params.mailId)
        if (params.id && params.mailId) {
            _url.current = `/mercury/mail/mailboxes/folders/${params?.id}/mail/${params?.mailId}`
            getNote()
        }
    }, [params.id, params.mailId, refreshMailData])

    const submitForm = () => 
	{
		onNoteEdit( note ).then( res =>
		{
			resetForm()
			document.getElementById('closeNoteEditModal').click()
		})
		.catch( err =>
		{

		})
    }

    const NoteEdit = () => 
	{
        let data = {
            note: note
        }
        putService(_url.current + '/copies/note', data, "").then((res) => 
		{
            setRefreshMailData(!refreshMailData)
            resetForm()
            document.getElementById('closeNoteEditModal').click()
        }, (err) => { })
    }

    const getNote = () => {
        getService(_url.current + '/copies/note', true, "").then((res) => {
            setNoteDetail(res.data)
            setNote(res.data.note ?? '')
        }, (err) => { console.log(err) })
    }

    const resetForm = () => {
        setNote('')
        setNoteDetail()
    }


    return (
        <div className="offcanvas offcanvas-top fade note note_edit" id="note_edit" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" aria-labelledby="offcanvasScrollingLabel">
            {/* <div  className="modal-dialog modal-lg">
                <div  className="modal-content"> */}
            <div className="offcanvas-header pb-0 border-0">
                <button onClick={() => resetForm()} type="button" className="btn" data-bs-dismiss="offcanvas" aria-label="Close" id="closeNoteEditModal">
                    <i className="bi bi-x"></i>
                </button>
            </div>
            <div className="offcanvas-body py-lg-0 info_text">
                <div className="row align-items-center ps-lg-4">
                    <div className="col-lg-10">
                        {noteDetail?.note && <p>Note from <b>{noteDetail?.noteAuthorName?.fullName}</b> on {new Date(noteDetail?.noteModified).toLocaleDateString("en-IN", { day: '2-digit', month: 'long', year: 'numeric' })}</p>}
                        {!noteDetail?.note && <p>Note :</p>}
                        <div className="fm_grp">
                            <input type="text" className="form-control" id="inputAddress" onInput={(e) => setNote(e.target.value)} value={note} />
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <button className="btn btn-primary btn_save" onClick={submitForm}>Save</button>
                    </div>
                </div>
            </div>
            {/* </div>
            </div> */}
        </div>
    )
}

export default NoteEditModal;