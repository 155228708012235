import Highcharts from "highcharts";

/**
 * Expected data format:
 * 
	chart: 
	{
		"title": "System Activity",
		"workstations": 
		[
			{
				"workstationId": "1370b781-c286-4ad2-9207-61188c7ae7a4",
				"data": 
				[
					{
						"date": "2022-10-01T00:00:00Z",
						"totalImages": 28,
						"operator": null
					},
					{
						"date": "2022-11-30T00:00:00Z",
						"totalImages": 93,
						"operator": null
					},
					{
						"date": "2022-11-03T00:00:00Z",
						"totalImages": 105,
						"operator": null
					},
					{
						"date": "2022-10-19T00:00:00Z",
						"totalImages": 276,
						"operator": null
					}
				],
				"date": null,
				"totalImages": 198,
				"operator": null,
				"workstation": "Direct Mail Depot"
			},
			...
		],
		"days": 365
	}
*/
export const filterSysData = (chartdata) => 
{
	var options;
	if( chartdata?.workstations ) 
	{
		let xvals = [];
		let xlabels = [];
		let series = [];
		chartdata.workstations.forEach( (workstation,i ) =>
		{
			let workstation_data = [];
			workstation?.data.forEach( (ele,j) =>
			{
				workstation_data[j] =
				{
					name: ele?.date,
					y: ele?.totalImages
				}
				// collect the labels for the bottom.
				xvals.push( ele?.date )
			});
			series[i] =
			{
				name: workstation.workstation,
				showInLegend: true,
				data: workstation_data // jun30, 15
			}
		});
		// sort and filter the labels so only unique values in sequence are present.
		xlabels = [...new Set(xvals)].sort(function(a, b)
		{
			const date1 = new Date(a)
			const date2 = new Date(b)
			return date1 - date2; 
		});
		// make sure all of the series have the same number of values.
		series.forEach( (series_ele,k) =>
		{
			let new_series_data = new Array(xlabels.length).fill(0).map( (ele,i) => ({ name:xlabels[i], y:0 }) )
			series_ele.data.forEach( (ele,i) =>
			{
				new_series_data[ xlabels.indexOf(ele.name) ].y = ele.y;
			})
			series_ele.data = new_series_data
		});

		options = 
		{
			title: 
			{
				text: "",
			},
			yAxis: 
			{
				title: 
				{
					text: "Total Images",
				},
			},
			xAxis: 
			{
				categories: xlabels,
				labels: 
				{
					formatter: function () 
					{
						return Highcharts.dateFormat("%e %b %Y", new Date(this.value));
					},
				},
			},
			series: series,
			responsive: 
			{
				rules: 
				[
					{
						condition: 
						{
							maxWidth: 500,
							maxHeight: 500,
						},
						chartOptions: 
						{
							legend: 
							{
								layout: "horizontal",
								align: "center",
								verticalAlign: "bottom",
							},
						},
					},
				],
			},
		};
	} 
	else 
	{
		options = {
			title: 
			{
				text: "System Activity",
			},
			series: 
			[
				{ data: [], },
			],
		};
	}
	// console.log(options);
	return options;
};
