/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { getListing } from "../../../services/AuthApi";
//import { getService } from "../../../services/commonApi";
import { getCompanyIdToken } from "../../../services/tokenService";

const CompanyInfo = ({
  navLink,
  setNavLink,
  error,
  setError,
  companyInput,
  setCompanyInput,
}) => {


  const [address, setAddress] = useState(companyInput ? companyInput.address : '')
  const [mailstop, setMailstop] = useState(companyInput ? companyInput.mailstop : '')
  const [department, setDepartment] = useState(companyInput ? companyInput.department : '')
  const [employeenumber, setEmployee_number] = useState(companyInput ? companyInput.employeenumber : '')
  const [position, setPosition] = useState(companyInput ? companyInput.position : '')
  const [costCenter, setCostCenter] = useState(companyInput ? companyInput.costCenter : '')


  const [addresslist, setAddresslist] = useState([])
  const [mailStopList, setMailStopList] = useState([])
  const [departmentList, setDepartmentList] = useState([])
  // const [employeeNumberList, setEmployeeNumberList] = useState([])
  const [positionList, setPositionList] = useState([])
  const [costCenterList, setCostCenterList] = useState([])


  const getAddressList = () => {
    getListing(`/mercury/company/${getCompanyIdToken()}/address/list`, true).then((res) => {
      console.log(res)
      if (res?.status === 200) {
        setAddresslist(res.data.list)
      }
    })
  }
  const getMailStopList = () => {
    getListing(`/mercury/company/${getCompanyIdToken()}/mailstop/list`, true).then((res) => {
      if (res?.status === 200) {
        setMailStopList(res.data.list)
      }
    })
  }
  const getDepartmentList = () => {
    getListing(`/mercury/company/${getCompanyIdToken()}/department/name/list?start=1&stop=30&primary=true`, true).then((res) => {
      if (res?.status === 200) {
        setDepartmentList(res.data.list)
      }
    })
  }
  const getPositionList = () => {
    // "?start=1&stop=40&primary=true"
    getListing(`/mercury/company/${getCompanyIdToken()}/position/name/list?primary=true`, true).then((res) => {
      if (res?.status === 200) {
        setPositionList(res.data.list)
      }
    })
  }

  const getCostCenterList = () => {
    getListing(`/mercury/company/${getCompanyIdToken()}/costcenter/list`).then((res) => {
      if (res?.status === 200) {
        setCostCenterList(res?.data?.list)
      }
    })
  }


  useEffect(() => {
    getAddressList()
    getDepartmentList()
    getCostCenterList()
    getMailStopList()
    getPositionList()
  }, [])
/*
  useEffect(() => {
    if (!address) {
      setAddress(addresslist[0]?.addressId)
    }
    if (!department) {
      setDepartment(departmentList[0]?.departmentId)
    }
    if (!mailstop) {
      setMailstop(mailStopList[0]?.mailStopId)
    }
    if (!position) {
      setPosition(positionList[0]?.positionId)
    }
    if (!costCenter) {
      setCostCenter(costCenterList[0]?.costCenterId)
    }

  }, [addresslist, departmentList, mailStopList, positionList, costCenterList])
*/
  useEffect(() => {
    if (!address) {
      setAddress(addresslist[0]?.addressId)
    }
  }, [addresslist])

  useEffect(() => {
    if (!department) {
      setDepartment(departmentList[0]?.departmentId)
    }
  }, [departmentList])

  useEffect(() => {
    if (!mailstop) {
      setMailstop(mailStopList[0]?.mailStopId)
    }
  }, [ mailStopList ])

  useEffect(() => {
    if (!position) {
      setPosition(positionList[0]?.positionId)
    }
  }, [positionList])

  useEffect(() => {
    if (!costCenter) {
      setCostCenter(costCenterList[0]?.costCenterId)
    }
  }, [costCenterList])

  const [,setSubmit] = useState(false)

  useEffect(() => {
    setCompanyInput({ address, mailstop, department, employeenumber, position, costCenter });
  }, [address, mailstop, department, employeenumber, position, costCenter])


  const setCompanyInputData = () => {
    setSubmit(true)
    setCompanyInput({
      address, mailstop, department, employeenumber, position, costCenter
    });
    if (!address || !mailstop || !department || !employeenumber || !position || !costCenter) {
      if (!address && mailstop && department && employeenumber && position && costCenter) {
        return setError("Address is required field");
      } else if (!mailstop && address && department && employeenumber && position && costCenter) {
        return setError("Mailstop is required field");
      } else if (!department && address && mailstop && employeenumber && position && costCenter) {
        return setError("Department is required field");
      } else if (!employeenumber && address && department && mailstop && position && costCenter) {
        return setError("Employee Number is required field");
      } else if (!position && address && department && employeenumber && mailstop && costCenter) {
        return setError("Position is required field");
      } else if (!costCenter && address && department && employeenumber && mailstop && position) {
        return setError("Cost center is required field");
      }
      return setError("Required fields are missing.");
    }

    setError("");

    setNavLink("contact");
  }

  return (
    <div
      className={`tab-pane fade ${navLink === "company" ? "show active" : ""}`}
      aria-labelledby="v-pills-profile-tab"
    >
      <div className="col-lg-9 offset-lg-1 modalContentItems">
        <h2 className="heading_1">Let’s create your account</h2>
        <p className="mb-0">
          In order to create your account, we will need certain information from
          you. Please provide all the following information so that we can
          process your request.
        </p>
        <p className="fnt_w_bold">Please be as thorough as possible.</p>
        <form className="pt-4">
          <div className="row g-3 align-items-center">

            <div className="fm_grp col-md-6">
              <label htmlFor="inputAddress" className={`form-label ${error && !address ? 'error_text' : ''}`}>
                * Address {error && !address && <span>is required</span>}
              </label>
              <select id="inputAddress" className={`form-select ${error && !address ? 'error_input' : ''}`} onInput={(e) => setAddress(e.target.value)}
                value={address}>
                {addresslist?.map((data, i) => {
                  return (
                    <option key={i} value={data.addressId}>{data.address1} {data?.address2},{data?.city} {data?.state} {data?.zipcode}</option>
                  )
                })}
              </select>
            </div>

            <div className="fm_grp col-md-6">
              <label htmlFor="inputMailStop" className={`form-label ${error && !mailstop ? 'error_text' : ''}`}>
                * Mail Stop {error && !mailstop && <span>is required</span>}
              </label>
              <select id="inputMailStop" className={`form-select ${error && !mailstop ? 'error_input' : ''}`} onInput={(e) => setMailstop(e.target.value)}
                value={mailstop}>
                {mailStopList?.map((data, i) => {
                  return (
                    <option key={i} value={data.mailStopId}>{data.name}</option>
                  )
                })}
              </select>
            </div>

            <div className="fm_grp col-md-6">
              <label htmlFor="inputDepartment" className={`form-label ${error && !department ? 'error_text' : ''}`}>
                * Department {error && !department && <span>is required</span>}
              </label>
              <select id="inputDepartment" className={`form-select ${error && !department ? 'error_input' : ''}`}
                onInput={(e) => setDepartment(e.target.value)}
                value={department}
              >
                {departmentList?.map((data, i) => {
                  return (
                    <option key={i} value={data?.departmentId}>{data?.name}</option>
                  )
                })}
              </select>
            </div>

            <div className="fm_grp col-md-6">
              <label htmlFor="inputEmployeeNumber" className={`form-label ${error && !employeenumber ? 'error_text' : ''}`}>
                * Employee number {error && !employeenumber && <span>is required</span>}
              </label>
              <input type="text" id="inputEmployeeNumber" className={`form-control ${error && !employeenumber ? 'error_input' : ''}`} onInput={(e) => setEmployee_number(e.target.value)}
                value={employeenumber} />
              {/* <option value='2017585'>2017585</option>
                <option value='2017586'>2017586</option>
                <option value='2017587'>2017587</option>
                <option value='2017588'>2017588</option>
              </select> */}
            </div>

            <div className="fm_grp col-md-6">
              <label htmlFor="inputCost" className={`form-label ${error && !costCenter ? 'error_text' : ''}`}>
                * Cost Center {error && !costCenter && <span>is required</span>}
              </label>
              <select id="inputCost" className={`form-select ${error && !costCenter ? 'error_input' : ''}`}
                onInput={(e) => setCostCenter(e.target.value)}
                value={costCenter}
              >
                {costCenterList?.map((data, i) => {
                  return (
                    <option key={i} value={data?.costCenterId}>{data?.name}</option>
                  )
                })}
              </select>
            </div>

            <div className="fm_grp col-md-6">
              <label htmlFor="inputPostion" className={`form-label ${error && !position ? 'error_text' : ''}`}>
                * Position {error && !position && <span>is required</span>}
              </label>

              <select id="inputPostion" className={`form-select ${error && !position ? 'error_input' : ''}`} onInput={(e) => setPosition(e.target.value)}
                value={position}>
                {
                  positionList?.map((data, i) => {
                    return (
                      <option key={i} value={data?.positionId}>{data.name}</option>
                    )
                  })
                }
              </select>
              {/* <div  className="serach_box">
                <input type="text" style={{ 'borderColor': '#A8A8A8' }} aria-label="Search" placeholder="VP HR" onInput={(e) => setPosition(e.target.value)}
                  value={position} className={`form-control ${submit && !position ? ' error_input' : ''}`} />
                <a href="#"><span  className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
              </div>
            </div> */}


            </div>
          </div>
        </form>
      </div>
      <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
        <div> </div>
        <div className="p-md-4 text-end">
          <button
            type="button"
            className="btn btn-link  btn_link_primary me-3"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn_cstm_primary px-4"
            onClick={() => setCompanyInputData()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
