/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { AuthState } from '../../../../context/authContext'
import { getService } from '../../../../services/commonApi'
import { getCompanyIdToken } from '../../../../services/tokenService'
import DepartmentAdd from './Modals/DepartmentModals/DepartmentAdd'
import DepartmentDelete from './Modals/DepartmentModals/DepartmentDelete'

const Departments = ({ activeTab }) => {
	const { databaseRoute, setDatabaseRoute } = AuthState()
	const [type, setType] = useState("none")
	const [dataToDelete, setDataToDelete] = useState()
	const [departmentList, setDepartmentList] = useState([])
	const [sortby, setSortby] = useState()
	const [filter, setFilter] = useState()
	const [mailStopList, setMailStopList] = useState([])
	var start = 1;
	var stop = 30;
	var primary = true;

    var departmentUrl = `/mercury/company/${getCompanyIdToken()}/department`

	useEffect(() => 
	{
		if (databaseRoute === 'departments') 
		{
			getDepartmentList()
			getMailStopList()
		}
	}, [databaseRoute])

	const getDepartmentList = () => 
	{
		getService(`${departmentUrl}/name/list`, true, 
		{
			params: 
			{
				primary, sortby, filter
			}
		}).then((res) => 
		{
			if (res?.status === 200) 
			{
				setDepartmentList(res?.data?.list)
			}
		})
	}

	const getMailStopList = () => 
	{
		getService(`/mercury/company/${getCompanyIdToken()}/mailstop/list`, true).then((res) => 
		{
			if (res?.status === 200) 
			{
				setMailStopList(res?.data.list)
			}
		})
	}

	useEffect(() => 
	{
		const search = setTimeout(() => 
		{
			if (filter || sortby) 
			{
				getDepartmentList()
			}
		}, 1500);
		return () => clearTimeout(search)
	}, [filter, sortby])


    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-f" onClick={() => { setDatabaseRoute('departments') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-f" aria-expanded="true" aria-controls="collapse-f">
                        Departments  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>

            <div id="collapse-f" className={`collapse ${databaseRoute === "departments" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-f">
                <div className="head d-none d-md-block">
                    <h4 className="heading_1 text_dark mb-0">Departments</h4>
                </div>
                <div className="p-4">
                    <p> Please enter all of your company’s Departments that the system will use to receive mail, and keep this list current. If the list is not kept current, Departments for mail may be inaccurate.<a className="text_dark">More…</a></p>
                </div>
                <div className="p-4  pt-0 col-lg-12">
                    <div className="row">
                        <div className="col-lg-11">
                            {/* <!-- Start search--> */}
                            <div className="col-md-5 col-xl-4">
                                <div className="serach_box mb-3">
                                    <input className="form-control" type="text" aria-label="Search" placeholder="Search Department" onInput={(e) => setFilter(e.target.value)} value={filter} />
                                    <a><span className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
                                </div>
                            </div>
                            {/* <!-- Start search -->

                            <!-- Start Table --> */}
                            <div className="col-md-8  col-xl-4">
                                <div className="table-responsive cst_table departments_table">
                                    <table className="table">
                                        <tbody>
                                            {
                                                departmentList?.map((item, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td style={{ width: '80%' }}>{item.name}</td>
                                                            <td className="acction_icon">
                                                                <a onClick={() => { setType("edit"); setDataToDelete(item) }} data-bs-toggle="modal" data-bs-target="#AddDepartment">
                                                                    <img src="assets/img/Edit.svg" alt="" />
                                                                </a>
                                                                <a onClick={() => setDataToDelete(item)} data-bs-toggle="modal" data-bs-target="#delete_department">
                                                                    <img src="assets/img/Delete.svg" alt="" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <!-- End Table -->
                        
                            <!-- Add an Account --> */}

                            <div className="add_account mt-4">
                                <a onClick={() => { setType(""); setDataToDelete('new') }} data-bs-toggle="modal" data-bs-target="#AddDepartment"> 
									<img src="assets/img/add_account.svg" alt="" />
									<span>Add a Department</span>
								</a>
                            </div>
                            {/* <!-- Add an Account --> */}
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            <DepartmentAdd type={type} url={departmentUrl} data={dataToDelete} mailStopList={mailStopList} getList={getDepartmentList} 
					onClose={ () => { setDataToDelete(''); document.getElementById('closeDepartmentAddModal').click(); } } />
            <DepartmentDelete data={dataToDelete} url={departmentUrl} getList={getDepartmentList} setDataToDelete={setDataToDelete} />
        </>
    )
}

export default Departments