/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

const InactivityBanner = ({ showPopup, seconds, reset, logout }) => 
{
	const [tick, setTick] = useState( seconds-1 )
	let interval = null;

	function startInterval()
	{
		return setInterval(() => 
		{
			setTick( tick - 1 )
			if( tick === 0 )
			{
				clearInterval(interval);
				logout();
			}
		}, 1000);
	}

	useEffect(() => 
	{
		if( showPopup )
		{
			interval = startInterval();
			return () => clearInterval(interval);
		}
	}, [ showPopup, tick, interval ])

	// the user clicked refresh.
	function refresh() 
	{
		setTick( seconds - 1 );
		clearInterval(interval);
		reset();
	}

    return (
        <div className="offcanvas offcanvas-top fade delete_banner warn_modal" id="inactivityBanner" data-bs-scroll="true" data-bs-backdrop="false" 
						tabIndex="-1" aria-labelledby="offcanvasScrollingLabel">
            <div className="offcanvas-body">
                <div className="row align-items-center">
                    <div className="col-lg-9">
                        <p>Due to inactivity, you will be logged out in <b>{tick}</b> seconds</p>
                    </div>
                    <div className="col-lg-2 text-lg-end">
                        <button type="button" className="btn btn_cstm_primary px-4 btn-sm" onClick={() => refresh()} data-bs-dismiss="offcanvas">I'm still working</button>
                    </div>

                    <div className="col-lg-1 text-end">
                        <button type="button" className="btn close_btn" data-bs-dismiss="offcanvas" id="closedeleteNoteModal" >
                            <i className="bi bi-x"></i>
                        </button>
                    </div>
                </div>
            </div>
            {/* </div>
            </div> */}
        </div>
    )
}

export default InactivityBanner