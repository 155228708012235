/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

const WrongCompanyBanner = ({ close, setClose }) => {

    useEffect(() => {
        if (close) {
            // closeFunction()
            document.getElementById('closeReassignCanvas').click()
            setClose(false)
        }
    }, [close])

    return (
        // <div className="modal fade wrong_company_banner warn_modal" id="wrong_company_banner" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        //     <div className="modal-dialog modal-xl">
        //         <div className="modal-content">
        //             <div className="modal-body">
        //                 <div className="row align-items-center">
        //                     <div className="col-lg-9">
        //                         <p>That piece of mail has been sent back for reassignment.</p>
        //                     </div>

        //                     <div className="col-lg-3 text-end">
        //                         <button type="button" className="btn close_btn" data-bs-dismiss="modal" aria-label="Close">
        //                             <i className="bi bi-x"></i>
        //                         </button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className="offcanvas offcanvas-top fade delete_banner warn_modal" id="reassignCanvas" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" aria-labelledby="offcanvasScrollingLabel">
            {/* <div  className="modal-dialog modal-xl">
                <div  className="modal-content"> */}
            <div className="offcanvas-body">
                <div className="row align-items-center">
                    <div className="col-lg-9">
                        <p>That piece of mail has been sent back for reassignment.</p>
                    </div>

                    <div className="col-lg-3 text-end">
                        <button type="button" className="btn close_btn" id="closeReassignCanvas" data-bs-dismiss="offcanvas" aria-label="Close">
                            <i className="bi bi-x"></i>
                        </button>
                    </div>
                </div>
            </div>
            {/* </div>
            </div> */}
        </div>
    )
}

export default WrongCompanyBanner