import React from 'react'
import { useEffect } from 'react';
import "./NewMailBanner.css";

const NewMailBanner = ({ showPopup, seconds, dismissCallback }) => 
{
	useEffect(() =>
	{
		if( showPopup )
		{
			setTimeout(() => 
			{
				dismissCallback();
			}, seconds*1000 );	
		}
	}, [showPopup, seconds, dismissCallback])

	return (
        <div className="new_mail delete_banner warn_modal evaluate_rules" id="newMailBanner" tabIndex="-1" data-bs-scroll="true" 
						data-bs-backdrop="false" aria-labelledby="offcanvasScrollingLabel">

            <div >
                <div className="row align-items-center">
                    <div className="col-lg-11">
                        <p className="vertical-center">You have received new mail.</p>
                    </div>

                    <div className="col-lg-1 text-end">
                        <button type="button" className="cbtn center" id="closeNewMailPopup" onClick={dismissCallback} aria-label="Close">
                            <i className="bi bi-x"></i>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NewMailBanner