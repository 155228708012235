/* eslint-disable no-unused-vars */
import React from 'react'
import { RouteState } from '../../../../context/routeContext'


function ReportTabSidebar() {
    const { dashboardRoute, setDashboardRoute, } = RouteState()

    return (
        <ul className="nav nav-tabs responsive_tabs ps-lg-3" role="tablist">
            <li className="nav-item">
                <a id="tab-A" href="#pane-A" data-bs-toggle="tab" role="tab" onClick={() => setDashboardRoute("dashboard")} className={`nav-link ${dashboardRoute === 'dashboard' ? 'active' : ''}`}>Dashboard
                </a>
            </li>
            <li className="nav-item">
                <a id="tab-B" href="#pane-B" data-bs-toggle="tab" role="tab" onClick={() => setDashboardRoute("report")} className={`nav-link ${dashboardRoute === 'report' ? 'active' : ''}`}>Generate Report </a>
                {/* <Link to="/accountsRequest">
                        </Link> */}
            </li>
        </ul>
    )
}

export default ReportTabSidebar