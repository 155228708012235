/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { CommonState } from '../../../../../context/commonContext';
import { getService } from '../../../../../services/commonApi'
export default function NoteModal({ url }) {
    const [note, setNote] = useState({})
    // const url = `/mercury/mail/mailboxes/folders/:${folderId}/mail/:${mailId}/copies/note`
    const { refreshMailData } = CommonState()
    var params = useParams();

    // useEffect(() => {
    //     if (params.mailId) {
    //         console.log(url, params.mailId)
    //         getNote()
    //     }
    // }, [url && params.mailId])


    useEffect(() => {
        if (params.id && params.mailId) {
            getNote(`/mercury/mail/mailboxes/folders/${params?.id}/mail/${params?.mailId}`)
            // getFolderMail(url)
            // getFolderInfo(url)
            // console.log("url = ", url)
        }
    }, [params.id, params.mailId, refreshMailData])


    const getNote = (_url) => {
        // console.log('url=>', url)
        getService(`${_url}/copies/note`, true, "").then(
            (res) => {
                // console.log(res)
                if (res.data.note) {
                    setNote(res.data)
                } else {
                    setNote()
                    document.getElementById('closeViewNoteModal').click()
                }
            }, (err) => { console.log(err) })
    }


    return (
        <div className="offcanvas offcanvas-top note" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">

            <div className="offcanvas-header pb-0 border-0">
                <button type="button" id="closeViewNoteModal" className="btn" data-bs-dismiss="offcanvas" aria-label="Close">
                    <i className="bi bi-x"></i>
                </button>
            </div>
            <div className="offcanvas-body pt-0 info_text">
                <div className="row align-items-center ps-lg-4">
                    <div className="col-lg-10">
                        <p>Note from <b>{note?.noteAuthorName?.fullName}</b> on {new Date(note?.noteModified).toLocaleDateString("en-IN", { day: '2-digit', month: 'long', year: 'numeric' })}</p>
                        <p>{note?.note}</p>
                        {/* <p>Note from <b>Michelle Davidson</b> sent 3 January 2022</p>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna</p> */}
                    </div>
                    <div className="col-lg-2">
                        <div className="acction_icon" data-bs-dismiss="offcanvas">
                            <a data-bs-toggle="offcanvas" aria-controls="offcanvasScrolling" data-bs-target="#note_edit" >
                                <img src="/assets/img/Edit.svg" alt="" />
                            </a>
                            <a data-bs-toggle="offcanvas" aria-controls="offcanvasScrolling" data-bs-target="#delete_banner" >
                                <img src="/assets/img/Delete.svg" alt="" />
                            </a>
                            {/* <button data-bs-toggle="offcanvas" aria-controls="offcanvasScrolling" data-bs-target="#note_edit" type="button" className="btn" data-bs-dismiss="offcanvas" aria-label="Close">
                                <img src="/assets/img/Edit.svg" alt="" />
                            </button>
                            <button data-bs-toggle="offcanvas" aria-controls="offcanvasScrolling" data-bs-target="#delete_banner" type="button" className="btn" data-bs-dismiss="offcanvas" aria-label="Close">
                                <img src="/assets/img/Delete.svg" alt="" />
                            </button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" I="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
        //     <div className="offcanvas-header">
        //         <h5 className="offcanvas-title" id="offcanvasScrollingLabel">Offcanvas with body scrolling</h5>
        //         <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        //     </div>
        //     <div className="offcanvas-body">
        //         <p>Try scrolling the rest of the page to see this option in action.</p>
        //     </div>
        // </div>
    )
}
