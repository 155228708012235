/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { getService, postService } from '../../../../../services/commonApi'
import { CommonState } from '../../../../../context/commonContext'

// import Multiselect from 'multiselect-react-dropdown';
export default function ForwardMailModal({ url }) 
{
	// const [folderId, setFolderID] = useState()
	// const [mailId, setMailId] = useState()
	const [seenEmployees, setSeenEmployees] = useState()
	const [searchKey, setSearchKey] = useState("")
	const [note, setNote] = useState("")
	var [options, setOptions] = useState([])
	const [addedRecipient, setAddedRecipient] = useState([])

	const { currentMail } = CommonState()

	var params = useParams();
	var _url = useRef()

	const setAddedRecipientFunc = (value, i) => 
	{
		setAddedRecipient([...addedRecipient, value])
		options.splice(i, 1)
	}
	const removeAddedRecipientFunc = (value, i) => {
		addedRecipient.splice(i, 1)
		setOptions([...options, value])
	}

	useEffect(() => 
	{
		if (params.id && params.mailId) 
		{
			_url.current = `/mercury/mail/mailboxes/folders/${params.id}/mail/${params.mailId}`
			getEmployeesWhoCanSeeThisMail()
		}
	}, [params.id && params.mailId])

	useEffect(() =>
	{
		if( currentMail?.note )
		{
			setNote( currentMail.note )
		}
		else
		{
			setNote("")
		}
	}, [currentMail])

	const getEmployeesWhoCanSeeThisMail = () => 
	{
		getService(`${_url.current}/copies`, true, "").then((res) => 
		{
			setSeenEmployees(res.data.copies)
		}, (err) => {
		})
	}

	const forwardMail = () => 
	{
		const data = {
			note: note,
			employees: addedRecipient?.map(item => { return item.id })
		}
		postService(`${_url.current}/copies`, data, "").then((res) => 
		{
			document.getElementById('closeForwardMailModal').click()
		}, (err) => { })
	}

	useEffect(() => 
	{
		const search = setTimeout(() => 
		{
			if (searchKey) 
			{
				searchEmployee()
			} 
			else 
			{
				setOptions([])
			}
		}, 1500);
		return () => clearTimeout(search)
	}, [searchKey])

	const searchEmployee = () => 
	{
		getService(`/mercury/employee/search?name=${searchKey}`, true).then((res) => 
		{
			if (res?.status === 200) {
				setOptions(res.data?.employeeNames.map(el => ({ id: el?.employeeId, fullName: el?.fullName })))
			}
			// console.log(res?.data?.searchResults?.employeeNames)
		})
		.catch(
			setOptions([])
		)
	}


	const submitForm = () => {
		forwardMail()
	}

	const resetForm = () => {
		setNote("")
		setAddedRecipient([])
		setOptions([])
		setSearchKey("")
	}



	return (
		<div className="modal fade Note forwardmail" id="forwardmail" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered  modal_560">
				<div className="modal-content">
					<div className="modal-body py-4 ">
						<div className="col-lg-9 m-auto">
							<div><h2 className="heading_1">Forward mail</h2></div>
							<div className="fm_grp">
								<label htmlFor="">The following users already have a copy of this mail:</label>
								<div className="mail_list">
									<ul style={{ 'display': 'flex', 'flexDirection': 'row', 'flexWrap': 'wrap', 'gap': '10px', 'fontSize': '1rem' }}>
										{
											seenEmployees?.map((item, i) => 
											{
												return ( <li key={i}>{item?.fullName}</li> )
											})
										}
									</ul>
								</div>
							</div>

							{/* search start */}
							<div className="fm_grp  ">
								<label htmlFor="">* Name</label>
								<div className="serach_box">
									<a href="#"><span className="search_cion"><img src="/assets/img/search_primary.svg" alt="" /></span></a>
									<input className="form-control" type="search" onInput={(e) => setSearchKey(e.target.value)} aria-label="Search" placeholder="Search names" value={searchKey} />
									{/* <span  className="close_cion"><i  className="bi bi-x"></i></span> */}
								</div>

								{options?.map((item, i) => {
									return (
										<div key={i} className="d-flex ForwardSelectedBox" onClick={() => setAddedRecipientFunc(item, i)}>
											<div>
												<p>{item?.fullName}</p>
											</div>
										</div>
									)
								})}

							</div>
							{/* search end */}

							<div className="fm_grp recipients  ">
								{
									addedRecipient && <>
										{
											addedRecipient.length > 0 &&
											<label htmlFor="">You’ve added the following recipients:</label>

										}
										{
											addedRecipient?.map((item, i) => {
												return (
													<p key={i}><span>{item?.fullName}</span>  <a href="#" onClick={() => removeAddedRecipientFunc(item, i)}> <img src="/assets/img/material_delete.png" alt="" /> </a></p>
												)
											})
										}
									</>
								}
							</div>

							<div className="fm_grp  ">
								<label htmlFor="Firstname">* Note (optional)</label>
								<input type="email" className="form-control" id="Firstname" onChange={(e) => setNote(e.target.value?e.target.value:"")} value={note} />
							</div>

						</div>

						<div className="d-flex flex-wrap align-items-center justify-content-end offset-md-1 h-100 mt-5">
							<div className="text-end">
								<button type="button" className="btn btn-link  btn_link_primary" data-bs-dismiss="modal" id="closeForwardMailModal" onClick={resetForm}>Cancel</button>
								<button type="button" className="btn btn_cstm_primary" onClick={() => submitForm()}>Forward</button>
							</div>
						</div>


					</div>
				</div>
			</div>
		</div>
	)
}
