/**
 * 
 * All changes must adhere to the Authorization Code Flow with PKCE
 * Implicit flow is not allowed.
 * 
 * 		https://learn.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-auth-code
 * 
 * 
 * 
 */

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Login from "./authentication/login/Login";
import Home from "./home/pages/home/Home";
import "./App.css";
import "./App2.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Database from "./home/pages/dashboard/databaseModule/Database";
import Mail from "./home/pages/dashboard/mailModule/Mail";
import Deliver from "./home/pages/dashboard/deliverModule/Deliver";
import Reports from "./home/pages/dashboard/reportsModule/Reports";
import System from "./home/pages/dashboard/systemModule/System";
import { AuthState } from "./context/authContext";
import { useNavigate } from "react-router-dom";
import { validateExpTimestamp } from "./services/tokenService";
import { getconfiguration } from "./services/AuthApi";
import { signOn } from "./environment/environment"
import { CommonState } from "./context/commonContext";
import { MsalProvider, useMsal } from "@azure/msal-react";

function App({msalInstance}) 
{
	const [isSSO,setIsSSO] = useState(false)
	const navigate = useNavigate();
	const USER = localStorage.getItem("user");
	const { accounts } = useMsal();
	const { setHelpUrl, setInactivityTimeout } = CommonState()

	useEffect(() => 
	{
		// console.log('calling')
		getconfiguration().then((result) => 
		{
			setHelpUrl( result?.helpUrl )
			setInactivityTimeout( result?.inactivity )
			signOn.authentication = result?.authentication;
			signOn.isSSO = result?.authentication !== "Mercury";
			signOn.instance = msalInstance;
			signOn.accounts = accounts;
			setIsSSO( signOn.isSSO )
			// checks to see if access token still viable.
			if (!validateExpTimestamp()) 
			{
				navigate("auth");
			}
		});
	}, []);

	useEffect(() =>
	{
		signOn.accounts = accounts;
	}, [accounts] );

	return (
		<div className={`App ${USER ? "bgYellow" : ""}`}>
			<Outlet />
			<ToastContainer />
			<Routes>
				{/* <Route> */}
				<Route path="/auth" element={<Login useSSO={isSSO} />} />
				<Route path="/layout" element={ <div>
						<h1>Layout</h1>
						<Outlet />
						</div>
					}
				/>				
				<Route path="/" element={<Home />} >
					<Route path="database" element={<Database />} />
					<Route path="mail" element={<Mail />} />
					<Route path="mail/:id" element={<Mail />} />
					<Route path="mail/:id/:mailId" element={<Mail />} />
					<Route path="deliver" element={<Deliver />} />
					<Route path="reports" element={<Reports />} />
					<Route path="system" element={<System />} />
				</Route>
			</Routes>
		</div>
	);
}

// function RedirectToDefaultValue() {
//   const navigate = useNavigate();
//   navigate("mail/inbox");
// }
export default App;
