/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { AuthState } from "../../../../context/authContext"
import SenderDelete from './Modals/SenderModal/SenderDelete'
import SenderAdd from "./Modals/SenderModal/SenderAdd"
import SenderEdit from './Modals/SenderModal/SenderEdit'
import { getService } from '../../../../services/commonApi'
function Senders() {
    const { databaseRoute, setDatabaseRoute } = AuthState()
    const [data, setData] = useState()
    const [dataToDelete, setDataToDelete] = useState()

    const [senderList, setSenderList] = useState([])

    const [sort, setSort] = useState()
    const [start, setStart] = useState(1)
    const [stop, setStop] = useState(30)
    const [filter, setFilter] = useState()

    var senderUrl = `/mercury/sender`


    var sortFilter = [
        'Up', 'Down']


    useEffect(() => {
        if (databaseRoute === 'senders') {
            getSenderData()
            // console.log('sender')
        }
    }, [databaseRoute])

    const getSenderData = () => {
        setDataToDelete()
        getService(senderUrl + '/list', true, {
            params: {
                sort, filter
            }
        }).then((
            res => {
                if (res?.status === 200) {
                    setSenderList(res?.data?.senders)
                }
            }
        ))
    }

    useEffect(() => {
        const search = setTimeout(() => {
            if (databaseRoute === 'senders') {
                getSenderData()
            }
        }, 1500);
        return () => clearTimeout(search)
    }, [filter, sort])

    const sortByAlpha = (alpha) => {
        let data = document.querySelectorAll(".label")
        let count = 0
        data.forEach((item, i) => {
            let name = item.innerText
            const filteredText = name.replace(/[^a-zA-Z0-9 ]/g, '');
            if (filteredText.charAt(0).toLocaleLowerCase().charCodeAt(0) > (alpha.charCodeAt(0) - 1)) {
                count++
                if (count === 1) {
                    item.scrollIntoView(true)
                }
                return
            }
        })
    }

    return (
        <>
            {/* <div id="pane-i"  className="card tab-pane fade" role="tabpanel" aria-labelledby="tab-i"> */}
            <div className="card-header d-md-none" role="tab" id="heading-I" onClick={() => { setDatabaseRoute('senders') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-I" aria-expanded="true" aria-controls="collapse-I">
                        Senders  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-I" className={`bgYellow collapse ${databaseRoute === 'senders' ? 'show' : ""}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-I">
                <div className="head d-none d-md-block">
                    <h4 className="heading_1 text_dark mb-0">Senders</h4>
                </div>
                <div className="p-4">
                    <p>Please enter sender information.</p>
                </div>
                <div className="p-3  pt-0 col-lg-12">

                    {/* <!-- Start search--> */}
                    <div className="col-lg-11">
                        <div className="row mb-3 mt-4">
                            <div className="col-md-6 col-xl-4">
                                <div className="serach_box">
                                    <input className="form-control" type="text" aria-label="Search" placeholder="Search Senders" onInput={(e) => setFilter(e.target.value)} value={filter} />
                                    <a><span className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-2 offset-xl-6">
                                <select className="form-select form-select-sm w_lg_75 ms-auto" onChange={(e) => setSort(e.target.value)}>
                                    <option defaultValue={''} hidden disabled>Sort</option>
                                    {sortFilter.map((data, i) => (
                                        <option key={i} value={(data).toLocaleLowerCase().replace(' ', '')}>{data}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Start search --> */}
                    {/* <!-- Start Table --> */}
                    <div className="row">
                        <div className="col-lg-11">
                            <div className="table-responsive cst_border cst_table senders_table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Address</th>
                                            <th>Cityname</th>
                                            <th>State</th>
                                            <th>Zip code</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            senderList?.map((item, i) => {
                                                return (

                                                    <tr key={i}>
                                                        <td className="label">{item.label}</td>
                                                        <td>{item.address1}</td>
                                                        <td>{item.city}</td>
                                                        <td>{item.state}</td>
                                                        <td>{item.zipcode}</td>
                                                        <td>
                                                            <a data-bs-toggle="modal" data-bs-target="#sender_name" onClick={() => setData({ 'label': item.label, 'senderId': item.senderId })}><img src="assets/img/Edit.svg" alt="" />
                                                            </a>
                                                        </td>
                                                        <td><a onClick={() => setData({ 'label': item.label, 'senderId': item.senderId })} data-bs-toggle="modal" data-bs-target="#sender_delete"><img src="assets/img/Delete.svg" alt="" /> </a></td>
                                                    </tr>

                                                )
                                            })
                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-1 d-none d-lg-block">
                            <div className="alphabet pt-lg-0 pt-md-3">
                                <div onClick={() => sortByAlpha("a")}>A - B</div>
                                <div onClick={() => sortByAlpha("c")}>C - D</div>
                                <div onClick={() => sortByAlpha("e")}>E - F</div>
                                <div onClick={() => sortByAlpha("g")}>G - H</div>
                                <div onClick={() => sortByAlpha("i")}>I - J</div>
                                <div onClick={() => sortByAlpha("k")}>K - L</div>
                                <div onClick={() => sortByAlpha("m")}>M - N</div>
                                <div onClick={() => sortByAlpha("o")}>O - P</div>
                                <div onClick={() => sortByAlpha("q")}>Q - R</div>
                                <div onClick={() => sortByAlpha("s")}>S - T</div>
                                <div onClick={() => sortByAlpha("u")}>U - V</div>
                                <div onClick={() => sortByAlpha("w")}>W - X</div>
                                <div onClick={() => sortByAlpha("y")}>Y - Z</div>
                                <div onClick={() => sortByAlpha("0")}>0 - 3</div>
                                <div onClick={() => sortByAlpha("4")}>4 - 6</div>
                                <div onClick={() => sortByAlpha("7")}>7 - 9</div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Table --> */}
                    {/* <!-- Add an Account --> */}
                    <div className="add_account mt-4">
                        <a data-bs-toggle="modal" data-bs-target="#add_sender"> <img src="assets/img/add_account.svg" alt="" /><span>Add a Sender</span></a>
                    </div>
                    {/* <!-- Add an Account --> */}
                    <div>
                    </div>
                </div>
            </div>
            {/* </div> */}
            <SenderDelete deletedata={data} senderUrl={senderUrl} getSenderData={getSenderData} />
            <SenderAdd senderUrl={senderUrl} getSenderData={getSenderData} />
            <SenderEdit data={data} setData={setData} senderUrl={senderUrl} getSenderData={getSenderData} />
        </>
    )
}

export default Senders