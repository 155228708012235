/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Mailheader from './Mailheader'
import MailBody from './MailBody'

import ImageGallery from '../deliverModule/ImageGallery'

import ForwardMailModal from './Modals/ForwardMailModal'
import NoteModal from './Modals/NoteModal'
import NoteEditModal from './Modals/NoteEditModal'
import NoteMineModal from './Modals/NoteMineModal'
import TrashMailModal from './Modals/TrashMailModal'
import SenderEdit from '../databaseModule/Modals/SenderModal/SenderEdit';
import { CommonState } from '../../../../context/commonContext'
import EmptyTrashModal from './Modals/EmptyTrashModal'
import AddFolder from './Modals/AddFolder'
import DeleteBanner from "../deliverModule/modals/DeleteBanner";
import { deleteService, getService, putService } from '../../../../services/commonApi'
import { useParams } from 'react-router-dom'
import { url as MainUrl, url } from '../../../../environment/environment'
import DeleteFolder from './Modals/DeleteFolder'
import { useSnackbar } from 'notistack'
import { getAuthToken } from '../../../../services/refreshBearerToken';
import { errorHandler } from '../../../../services/ErrorHandler';
import { postService } from '../../../../services/commonApi'
import { getLoginTimestamp } from '../../../../services/tokenService'


var axios = require('axios');

const Mail = () => 
{
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	var params = useParams();
	const [text, setText] = useState('')
	const [mailUrl,setMailUrl] = useState('')
	const [mailDetails, setMailDetails] = useState({})
	const [mailImagesList, setMailImagesList] = useState([])
	const { toggleMailMobilebar, refreshMailData, setRefreshMailData, 
			refreshFolderData, setRefreshFolderData, currentMail, setCurrentMail, currentFolder,
			setHasNewMail, folderList, setFolderList, setCurrentFolder } = CommonState()
	const [close, setClose] = useState(false)
	const navigate = useNavigate()
	const [region,setRegion] = useState(null)
	const folderurl = `/mercury/mail/mailboxes/folders`
	const [senderSelect, setSenderSelect] = useState()

	useEffect(() => 
	{
		if (sessionStorage.getItem("last_url")) 
		{
			navigate(sessionStorage.getItem("last_url"))
		}
	}, [])

	useEffect(() => 
	{
		// set an interval to check mail every two minutes.
		const interval = setInterval(() => 
		{
			updateFolderList()
		}, 120000);
		return () => clearInterval(interval);
	})	

	useEffect( ()=>
	{
		let should_open = false;
		let most_recent = null;
		let newesttime = sessionStorage.getItem("newestMailTime");
		if( newesttime != null )
		{
			most_recent = new Date( newesttime );
		}
		else
		{
			most_recent = getLoginTimestamp();
		}
		folderList.forEach((ele, i) => 
		{
			if( ele?.newestUnreadMail )
			{
				const when = new Date( ele?.newestUnreadMail );
				if( when > most_recent )
				{
					most_recent = when;
					should_open = true;
				}
			}
			if( !currentFolder && ele?.folderId === 'inbox' )
			{
				setCurrentFolder( ele )
			}
		})
		sessionStorage.setItem("newestMailTime", most_recent.toISOString() );
		if( should_open ) 
		{
			setHasNewMail( true )
		}
	}, [folderList])


	useEffect(() => 
	{
		// refresh the list of folders. It may have changed.
		updateFolderList()
		if (params?.id && params?.mailId) 
		{
			setMailImagesList([]);
			if( params?.mailId )
			{
				let cur_url = `/mercury/mail/mailboxes/folders/${params.id}/mail/${params.mailId}`;
				sessionStorage.setItem("last_url", `/mail/${params?.id}/${params.mailId}`)
				setMailUrl( cur_url )
				setCurrentMail( { folderid:params?.id, mailid:params?.mailId } )
				// get the list of mail images for the selected piece.
				getService( cur_url, true, "").then( (res) => 
				{
					setMailDetails(res.data)
					setSenderSelect({ 'label': res?.data?.senderName?.name, 'name':res?.data?.senderName, 'address':"", 'senderId': res?.data?.senderId })
					setMailImagesList(res?.data?.mailParts)
					setCurrentMail( { ...currentMail, note:res?.data?.note } )
				})
			}	
			else if( params?.id )
			{
				sessionStorage.setItem("last_url", `/mail/${params?.id}/ `)
				setMailUrl( `/mercury/mail/mailboxes/folders/${params?.id}` )
			}
		}
		else if( params?.id )
		{
			setCurrentMail( { folderid:params?.id, mailid:null } )
			setMailImagesList([]);
			setMailDetails({})
		}
	}, [params.id, params.mailId])

	const deleteMail = () => 
	{
		deleteService(`${mailUrl}/copies`, '', true).then((res) => 
		{
			if (res.status === 200) 
			{
				showAlertBox('That item has been moved to trash.')
				sessionStorage.removeItem('last_url')
				navigate(`/mail/${params?.id}`)
				setRefreshMailData(!refreshMailData)
				setRefreshFolderData(!refreshFolderData)
				setMailImagesList([]);
				setMailDetails({})
			}
		})
	}

	const printMail = async () => 
	{
		getAuthToken().then( token => 
		{
			var config = {
				method: 'get',
				url: `${MainUrl}${mailUrl}/pdf?save=true`,
				headers: {
					'Authorization': 'Bearer ' + token
				},
				responseType: 'arraybuffer'
			}
			axios(config, { validateStatus: status => { return status >= 200 && status < 300; } } ).then(res => 
			{
				var file = new Blob([res.data], { type: 'application/pdf' });
				var fileUrl = URL.createObjectURL(file);		
				var fileName = "mail.pdf";
				//open it directly 
				window.open(fileUrl, '_blank').print();
			})
			.catch( err =>
			{
				errorHandler( err );
			});
		})
		.catch( err =>
		{
			errorHandler( err );
		});
	}

	const saveMail = async () => 
	{
		getAuthToken().then( token => 
		{
			var config = {
				method: 'get',
				url: `${MainUrl}${mailUrl}/pdf?save=true`,
				headers: {
					'Authorization': 'Bearer ' + token
				},
				responseType: 'arraybuffer'
			}
			axios(config, { validateStatus: status => { return status >= 200 && status < 300; } } ).then(res => 
			{
				var file = new Blob([res.data], { type: 'application/pdf' });
				var fileUrl = URL.createObjectURL(file);		
				var fileName = "mail.pdf";
				var a = document.createElement("a");
				document.body.appendChild(a);
				a.href = fileUrl;
				a.download = fileName;
				a.click();
			})
			.catch( err =>
			{
				errorHandler( err );
			});
		})
		.catch( err =>
		{
			errorHandler( err );
		});
	}


	const deleteNote = () => 
	{
		deleteService(`${mailUrl}/copies/note`, '', true).then((res) => 
		{
			if (res.status === 200) 
			{
				mailDetails.note = null;
				setRefreshMailData(!refreshMailData)
				setCurrentMail( { ...currentMail, note:'' } )
				setClose(true)
			}
		})
	}

    const noteEdit = ( note ) => 
	{
		return new Promise( (resolve,reject) =>
		{
			let data = {
				note: note
			}
			putService( `${mailUrl}/copies/note`, data, "").then((res) => 
			{
				mailDetails.note = note;
				setRefreshMailData(!refreshMailData)
				setCurrentMail( { ...currentMail, note:note } )
				resolve(res)
			}, (err) => { reject(err) })
		});
    }	

	const requestRescanMail = () => 
	{
		putService(`${mailUrl}/copies/rescan`, '', true).then( (res) => 
		{			
			mailDetails.rescanRequestId = res?.data?.requestId;
			setRefreshMailData(!refreshMailData)
			showAlertBox('Your rescan request has been recieved.')
		}, err => 
		{
			errorHandler(err);
		});
	}

	const requestOriginalMail = () => 
	{
		putService(`${mailUrl}/copies/original`, '', true).then( (res) => 
		{
			mailDetails.originalRequested = true;
			setRefreshMailData(!refreshMailData)
			showAlertBox('Your request has been recieved.')
		}, err => 
		{
			errorHandler(err);
		});
	}

	const handleImageControl = (imageid, deg, scale) => 
	{
		putService(`${mailUrl}/images/${imageid}?rotation=${deg}&scale=${scale}`);
	}

	const updateFolderList = () =>
	{
		// refresh the list of folders. It may have changed.
		getService(`${folderurl}`, true, "").then((res) => 
		{
			setFolderList(res.data.folders)
			setRefreshFolderData(!refreshFolderData)
		}, (err) => console.log(err))
	}
	
	const moveToFolder = (folderId) => 
	{
		putService(`${mailUrl}/copies/folder?moveto=${folderId}`, "", "").then((res) => 
		{
			navigate(`/mail/${params.id}`)
			setRefreshMailData(!refreshMailData)
			setMailImagesList([]);
			setMailDetails({})
			sessionStorage.removeItem('last_url')
			document.getElementById('dropdown-basic').click()
		}, 
		(err) => 
		{
			console.log(err)
		})
	}

    const addFolder = ( folderName ) => 
	{
		return new Promise( ( resolve, reject ) =>
		{
			postService('/mercury/mail/mailboxes/folders', { name: folderName }, true).then( (res) => 
			{
				// refresh the list of folders. It may have changed.
				getService(`${folderurl}`, true, "").then((res) => 
				{
					setFolderList(res.data.folders)
					setRefreshFolderData(!refreshFolderData)
					resolve( res );
				})
				.catch( err =>
				{
					reject( err );
				})
			})
			.catch( (err) =>
			{
				reject( err );
			})
		});
    }

    const deleteFolder = ( folderid, replacementid ) => 
	{
		return new Promise( ( resolve, reject ) =>
		{
			deleteService('/mercury/mail/mailboxes/folders/' + folderid+'?replacementId='+replacementid, true).then((res) => 
			{
				// navigate away from the current folder since it is no longer valid.  goto the inbox
				if( currentFolder.folderId === folderid )
				{
					navigate(`/mail/inbox`)
				}
				// refresh the list of folders. It may have changed.
				getService(`${folderurl}`, true, "").then((res) => 
				{
					setFolderList(res.data.folders)
					setRefreshFolderData(!refreshFolderData)
					resolve( res );
				})
				.catch( err =>
				{
					reject( err );
				})
			})
			.catch( (err) =>
			{
				reject( err );
			})
		})
    }

	// const url = `/mercury/mail/mailboxes/folders/:${folderId}/mail`
	function deleteTrash(url) 
	{
		return new Promise( ( resolve, reject ) =>
		{
			// console.log("deletefunc")
			deleteService( url, "", "").then((res) => 
			{
				setRefreshFolderData(!refreshFolderData)
				setCurrentMail( { folderid:params?.id, mailid:null } )
				setMailImagesList([]);
				setMailDetails({})
				resolve( res );
			})
			.catch( (err) =>
			{
				reject( err );
			})
		})
	}

	function showAlertBox(msg) {
		const action = snackbarId => (
			<>
				<button className="btn close_btn" style={{ 'color': '#3D75B1', 'font-size': '25px' }} onClick={() => { closeSnackbar(snackbarId) }}>
					<i className="bi bi-x"></i>
				</button>
			</>
		);
		enqueueSnackbar(msg, 
		{
			variant: 'warning',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center'
			},
			autoHideDuration: 5000,
			action,
			preventDuplicate: true
		})
	}

	return (
		<div className='mailview full_height'>
		
			<div className="d-none d-sm-block full_height">
				<div id="pane-A" className="card tab-pane fade show active full_height" role="tabpanel" aria-labelledby="tab-A">
					<div id="collapse-A" className="collapse show full_height" data-bs-parent="#content" role="tabpanel"
						aria-labelledby="heading-A">
						<Mailheader mailUrl={mailUrl} mailDetails={mailDetails} saveMail={() => saveMail()} printMail={()=> printMail()} 
									rescanRequest={() => requestRescanMail() } requestOriginalMail={() => requestOriginalMail()} 
									deleteMail={() => deleteMail()} moveMail={moveToFolder} />
						<ImageGallery imageList={mailImagesList} region={region} mailUrl={mailUrl} showWrong={false} callBackFunc={()=>{}} setPropCallback={handleImageControl} />
					</div>
				</div>				
			</div>
			<AddFolder onAddFolder={addFolder} />
			<EmptyTrashModal onEmptyTrash={deleteTrash} />
			<ForwardMailModal url={mailUrl} />
			<NoteModal url={mailUrl} />
			<NoteEditModal onNoteEdit={noteEdit} url={mailUrl} />
			<NoteMineModal url={mailUrl} setText={setText} />
			<TrashMailModal />
			<DeleteBanner callBackFunction={deleteNote} close={close} setClose={setClose} />
			<DeleteFolder  onDeleteFolder={deleteFolder} />
			<SenderEdit data={senderSelect} senderUrl={'/mercury/sender'} />

		</div>
	)

}

export default Mail

