/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { url as BaseUrl } from '../../../../../environment/environment'
import { getService } from '../../../../../services/commonApi'
import { getAuthToken } from '../../../../../services/refreshBearerToken';
import { errorHandler } from "../../../../../services/ErrorHandler";


import SearchField from './SearchField'

const Generatereport = ({ cannedReportid, setCannedReportid, url }) => 
{
	const [dynamicForm, setDynamicForm] = useState()
	const [name, setName] = useState('')
	const [error,setError] = useState('')

	useEffect(() => 
	{
		if (cannedReportid?.reportId) 
		{
			getReportDetails()
		}
	}, [cannedReportid?.reportId])

	const getReportDetails = () => 
	{
		getService(url + '/' + cannedReportid?.reportId, true).then( (res) => 
		{
			if (res.status === 200) 
			{
				setName(res?.data?.title)
				setDynamicForm([...JSON.parse(res?.data?.input)])
			}
		})
	}

	useEffect(() => 
	{
		console.log(dynamicForm);
	}, [dynamicForm])

	const handleInput = (index, value) => 
	{
		console.log(index, value)
		dynamicForm[index] = { ...dynamicForm[index], value }
		setDynamicForm([...dynamicForm])
	}

	const genReport = () => 
	{
		let has_err = false;
		setError("")
		for (let i = 0; i < dynamicForm.length; i++) 
		{
			if( !dynamicForm[i]?.value )
			{
				dynamicForm[i] = { ...dynamicForm[i], 'has_error': true }
				setDynamicForm([...dynamicForm])	
				has_err = true
			}
			else if( dynamicForm[i]?.value && dynamicForm[i]?.has_error )
			{
				dynamicForm[i] = { ...dynamicForm[i], 'has_error': false }
				setDynamicForm([...dynamicForm])	
			}
		}
		if( !has_err )
		{
			getAuthToken().then( token =>
			{
				var request = new XMLHttpRequest();
				request.open('POST', `${BaseUrl}/mercury/reports/canned/${cannedReportid?.reportId}`, true);
				// request.setRequestHeader('Content-Type', 'multipart/form-data');
				request.setRequestHeader('Authorization', 'Bearer ' + token );
				request.responseType = 'blob';
				const formData = new FormData()
				for (let i = 0; i < dynamicForm.length; i++) 
				{
					const element = dynamicForm[i];
					formData.append(element?.inputvalue, element?.value)
				}
				request.send(formData);
				request.onload = function () 
				{
					// Only handle status code 200
					if (request.status === 200) {
						// Try to find out the filename from the content disposition `filename` value
						var disposition = request.getResponseHeader('content-Disposition');
						var matches = /"([^"]*)"/.exec(disposition);
						var filename = (matches != null && matches[1] ? matches[1] : 'file.csv');

						// The actual download
						var blob = new Blob([request.response], { type: 'text/csv' });
						var link = document.createElement('a');
						link.href = window.URL.createObjectURL(blob);
						link.download = filename;

						document.body.appendChild(link);

						link.click();
						document.getElementById('closegenrepomodl').click()
						document.body.removeChild(link);
					}
				};
			})
			.catch( err => 
			{
				errorHandler( err );
				setError("An error prevented the report from being generated.")
			})
		}
		else
		{
			setError("Some fields are missing values.  Please supply a value and try again.")
		}
	}

	const resetForm = () => 
	{
		setDynamicForm([])
		setCannedReportid()
		setName('')
		setError('')
	}


	return (
		// <!--Modal Generate report-- >
		<div className="modal fade generate_report" id="generate_report" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content">
					<div className="modal-body p-4">
						{error && (
							<div className="alert alert-danger ctm_alert d-none d-lg-block" role="alert">
								<span className="me-3">
									<img src="/assets/img/error_icon_white.png" className="img-fluid" alt=""/>
								</span>{" "}
								{error}
							</div>
						)}
						<h2 className="heading_1 mb-3">Generate report</h2>
						<p>{name}</p>
						<div className="row">
							{dynamicForm?.map((field, i) => 
							(
								['string', 'number', 'date'].includes(field.inputtype) ?
									<div key={i} className="fm_grp col-md-12">
										<label htmlFor={`field${i}`} className="form-label">{field?.inputlabel}</label>
										<input type={field?.inputtype} className={`form-control ${field?.has_error ? 'report_error_input' : ''}`} id={`field${i}`} onChange={(e) => handleInput(i, e.target.value)} value={field?.value} />
									</div> :
									<SearchField index={i} inputData={field} dynamicForm={dynamicForm} setDynamicForm={setDynamicForm} />
							))}
							<div className="d-flex flex-wrap align-items-center justify-content-end mt-5">
								<div className="text-end">
									<button type="button" className="btn btn-link btn_link_primary" onClick={() => resetForm()} id='closegenrepomodl' data-bs-dismiss="modal">Cancel</button>
									<button type="button" onClick={() => genReport()} className="btn btn_cstm_primary px-3">Generate</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}



export default Generatereport
