/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';

const HelpItem = ({ data, setUrl }) => {

    return (
        <>
				<div className='search_results_item' id='help_search_results'>
					<span className="d-flex">
						<p><b>Help Topics</b></p>
					</span>
					{data?.map((ele,i) => (
						<Dropdown.Item key={i} className="help_box" as={'div'} onClick={() => setUrl(ele?.url)} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" >
							<span className="d-flex" >
								<b>{ele?.title}</b>
							</span>
							<p dangerouslySetInnerHTML={{ __html: ele?.textMatch }}></p>
						</Dropdown.Item>
					))}
				</div>
        </>
    )
}

export default HelpItem