/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { getService, postService, putService } from '../../../../../../services/commonApi'

export default function MailStopAdd({ url, getList }) {
    const [error, setError] = useState(false)
    const [inputName, setInputName] = useState()
    const [load, setLoad] = useState(false)


    const checkValidate = () => {
        // if (inputName) { setError(false) }
        // else { setError(true) }
        setLoad(true)
        submit_form()
    }


    const reset_form = () => {
        setInputName(" ")
    }

    const submit_form = () => {
        let data = {
            name: inputName,
        }
        postService(url, data, true)
            .then(
                (res) => {
                    // console.log('res', res)
                    if (res?.request?.status === 200) {
                        getList()
                        document.getElementById('closeAddMailStopModal').click()
                    }
                    else {
                        return setError(res?.request.statusText)
                    }
                }, err => { return setError(err?.request.statusText) });
    }



    return (
        <>
            {/* Modal Mail Stops  */}
            <div className="modal fade mail_stops" id="mail_stops" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body py-4 px-lg-0">
                            <h2 className="heading_1 ps-lg-4">Add Mail Stop</h2>
                            {!inputName && load ?
                                <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                                    <span className="me-3">
                                        <img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" />
                                    </span>
                                    Departments.
                                </div>
                                : ""
                            }
                            <div className="p-lg-5">
                                <div className="fm_grp">
                                    <label htmlFor="inputAddress" className="form-label" required>Add the following Mail Stop:</label>
                                    <input type="text" className={`form-control ${(inputName || !load) ? "" : "error_input"}`} onChange={(e) => setInputName(e.target.value)} value={inputName} id="inputAddress" />
                                </div>
                                <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
                                    <div> </div>
                                    <div className=" text-end">
                                        <button id="closeAddMailStopModal" onClick={reset_form} type="button" className="btn btn-link btn_link_primary me-3" data-bs-dismiss="modal">Cancel</button>
                                        <button type="button" onClick={checkValidate} className="btn btn_cstm_primary px-4">Add Mail Stop</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Mail Stops */}

        </>
    )
}
