import React, { useState } from 'react'
import { putService } from '../../../../../services/commonApi';

const RestartProcess = ({ refreshList, url }) => 
{
	const [batchId, setBatchId] = useState('')
	const [error, setError] = useState(false)
	// "^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$"

	// closerestartModal
	const restartMail = () => 
	{
		const result = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(batchId);
		if( batchId && !result ) 
		{
			return setError(true)
		}
		putService(url, '', true, 
		{
			params: {
				batchId
			}
		}).then( (res) => 
		{
			document.getElementById('closerestartModal').click()
			resetData()
			refreshList()
		})
	}

	function resetData() 
	{
		setBatchId('')
		setError(false)
	}

	return (
		<div className="offcanvas offcanvas-top Restart_process" data-bs-backdrop="false" id="Restart_process" tabIndex="-1" aria-labelledby="Restart_process" aria-hidden="true">
			<div className="offcanvas-body px-5 py-4">
				<div className="row align-items-center">
					<div className="col-lg-4">
						<div>
							<p><b>Restart process</b></p>
							<p>Please enter the batch number from the first image or the batch page.</p>
						</div>
					</div>
					<div className="col-lg-5 my-4 my-lg-0">
						<div className={`text-center ${error ? 'error_inupt' : ''}`}>
							<input type="text" className="form-control text-center" id="inputAddress" placeholder="-      -       -       -" 
												onChange={(e) => setBatchId(e.target.value)} value={batchId} />
						</div>
					</div>
					<div className="col-lg-3 text-lg-end">
						<div>
							<button type="button" className="btn btn-link btn_link_primary" data-bs-dismiss="offcanvas" id="closerestartModal" onClick={() => resetData()}>Cancel</button>
							<button type="button" className="btn btn_cstm_primary px-4" onClick={() => restartMail()}>Restart</button>
						</div>
					</div>
				</div>
				{/* <div className="modal-content">
					<div className="modal-body px-5 py-4">
					</div>
				</div> */}
			</div>
		</div>
	)
}

export default RestartProcess