/* eslint-disable no-unused-vars */
import React, { useState } from 'react'

const ResetPass = () => {

    return (
        <div className="modal fade forget_password" id="exampleModalToggle3" aria-hidden="true" aria-labelledby="exampleModalToggleLabel3" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">

                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h5 className="title">Forgot Password </h5>
                    </div>
                    <div className="modal-body">
                        <h6>Your password reset request has been successfully received.</h6><p>You will receive your new password by <b>email</b>.  If not, contact your <b>administrator</b> to reset your password. </p>
                    </div>
                    <div className="modal-footer border-0">
                        {/* <button type="button"  className="btn btn-link btn_link_primary" data-bs-dismiss="modal">Cancel</button> */}
                        <button className="btn btn_cstm_primary px-4" data-bs-dismiss="modal">Okay</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPass