/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Combobox from "react-widgets/Combobox";
import { getconfiguration } from '../../../../../../../services/AuthApi';
import { setCompanyIdToken } from '../../../../../../../services/tokenService';

const Name = ({ navLink,
	setNavLink,
	error,
	setError,
	nameInput,
	setNameInput, mode, resetData }) => {

	const [firstName, setFirst_name] = useState('');
	const [title, setTitle] = useState('');
	const [middleName, setMiddle_name] = useState('');
	const [lastName, setLast_name] = useState('');
	const [maidenName, setMaiden_name] = useState('');
	const [suffix, setSuffix] = useState('');
	const [nickNames, setAlternate] = useState([]);
	const [submit, setSubmit] = useState(false)
	const [titlesList, setTitlesList] = useState([])
	const [suffixList, setSuffixList] = useState([])

	const getConfigurationData = () => 
	{
		getconfiguration().then((result) => 
		{
			console.log(result)
			setCompanyIdToken(result?.companyId)
			setTitlesList(result?.displaySettings?.titles)
			setSuffixList(result?.displaySettings?.suffixes)
		})
	}

	useEffect(() => 
	{
		getConfigurationData()
	}, [])

	useEffect(() => 
	{
		setNameInput({ firstName, title, middleName, lastName, maidenName, suffix, 'nickNames': nickNames?.length > 0 ? nickNames.split('\n') : [], });
	}, [firstName, middleName, maidenName, lastName, nickNames, title, suffix])



	useEffect(() => 
	{
		setFirst_name(nameInput?.firstName?nameInput?.firstName:'')
		setTitle(nameInput?.title?nameInput?.title:'')
		setMiddle_name(nameInput?.middleName?nameInput?.middleName:'')
		setLast_name(nameInput?.lastName?nameInput?.lastName:'')
		setMaiden_name(nameInput?.maidenName?nameInput?.maidenName:'')
		setSuffix(nameInput?.suffix?nameInput?.suffix:'')
		setAlternate(nameInput?.nickNames ? nameInput?.nickNames?.join('\n') : [])
	}, [nameInput?.employeeId])

	const setNameInputData = () => 
	{
		setSubmit(true)
		setNameInput({ firstName, title, middleName, lastName, maidenName, suffix, 'nickNames': nickNames.length > 0 ? nickNames?.split('\n') : [], });
		if (!firstName || !lastName) 
		{
			if (!firstName && lastName) 
			{
				return setError({ err:true, desc:"First name is required", firstName:true });
			}
			else if (!lastName && firstName) 
			{
				return setError({ err:true, desc:"Last name is required", lastName:true });
			}
			return setError({ err:true, desc:"First and last name are required", lastName:true,firstName:true });
		}
		setError({ err:false, desc:"" });
		setNavLink("company");
	};


	return (
		<div className={`tab-pane fade ${navLink === "name" ? "show active" : ""}`} aria-labelledby="v-pills-home-tab" >
			<div className="col-lg-9 offset-lg-1 modalContentItems">
				{mode === 'request' && (<> <h2 className="heading_1">Account requester information</h2>
					<p className="mb-0">
						Please review all the following information before process your request.
					</p>
					<p className="fnt_w_bold">Please be as thorough as possible.</p></>)}
				<form className=" pt-4">
					<div className="row g-3">
						<div className="fm_grp col-md-6">
							<label htmlFor="Firstname" className={`form-label ${error?.firstName && !firstName ? 'error_text' : ''}`}>
								* First name {submit && !firstName && <span>is required</span>}
							</label>
							<input type="text" className={`form-control ${error?.firstName && !firstName ? 'error_input' : ''}`}
								id="Firstname" onInput={(e) => setFirst_name(e.target.value)} value={firstName} />
						</div>
						<div className="fm_grp col-md-6">
							<label htmlFor="inputTitle" className="form-label">
								Title
							</label>

							<Combobox
								value={title}
								className="form-control"
								id="inputTitle"
								onChange={(e) => { setTitle(e) }}
								// onInput={(e) => console.log(e)}
								data={titlesList}
							/>
						</div>
						<div className="fm_grp col-md-6">
							<div className="mb-3">
								<label htmlFor="Middlename" className="form-label">
									Middle name
								</label>
								<input type="text" className="form-control" id="Middlename" onInput={(e) => setMiddle_name(e.target.value)} value={middleName} />
							</div>

							<div>
								<label htmlFor="inputLastName" className={`form-label ${submit && !lastName ? 'error_text' : ''}`}>
									* Last name {error?.lastName && !lastName && <span>is required</span>}
								</label>
								<input type="text" className={`form-control ${error?.lastName && !lastName ? 'error_input' : ''}`}
									id="inputLastName" onInput={(e) => setLast_name(e.target.value)} value={lastName}
								/>
							</div>
						</div>
						<div className="fm_grp col-md-6">
							<label htmlFor="inputAltFirstName" className="form-label">
								Alternate first names (1 per line)
							</label>
							<textarea className="form-control" id="inputAltFirstName" rows="5" onInput={(e) => setAlternate(e.target.value)} value={nickNames}></textarea>
						</div>

						<div className="fm_grp col-md-6">
							<label htmlFor="inputMaiden" className="form-label">
								Previous Last Name
							</label>
							<input
								type="text"
								className="form-control"
								id="inputMaiden"
								onInput={(e) => setMaiden_name(e.target.value)}
								value={maidenName}
							/>
						</div>
						<div className="fm_grp col-md-6">
							<label htmlFor="inputSuffix" className="form-label">
								Suffix
							</label>

							<Combobox
								value={suffix}
								id="inputSuffix"
								className="form-control"
								onChange={(e) => { setSuffix(e) }}
								data={suffixList}
							/>

						</div>
					</div>
				</form>
			</div>
			<div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
				<div className=""></div>
				<div className="p-md-4 text-end">
					<button
						type="button"
						className="btn btn-link  btn_link_primary me-3"
						data-bs-dismiss="modal"
						onClick={() => { setSubmit(false); setError({ err:false, desc:"" }); resetData() }}
					>
						Cancel
					</button>
					{(!mode || mode === 'request') && <button
						type="button"
						className="btn btn_cstm_primary px-4"
						onClick={() => setNameInputData()}
					>
						Next
					</button>}
					{mode === 'edit' && <button
						type="button"
						className="btn btn_cstm_primary px-4"
						onClick={() => setNameInputData()}
					>
						Save edits
					</button>}
				</div>
			</div>
		</div>
	)
}

export default Name