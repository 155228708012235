/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { RouteState } from '../../../../context/routeContext'
import './imagegallery.css'
import { url as MainUrl } from '../../../../environment/environment';

import EvaluateRules from './modals/EvaluateRules'
import SenderEdit from '../databaseModule/Modals/SenderModal/SenderEdit'
import { deleteService, getService, putService } from '../../../../services/commonApi'
import ImageGallery from './ImageGallery';
import Dropdown from 'react-bootstrap/Dropdown';
import { CommonState } from '../../../../context/commonContext';
import { generateReceiverList, generateSenderList, filterExistData, filterFirstChoiceData, filterSearch } from './UnassignedMailSearchListLogic';
import SenderAdd from '../databaseModule/Modals/SenderModal/SenderAdd';
import { errorHandler } from '../../../../services/ErrorHandler';

import './UnassignedMail.css'

const UnassignedMail = () => {

	var imageRef = useRef()
//	var url = useRef()
	const [url,setUrl] = useState('')
	var senderUrl = '/mercury/sender'
	const { deliveryRoute, setDeliveryRoute } = RouteState()

	const [mailList, setMailList] = useState([])
	const [mailDetail, setMailDetail] = useState({})
	const [mailDetailBeforeChange, setMailDetailBeforeChange] = useState({})

	const [senders, setSenders] = useState([])
	const [sendersList, setSendersList] = useState([])
	const [senderSelect, setSenderSelect] = useState('')
	const [senderSearch, setSenderSearch] = useState('')
	const [sender1stChoice, setSender1stChoice] = useState()

	const [receivers,setReceivers] = useState([])
	const [selectedReceiver,setSelectedReceiver] = useState(null)
	const [selectedSender,setSelectedSender] = useState(null)
	const [region,setRegion] = useState(null);

	const [receiversList, setReceiversList] = useState([])
	const [receiverSelect, setReceiverSelect] = useState('')
	const [receiverSearch, setReceiverSearch] = useState('')
	const [receiver1stChoice, setReceiver1stChoice] = useState()

	const [mailImagesList, setMailImagesList] = useState([])

	const [skippedDetails, setSkippedDetails] = useState([])

	const [currentIndex, setCurrentIndex] = useState(-1)

	const [loader, setLoader] = useState(false)
	const [assignRuleList, setAssignRuleList] = useState([])

	const [rules, setRules] = useState('')
	const { refreshMailStats, setRefreshMailStats } = CommonState()

	const [searchReceipient, setSearchReceipient] = useState(false)
	const [searchSender, setSearchSender] = useState(false)

	const [receiversSearchList, setReceiversSearchList] = useState([])
	const [sendersSearchList, setSendersSearchList] = useState([])

	const [allErrorCheck, setAllErrorCheck] = useState(false)

	const [receiverSearchSelectList, setReceiverSearchSelectList] = useState([])
	const [senderSearchSelectList, setSenderSearchSelectList] = useState([])

	const [senderChange, setSenderChange] = useState(false)
	const [receiverChange, setReceiverChange] = useState(false)


	const [assignData, setAssignDataa] = useState({
		"senderId": null,
		"receiverId": null,
		"positionId": null,
		"departmentId": null,
		"companyNameId": null
	})

	useEffect(() => {
		if (deliveryRoute === 'unassigned') {
			getUnassignedMailList()
			getRulesList()
		}
	}, [deliveryRoute])

	useEffect(() =>
	{
		if( selectedSender && selectedSender?.id && selectedReceiver && ( selectedReceiver?.receiver?.id || 
				selectedReceiver?.position?.id || selectedReceiver?.department?.id || selectedReceiver?.companyName?.id )  )
		{
			setAllErrorCheck( true );
		}
		else
		{
			setAllErrorCheck( false )
		}
	}, [ selectedReceiver, selectedSender ])

	useEffect(() =>
	{
		if( selectedReceiver && selectedReceiver.block && mailImagesList )
		{
			mailImagesList.forEach( (element,page) =>
			{
				if( selectedReceiver.block.imageId === element.imageId )		
				{
					// scroll to the page that the item is on.
					setRegion( 
					{ 
						page: page, 
						topleft: { x: selectedReceiver.block.upperX, y: selectedReceiver.block.upperY },
						bottomright: { x: selectedReceiver.block.lowerX, y: selectedReceiver.block.lowerY },
						text: ( selectedReceiver.block.lineText ? selectedReceiver.block.lineText.map( text => text.line ) : [] )
					});
				}
			})
		}
	}, [ selectedReceiver ])

	const getUnassignedMailList = ( ) => 
	{
		getService('/mercury/mail/unassigned', true, "").then((res) => 
		{
			setMailList(res?.data?.messages)
			if (res?.data?.messages?.length === 0) 
			{
				setMailDetail({})
				setSendersList([])
				setReceiversList([])
				setMailImagesList([])
				setCurrentIndex(-1)
			}
			else if( currentIndex === -1 )
			{
				setCurrentIndex(0);
			}
		})
	}

	// get the details of the piece of mail at the current selected piece
	useEffect(() => 
	{
		if( currentIndex >= 0 && currentIndex < mailList?.length ) 
		{
			getMailDetail(currentIndex)
		}
	}, [mailList,currentIndex])

	const getMailDetail = (index) => 
	{
		setMailImagesList([])
		let curr_url = `/mercury/mail/unassigned/${mailList[index]?.assignId}`
		setUrl( `/mercury/mail/unassigned/${mailList[index]?.assignId}` )
		getService( curr_url, true, "").then( (res) => 
		{
			setMailDetail(res.data)
			setMailDetailBeforeChange(res.data)

			resetSearchData()

			setSendersList( res.data.senders ? [...res.data?.senders] : [] )

			if( !res.data?.senderName ) 
			{
				setSearchSender(true)
			}
			setReceivers( generateReceiverList( res?.data ))
			setSenders( generateSenderList( res?.data ))
			setSelectedReceiver(null)
			setSelectedSender(null)
			setMailImagesList(res.data.mailParts)

			setSender1stChoice({ ...res.data?.senderName, 'senderId': res.data?.senderId })
		})
	}

	const assignMail = () => 
	{
		if( selectedReceiver != null && (  selectedReceiver?.receiver?.id || selectedReceiver?.position?.id || selectedReceiver?.department?.id || 
					selectedReceiver?.companyName?.id ) && selectedSender?.id )
		{
			let assign = 
			{
				"senderId": selectedSender?.id ? selectedSender?.id : null,
				"receiverId": selectedReceiver?.receiver?.id ? selectedReceiver?.receiver?.id : null,
				"positionId": selectedReceiver?.position?.id ? selectedReceiver?.position?.id : null,
				"departmentId": selectedReceiver?.department?.id ? selectedReceiver?.department?.id : null,
				"companyNameId": selectedReceiver?.companyName?.id ? selectedReceiver?.companyName?.id : null,
				"addressId" : selectedReceiver?.address?.id ? selectedReceiver?.address?.id : null
			};
			putService(url, assign, true).then( (res) => 
			{
				if( mailList.length > 1 )
				{
					setCurrentIndex( currentIndex <= mailList?.length - 1 ? currentIndex : currentIndex-1 )
					setMailImagesList([])
					resetSearchData()
					setRefreshMailStats(!refreshMailStats)
					getUnassignedMailList()
				}
				else
				{
					setReceivers([])
					setSenders([])
					setCurrentIndex( -1 )
					setMailDetail({})
					setMailImagesList([])
					resetSearchData()
					setRefreshMailStats(!refreshMailStats)
					getUnassignedMailList()
				}
			})
			.catch( (err) => 
			{
				errorHandler( err )
			})
		}
	}

	const getReason = (value) => 
	{
		let reason = ''
		switch (value) {
			case 'N':
				reason = 'Insufficient match information (nomatch)'
				break;
			case 'D':
				reason = 'Ambiguous choices available (duplicate)'
				break;
			case 'Z':
				reason = 'No company addresses found'
				break;
			case 'I':
				reason = 'Incorrect Assignment. Sent back for reassignment'
				break;
			case 'E':
				reason = 'Match found but poor choice'
				break;
			default:
				break;
		}
		return reason
	}

	const getRulesList = () => 
	{
		getService('/mercury/mail/assignmentrules', true, "").then((res) => 
		{
			setAssignRuleList(res?.data?.rules)
		})
	}

	const [ruleTextData, setRuleTextData] = useState()

	const evaluateMail = () => 
	{
		setReceiversSearchList([])
		setSearchReceipient(false)
		setReceiverChange( false )
		setSendersSearchList([])
		setSearchSender(false)
		setSenderChange( false )
		getService(url + '/rules', true, { params: { rules } }).then( (res) => 
		{
			let changes = detectChanges(res?.data);
			if( changes.receiverChange )
			{
				setReceiversSearchList(generateReceiverList(res?.data))
				setSearchReceipient(true)
			}
			if( changes.senderChange )
			{
				let item = {
					id: res.data?.senderId,
					name: res.data?.senderName.name,
					address: res.data?.senderAddress
				};
				setSendersSearchList( [ item ] )	
				setSearchSender(true)
			}
			setRefreshMailStats(!refreshMailStats)
			setRuleTextData(res?.data)
//			setSendersList(res.data?.senders ? [...res.data?.senders] : [])
//			setSender1stChoice(res.data?.senderName)
//			setReceiver1stChoice(...filterFirstChoiceData(res.data?.receiverName, res.data.positionName, res.data.departmentName, res.data.companyName))
//			setReceiversList(res.data?.receivers ? [...filterExistData(res.data?.receivers)] : [])
//			console.log(res?.data)
		})
	}

	const removePieceOfmail = () => 
	{
		deleteService(`${url}`, '', true, { params: { delete: false } }).then( res => 
		{
			if( currentIndex+1 > 0 && currentIndex <= mailList.length )
			{
				setCurrentIndex( currentIndex + 1 )
				setMailImagesList([])
				resetSearchData()
				setRefreshMailStats(!refreshMailStats)
				getUnassignedMailList()
			}
			else
			{
				setCurrentIndex( -1 )
				setMailDetail({})
				setMailImagesList([])
				resetSearchData()
				setRefreshMailStats(!refreshMailStats)
				getUnassignedMailList()
			}
		})
	}

	useEffect(() => {
		const search = setTimeout(() => {
			if (deliveryRoute === 'unassigned' && receiverSearch) {
				searchReceipientFunc()
			}
		}, 1500);
		return () => clearTimeout(search)
	}, [receiverSearch])

	const searchReceipientFunc = () => 
	{
		if( receiverSearch?.length > 0 )
		{
			getService('/mercury/search/resources', true, {
				params: {
					filter: receiverSearch,
					include_type: 'companyname,department,position,employee,address',
					separator: "\n",
					start: 1, 
					stop: 10
				}
			}).then( res => 
			{
				setReceiversSearchList(filterSearch(res?.data))
			})
		}
	}

	useEffect(() => 
	{
		const search = setTimeout(() => 
		{
			if (deliveryRoute === 'unassigned' && senderSearch) 
			{
				searchSenderFunc()
			}
		}, 1500);
		return () => clearTimeout(search)
	}, [senderSearch])

	const searchSenderFunc = () => 
	{
		getService('/mercury/search/resources', true, {
			params: {
				filter: senderSearch,
				include_type: 'sender'
			}
		}).then( res => 
		{
			let list = [];
			let index = 0;
			// console.log(res)
			res?.data?.matches[0]?.list?.forEach( (item,i) => 
			{
				if( item.senderId )
				{
					list[index++] = {
						id: item.senderId,
						name: item.label,
						address: ( item.addresses?.length > 0 ? item.addresses[0] : null )
					};
				}
			})
			setSendersSearchList( list )
		})
	}

	function checkValues() 
	{
		if ( assignData?.senderId && (assignData?.companyNameId || assignData?.departmentId || assignData?.receiverId || assignData?.positionId)) 
		{
			setAllErrorCheck(true)
		} 
		else 
		{
			setAllErrorCheck(false)
		}
	}

	function isReceiverChange( data )
	{
		let retval = false;
		let receiverChange = mailDetail?.receiverId !== data?.receiverId
		let companyChange = mailDetail?.companyNameId !== data?.companyNameId
		let positionChange = mailDetail?.positionId !== data?.positionId
		let departmentChange = mailDetail?.departmentId !== data?.departmentId
		if (receiverChange || companyChange || positionChange || departmentChange) 
		{
			setReceiverChange(true)
			retval = true;
		}
		return retval;
	}

	function isSenderChange( data )
	{
		let retval = false;
		if( mailDetail?.senderId !== data?.senderId ) 
		{
			setSenderChange(true)
			retval = true;
		}
		return retval;
	}


	function detectChanges(data) 
	{
		let retval =
		{
			receiverChange: isReceiverChange( data ),
			senderChange: isSenderChange( data )
		}
		document.getElementById('evaluatePopu').click()
		return retval;
	}

	function resetSearchData() {
		setReceiversSearchList([])
		setSendersSearchList([])

		setSelectedReceiver(null); 
		setSearchReceipient(false)
		setSearchSender(false)

		setReceiverSearch('')
		setSenderSearch('')

		setSenderSearchSelectList([])
		setReceiverSearchSelectList([])

		setRegion(null)
	}

	function setSkipstate() {
		skippedDetails[currentIndex] = { ...mailDetail, index: currentIndex }
	}

	function moveToIndex(i) 
	{
		setCurrentIndex( i )
		setSkippedDetails( skippedDetails.splice(0, i))
	}

	return (
		<>
			<div className="card-header d-md-none" role="tab" id="heading-B" onClick={() => { setDeliveryRoute('unassigned') }}>
				<h5 className="mb-0">
					<a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-B" aria-expanded="true" aria-controls="collapse-B">
						Unassigned Mail  <span> <i className="bi bi-chevron-down"></i></span>
					</a>
				</h5>
			</div>
			<div id="collapse-B" className={`bgYellow collapse ${deliveryRoute === "unassigned" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
				aria-labelledby="heading-B">
				<div className="head mt-2 mt-lg-0">
					<div className={`row ${mailList?.length < 1 && 'mailHeadDisable'}`}>
						<div className="col-12 col-xl-6">
							{mailList?.length > 0 ?
								<p className="text_dark mb-0">Item {currentIndex + 1} of {mailList?.length}, received on {new Date(mailDetail?.received).toLocaleDateString()} at {new Date(mailDetail?.received).toLocaleTimeString()}</p> :
								<p className="text-dark mb-0">No mail left</p>
							}

						</div>
						<div className="col-12 col-xl-2 offset-xl-4">
							<div className="btn_link d-flex justify-content-end">

								{/* <!-- Dropdown --> */}
								<Dropdown>
									{skippedDetails.length > 0 && <Dropdown.Toggle as={'a'} variant="none" align="end" id="dropdown-basic">
											<i className="bi bi-caret-right-fill"></i> Skipped ({skippedDetails?.length})
										</Dropdown.Toggle>
									}
									<Dropdown.Menu as={'ul'} className="Skipped_drp">
										{skippedDetails?.map((data, i) => (
											<Dropdown.Item key={i} as={'li'}>
													<a onClick={() => { moveToIndex(data?.index) }}>
														<span>
															<p><b>{data?.index + 1}. Skipped</b></p>
															<p>{new Date(data?.received).toLocaleDateString()} @ {new Date(data?.received).toLocaleTimeString()}</p>
														</span>
														<span>
															<img className="ms-1" src="assets/img/arrow-right.png" alt="" />
														</span>
													</a>
											</Dropdown.Item>
										))}
									</Dropdown.Menu>
								</Dropdown>
								{currentIndex + 1 !== mailList?.length && 
									<a className="ms-3 fnt_16" onClick={() => { setSkipstate(); setCurrentIndex(currentIndex + 1) }}>Skip 
										<img className="ms-1" src="assets/img/arrow-right.png" alt="" />
									</a>
								}
							</div>
						</div>
					</div>
				</div>

				<div className="p-2">
					<div className="row">
						<div className="col-lg-9">
							{/* <!-- Add doc sheet area --> */}
							<p className="mb-0 mt-4">{mailDetail?.reason ? `${getReason(mailDetail?.reason)}:` : '' }</p>

							<ImageGallery imageList={mailImagesList} region={region} mailUrl={url} showWrong={true} callBackFunc={removePieceOfmail} />

							{/* <!-- Add doc sheet area --> */}
						</div>

						<div className="col-lg-3">
							<div className="right_box mt-4 pt-1">
								{!searchReceipient ?
									<>
										<p className="mb-3 d-flex justify-content-between">
											<span><b>Recipient</b> Best guesses:</span>
											<a onClick={() => setSearchReceipient(true)}> <img src="assets/img/serach_dark.svg" alt="" /></a>
										</p>
										<div className="inner_right_box py-0">
											<ul className={'assign-list'}>
												{ receivers.length>0 && receivers.map( (recipient,i) => 
												
													<li key={i} className={`receiver-list-item ${selectedReceiver === recipient ? 'selected' : '' }`} onClick={() => setSelectedReceiver(recipient) }>
															<>
																{ recipient && <div><span className={'split-left'} ><strong>{ i === 0 ? 'Preferred' : 'Alternate' } Choice</strong></span><span className={'split-right'} ><em>
																			{ i === 0 ? ` Confidence: ${Math.round(recipient.confidence*100)}%` : '' }</em></span></div> }
																{recipient.receiver && <div><span className={'split-left'} >{recipient.receiver.name}</span><span className={'split-right'} ><em>Employee {Math.round(recipient.receiver.confidence*100)}%</em></span></div> }
																{recipient.position && <div><span className={'split-left'} >{recipient.position.name}</span><span className={'split-right'} ><em>Position {Math.round(recipient.position.confidence*100)}%</em></span></div> }
																{recipient.department && <div><span className={'split-left'} >{recipient.department.name}</span><span className={'split-right'} ><em>Department {Math.round(recipient.department.confidence*100)}%</em></span></div>}
																{recipient.companyName && <div><span className={'split-left'} >{recipient.companyName.name}</span><span className={'split-right'} ><em>Company {Math.round(recipient.companyName.confidence*100)}%</em></span></div>}
																{recipient.address && <div><span className={'split-left'} >{recipient.address.address1 + ' ' +recipient.address.city +' '+recipient.address.state+' '+recipient.address.zipcode }</span></div>}
															</>
													</li>
												)}
												{ receivers.length === 0 &&
													<li>No Addresses Were Found</li>
												}
											</ul>
										</div>
									</>
									:
									<>
										<p className="mb-3 d-flex justify-content-between">
											<span><b>Recipient</b></span> <img src="/assets/img/serach_dark.svg" alt="" />
										</p>
										<div className="serach_box unassigned_search">
											<textarea className="form-control" rows={3} type="text" onChange={(e) => setReceiverSearch(e.target.value)} value={receiverSearch} aria-label="Search" placeholder="Search For..." />
											<a href="#" className="search_close_icon" onClick={ resetSearchData }> <i className="bi bi-x"></i></a>
										</div>

										<div className="inner_right_box py-0">
											<ul className={'assign-list'}>
												{ receiversSearchList.length>0 && receiversSearchList.map( (recipient,j) => 
													
													<li key={j} className={`receiver-list-item ${ selectedReceiver === recipient ? 'selected' : '' }`} onClick={() => setSelectedReceiver(recipient) }>
															<>
																{recipient.receiver && <div><span className={'split-left'} >{recipient.receiver.name}</span><span className={'split-right'} ><em>Employee</em></span></div> }
																{recipient.position && <div><span className={'split-left'} >{recipient.position.name}</span><span className={'split-right'} ><em>Position</em></span></div> }
																{recipient.department && <div><span className={'split-left'} >{recipient.department.name}</span><span className={'split-right'} ><em>Department</em></span></div>}
																{recipient.companyName && <div><span className={'split-left'} >{recipient.companyName.name}</span><span className={'split-right'} ><em>Company</em></span></div>}
																{recipient.address && <div><span className={'split-left'} >{recipient.address.address1 + ' ' +recipient.address.city +' '+recipient.address.state+' '+recipient.address.zipcode }</span></div>}
															</>
													</li>
												)}
												{ receivers.length === 0 &&
													<li>No Results Were Returned</li>
												}
											</ul>
										</div>
									</>
								}
							</div>
							<div className="right_box mt-4 pt-1">
								{!searchSender ?
									<>
										<p className="mb-3 d-flex justify-content-between">
											<span><b>Sender</b> Best guesses:</span><a onClick={() => setSearchSender(true)}><img src="assets/img/serach_dark.svg" alt="" /></a>
										</p>
										<div className="inner_right_box py-0">
											<ul className={'assign-list'} >
												{ senders.length>0 && senders.map( (sender,i ) =>
													<li key={i} className={`sender-list-item ${selectedSender === sender ? 'selected' : '' }`} onClick={() => setSelectedSender(sender) }>
													<>
														{ sender && <div><span className={'split-left'} ><strong>{ i === 0 ? 'Preferred' : 'Alternate' } Choice</strong></span><span className={'split-right'} ><em>
																	{ i === 0 ? ` Confidence: ${Math.round(sender.confidence*100)}%` : '' }</em></span></div> }
														{sender.name && <div><span className={'split-left'} >{sender.name}</span><span className={'split-right'} ></span></div> }
														{sender.address && <div><span className={'split-left'} >{ (sender.address.address1?sender.address.address1:'') + ' ' 
																+(sender.address.city?sender.address.city:'') +' '+(sender.address.state?sender.address.state:'')+' '
																+(sender.address.zip?sender.address.zip:'') }</span></div>}
													</>
													</li>
												)}
												{ senders.length === 0 &&
													<li>No Sender Information Was Found</li>
												}

											</ul>
										</div>
									</>
									:
									<>
										<p className="mb-3 d-flex justify-content-between"><b>Sender</b> <img src="/assets/img/serach_dark.svg" alt="" /></p>

										<div className="serach_box unassigned_search">
											<input className="form-control" type="text" onChange={(e) => setSenderSearch(e.target.value)} value={senderSearch} aria-label="Search" placeholder="John" />
											<a href="#">
												<span className="search_cion"><img src="/assets/img/search_gray.svg" alt="" /></span>
											</a>
											<a href="#" className="search_close_icon" onClick={() => { setSelectedSender(null); setSearchSender(false); setSenderSearch('') }}> <i className="bi bi-x"></i></a>
										</div>
										<div className="inner_right_box py-0">
											<ul className={'assign-list'} >
												{sendersSearchList?.map((data, i) => (
													<li key={i} className={`sender-list-item ${selectedSender === data ? 'selected' : '' }`} onClick={() => setSelectedSender(data) }>
														<>
															<div><span className={'split-left'} >{data.name}</span></div>
															<div><span className={'split-left'} >{ (data.address.address1?data.address.address1:'') + ' ' 
																	+(data.address.city?data.address.city:'') +' '+(data.address.state?data.address.state:'')+' '
																	+(data.address.zip?data.address.zip:'') } </span></div>
														</>
													</li>

												))}
											</ul>
										</div>
										<div className="add_items mt-2">
											<a href="#" data-bs-toggle="modal" data-bs-target="#add_sender"> <img src="assets/img/add_account.svg" alt="" /><span className="ms-2 text_dark">Add sender</span></a>
										</div>
									</>
								}
							</div>

							<div className="mt-4 pt-1">
								<p className="mb-3 d-flex justify-content-between">
									<span><b>Rules</b></span>
								</p>
								<div className="fm_grp mb-0">
									<select className="form-select form-select-sm" onChange={(e) => { setRules(e.target.value) }}>
										<option value={''}>All rule</option>
										{assignRuleList?.map((data,i) => (
											<option key={i} value={data?.id}>{data?.name}</option>
										))}
									</select>
								</div>
								<div className="btn_link text-end">
									<a href="#" onClick={() => evaluateMail()}> Evaluate rule</a>
									<a data-bs-toggle="offcanvas" aria-controls="offcanvasScrolling" data-bs-target="#evaluate_rules" id='evaluatePopu' className='d-none'>o</a>
								</div>
							</div>

							<div className="text-end mt-4">
								<button type="button" className="btn btn_cstm_primary" onClick={() => assignMail()} disabled={!allErrorCheck}>Assign</button>
							</div>
						</div>
					</div>
				</div>
				<SenderEdit data={senderSelect} senderUrl={senderUrl} />
				<SenderAdd senderUrl={senderUrl} />
				<EvaluateRules oldData={mailDetailBeforeChange} changedData={ruleTextData} receiverChange={receiverChange} senderChange={senderChange}
						rule={rules} rulesList={assignRuleList} />
			</div>
		</>
	)
}

export default UnassignedMail