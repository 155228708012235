/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { stringPipe } from '../../../../../pipes/stringPipes'
import { getService, putService } from '../../../../../services/commonApi'
import { url as MainUrl } from '../../../../../environment/environment'
import { getAuthToken } from '../../../../../services/refreshBearerToken';
import { errorHandler } from "../../../../../services/ErrorHandler";

var axios = require('axios');

const RequestDetails = ({ refresh, getOriginalReqList, url, data, type }) => {

    const [reqDetails, setReqDetails] = useState({})

    useEffect(() => {
        if (data && type) {
            getRequestDetails()
        }
    }, [data, type])


    const getRequestDetails = () => {
        // console.log(data)
        getService(`${url}/${data}`, true).then(
            (res) => {
                // console.log(res)
                setReqDetails(res?.data)
            }
        )
    }

    const fullfillrequest = () => {
        putService(`${url}/${data}`, '', true, {
            params: { 'response': 'fulfilled' }
        }).then(
            (res) => {
                if (res.status === 200) {
                    refresh()
                    document.getElementById('dismissreqModal').click()
                }
            }
        )
    }


    const saveMail = async () => 
	{
		getAuthToken().then( token =>
		{
			var config = {
				method: 'get',
				url: `${MainUrl}${url}/${data}/pdf`,
				headers: {
					'Authorization': 'Bearer ' + token,
					// data: image / jpeg; charset=utf - 8; base64,
				},
				responseType: 'arraybuffer'
			}
			axios(config).then(res => {
				console.log(res)
				var file = new Blob([res.data], { type: 'application/pdf' });
				var fileUrl = URL.createObjectURL(file);

				var fileName = "download.pdf";
				var a = document.createElement("a");
				document.body.appendChild(a);
				a.href = fileUrl;
				a.download = fileName;
				a.click();
				//open it directly 
				window.open(fileUrl);
			})
		})
		.catch( err => 
		{
			errorHandler( err );
		})
	}

    return (
        <div className="modal fade request_details" id="request_details" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body py-4">
                        <div className="row align-items-center">
                            <div className="col-lg-11 m-auto">
                                <h2 className="heading_1 mb-4">Request details</h2>
                                <p>{type === 'fullfill' && <>Please annotate the piece of mail with the following request code and send it to the person listed below. </>}
                                    <br /> Request code: <b> {reqDetails?.trackingCode}</b>
                                    {/* <br />Request status :<b>{reqDetails?.}</b> */}
                                </p>

                                <div className="my-4 information">
                                    <p className="title"><b>Requesting Account</b></p>
                                    <p className="mb-1"><b>{reqDetails?.employeeName}</b></p>
                                    <p className="mb-1">{reqDetails?.employee?.companyDetails?.address?.address1}</p>
                                    <p className="mb-1">{reqDetails?.employee?.contactDetails?.emailAddress1}</p>
                                    {reqDetails?.employee?.companyDetails?.department && <p>{reqDetails?.employee?.companyDetails?.department}</p>}
                                </div>

                                <div className="row information">
                                    <div className="col-lg-6">
                                        <p className="title"><b>Batch information</b></p>
                                        <p>Batch: <b>{stringPipe(reqDetails?.mail?.batchId, 9)}</b></p>
                                        <p>Position: <b>{reqDetails?.mail?.batchSequence}</b></p>
                                        <p>Tracking ID: <b>{stringPipe(reqDetails?.requestId, 9)}</b></p>
                                    </div>
                                    <div className="col-lg-6">
                                        <p className="title"><b>Mail information</b></p>
                                        <p>Digitized: <b>{new Date(reqDetails?.batchDate).toLocaleDateString()}</b></p>
                                        <p>Number of pages: <b>{reqDetails?.mail?.mailParts?.length}</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-between mt-4">
                                <div className="ms-lg-4">
                                    <a onClick={() => saveMail()} className="text_dark">Print</a>
                                </div>
                                {type !== "detail" && <div className="p-md-4 text-end">
                                    <button type="button" className="btn btn-link btn_link_primary" onClick={() => getOriginalReqList()} id="dismissreqModal" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn_cstm_primary px-4" onClick={() => fullfillrequest()}>Fulfill</button>
                                </div>}
                                {type === "detail" && <div className="p-md-4 text-end">
                                    <button type="button" className="btn btn_cstm_primary px-4" data-bs-dismiss="modal">Close</button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default RequestDetails