export const filterQualData = (data) => 
{
	var options;
	if (data) 
	{
		options = 
		{
			chart: 
			{
				type: 'pie',
			},
			title: {
				text: '',
			},			
			plotOptions: 
			{
				pie: 
				{
					allowPointSelect: true,
					cursor: 'pointer',
					dataLabels: 
					{
						enabled: true,
						format: '<b>{point.name}</b>: {point.percentage:.0f} %'
					},
				},
			},
			tooltip: 
			{
				pointFormat: '<b>{point.name}</b>: {point.percentage:.1f} %',
			},
			accessibility: 
			{
				point: 
				{
					valueSuffix: "%",
				},
			},
			series: [{
				name: 'Responses',
				size: "45%",
				data: 
				[
					{ name: 'Yes Sender', y: (data?.sendersOk / data?.totalPieces) * 50 },
					{ name: 'No Senders', y: ((data?.totalPieces - data?.sendersOk) / data?.totalPieces) * 50 },
					{ name: 'Yes Receiver', y: (data?.receiversOk / data?.totalPieces) * 50 },
					{ name: 'No Receiver', y: ((data?.totalPieces - data?.receiversOk) / data?.totalPieces) * 50 },
				],
			}],
		}
	} else {
		options = {
		title: {
			text: "",
		},
		series: [
			{
			data: [],
			},
		],
		lang: {
			noData: "No data found",
		},
		noData: {
			style: {
			fontWeight: "bold",
			fontSize: "15px",
			},
		},
		};
	}

	return options;
	};
