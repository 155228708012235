import React, { useEffect, useState } from 'react'
import './SettingsModal.css'
import NameInfo from "./NameInfo";
import CompanyInfo from "./CompanyInfo";
import ContactInfo from "./ContactInfo";
import Forwarding from './Forwarding';
import MailNotification from './MailNotification';
import ChangePassword from './ChangePassword';

const SettingsModal = ( { onClose, message }) => {

    const [navLink, setNavLink] = useState("name");

    const [nameInput, setNameInput] = useState();
    const [nameInputError, setNameInputError] = useState(false);

    const [companyInput, setCompanyInput] = useState();
    const [companyInputError, setCompanyInputError] = useState(false);

    const [contactInput, setContactInput] = useState();
    const [contactInputError, setContactInputError] = useState(false);

    const [forwardingInput, setForwardingInput] = useState()
    const [forwardingInputError, setForwardingInputError] = useState(false)

    const [mailNotificationInput, setMailNotificationInput] = useState()
    const [mailNotificationInputError, setMailNotificationInputError] = useState(false)

    const [changePasswordInput, setChangePasswordInput] = useState()
    const [changePasswordInputError, setChangePasswordInputError] = useState(false)


    const [error, setError] = useState(false);
    const [allErrorCheck, setAllErrorCheck] = useState(false)



    useEffect(() => {
        switch (navLink) {
            case 'name':
                setError(nameInputError)
                break;
            case 'company':
                setError(companyInputError)
                break;
            case 'contact':
                setError(contactInputError)
                break;
            case 'forwarding':
                setError(forwardingInputError)
                break;
            case 'mailNotification':
                setError(mailNotificationInputError)
                break;
            default:
                setError(changePasswordInputError)
                break;
        }
        if (nameInputError || companyInputError || contactInputError || forwardingInputError || mailNotificationInputError || changePasswordInputError) {
            // console.log(nameInputError, companyInputError, contactInputError, forwardingInputError, mailNotificationInputError, changePasswordInputError)
            setAllErrorCheck(true)
        } else {
            setAllErrorCheck(false)
        }
    }, [navLink, nameInputError, companyInputError, contactInputError, forwardingInputError, mailNotificationInputError, changePasswordInputError])

	const handleClose = (event) =>
	{
		if( onClose && event.target.getAttribute('data-bs-dismiss') ) 
		{
			onClose();
		}
	}

    return (
        <>
            <div className="modal fade settings" id="settings" data-bs-backdrop="static" data-bs-keyboard="false" onClick={ handleClose }
					tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                    <div className="modal-content">
                        <div className="mdl_head">
                            <div className="row gx-0">
                                <div className="col-12 col-lg-3 col-xl-2">
                                    <div className="bg_primary">
                                        <div className="pstn_relative">
                                            <h5 className="modal-title p-4" id="staticBackdropLabel">Settings</h5>
                                            <button type="button" className="btn-close p-4 d-block d-lg-none sm_btn-close" data-bs-dismiss="modal" aria-label="Close" disabled={allErrorCheck}></button>
                                        </div>
                                    </div>
                                    {error && (
                                        <div
                                            className="alert alert-danger sm_ctm_alert pl-4 d-block d-lg-none"
                                            role="alert"
                                        >
                                            <span>
                                                <img
                                                    src="/assets/img/error_icon_white.png"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </span>{" "}
                                            {error}
                                        </div>
                                    )}
                                </div>

                                <div className="col-12 col-lg-9 col-xl-10 text-end">
                                    <div className="pstn_relative_right">
                                        <button
                                            type="button"
                                            className="btn-close p-4 d-none d-lg-block ms-auto"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                        {/* start desktop */}
                                        {error && (
                                            <div
                                                className="alert alert-danger ctm_alert d-none d-lg-block"
                                                role="alert"
                                            >
                                                <span className="me-3">
                                                    <img
                                                        src="/assets/img/error_icon_white.png"
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                </span>{" "}
                                                {error}
                                            </div>
										)}
										{message && !error && (
                                            <div
											className="alert alert-info ctm_alert d-none d-lg-block"
											role="alert"
											>
												<span>
													<img
														src="/assets/img/error_icon_white.png"
														className="img-fluid"
														alt=""
													/>
												</span>{" "}
												{message}
											</div>
										)}
                                        {/* end desktop */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-body p-lg-0">
                            <div className="row gx-0">
                                {/* <!-- Start Left nav-link --> */}
                                <div className="col-lg-3 col-xl-2 bg_primary mb-4 mb-lg-0">
                                    <div
                                        className="nav flex-column nav-pills"
                                        aria-orientation="vertical"
                                    >
                                        <button
                                            onClick={() => setNavLink("name")}
                                            className={`nav-link ${navLink === "name" ? "active" : ""
                                                }`}
                                            id="v-pills-home-tab"
                                            type="button"
                                        >
                                            Names
                                            {nameInputError && <span className="ms-3">
                                                <img
                                                    src="/assets/img/error_icon.svg"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </span>}
                                        </button>

                                        <button
                                            onClick={() => setNavLink("company")}
                                            className={`nav-link ${navLink === "company" ? "active" : ""
                                                }`}
                                            id="v-pills-Companyinfo-tab"
                                            type="button"
                                        >
                                            Company info
                                            {companyInputError && <span className="ms-3">
                                                <img
                                                    src="/assets/img/error_icon.svg"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </span>}
                                        </button>

                                        <button
                                            onClick={() => setNavLink("contact")}
                                            className={`nav-link ${navLink === "contact" ? "active" : ""
                                                }`}
                                            id="v-pills-ContactInfo-tab"
                                            type="button"
                                        >
                                            Contact info
                                            {contactInputError && <span className="ms-3">
                                                <img
                                                    src="/assets/img/error_icon.svg"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </span>}
                                        </button>

                                        <button
                                            onClick={() => setNavLink("forwarding")}
                                            className={`nav-link ${navLink === "forwarding" ? "active" : ""
                                                }`}
                                            id="v-pills-forwarding-tab"
                                            type="button"
                                        >
                                            Forwarding
                                            {forwardingInputError && <span className="ms-3">
                                                <img
                                                    src="/assets/img/error_icon.svg"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </span>}
                                        </button>

                                        <button
                                            onClick={() => setNavLink("mailNotification")}
                                            className={`nav-link ${navLink === "mailNotification" ? "active" : ""
                                                }`}
                                            id="v-pills-mail_notification-tab"
                                            type="button"
                                        >
                                            Mail Notification
                                            {mailNotificationInputError && <span className="ms-3">
                                                <img
                                                    src="/assets/img/error_icon.svg"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </span>}
                                        </button>

                                        <button
                                            onClick={() => setNavLink("changePassword")}
                                            className={`nav-link ${navLink === "changePassword" ? "active" : ""
                                                }`}
                                            id="v-pills-messages-tab"
                                            type="button"
                                        >
                                            Change Password
                                            {changePasswordInputError && <span className="ms-3">
                                                <img
                                                    src="/assets/img/error_icon.svg"
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            </span>}
                                        </button>

                                    </div>
                                </div>
                                {/* <!-- End Left nav-link --> */}

                                {/* <!-- Start right Tab-content --> */}
                                <div className="col-lg-9 col-xl-10">
                                    <div className="tab-content">
                                        {/* <!-- Start Name Tab-pane--> */}
                                        {navLink === "name" && (
                                            <NameInfo
                                                navLink={navLink}
                                                setNavLink={setNavLink}
                                                error={nameInputError}
                                                setError={setNameInputError}
                                                nameInput={nameInput}
                                                setNameInput={setNameInput}
                                                allErrorCheck={allErrorCheck}
                                            />
                                        )}
                                        {/* <!-- End Name Tab-pane--> */}

                                        {/* <!-- Start Comany Info Tab-pane--> */}
                                        {navLink === "company" && (
                                            <CompanyInfo
                                                navLink={navLink}
                                                setNavLink={setNavLink}
                                                error={companyInputError}
                                                setError={setCompanyInputError}
                                                companyInput={companyInput}
                                                setCompanyInput={setCompanyInput}
                                                allErrorCheck={allErrorCheck}
                                            />
                                        )}
                                        {/* <!-- End Comany Info Tab-pane--> */}

                                        {/* <!-- End Contact Info Tab-pane--> */}
                                        {navLink === "contact" && (
                                            <ContactInfo
                                                navLink={navLink}
                                                setNavLink={setNavLink}
                                                error={contactInputError}
                                                setError={setContactInputError}
                                                contactInput={contactInput}
                                                setContactInput={setContactInput}
                                                allErrorCheck={allErrorCheck}
                                            />
                                        )}
                                        {/* <!-- End Contact Info Tab-pane--> */}

                                        {/* <!-- End Forwarding Info Tab-pane--> */}
                                        {navLink === "forwarding" && (
                                            <Forwarding
                                                navLink={navLink}
                                                setNavLink={setNavLink}
                                                error={forwardingInputError}
                                                setError={setForwardingInputError}
                                                forwardingInput={forwardingInput}
                                                setForwardingInput={setForwardingInput}
                                                allErrorCheck={allErrorCheck}
                                            />
                                        )}
                                        {/* <!-- End Forwarding Info Tab-pane--> */}

                                        {/* <!-- End Mail Notification Tab-pane--> */}
                                        {navLink === "mailNotification" && (
                                            <MailNotification
                                                navLink={navLink}
                                                setNavLink={setNavLink}
                                                error={mailNotificationInputError}
                                                setError={setMailNotificationInputError}
                                                mailNotificationInput={mailNotificationInput}
                                                setMailNotificationInput={setMailNotificationInput}
                                                allErrorCheck={allErrorCheck}
                                            />
                                        )}
                                        {/* <!-- End Mail Notification Tab-pane--> */}

                                        {/* <!-- End Change Password Info Tab-pane--> */}
                                        {navLink === "changePassword" && (
                                            <ChangePassword
                                                navLink={navLink}
                                                setNavLink={setNavLink}
                                                error={changePasswordInputError}
                                                setError={setChangePasswordInputError}
                                                changePasswordInput={changePasswordInput}
                                                setChangePasswordInput={setChangePasswordInput}
                                                allErrorCheck={allErrorCheck}
                                            />
                                        )}
                                        {/* <!-- End Change Password Info Tab-pane--> */}

                                    </div>
                                </div>
                                {/* <!-- End right Tab-content --> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>

    )
};

export default SettingsModal