import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';

const EmployeeNameListItem = ({ data }) => {
    console.log(data)
    return (
        <>
			<div className='search_results_item' id='employee_search_results'>
				<span className="d-flex">
					<p><b>Employees</b></p>
				</span>
				{data?.map((data, i) => (
					<Dropdown.Item key={i} className="help_box" as={'div'} >
						<p><b>{data?.employeeName?.fullName}</b> </p>
						{data?.companyDetails?.address.address1 &&
							<p><small>
								{data?.companyDetails?.address.address1 ? data?.companyDetails?.address.address1+', ' : '' }
								{data?.companyDetails?.address.address2 ? data?.companyDetails?.address.address2+', ' : '' }
								{data?.companyDetails?.address.city} {data?.companyDetails?.address.state} {data?.companyDetails?.address.zipcode}
							</small></p>}
						{data?.companyDetails?.department &&
							<p><small>
								{data?.companyDetails?.department}
							</small></p>}
						{data?.contactDetails?.emailAddress1 &&
							<p><small>
								{data?.contactDetails?.emailAddress1}
								{data?.contactDetails?.emailAddress2 && ', ' + data?.contactDetails?.emailAddress2}
							</small></p>}
					</Dropdown.Item>
	            ))}
			</div>
        </>
    )
}

export default EmployeeNameListItem