/* eslint-disable react-hooks/exhaustive-deps */
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef } from 'react'
import { deleteService } from '../../../../../services/commonApi'
import { CommonState } from '../../../../../context/commonContext';
import { useNavigate, useParams } from 'react-router-dom';

export default function NoteMineModal({ url, setText }) {

    const { refreshMailData, setRefreshMailData, setRefreshFolderData, refreshFolderData } = CommonState()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const navigate = useNavigate()

    var params = useParams();
    var _url = useRef()

    useEffect(() => {
        if (params.id) {
            _url.current = `/mercury/mail/mailboxes/folders/${params?.id}${params?.mailId ? '/mail/' + params.mailId : ''}`

        }
    }, [params.id, params.mailId])


    function sendBackMail() {
        // console.log("deletefunc")
        deleteService(_url.current, "", "").then((res) => {
            // console.log(res, "res")
            navigate(`/mail/${params.id}`)
            setRefreshMailData(!refreshMailData)
            setRefreshFolderData(!refreshFolderData)
            sessionStorage.removeItem('last_url')
            document.getElementById('closeNotMineModal').click()
            if (res.status === 200) {
                showAlertBox('Mail has been sent back.')
            } else {
                showAlertBox(res.data.error.desc)
            }

            // setText('Mail has been sent back.')
            // var a = document.createElement('a');
            // document.body.appendChild(a);
            // a.style = "display:none";
            // a.href = ('#')
            // a.setAttribute('data-bs-toggle', 'modal')
            // a.setAttribute('data-bs-target', '#alertModal')
            // console.log(a)
            // a.click()
        }, (err) => { console.log(err, "err") })
    }

    function showAlertBox(msg) {

        const action = snackbarId => (
            <>
                <button className="btn close_btn" style={{ 'color': '#3D75B1', 'font-size': '25px' }} onClick={() => { closeSnackbar(snackbarId) }}>
                    <i className="bi bi-x"></i>
                </button>
            </>
        );
        enqueueSnackbar(msg, {
            variant: 'warning',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            },
            autoHideDuration: 10000,
            action,
            preventDuplicate: true
        }
        )
    }

    return (
        <>
            {/* <SnackbarProvider /> */}
            <div className="modal fade Note note_mine" id="note_mine" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                    <div className="modal-content">
                        <div className="modal-body py-4 px-lg-5 h_40vh d_inline_grid">
                            <div>
                                <h2 className="heading_1">Confirm</h2>
                                <p>Please confirm that you want to send this mail back.</p>
                            </div>
                            <div>
                                <div className="d-flex flex-wrap align-items-center justify-content-end offset-md-1 h-100">
                                    <div className="text-end">
                                        <button type="button" className="btn btn-link  btn_link_primary" data-bs-dismiss="modal" id="closeNotMineModal">Cancel</button>
                                        <button type="button" className="btn btn_cstm_primary" onClick={() => sendBackMail()} >Send back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
