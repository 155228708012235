/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Settings_Get_API, Settings_Put_API } from '../../../../services/SettingsAPI'
import { getService } from '../../../../services/commonApi'

const Forwarding = ({ navLink,
	setNavLink,
	error,
	setError, forwardingInput, setForwardingInput, allErrorCheck }) => {

	const [dropdownState, setDropdownState] = useState(false)
	const [autoForward, setForward] = useState(forwardingInput?.autoForward)
	const [copyOnForward, setCopyOnForward] = useState(forwardingInput?.copyOnForward)
	const [forwardToId, setForwardToId] = useState(forwardingInput?.forwardToId)

	const [changed, setChanged] = useState(false)

	useEffect(() => {
		if (!forwardingInput) {
			Settings_Get_API('/mercury/employee/preferences/forwarding').then((res) => {
				// setForwardingInput(res.data.forwarding)
				setForward(res?.data?.forwarding?.autoForward)
				setCopyOnForward(res?.data?.forwarding?.copyOnForward)
				setForwardToId(res?.data?.forwarding?.forwardToId)
				searchEmployeeById(res?.data?.forwarding?.forwardToId)
			})
		}
	}, [])



	const [employeeSearchList, setEmployeeSearchList] = useState([])

	const [employeeSearchResult, setemployeeSearchResult] = useState()
	const [submit, setSubmit] = useState(false)

	useEffect(() => {
		forwardToId && searchEmployeeById(forwardToId)
	}, [forwardToId])

	const searchEmployeeById = (id) => 
	{
		getService(`/mercury/employee/${id}`, true).then(
			(res) => {
				setemployeeSearchResult(res.data.employee)
			}
		)
	}

	const [forwardEmployeeSearch, setForwardEmployeeSearch] = useState('')

	useEffect(() => {
		const search = setTimeout(() => {
			if (forwardEmployeeSearch) {
				searchEmployee()
			}
		}, 1500);
		return () => clearTimeout(search)
	}, [forwardEmployeeSearch])

	const searchEmployee = () => {
		getService('/mercury/search/resources', true, {
			params: {
				filter: forwardEmployeeSearch,
				include_type: 'employee'
			}
		}).then(
			res => {
				console.log(res)
				setEmployeeSearchList(res?.data?.matches[0].list)
			}
		)
	}

	const setForwardingInputData = () => 
	{
		setSubmit(true)
		if (autoForward && !forwardToId) 
		{
			return setError('Please check "Forward all my mail" or choose a  forwarding account')
		}

		Settings_Put_API('/mercury/employee/preferences/forwarding', { autoForward, copyOnForward, forwardToId }).catch((res) => 
		{
			setError(res?.response?.data?.error.info);
		})
		setError('')
		setSubmit(false)
	}


	useEffect(() => 
	{
		if (!autoForward)
		{
			 setForwardToId(''); 
			 setemployeeSearchResult(); 
			 setForwardEmployeeSearch('')
		}
		setForwardingInput({ autoForward, copyOnForward, forwardToId });
		const set = setTimeout(() => 
		{
			if (changed) 
			{
				setForwardingInputData()
			}
		}, 1500);
		return () => clearTimeout(set)
	}, [autoForward, copyOnForward, forwardToId])



	return (
		<div
			className={`tab-pane fade ${navLink === "forwarding" ? "show active" : ""}`}
			aria-labelledby="v-pills-forwarding-tab"
		>
			<div className="col-lg-9 offset-lg-1 modalContentItems">
				<h2 className="heading_1">Forwarding</h2>
				<p> Please identify to whom you’d like your mail forwarded.</p>
				<form className="pt-4">
					<div className="row g-3 align-items-center">
						<div className="fm_grp col-md-12">
							<div className="mb-3 form-check">
								<input type="checkbox" className="form-check-input" id="forwardMyMail" onChange={() => { setForward(!autoForward); setChanged(true) }} checked={autoForward} />
								<label className="form-check-label ms-2" htmlFor="forwardMyMail">Forward all my mail</label>
							</div>
							<div className="form-check">
								<input type="checkbox" disabled={!autoForward} className="form-check-input" id="copyMail" onChange={() => { setCopyOnForward(!copyOnForward); setChanged(true) }} checked={copyOnForward} />
								<label className="form-check-label ms-2" htmlFor="copyMail" >Retain a copy of my mail</label>
							</div>
						</div>
						<div className="fm_grp col-md-8 d-flex" style={{ 'marginLeft': '-25px' }}>
							<span className="me-2 d-flex d-none d-md-block align-items-end mt-4" style={{ 'visibility': error ? 'visible' : 'hidden' }}><img src="assets/img/error_icon.svg" className="img-fluid" alt="" /></span>
							<span className="me-2 pb-2 d-flex align-items-end d-md-none" style={{ 'visibility': 'hidden' }}><img src="assets/img/error_icon.svg" className="img-fluid" alt="" /></span>
							<div className="fm_grp col-md-auto">
								<label htmlFor="Position" className="form-label">*  Forward to</label>
								{employeeSearchResult && (
									<div className="d-flex ForwardSelectedBox">
										<div>
											<p>{employeeSearchResult?.employeeName?.fullName}</p>
											<p>{employeeSearchResult?.employeeName?.suffix ?? ''}</p>
											<p>{employeeSearchResult?.employeeName?.nickNames.length > 0 ?? ''}</p>
											<p>{employeeSearchResult?.companyDetails?.address?.address1}</p>
											<p>{employeeSearchResult?.companyDetails?.address?.city} {employeeSearchResult?.companyDetails?.address?.zipcode ?? ''}</p>
											<p>{employeeSearchResult?.contactDetails?.emailAddress1}{employeeSearchResult?.contactDetails?.emailAddress2 ? ', ' + employeeSearchResult?.contactDetails?.emailAddress2 : ''} </p>
										</div>
										<div>
											<span className='px-2'> <img src="/assets/img/Delete.svg" alt="" onClick={() => { setemployeeSearchResult(null); setForwardToId(''); setChanged(true) }} /> </span>
										</div>
									</div>
								)}
								{!employeeSearchResult && (
									<div className="serach_box" id="dropdownMenuButton2" data-bs-toggle="dropdown">
										<input type="text" disabled={!autoForward} aria-label="Search" placeholder="Search employees" onChange={(e) => { setForwardEmployeeSearch(e.target.value); setDropdownState(true) }} className={`form-control ${(submit && error) ? 'error_input' : ''}`} style={{ 'borderColor': error ? 'red' : '' }} defaultValue={forwardToId} />
										<a href="#"><span className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
									</div>
								)}
								<ul className={`dropdown dropdown-menu search_dropdown employee_search_dropdown ${dropdownState ? 'show' : ''}`} aria-labelledby="dropdownMenuButton2">
									{employeeSearchList?.length < 1 ? (<li>
										<a className="dropdown-item" href="#">
											<p>No Matches Found</p>
										</a>
									</li>) : (
										employeeSearchList?.map((data, i) => {
											return (
												<li key={i} onClick={() => { setForwardToId(data?.employeeId); setemployeeSearchResult(data); setDropdownState(false); setChanged(true) }}>
													<a className="dropdown-item" href="#">
														<p>{data?.employeeName?.fullName}</p>
														<p>{data?.employeeName?.suffix ?? ''}</p>
														<p>{data?.employeeName?.nickNames?.length > 0 ?? ''}</p>
														<p>{data?.companyDetails?.address?.address1}</p>
														<p>{data?.contactDetails?.emailAddress1}{data?.contactDetails?.emailAddress2 ? ", " + data?.contactDetails?.emailAddress2 : ''}</p>
													</a>
												</li>
											)
										})
									)}
								</ul>
							</div>
						</div>
					</div>
				</form>
			</div>
			<div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
				<div> </div>
				<div className="p-md-4 text-end">
					<button type="button" className="btn btn_cstm_primary" data-bs-dismiss="modal" disabled={allErrorCheck} >Done</button>
				</div>
			</div>
		</div>
	)
}

export default Forwarding