/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import './FloatingSidebar.css'
import { NavLink } from 'react-router-dom'
import { CommonState } from '../../../context/commonContext'
import { AuthState } from "../../../context/authContext";

const FloatingSidebar = () => {

	const { currentMail } = CommonState()
	const { entitlement } = AuthState()

	const seekPermission = (link) => 
	{
		return( entitlement.includes(link) ) 
	}

	const linksData = [
		{ link: "/mail", icon: "bi bi-envelope-fill", text: "Mail", permission: seekPermission("basic") },
		{ link: "/deliver", icon: "bi bi-arrow-right-circle", text: "Deliver", permission: seekPermission("assign") },
		{ link: "/reports", icon: "bi bi-file-earmark-fill", text: "Reports", permission: seekPermission("report") },
		{ link: "/database", icon: "bi bi-server", text: "Database", permission: seekPermission("account") },
		{ link: "/system", icon: "bi bi-laptop", text: "System", permission: seekPermission("settings") },
	]

	return (
		<>

		<div className="offcanvas offcanvas-start collapse_menubar collapse_menubar d-none d-md-block" tabIndex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="false">
			<div className="offcanvas-header">
			<button type="button" className="text-reset btn_close_offcanvas" data-bs-dismiss="offcanvas" aria-label="Close">
				<img src="/assets/img/togglebar.svg" alt="" />
			</button>
			<button className="btn float-end btn_open_offcanvas" data-bs-toggle="offcanvas" data-bs-target="#offcanvas">
				<img src="/assets/img/togglebar.svg" alt="" />
			</button>
			</div>
			<ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
			{
				linksData.filter((el) => el?.permission).map((item, i) => {
					return(
						i === 0 ?
						<li className="nav-item" data-bs-dismiss="offcanvas" key={i}>
						<NavLink to={`${item.link}/${currentMail.folderid}${ currentMail.mailid ? '/'+currentMail.mailid : '' }`} >
							<span className="nav-link">
							{/* <img src="assets/img/mail.svg" alt="" /> */}
							<i className={item.icon}></i>
							<span className="ms-4">{item.text}</span>
							</span>
						</NavLink>
						</li>
					:
						<li className="nav-item" data-bs-dismiss="offcanvas" key={i}>
						<NavLink to={item.link} >
							<span className="nav-link">
							{/* <img src="assets/img/mail.svg" alt="" /> */}
							<i className={item.icon}></i>
							<span className="ms-4">{item.text}</span>
							</span>
						</NavLink>
						</li>
					)
				})
			}
			</ul>
		</div>

		{/* </div>
		</div> */}

		</>
	)
}

export default FloatingSidebar
