/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { RouteState } from '../../../../context/routeContext'

import RestartProcess from './modals/RestartProcess'
import ProcessingDelete from './modals/ProcessingDelete'
import { deleteService, getService } from '../../../../services/commonApi'
import ImageGallery from './ImageGallery'

const Processing = () => {
    const { deliveryRoute, setDeliveryRoute } = RouteState()

    const [mailList, setMailList] = useState([])
    const [selectedMail, setSelectedMail] = useState('')
    const [sort, setSort] = useState()
    // const [currentIndex, setCurrentIndex] = useState(0)
    const [workStationList, setWorkStationList] = useState([])
    const [status, setStatus] = useState()
    const [workstationId, setWorkstationId] = useState()

    const [mailImagesList, setMailImagesList] = useState([])

    const [batchMailList, setBatchMailList] = useState([])
    const [envelopeMailList, setEnvelopeMailList] = useState([])
    const [seperatorMailList, setSeperatorMailList] = useState([])
    const [contentMailList, setContentMailList] = useState([])
    const [unknownMailList, setUnknownMailList] = useState([])

    const [selectedImage, setSelectedImage] = useState([])

    var PrimaryUrl = '/mercury/mail/processingjobs'
//    var url = useRef()
	const [url,setUrl] = useState('')

    const [pageCount, setPageCount] = useState({
        start: 1, stop: 25
    })

    var statuses = ['complete', 'incomplete', 'failed', 'waiting']

    useEffect(() => 
	{
        if (deliveryRoute === 'processing') 
		{
            getProcessingJobsList()
        }
    }, [deliveryRoute, sort, status, workstationId, pageCount.stop, pageCount.start])

    useEffect(() => 
	{
        if (deliveryRoute === 'processing') 
		{
            getWorkStationlList()
        }
    }, [deliveryRoute])


    useEffect(() => 
	{
        if (selectedMail) 
		{
			let curr_url = `${PrimaryUrl}/${selectedMail}` 
			setSelectedImage([])
            setMailImagesList([])
			setUrl( `${PrimaryUrl}/${selectedMail}` )
			// get the images
			getService(curr_url+'/images', true, "").then((image_res) => 
			{
				setMailImagesList( image_res?.data?.images)
			})
        }
    }, [selectedMail])


	const getProcessingJobsList = () => 
	{
		const params = {}
		params.status = status;
		if (workstationId) params['workstationId'] = workstationId;
		params.sort = sort;

		getService(PrimaryUrl, true, 
		{
			params: 
			{
				...params, ...pageCount
			}
		}).then( (res) => 
		{
			setMailList(res?.data?.jobs.map((data) => setMailStatus(data)))
			setSelectedMail(res?.data?.jobs[0]?.processingId)
		})
	}

    function getImageType(type) 
	{
        if (type === 'B') return 'Batch'
        else if (type === 'E') return 'Envelope'
        else if (type === 'S') return 'Seperator'
        else if (type === 'U') return 'Unknown'
        else if (type === 'C') return 'Content'
    }

    function setMailStatus(mailData) {
        if (mailData?.failed) {
            return { ...mailData, status: 'Failed' }
        }
        else if (!mailData?.completed) {
            return { ...mailData, status: 'Incomplete' }
        }
        else if (mailData?.completed && mailData?.processed && !mailData?.failed) {
            return { ...mailData, status: 'Completed' }
        } else {
            return { ...mailData, status: 'Waiting' }
        }
    }


    const getWorkStationlList = () => {
        getService('/mercury/system/workstations', true, "").then(
            (res) => {
                if (res.status === 200) {
                    setWorkStationList(res?.data?.workstations)
                }
            }
        )
    }



    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-A" onClick={() => { setDeliveryRoute('processing') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
                        Processing Jobs  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-A" className={`bgYellow collapse ${deliveryRoute === "processing" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-A">
                <div>
                    <div className="p-3 pt-4 pb-0 col-lg-11 text_primary_2">
                        <p>View the status of scanned images. Choose criteria from the list below and click “Refresh.”</p>
                    </div>
                </div>
                <div className="p-3">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="row mb-3 select_item">
                                <div className="col-md-6 col-xl-3">
                                    <label htmlFor="">Workstation</label>
                                    <select className="form-select form-select-sm" onChange={(e) => setWorkstationId(e.target.value)}>
                                        <option value=''>All Workstations</option>
                                        {workStationList?.map((data, i) => (
                                            <option key={i} value={data?.workstationId}>{data?.workstationName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-6 col-xl-2">
                                    <label htmlFor="">Count</label>
                                    <select className="form-select form-select-sm" onChange={(e) => setPageCount({ start: pageCount.start, stop: e.target.value })}>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                                <div className="col-md-6 col-xl-3">
                                    <label htmlFor="">Status</label>
                                    <select className="form-select form-select-sm" onChange={(e) => setStatus(e.target.value)}>
                                        <option value={''}>All status</option>
                                        {statuses.map((data, i) => (
                                            <option key={i} value={data}>{data}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-6 col-xl-2 offset-xl-2 align-self-end sort">
                                    <select className="form-select form-select-sm" onChange={(e) => setSort(e.target.value)}>
                                        <option value="up">Up</option>
                                        <option value="down">Down</option>
                                    </select>
                                </div>
                            </div>
                            {/* <!-- Start Table --> */}
                            <div className="table-responsive cst_border cst_table processing_table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Created</th>
                                            <th>Status</th>
                                            <th>Workstation</th>
                                            <th>Operator</th>
                                            <th>Images</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {mailList?.map((data, i) => (
                                            <tr key={i} onClick={() => setSelectedMail(data?.processingId)}>
                                                <td>{new Date(data?.created).toLocaleDateString()}</td>
                                                <td>{data?.status}</td>
                                                <td>{data?.workstation}</td>
                                                <td>{data?.operatorName}</td>
                                                <td>{data?.imageCount}</td>
                                                <td onClick={() => setSelectedMail(data?.processingId)}>
                                                    {(data?.failed && data?.completed) && <a data-bs-toggle="offcanvas" data-bs-target="#Restart_process">
                                                        <img src="assets/img/refresh.png" alt="" />
                                                    </a>}
                                                    {(data?.failed && !data?.completed) && <a data-bs-toggle="modal" data-bs-target="#Processing_delete">
                                                        <img src="assets/img/Delete.svg" alt="" />
                                                    </a>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {/* <!-- End Table --> */}
                        </div>

                        <div className="col-lg-3">
                            <div className="right_box mt-4 pt-1">
                                <h4 className="fnt_16 mb-4">Selected mail piece</h4>
                                <div className="inner_right_box">
                                    <ul className='fullHieghtBox'>
                                        {mailImagesList.map((ele, i) =>
                                            <li key={i} className={`${selectedImage[0]?.imageId === ele?.imageId ? 'active' : ''}`}>
												<a onClick={() => setSelectedImage([ele])}>{i + 1}) {getImageType(ele?.imageType)}</a>
											</li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <ImageGallery imageList={selectedImage} mailUrl={url} />
                    </div>
                </div>
            </div>
            <RestartProcess url={url} refreshList={getProcessingJobsList} />
            <ProcessingDelete url={url} refreshList={getProcessingJobsList} />
        </>
    )
}

export default Processing