/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

const ContactInfo = ({
  navLink,
  setNavLink,
  error,
  setError,
  contactInput,
  setContactInput,
}) => {

  const [email, setEmailAddress] = useState(contactInput ? contactInput.email : '')
  const [cellphone, setCellPhone] = useState(contactInput ? contactInput.cellphone : '')
  const [workphone, setWorkPhone] = useState(contactInput ? contactInput.workphone : '')

  const [submit, setSubmit] = useState(false)
  const [validEmail, setValidEmail] = useState(true)


  useEffect(() => {
    setContactInput({
      email, cellphone, workphone
    });
  }, [email, cellphone, workphone])


  const setContactInputData = () => {
    setSubmit(true)
    setContactInput({
      email, cellphone, workphone
    });
    if (!email) {
      return setError("Email address is required field");
    } else if (emailValidation(email)) {
      setValidEmail(false)
      return setError("Invalid email")
    }
    setValidEmail(true)

    setError("");
    setNavLink("account")
  }

  function emailValidation(email) {
    const regex =
      // eslint-disable-next-line
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !(!email || regex.test(email) === true);
  }


  return (
    <div
      className={`tab-pane fade ${navLink === "contact" ? "show active" : ""}`}
      aria-labelledby="v-pills-messages-tab"
    >
      <div className="col-lg-9 offset-lg-1 modalContentItems">
        <h2 className="heading_1">Let’s create your account</h2>
        <p className="mb-0">
          In order to create your account, we will need certain information from
          you. Please provide all the following information so that we can
          process your request.
        </p>
        <p className="fnt_w_bold">Please be as thorough as possible.</p>
        <form className="pt-4">
          <div className="row g-3 align-items-center">
            <div className="fm_grp col-md-6">
              <div className="error_inupt_box">
                {/* <span  className="me-3">
                  <img
                    src="assests/img/error_icon.svg"
                     className="img-fluid"
                    alt=""
                  />
                </span> */}
                <div className="error_input">
                  <label htmlFor="inputEmail" className={`form-text ${error && (!email || !validEmail) ? 'error_text' : ''}`}>
                    * Email address {error && !email && validEmail && <span>is required</span>}{error && email && !validEmail && <span>is invalid</span>}
                  </label>
                  <input
                    type="email"
                    className={`form-control ${error && (!email || !validEmail) ? 'error_input' : ''}`}
                    id="inputEmail"
                    placeholder=""
                    onInput={(e) => setEmailAddress(e.target.value)}
                    value={email}
                  />
                </div>
              </div>
            </div>
            <div className="fm_grp col-md-6">
              <label htmlFor="inputCell" className="form-label">
                Cell phone
              </label>
              <input
                type="text"
                className="form-control"
                id="inputCell"

                onInput={(e) => setCellPhone(e.target.value)}
                value={cellphone}
              />
            </div>
            <div className="fm_grp col-md-6">
              <label htmlFor="inputWorkPhone" className="form-label">
                Work phone
              </label>
              <input
                type="text"
                className="form-control"
                id="inputWorkPhone"
                placeholder=""
                onInput={(e) => setWorkPhone(e.target.value)}
                value={workphone}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
        <div> </div>
        <div className="p-md-4 text-end">
          <button
            type="button"
            className="btn btn-link  btn_link_primary me-3"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn_cstm_primary px-4"
            onClick={() => setContactInputData()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
