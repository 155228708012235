/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react'
import { AuthState } from '../../../../context/authContext'
import { getService } from '../../../../services/commonApi'
import AccountAdd from './Modals/AccountModals/AccountAdd/AccountAdd'
import AccountDelete from './Modals/AccountModals/AccountDelete'
import { getCompanyIdToken } from '../../../../services/tokenService'
import { stringPipe } from '../../../../pipes/stringPipes'
import ReassignMail from './Modals/AccountModals/ReassignMail';
import ResetPassword from './Modals/AccountModals/ResetPassword';

const Accounts = ({ activeTab }) => 
{
	const loadCount = 200
	const { databaseRoute, setDatabaseRoute } = AuthState()
	const [accountid, setAccountid] = useState()
	const [sortby, setSortby] = useState()
	const [filter, setFilter] = useState()
	const [prevFilter, setPreviousFilter] = useState()
	const [accountList, setAccountList] = useState([])
	const [deleteName, setDeleteName] = useState()
	const [accountTypeList, setAccountTypeList] = useState([])
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [hasMore, setHasMore] = useState(true);

	const url = '/mercury/employee'
	const user = JSON.parse(localStorage?.getItem('user'))
	const sortFilter = 
	[
		'Last Name',
		'Email',
		'Department',
		'User Name',
		'Mailstop',
		'Address',
		'Position',
		'Cost Center',
		'Employee Number',
		'Account Type'
	]

	let start = 1;

	const seekPermission = (link) => 
	{
		if (user?.entitlements?.includes(link)) 
		{
			return true
		} 
		else 
		{
			return false
		}
	}

	useEffect(() => 
	{
		if (databaseRoute === 'accounts') 
		{
			getAccountTypeList()
		}
	}, [databaseRoute])

	const getAccountList = () => 
	{
		let first = start
		let last = first+loadCount;
		setIsLoading(true)
		setError(null)
		getService(`${url}/list`, true, 
		{
			params: 
			{
				sortby, filter, 'start': first, 'stop': last
			}
		}).then((res) => 
		{
			setIsLoading(false)
			if (res?.status === 200) 
			{
				let data = res?.data?.employees;
				// keep the list updated by adding the new data to the end of the existing account list.
				setAccountList( prevList => [...prevList, ...data] );
				// check to see if we loaded all of the records.
				if( accountList.length === res?.data?.available ) 
				{
					setHasMore(false);
				}	
			}
			else
			{
				setError({ 'code': res?.status, 'message': res?.statusText })
			}
		})
		start += loadCount
	}

	const getAccountTypeList = () => 
	{
		getService(`/mercury/company/${getCompanyIdToken()}/accounttypes/list`, true).then( (res) => 
		{
			if (res.status === 200) 
			{
				setAccountTypeList(res?.data?.accountTypes)
			}
		})
	}

	const filterAccountType = (id) => 
	{
		return accountTypeList.filter(data => data.id === id).map(ele => ele.name)
	}

	useEffect(() => 
	{
		const search = setTimeout(() => 
		{
			if( databaseRoute === 'accounts' && filter ) 
			{
				// filter search so clear the existing list then query the new one.
				start = 1;
				setPreviousFilter( filter )
				setAccountList( [] )
				getAccountList()
			}
			else if( databaseRoute === 'accounts' && !filter && filter !== prevFilter )  
			{
				start = 1;
				setPreviousFilter( "" )
				setAccountList( [] )
				getAccountList()
			}
		}, 1500);
		return () => clearTimeout(search)
	}, [filter, sortby])

	const sortByAlpha = (alpha) => 
	{
		let data = document.querySelectorAll(".lastname")
		let count = 0
		data.forEach((item, i) => 
		{
			let name = item.innerText.split(" ")
			if (name[0].charAt(0).toLocaleLowerCase().charCodeAt(0) > (alpha.charCodeAt(0) - 1)) 
			{
				count++
				if (count === 1) 
				{
					item.scrollIntoView(true)
				}
				return
			}
		})
	}

	const observerTarget = useRef(null);
	useEffect(() => 
	{
		const observer = new IntersectionObserver( entries => 
		{
			if( entries[0].isIntersecting && hasMore ) 
			{
				getAccountList( );
			}
		}, { threshold: 1 } );
	  
		if( observerTarget.current ) 
		{
			observer.observe(observerTarget.current);
		}
	  
		return () => 
		{
			if (observerTarget.current) 
			{
				observer.unobserve(observerTarget.current);
			}
		};
	  }, [observerTarget]);

    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-A" onClick={() => { setDatabaseRoute('accounts') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
                        Accounts  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-A" className={`bgYellow collapse ${databaseRoute === "accounts" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-A">
                <div>
                    <div className="head d-none d-md-block">
                        <h4 className="heading_1 text_dark mb-0">Accounts</h4>
                    </div>
                    <div className="p-4 pb-0 col-lg-11">
                        <p> Please enter all of your company’s Accounts that the system will use to receive mail, and keep this list current. If the list is not kept current, Accounts for mail may be inaccurate. <a className="text_dark">More…</a></p>
                    </div>
                    <div className="p-4  pt-0 col-lg-12">
                        <div className="row">
                            <div className="col-lg-11">
                                {/* <!-- Start search--> */}
                                <div className="row mb-3 mt-4">
                                    <div className="col-md-4 col-xl-4">
                                        <div className="serach_box">
                                            <input className="form-control" type="text" aria-label="Search" placeholder='Search Accounts' onInput={(e) => setFilter(e.target.value)} value={filter} />
                                            <a><span className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xl-3 offset-xl-2">
                                        <div className="cst_mng_icon my-3 my-md-0">
                                            <a data-bs-toggle="modal" data-bs-target="#resetAccountPassword" className={`${!accountid ? 'disableLink' : ''}`}>
                                                <img src="assets/img/Password.svg" alt="" />
                                                <span> Password</span>
                                            </a>
                                            {seekPermission("reassign") && <a data-bs-toggle="modal" data-bs-target="#reassignPassword" className={`${!accountid ? 'disableLink' : ''}`}>
                                                <img src="assets/img/arrow.svg" alt="" />
                                                <span> Reassign</span>
                                            </a>}
                                            <a data-bs-toggle="modal" data-bs-target="#staticBackdrop" className={`${!accountid ? 'disableLink' : ''}`} >
                                                <img src="assets/img/Edit.svg" alt="" />
                                                <span> Edit</span>
                                            </a>
                                            <a data-bs-toggle="modal" data-bs-target="#Delete_replace" className={`${!accountid ? 'disableLink' : ''}`} >
                                                <img src="assets/img/Delete.svg" alt="" />
                                                <span> Delete</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xl-3">
                                        <select className="form-select form-select-sm w_lg_75 ms-auto" onChange={(e) => setSortby(e.target.value)}>
                                            <option defaultValue={''} hidden disabled>Sort</option>
                                            {sortFilter?.map((data, i) => (
                                                <option key={i} value={(data).toLocaleLowerCase().replace(' ', '')}>{data}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="table-responsive cst_border cst_table acc_table">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Last Name</th>
                                                <th>First Name</th>
                                                <th>Address</th>
                                                <th>Email address</th>
                                                <th>Work phone </th>
                                                <th>Cell phone</th>
                                                <th>Position</th>
                                                <th>Dept</th>
                                                <th>Mail Stop</th>
                                                <th>Cost Center</th>
                                                <th>Employee Number</th>
                                                <th>Username</th>
                                                <th>Account type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
											{accountList?.map((data, i) => (
												<tr key={i}>
													<td>
														<div className="mb-3 form-check">
															<input type="checkbox" className="form-check-input" id="exampleCheck1" onChange={() => { setAccountid(data?.employee?.employeeId); setDeleteName(data?.employee?.employeeName?.fullName) }} checked={data?.employee?.employeeId === accountid} />
														</div>
													</td>
													<td className="lastname">{stringPipe(data?.employee?.employeeName?.lastName, 20)}</td>
													<td className="firstname">{stringPipe(data?.employee?.employeeName?.firstName, 20)}</td>
													<td>{stringPipe(data?.employee?.companyDetails?.address?.address1, 25)}</td>
													<td>{stringPipe(data?.employee?.contactDetails?.emailAddress1, 20)}</td>
													<td>{stringPipe(data?.employee?.contactDetails?.workPhone, 20)}</td>
													<td>{stringPipe(data?.employee?.contactDetails?.cellPhone, 20)}</td>
													<td>{ stringPipe(data?.employee?.companyDetails?.positionNames.join(" / "),25) }</td>
													<td>{stringPipe(data?.employee?.companyDetails?.department, 20)}</td>
													<td>{stringPipe(data?.employee?.companyDetails?.primaryMailStop, 20)}</td>
													<td>{stringPipe(data?.employee?.companyDetails?.costCenter, 20)}</td>
													<td>{stringPipe(data?.employee?.companyDetails?.employeeNumber, 10)}</td>
													<td>{data?.employeeAccount?.userName}</td>
													<td>{filterAccountType(data?.employeeAccount?.accountTypeId)}</td>
												</tr>))
											}
											<div key="end" ref={observerTarget} >
													{isLoading && <p>Loading...</p>}
													{error && <p>Error: {error.message}</p>}	
											</div>
                                        </tbody>
                                    </table>
                                </div>
                                {/* <!-- End Table -->
                                          <!-- Add an Account --> */}
                                <div className="add_account mt-4">
                                    <a onClick={() => setAccountid('')} data-bs-toggle="modal" data-bs-target="#staticBackdrop"> <img src="assets/img/add_account.svg" alt="" /><span>Add an Account</span></a>
                                </div>
                                {/* <!-- Add an Account --> */}
                            </div>
                            <div className="col-lg-1">
                                <div className="alphabet mt-5 pt-4">
                                    <div onClick={() => sortByAlpha("a")}>A - B</div>
                                    <div onClick={() => sortByAlpha("c")}>C - D</div>
                                    <div onClick={() => sortByAlpha("e")}>E - F</div>
                                    <div onClick={() => sortByAlpha("g")}>G - H</div>
                                    <div onClick={() => sortByAlpha("i")}>I - J</div>
                                    <div onClick={() => sortByAlpha("k")}>K - L</div>
                                    <div onClick={() => sortByAlpha("m")}>M - N</div>
                                    <div onClick={() => sortByAlpha("o")}>O - P</div>
                                    <div onClick={() => sortByAlpha("q")}>Q - R</div>
                                    <div onClick={() => sortByAlpha("s")}>S - T</div>
                                    <div onClick={() => sortByAlpha("u")}>U - V</div>
                                    <div onClick={() => sortByAlpha("w")}>W - X</div>
                                    <div onClick={() => sortByAlpha("y")}>Y - Z</div>
                                    <div onClick={() => sortByAlpha("0")}>0 - 3</div>
                                    <div onClick={() => sortByAlpha("4")}>4 - 6</div>
                                    <div onClick={() => sortByAlpha("7")}>7 - 9</div>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>

                </div>
                <AccountDelete userID={accountid} setuserID={setAccountid} addressUrl={url} deleteName={deleteName} getAccountList={getAccountList} />
                <AccountAdd userID={accountid} setuserID={setAccountid} getAccountList={getAccountList} />
                <ReassignMail userID={accountid} addressUrl={url} deleteName={deleteName} setuserID={setAccountid} />
                <ResetPassword userID={accountid} addressUrl={url} deleteName={deleteName} setuserID={setAccountid} />
            </div>
        </>

    )
}

export default Accounts