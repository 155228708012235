import React, { useState } from 'react'
import { CommonState } from '../../../../../context/commonContext'
//import { postService } from '../../../../../services/commonApi'

const AddFolder = ({ onAddFolder }) => {

    const { refreshrefreshFolderData,
        setRefreshFolderData } = CommonState()

    const [folderName, setFolderName] = useState('')
    const [error, setError] = useState('')

    const addFolder = () => 
	{
        if (!folderName) 
		{
            return setError('Provide a folder name')
        }
        else 
		{
			onAddFolder( folderName ).then( (res) =>
			{
				resetForm()
				setRefreshFolderData(!refreshrefreshFolderData)
				document.getElementById('modalClose').click()
			})
			.catch((err) => 
			{
				return setError()
			})
        }
    }

    const resetForm = () => 
	{
        setError('')
        setFolderName('')
    }

    return (
        <div className="modal fade add_condition" id="add_folder" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body p-4">
                        <h2 className="heading_1 mb-3">Add Folder</h2>
                        {error && <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                            <span className="me-3"><img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" /></span>{error}
                        </div>}
                        <div className="row">
                            <div className="fm_grp col-md-12">
                                <label htmlFor="folderName" className="form-label"><p>Add the following folder:</p></label>
                                <input className="form-control" type="text" id="folderName" onInput={(e) => setFolderName(e.target.value)} value={folderName} />
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-end mt-lg-5 pt-lg-5">
                                <div className="text-end">
                                    <button type="button" className="btn btn-link  btn_link_primary" data-bs-dismiss="modal" id='modalClose' onClick={resetForm}>Cancel</button>
                                    <button type="button" className="btn btn_cstm_primary" onClick={() => addFolder()}>Add Folder</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddFolder
