/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { getService, postFormData, putFormData } from '../../../../../services/commonApi'
import AddInputReport from '../modal/AddInputReport'


const Addreport = ({ reportId, url, setReportId, getList }) => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [version, setVersion] = useState('')
    const [inputs, setInputs] = useState([])
    const [input, setInput] = useState()
    const [reportmap, setReportmap] = useState({})
    const [error, setError] = useState(false)
    const [submit, setSubmit] = useState(false)

    var [subfiles, setSubfiles] = useState([])

    useEffect(() => {
        if (reportId) {
            getReportDetails()
        } else {
            resetForm()
        }
    }, [reportId])


    var [file, setFile] = useState([])

    const getReportDetails = () => {
        getService(url + '/' + reportId?.reportId, true).then(
            (res) => {
                if (res.status === 200) {
                    setName(res?.data?.title)
                    setDescription(res?.data?.description)
                    setVersion(res?.data?.version)
                    setReportSubmit(res?.data?.files[0]?.fileName)
                    // setFile([res?.data?.files])
                    setSubfiles(res?.data?.files.slice(1).map((fileData, i) => ({ fileName: fileData?.fileName, variable: fileData.label, value: 'subreport' + i + 1 })))
                    setInputs([...JSON.parse(res?.data?.input)])
                    // setReportmap(res?.data?.files)
                }
            }
        )
    }

    const [reportSubmit, setReportSubmit] = useState()

    const appendFile = (property, e) => {
        // console.log(e)
        setFile([...file, { name: property, fileName: e.target.files[0].name, file: e.target.files[0] }])
        if (property === 'report') {
            reportmap[property] = null
            setReportSubmit(e.target.files[0].name)
            console.log('reportsubmit')
        } else {
            reportmap[property] = "SUBREPORT"
            const index = property.charAt(property.length - 1);
            subfiles[index - 1] = { value: property, fileName: e.target.files[0].name, variable: "SUBREPORT" }
            // setSubfiles([subfiles])
        }
    }

    // const removeFile = (index) => {
    //     reportmap.splice(index, 1)
    //     setReportmap([...reportmap])

    //     file.splice(index, 1)
    //     setFile([...file])

    //     subfiles.splice(index - 1, 1)
    //     setSubfiles([...subfiles])
    // }



	const setFormData = () => 
	{
		var formData = new FormData()
        setSubmit(true)

		if (!name || !description || !reportSubmit ) 
		{
			if ( !name  && description && reportSubmit ) 
			{
				return setError("Name is a required field");
			} 
			else if ( !description && name && reportSubmit) 
			{
				return setError("Description is a required field");
			} 
			else if ( description && name && !reportSubmit ) 
			{
				return setError("Report File is a required field");
			} 
			return setError("Required fields are missing !");
		}
		else
		{
			formData.append('name', name)
			formData.append('description', description)
			version && formData.append('version', version)
			formData.append('inputs', JSON.stringify(inputs))
			formData.append('access', true)
			formData.append('emailOnly', false)
			formData.append('version', 1)

			// formData.append('files', file)
			for (let i = 0; i < file.length; i++) 
			{
				console.log(file[i].file)
				formData.append(file[i].name, file[i].file)
			}
			formData.append('reportmap', JSON.stringify(createReportMap(file)))

			updateReport(formData)
		}
	}

    const updateReport = (data) => {
        reportId ?
            putFormData(url + '/' + reportId?.reportId, data, true).then(
                (res) => {
                    if (res.status === 200) {
                        getList()
                        document.getElementById('closeaddreportmodal').click()
                    }
                }
            )
            :
            postFormData(url, data, true).then(
                (res) => {
                    if (res.status === 200) {
                        getList()
                        document.getElementById('closeaddreportmodal').click()
                    }
                }
            )
			setError('')

    }

    const createReportMap = (data) => {
        let d = {}
        for (let i = 0; i < data.length; i++) {
            const element = data[i];

            d[element.name] = i === 0 ? null : subfiles[i - 1].variable
        }
        return d
    }

    const resetForm = () => {
        setName('')
        setInputs([])
        setVersion('')
        setReportmap([])
        setDescription('')
        setReportId('')
        setFile([])
        setSubfiles([])
        setReportSubmit('')
		setSubmit(false)
		setError('')
    }

    const addSubFile = () => {
        setSubfiles([...subfiles, { 'name': '', fileName: '', 'variable': '' }])
    }
    const deleteInput = event => {
        setInputs(inputs.filter(i => (i.inputlabel !== event.inputlabel)))
        // console.log(inputs);
    };
    useEffect(() => {
        if (input?.inputlabel) {
            setInputs([...inputs, input])
            // console.log(input, "i");
        }
    }, [input])

    const deleteSubFile = (i, property) => {

        // reportmap.splice(i + 1, 1)
        // setReportmap([...reportmap])
        delete reportmap[property]
        console.log(reportmap)
        setReportmap(reportmap)

        file.splice(i + 1, 1)
        setFile([...file])

        subfiles.splice(i, 1)
        setSubfiles([...subfiles])
    }

    function handleChange(i, e) {
        // file[i + 1].
        subfiles[i].variable = e
        setSubfiles([...subfiles])
    }

    return (
        <>
            <div className="modal fade add_canned_report" id="add_canned_report" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body p-4">
                            <h2 className="heading_1 mb-3">{reportId ? 'Edit' : 'Add'} canned report</h2>
								{error && <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
										<span className="me-3"><img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" /></span>{error}
									</div>
								}
                            <div className="row">
                                <div className="fm_grp col-md-10">
                                    <label htmlFor="reportName" className="form-label">Report name</label>
                                    <input type="text" className={`form-control ${submit && !name ? 'error_input' : ''}`}  id="reportName" onInput={(e) => setName(e.target.value)} value={name} />
                                </div>
                                <div className="fm_grp col-md-10">
                                    <label htmlFor="descrp" className="form-label">Description</label>
                                    <input type="text" className={`form-control ${submit && !description ? 'error_input' : ''}`} id="descrp" onInput={(e) => setDescription(e.target.value)} value={description} />
                                </div>
                                <hr className="cst_shaprater mx-lg-3" />

                                <div className="mb-3">
                                    <h3 className="fnt_14 text_primary fnt_w_bold">Report inputs</h3>
                                    <div className="add_rule_table form_table">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Display label</th>
                                                    <th>Value label</th>
                                                    <th>Input type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {inputs?.length && inputs?.map((data, i) => (
                                                    <tr key={i}>
                                                        <td>{data?.inputlabel}</td>
                                                        <td>{data?.inputvalue}</td>
                                                        <td>{data?.inputtype}</td>
                                                        <td><img src="assets/img/Delete.svg" alt="" onClick={() => deleteInput(data)} /></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* <!-- Add an Account --> */}
                                    <div className="add_account">
                                        <a data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#add_input_report"> <img src="assets/img/add_account.svg" alt="" /><span className="fnt_12">Add input</span></a>
                                    </div>
                                    {/* <!-- Add an Account --> */}
                                </div>
                                <hr className="cst_shaprater mx-lg-3" />

                                <div>
                                    <h3 className="fnt_14 text_primary fnt_w_bold">Report files</h3>
                                    <div className="fm_grp">
                                        <div className="row align-items-center">
                                            <span className="col-lg-10">
                                                <label htmlFor="upload-photo">Report file</label>
                                                <input type="text" className={`form-control ${submit && !reportSubmit ? 'error_input' : ''}`} value={reportSubmit} />
                                            </span>
                                            <span className="col-lg-2 pt-3">
                                                <input type="file" className="form-control d_none" onChange={(e) => appendFile('report', e)} id="upload-photo" />
                                                <label htmlFor="upload-photo" className="file_type">Browse...</label>
                                            </span>
                                        </div>
                                    </div>
                                    {subfiles?.map((data, i) => (
                                        <div key={i} className="col-lg-12 ps-3">
                                            <div className="row align-items-center">
                                                <span className="col-lg-7">
                                                    <div className="fm_grp">
                                                        {/* <label htmlFor="inputState"  className="form-label">Sub-report file</label>
                                                        <input type="text"  className="form-control" id="Firstname" /> */}
                                                        <div className="row align-items-center">
                                                            <span className="col-10 col-lg-11">
                                                                <label htmlFor={`upload-photo+${i + 1}`}>Sub-report file</label>
                                                                <input type="text" className="form-control" value={data?.fileName} />
                                                            </span>
                                                            <span className="col-2 col-lg-1 pt-2">
                                                                <input type="file" className="form-control d_none" onChange={(e) => appendFile('subreport' + (i + 1), e)} id={`upload-photo+${i + 1}`} />
                                                                <label htmlFor={`upload-photo+${i + 1}`} className="file_type">Browse...</label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </span>
                                                <span className="col-lg-4">
                                                    <div className="fm_grp">
                                                        <label htmlFor="inputState" className="form-label">Variable</label>
                                                        <input type="text" className="form-control" id="variable" onChange={(e) => { handleChange(i, e.target.value) }} value={data?.variable} />
                                                    </div>
                                                </span>
                                                <span className="col-lg-1">
                                                    <a onClick={() => deleteSubFile(i, data?.value)}>
                                                        <img src="/assets/img/Delete.svg" alt="" />
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                    {/* <!-- Add an Account --> */}
                                    <div className="add_account">
                                        <a href="#" onClick={() => addSubFile()}> <img src="assets/img/add_account.svg" alt="" /><span className="fnt_12">Add report file</span></a>
                                    </div>
                                    {/* <!-- Add an Account --> */}
                                </div>
                                <div className="d-flex flex-wrap align-items-center justify-content-end mt-3">
                                    <div className="text-end">
                                        <button type="button" className="btn btn-link btn_link_primary" id="closeaddreportmodal" onClick={() => resetForm()} data-bs-dismiss="modal">Cancel</button>
                                        <button type="button" className="btn btn_cstm_primary px-3" onClick={() => setFormData()}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddInputReport setInput={setInput} />
        </>
    )
}

export default Addreport