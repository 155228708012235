/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { request_account_api } from "../../services/AuthApi";
import "./Signup.css";
import AccountInfo from "./SignupComponents/AccountInfo";
import CompanyInfo from "./SignupComponents/CompanyInfo";
import ContactInfo from "./SignupComponents/ContactInfo";
import NameInfo from "./SignupComponents/NameInfo";

const Signup = ({ children, setUserData }) => {
  const [navLink, setNavLink] = useState("name");
  const [nameInput, setNameInput] = useState();
  const [nameInputError, setNameInputError] = useState();

  const [companyInput, setCompanyInput] = useState();
  const [companyInputError, setCompanyInputError] = useState();

  const [contactInput, setContactInput] = useState();
  const [contactInputError, setContactInputError] = useState();

  const [accountInput, setAccountInput] = useState();
  const [accountInputError, setAccountInputError] = useState();

  const [error, setError] = useState(false);


  useEffect(() => {
    switch (navLink) {
      case 'name':
        setError(nameInputError)
        break;
      case 'company':
        setError(companyInputError)
        break;
      case 'contact':
        setError(contactInputError)
        break;
      default:
        setError(accountInputError)
        break;
    }
  }, [navLink, nameInputError, companyInputError, contactInputError, accountInputError])


  const signup = (accountInput) => {

    if ((nameInput && !nameInputError) && (companyInput && !companyInputError) && (contactInput && !contactInputError) && accountInput) {

      // console.log({ ...nameInput, ...companyInput, ...contactInput, ...accountInput })
      const data = ({ ...nameInput, ...companyInput, ...contactInput, ...accountInput })

      request_account_api(data).then(
        (res) => {
          // console.log(res)
          if (res.request.status === 200) {
            setUserData({
              nameInput, companyInput, contactInput, accountInput
            })
            document.getElementById('closeButton').click()
          } else 
		  {
            setError(res.response.data.error.info[0])
          }
        }
      )
    }




  };

  return (
    <>
      <span>{children}</span>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="mdl_head">
              <div className="row gx-0">
                <div className="col-12 col-lg-3 col-xl-2">
                  <div className="bg_primary">
                    <div className="pstn_relative">
                      <h5 className="modal-title p-4" id="staticBackdropLabel">
                        Add account
                      </h5>
                      <button
                        type="button"
                        className="btn-close btnClose p-4 d-block d-lg-none sm_btn-close"
                        data-bs-dismiss="modal"
                        id="closeButton"
                        aria-label="Close"
                      ><i className="bi bi-x d-none"></i></button>
                    </div>
                  </div>
                  {/* start responsive */}
                  {error && (
                    <div className="alert alert-danger sm_ctm_alert pl-4 d-block d-lg-none" role="alert">
                      <span>
                        <img src="/assets/img/error_icon_white.png" className="img-fluid" alt=""/>
                      </span>{" "}
                      {error}
                    </div>
                  )}
                  {/* end responsive */}
                </div>

				<div className="col-12 col-lg-9 col-xl-10 text-end">
                  	<div className="pstn_relative">
						<button type="button" className="btn-close btnClose pt-3 pe-4 d-none d-lg-block ms-auto" data-bs-dismiss="modal" aria-label="Close"></button>
						{/* start desktop */}
						{error && (
							<div className="alert alert-danger ctm_alert d-none d-lg-block" role="alert">
								<span className="me-3">
									<img src="/assets/img/error_icon_white.png" className="img-fluid" alt=""/>
								</span>{" "}
								{error}
							</div>
                    	)}
                    	{/* end desktop */}
					</div>
				</div>
			   </div>
            </div>
            <div className="modal-body p-lg-0">
              <div className="row gx-0">
                {/* <!-- Start Left nav-link --> */}
                <div className="col-lg-3 col-xl-2 bg_primary mb-4 mb-lg-0">
                  <div
                    className="nav flex-column nav-pills"
                    aria-orientation="vertical"
                  >
                    <button
                      onClick={() => setNavLink("name")}
                      className={`nav-link ${navLink === "name" ? "active" : ""
                        }`}
                      id="v-pills-home-tab"
                      type="button"
                    >
                      Names
                      {nameInputError && <span className="ms-3">
                        <img
                          src="assets/img/error_icon.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </span>}
                    </button>
                    <button
                      onClick={() => setNavLink("company")}
                      className={`nav-link ${navLink === "company" ? "active" : ""
                        }`}
                      id="v-pills-profile-tab"
                      type="button"
                    >
                      Company info
                      {companyInputError && <span className="ms-3">
                        <img
                          src="assets/img/error_icon.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </span>}
                    </button>
                    <button
                      onClick={() => setNavLink("contact")}
                      className={`nav-link ${navLink === "contact" ? "active" : ""
                        }`}
                      id="v-pills-messages-tab"
                      type="button"
                    >
                      Contact info
                      {contactInputError && <span className="ms-3">
                        <img
                          src="assets/img/error_icon.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </span>}
                    </button>
                    <button
                      onClick={() => setNavLink("account")}
                      className={`nav-link ${navLink === "account" ? "active" : ""
                        }`}
                      id="v-pills-settings-tab"
                      type="button"
                    >
                      Account info
                      {accountInputError && <span className="ms-3">
                        <img
                          src="assets/img/error_icon.svg"
                          className="img-fluid"
                          alt=""
                        />
                      </span>}
                    </button>

                  </div>
                </div>
                {/* <!-- End Left nav-link --> */}

                {/* <!-- Start right Tab-content --> */}
                <div className="col-lg-9 col-xl-10">
                  <div className="tab-content">
                    {/* <!-- Start Name Tab-pane--> */}
                    {navLink === "name" && (
                      <NameInfo
                        navLink={navLink}
                        setNavLink={setNavLink}
                        error={nameInputError}
                        setError={setNameInputError}
                        nameInput={nameInput}
                        setNameInput={setNameInput}
                      />
                    )}
                    {/* <!-- End Name Tab-pane--> */}

                    {/* <!-- Start Comany Info Tab-pane--> */}
                    {navLink === "company" && (
                      <CompanyInfo
                        navLink={navLink}
                        setNavLink={setNavLink}
                        error={companyInputError}
                        setError={setCompanyInputError}
                        companyInput={companyInput}
                        setCompanyInput={setCompanyInput}
                      />
                    )}
                    {/* <!-- End Comany Info Tab-pane--> */}

                    {/* <!-- End Contact Info Tab-pane--> */}
                    {navLink === "contact" && (
                      <ContactInfo
                        navLink={navLink}
                        setNavLink={setNavLink}
                        error={contactInputError}
                        setError={setContactInputError}
                        contactInput={contactInput}
                        setContactInput={setContactInput}
                      />
                    )}
                    {/* <!-- End Contact Info Tab-pane--> */}

                    {/* <!-- End Account Info Tab-pane--> */}
                    {navLink === "account" && (
                      <AccountInfo
                        navLink={navLink}
                        setNavLink={setNavLink}
                        error={accountInputError}
                        setError={setAccountInputError}
                        accountInput={accountInput}
                        setAccountInput={setAccountInput}
                        signup={signup}
                      />
                    )}
                    {/* <!-- End Account Info Tab-pane--> */}
                  </div>
                </div>
                {/* <!-- End right Tab-content --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
    </>
  );
};

export default Signup;
