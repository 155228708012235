/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { AuthState } from '../../../../context/authContext';
import { getService } from '../../../../services/commonApi';
import { getCompanyIdToken } from '../../../../services/tokenService';
import AddressDeleted from './Modals/AddressModals/AddressDeleted';
import AddressModal from './Modals/AddressModals/AddressModal';

const Address = ({ activeTab }) => {
    const { databaseRoute, setDatabaseRoute } = AuthState()

    const [addressList, setAddressList] = useState([])
    const [sortby, setSortby] = useState()
    const [filter, setFilter] = useState()
    var start = 1;
    var stop = 30;

    var addressUrl = `/mercury/company/${getCompanyIdToken()}/address/`
    useEffect(() => {
        if (databaseRoute === 'address') {
            getAddressData()
        }
    }, [databaseRoute])

    const getAddressData = () => {
        getService(addressUrl + 'list', true, {
            params: {
                sortby, filter
            }
        }).then((
            res => {
                if (res?.status === 200) {
                    setAddressList(res?.data?.list)
                }
            }
        ))
    }
    const [addressID, setAddressID] = useState()

    const [selectedData, setSelectedData] = useState()

    useEffect(() => {
        const search = setTimeout(() => {
            if (databaseRoute === 'address') {
                getAddressData()
            }
        }, 1500);
        return () => clearTimeout(search)
    }, [filter, sortby])
    return (
        <>

            <div className="card-header d-md-none" role="tab" id="heading-C" onClick={() => { setDatabaseRoute('address') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-C" aria-expanded="true" aria-controls="collapse-C">
                        Addresses  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-C" className={`bgYellow collapse ${databaseRoute === "address" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-C">
                <div className="head d-none d-md-block">
                    <h4 className="heading_1 text_dark mb-0">Addresses</h4>
                </div>
                <div className="p-4">
                    <p>Please enter all of your company’s Addresses that the system will use to receive mail, and keep this list current. If the list is not kept current, Addresses for mail may be inaccurate. <a className="text_dark">More…</a></p>
                </div>
                <div className="p-4  pt-0 col-lg-12">
                    <div className="row">
                        <div className="col-lg-11">
                            {/* <!-- Start search--> */}
                            <div className="row mb-3 mt-4">
                                <div className="col-md-6 col-xl-4">
                                    <div className="serach_box">
                                        <input className="form-control" type="text" aria-label="Search" onInput={(e) => setFilter(e.target.value)} value={filter} placeholder="Search Addresses" />
                                        <a href="#"><span className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span></a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Start search -->
                            <!-- Start Table --> */}
                            <div className="table-responsive cst_border cst_table address_table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Address line 1</th>
                                            <th>Address line 2</th>
                                            <th>City</th>
                                            <th>State </th>
                                            <th>Zip code</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {addressList?.map((data, i) => (
                                            <tr key={i}>
                                                <td>{data?.address1}</td>
                                                <td>{data?.address2}</td>
                                                <td>{data?.city}</td>
                                                <td>{data?.state}</td>
                                                <td>{data?.zipcode}</td>
                                                <td>
                                                    <a onClick={() => setAddressID(data?.addressId)} data-bs-toggle="modal" data-bs-target="#Save_Address"><img src="assets/img/Edit.svg" alt="" />
                                                    </a>
                                                </td>
                                                <td><a onClick={() => { setAddressID(data?.addressId); setSelectedData(data) }} data-bs-toggle="modal" data-bs-target="#delete_Address"><img src="assets/img/Delete.svg" alt="" /> </a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {/* <!-- End Table -->
                                                                                                                    <!-- Add an Account --> */}
                            <div className="add_account mt-4">
                                <a onClick={() => setAddressID('')} data-bs-toggle="modal" data-bs-target="#Save_Address"> <img src="assets/img/add_account.svg" alt="" /><span>Add an Address</span></a>
                            </div>
                            {/* <!-- Add an Account --> */}
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
                <AddressModal addressID={addressID} setData={setAddressID} url={addressUrl} getList={getAddressData} />
                <AddressDeleted addressID={addressID} setAddressID={setAddressID} url={addressUrl} data={selectedData} getList={getAddressData} />
            </div>
        </>
    )
};

export default Address;