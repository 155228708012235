import React from 'react'
import { deleteService } from '../../../../../services/commonApi'

const DeleteRule = ({ url, name, getList, id, setId }) => {

    const deleteRule = () => {
        deleteService(url + '/' + id, '', true).then(
            (res) => {
                if (res.request.status === 200) {
                    setId()
                    document.getElementById('ruledeletemodal').click()
                    getList()
                } else {

                }
            }
        )
    }

    return (
        <div className="modal fade deleteconfirmation" id="delete_rule_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body py-4 px-lg-0">
                        <h2 className="heading_1 ps-lg-4">Delete confirmation</h2>

                        <div className="p-lg-5">
                            <div className="col-xl-8 m-auto py-4">
                                <h3 className="heading_2 mb-4">Delete “{name}”?</h3>


                                {/* <!--  Error alert input box  --> */}
                                {/* <div  className="fm_grp">
                                    <div  className="error_inupt_box">
                                        <span  className="me-3"><img src="assets/img/error_icon.svg"  className="img-fluid" alt="" /></span>
                                        <div  className="error_inupt">
                                            <label htmlFor="inputAddress"  className="form-label" required="">* Email address is a required field.</label>
                                            <input type="email"  className="form-control" id="inputAddress" placeholder="" />
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 px-3">
                            <div> </div>
                            <div className="text-end">
                                <button type="button" className="btn btn-link  btn_link_primary" data-bs-dismiss="modal" id="ruledeletemodal" onClick={() => setId()}>Cancel</button>
                                <button type="button" className="btn btn_cstm_primary" onClick={() => deleteRule()}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteRule