import { createContext, useContext, useState } from "react";

const CommonContext = createContext();

const CommonProvider = ({ children }) => 
{
	const [toggleSidebar, setToggleSidebar] = useState(false);
	const [toggleMailMobilebar, setToggleMailMobilebar] = useState(true);
	const [refreshMailStats, setRefreshMailStats] = useState("");
	const [refreshMailData, setRefreshMailData] = useState("");
	const [helpUrl, setHelpUrl] = useState("");
	const [inactivityTimeout,setInactivityTimeout] = useState(1800);
	const [refreshFolderData, setRefreshFolderData] = useState("");
	const [trackFolderMails, setTrackFolderMails] = useState(false);
	const [hasNewMail,setHasNewMail] = useState(false)
	const [currentMail,setCurrentMail] = useState({folderid:"inbox",mailid:null});
	const [currentFolder,setCurrentFolder] = useState();
	const [folderList, setFolderList] = useState([])
	const [actionFolder,setActionFolder] = useState();

	return (
		<CommonContext.Provider value={{
				toggleSidebar,
				setToggleSidebar,
				toggleMailMobilebar,
				setToggleMailMobilebar,
				refreshMailStats,
				setRefreshMailStats,
				refreshMailData,
				setRefreshMailData,
				refreshFolderData,
				setRefreshFolderData,
				trackFolderMails,
				setTrackFolderMails,
				currentMail,
				setCurrentMail,
				hasNewMail,
				setHasNewMail,
				currentFolder,
				setCurrentFolder,
				folderList, 
				setFolderList,
				helpUrl, setHelpUrl,
				inactivityTimeout,setInactivityTimeout,
				actionFolder,setActionFolder,
			}} >
			{children}
		</CommonContext.Provider>
	);
};

export const CommonState = () => {
	return useContext(CommonContext);
};

export default CommonProvider;
