/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Select from "react-select";
import { getService } from '../../../../../../../services/commonApi';
import { getCompanyIdToken } from '../../../../../../../services/tokenService';

const Company = ({navLink,setNavLink,error,setError,companyInput,setCompanyInput,mode, resetData}) => 
{
	const [addressId, setAddress] = useState(companyInput?.addressId ? companyInput?.addressId : '')
	const [mailStopId, setMailstop] = useState(companyInput?.mailStopId ? companyInput?.mailStopId : '')
	const [departmentId, setDepartment] = useState(companyInput?.departmentId ? companyInput?.departmentId : '')
	const [employeeNumber, setEmployee_number] = useState(companyInput?.employeeNumber ? companyInput?.employeeNumber : '')
	const [positions, setPosition] = useState( companyInput?.positions )
	const [costCenterId, setCostCenter] = useState(companyInput?.costCenterId ? companyInput?.costCenterId : '')
	const [addresslist, setAddresslist] = useState([])
	const [mailStopList, setMailStopList] = useState([])
	const [departmentList, setDepartmentList] = useState([])
	const [costCenterList, setCostCenterList] = useState([])
	const [positionList, setPositionList] = useState([])
	const [submit, setSubmit] = useState(false)
	const [defaultPositions, setDefaultPositions] = useState( companyInput?.positions && companyInput?.positionNames ? companyInput?.positions.map((pos,i) => ({ 'positionId': pos, 'label': companyInput?.positionNames[i] })) : [] )

	const getAddressList = () => 
	{
		getService(`/mercury/company/${getCompanyIdToken()}/address/list`, true).then((res) => 
		{
			if (res?.status === 200) 
			{
				setAddresslist(res?.data?.list)
			}
		})
	}

	const getMailStopList = () => 
	{
		getService(`/mercury/company/${getCompanyIdToken()}/mailstop/list`, true).then((res) => 
		{
			if (res?.status === 200) 
			{
				setMailStopList(res?.data.list)
			}
		})
	}

	const getDepartmentList = () => {
		getService(`/mercury/company/${getCompanyIdToken()}/department/name/list?start=1&stop=30&primary=true`, true).then((res) => {
			if (res?.status === 200) {
				setDepartmentList(res?.data?.list)
			}
		})
	}

	const getPositionList = () => 
	{
		getService(`/mercury/company/${getCompanyIdToken()}/position/name/list?primary=true`, true).then((res) => 
		{
			if (res?.status === 200) 
			{
				setPositionList(res?.data?.list.map((data, i) => (
				{
					'id': i,
					'positionId': data?.positionId,
					'label': data?.name
				})))
			}
		})
	}

	const getCostCenterList = () => {
		getService(`/mercury/company/${getCompanyIdToken()}/costcenter/list`, true).then((res) => {
			if (res?.status === 200) {
				setCostCenterList(res?.data?.list)
			}
		})
	}

	useEffect(() => 
	{
		getAddressList()
		getDepartmentList()
		getMailStopList()
		getPositionList()
		if (mode !== 'request') 
		{
			getCostCenterList()
		}
	}, [])

	useEffect(() => 
	{
		if (!addressId) 
		{
			setAddress(addresslist[0]?.addressId)
		}
		if (!departmentId) 
		{
			setDepartment(departmentList[0]?.departmentId)
		}
		if (!mailStopId) 
		{
			setMailstop(mailStopList[0]?.mailStopId)
		}
		if (!costCenterId) 
		{
			setCostCenter(costCenterList[0]?.costCenterId)
		}
	}, [addresslist, departmentList, mailStopList, positionList, costCenterList])


	useEffect(() => 
	{
		setCompanyInput({ addressId, mailStopId, departmentId, employeeNumber, positions, costCenterId });
	}, [addressId, mailStopId, departmentId, employeeNumber, positions, costCenterId])


	const setCompanyInputData = () => 
	{
		setSubmit(true)
		setCompanyInput({ addressId, mailStopId, departmentId, employeeNumber, positions, costCenterId });
		if( !addressId || !mailStopId || !departmentId || !employeeNumber || !positions ) 
		{
			if( !addressId && mailStopId && departmentId && employeeNumber && positions ) 
			{
				setError({ ...error, err: true, desc:"Address is required field", addressId:true  } );
			} 
			else if( !mailStopId && addressId && departmentId && employeeNumber && positions ) 
			{
				setError({ ...error, err: true, desc:"Mailstop is required field", mailStopId:true } );
			} 
			else if( !departmentId && addressId && mailStopId && employeeNumber && positions ) 
			{
				setError({ ...error, err: true, desc:"Department is required field", departmentId:true } );
			} 
			else if( !employeeNumber && addressId && departmentId && mailStopId && positions ) 
			{
				setError({ ...error, err: true, desc:"Employee Number is required field", employeeNumber:true } );
			} 
			else if( !positions && addressId && departmentId && employeeNumber && mailStopId ) 
			{
				setError({ ...error, err: true, desc:"Position is required field", position:true } );
			}
			else
			{
				setError({ ...error, err: true, desc:"Required fields are missing." } );
			}
		}
		else
		{
			setError({ err:false, desc:"" });
			// push to the next.
			setNavLink("contact");
		}
	}

	const onSelect = (e) =>
	{
		setPosition( e.map( (data) => { return data?.positionId } ) )
	}

	return (
		<div className={`tab-pane fade ${navLink === "company" ? "show active" : ""}`} aria-labelledby="v-pills-profile-tab">
			<div className="col-lg-9 offset-lg-1 modalContentItems">
				{mode === 'request' && (<> <h2 className="heading_1">Account requester information</h2>
					<p className="mb-0">
						Please review all the following information before process your request.
					</p>
					<p className="fnt_w_bold">Please be as thorough as possible.</p></>)}
				<form className="pt-4">
					<div className="row g-3 align-items-center">
						<div className="fm_grp col-md-6">
							<label htmlFor="inputAddress" className={`form-label ${submit && !addressId ? 'error_text' : ''}`}>
								* Address {submit && !addressId && <span>is required</span>}
							</label>
							<select id="inputAddress" className={`form-select ${submit && !addressId ? 'error_input' : ''}`} onInput={(e) => setAddress(e.target.value)}
								value={addressId}>
								{addresslist?.map((data, i) => {
									return (
										<option key={i} value={data.addressId}>{data.address1} {data?.address2},{data?.city} {data?.state} {data?.zipcode}</option>
									)
								})}
							</select>
						</div>
						<div className="fm_grp col-md-6">
							<label htmlFor="inputMailStop" className={`form-label ${submit && !mailStopId ? 'error_text' : ''}`}>
								* Mail Stop {submit && !mailStopId && <span>is required</span>}
							</label>
							<select id="inputMailStop" className={`form-select ${submit && !mailStopId ? 'error_input' : ''}`} onInput={(e) => setMailstop(e.target.value)}
								value={mailStopId}>
								{mailStopList?.map((data, i) => {
									return (
										<option key={i} value={data.mailStopId}>{data.name}</option>
									)
								})}
							</select>
						</div>
						<div className="fm_grp col-md-6">
							<label htmlFor="inputDepartment" className={`form-label ${submit && !departmentId ? 'error_text' : ''}`}>
								* Department {submit && !departmentId && <span>is required</span>}
							</label>
							<select id="inputDepartment" className={`form-select ${submit && !departmentId ? 'error_input' : ''}`}
								onInput={(e) => setDepartment(e.target.value)}
								value={departmentId}
							>
								{departmentList?.map((data, i) => {
									return (
										<option key={i} value={data?.departmentId}>{data?.name}</option>
									)
								})}
							</select>
						</div>
						<div className="fm_grp col-md-6">
							<label htmlFor="inputEmployeeNumber" className={`form-label ${error?.employeeNumber && !employeeNumber ? 'error_text' : ''}`}>
								* Employee number {submit && !employeeNumber && <span>is required</span>}
							</label>
							<input type="text" id="inputEmployeeNumber" className={`form-control ${error?.employeeNumber && !employeeNumber ? 'error_input' : ''}`} onInput={(e) => setEmployee_number(e.target.value)}
								value={employeeNumber} />
						</div>
						<div className="fm_grp col-md-6">
							<label id="inputposition" htmlFor="inputPostion" className={`form-label ${error?.position && !positions ? 'error_text' : ''}`}>
								* Position {submit && !positions && <span>is required</span>}
							</label>
							<Select
								defaultValue={defaultPositions}
								isMulti
								name="positions"
								options={positionList}
								getOptionLabel={ option => option.label }
								getOptionValue={ option => option.positionId }
								className="basic-multi-select"
								classNamePrefix="select"
								onChange={onSelect}
							/>

						</div>
						{mode !== 'request' && <div className="fm_grp col-md-6">
							<label htmlFor="inputDepartment" className={`form-label ${submit && !costCenterId ? 'error_text' : ''}`}>
								* Cost Center {submit && !costCenterId && <span>is required</span>}
							</label>
							<select id="inputDepartment" className={`form-select ${submit && !costCenterId ? 'error_input' : ''}`} 
									onInput={(e) => setCostCenter(e.target.value)} value={costCenterId}>
							{
								costCenterList?.map((data, i) => {
									return (
										<option key={i} value={data?.costCenterId}>{data.name}</option>
									)
								})
							}
							</select>
						</div>}
					</div>
				</form>
			</div>
			<div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
				<div> </div>
				<div className="p-md-4 text-end">
					<button type="button" className="btn btn-link  btn_link_primary me-3" data-bs-dismiss="modal" 
								onClick={() => { setSubmit(false); setError({err:false,desc:''}); resetData() }}>
						Cancel
					</button>
					{(!mode || mode === 'request') && 
						<button type="button"className="btn btn_cstm_primary px-4" onClick={() => setCompanyInputData()}>
							Next
						</button>
					}
					{mode === 'edit' && <button
						type="button"
						className="btn btn_cstm_primary px-4"
						onClick={() => setCompanyInputData()}
					>
						Save edits
					</button>}
				</div>
			</div>
		</div>
	)
}

export default Company 