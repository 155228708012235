/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { putService, getService } from '../../../../services/commonApi'
import Dropdown from 'react-bootstrap/Dropdown';
import { FaStickyNote } from 'react-icons/fa'
import { checkForValue } from '../../../../pipes/stringPipes';
import { CommonState } from '../../../../context/commonContext';

function Mailheader({ mailUrl, mailDetails, saveMail, printMail, rescanRequest, requestOriginalMail, deleteMail, moveMail }) {
	var params = useParams();
	var url = useRef()

	const [historyData, setHistoryData] = useState({})
	const [selectedNav, setSelectedNav] = useState(1)
	const [mailSelected, setMailSelected] = useState(false)
	const { folderList, setFolderList, } = CommonState()

	useEffect(() => 
	{
		setMailSelected(false)
		if( params.id ) 
		{
			url.current = `/mercury/mail/mailboxes/folders/${params.id}+${params?.mailId ? '/mail/' + params.mailId : ''}`
			if (params.mailId) { setMailSelected(true); }
		}
	}, [params.id, params.mailId] )

	useEffect(() => 
	{
		if( mailUrl ) 
		{
			setMailSelected(true)
			// queue up the history for quick look
			getService(`${mailUrl}/copies/history`, true).then((res) => 
			{
				setHistoryData(res?.data)
			}, 
			err => 
			{
				console.log(err)
			})
		}
		else
		{
			setMailSelected(false)
		}
	}, [mailUrl])

	return (
		<>
			<div className={`row g-1 align-items-center ${!mailSelected && 'mailHeadDisable'}`}>
				<div className="col-lg-6 flex-grow-1 rounded-0 no_radius">
					<div className="d-flex inbox_head inbox_head_left pt-0">
						{(mailSelected && mailDetails?.received) && <p className="flex-grow-1 fnt_14 mb-0 mt-2">You received this mail on <b>{(new Date(mailDetails?.received).toLocaleDateString()) ?? ''}</b> at {(new Date(mailDetails?.received).toLocaleTimeString()) ?? ''}{mailDetails?.viewed ? `, and it was viewed on ${new Date(mailDetails?.received).toLocaleDateString() === new Date(mailDetails?.viewed).toLocaleDateString() ? 'same day' : new Date(mailDetails?.viewed).toLocaleDateString()} at ${new Date(mailDetails?.viewed).toLocaleTimeString()}` : ''}</p>}
						{!(mailSelected && mailDetails?.received) && <p className="flex-grow-1 fnt_14 mb-0 mt-2">No mail selected</p>}
						<div className="ms-lg-4 inbox_right_icon_link">
							<a className={mailDetails?.note ? 'active' : ''} onClick={() => setSelectedNav(1)} data-bs-toggle="offcanvas" data-bs-target={mailDetails?.note ? '#offcanvasScrolling' : '#note_edit'} aria-controls="offcanvasScrolling">
								{!mailDetails?.note ?
									(<FaStickyNote fontSize={'1.2rem'} />) :
									(
										<img src="/assets/img/Note.png" height={21} alt="" />
									)}
								<span>Note</span>
							</a>
						</div>
					</div>
				</div>
				<div className="col-6 col-lg-auto">
					<div className="inbox_right_icon_link">
						<a data-bs-toggle="modal" data-bs-target="#note_mine" >
							<img src="/assets/img/Notmine.png" alt="" />
							<span>Not mine</span>
						</a>
						<a onClick={() => rescanRequest()} className={`${mailDetails?.rescanRequestId ? 'active' : ''}`}>
							{mailDetails?.rescanRequestId ?
								(<img src="/assets/img/ResetView.png" alt="" />) :
								(<img src="/assets/img/ResetView.png" alt="" />)
							}
							<span>Rescan</span>
						</a>
						<a data-bs-toggle="modal" data-bs-target="#forwardmail" >
							<img src="/assets/img/Forward.png" alt="" />
							<span>Forward</span>
						</a>
					</div>
				</div>
				<div className="col-lg-auto">
					<div className="inbox_right_icon_link">
						<a onClick={() => saveMail()}>
							<img src="/assets/img/Save.png" alt="" />
							<span>Save</span>
						</a>
						<a onClick={() => requestOriginalMail()} className={`${mailDetails?.originalRequested ? 'active' : ''}`}>
							{mailDetails?.originalRequested ?
								(<img src="/assets/img/Original.png" alt="" />) :
								(<img src="/assets/img/Original.png" alt="" />)
							}
							<span>Original</span>
						</a>
						<a>
							<img src="/assets/img/Print.png" alt="" onClick={() => printMail()} />
							<span>Print </span>
						</a>

						<Dropdown >
							<Dropdown.Toggle className='text-center' as={'a'} variant="none" align="end" >
								<img src="/assets/img/History.png" alt="" className="mb-1" />
								<span>History</span>
							</Dropdown.Toggle>
							<Dropdown.Menu >
								<div className="history_modal_body" aria-labelledby="dropdownMenuButton2">
									{/* <a title="Close" id="close" onClick={() => setHistoryDropdown(false)}>Close</a> */}
									{ historyData && 
										<>
											<div className="hist_box">
												<p><b>{checkForValue(historyData?.senderName?.name, true)}</b></p>
												{historyData?.senderAddress?.address1 && <p><small>{historyData?.senderAddress?.address1} {historyData?.senderAddress?.city + ', '} {historyData?.senderAddress?.state} {historyData?.senderAddress?.zip}</small></p>}
												<p>
													<a style={{ 'cursor': 'pointer' }} data-bs-toggle="modal" data-bs-target="#sender_name"><u>Edit</u></a>
												</p>
											</div>
											<div className="hist_box">
												<p>Addressed to</p>
												{ historyData?.receiverName?.fullName && <p><b>{ historyData?.receiverName?.fullName}</b></p> }
												{ historyData?.positionName?.name && ( historyData?.receiverName?.fullName ? <p>{historyData?.positionName?.name}</p> : <p><b>{historyData?.positionName?.name}</b></p> ) }
												{ historyData?.departmentName?.name && ( historyData?.receiverName?.fullName || historyData?.positionName?.name ? <p>{historyData?.departmentName?.name}</p> : <p><b>{historyData?.departmentName?.name}</b></p> ) }
												{ historyData?.companyName?.name && ( historyData?.receiverName?.fullName || historyData?.positionName?.name || historyData?.departmentName?.name ? <p>{historyData?.companyName?.name}</p> : <p><b>{historyData?.companyName?.name}</b></p> ) }
												{ historyData?.receiverName?.fullName && historyData?.positionName?.name && historyData?.departmentName?.name && historyData?.companyName?.name ? <p><b>Not Identified</b></p> : '' }
												{ historyData?.address?.city && <p>{historyData?.address?.address1 + ','} {historyData?.address?.city + ','} {historyData?.address?.state} {historyData?.address?.zipcode}</p> }
											</div>
											<div className="hist_box">
												<p><b><i>You received this mail because it was {historyData?.reason} to you.</i></b></p>
											</div>
											<div className="hist_box">
												<p>Digitized on <b>{new Date(historyData?.processed).toLocaleDateString()} at {new Date(historyData?.processed).toLocaleTimeString()}</b></p>
												<p>Digitially sent on <b>{new Date(historyData?.received).toLocaleDateString()} at {new Date(historyData?.received).toLocaleTimeString()}</b></p>
												<p>There are <b>{historyData?.pages}</b> pages in this mail</p>
											</div>
											{historyData?.rescanRequests && historyData?.rescanRequests?.length>0 &&
												<div className="hist_box">
													<p><b>These users have requested a rescan of this mail:</b></p>
													{historyData?.rescanRequests?.map((data, i) => (
														<div key={i} className="hist_box">
															<p>
																{data?.employeeName} on {new Date(data?.requestDate).toLocaleDateString()}  
																{ data?.fulfilledDate ? 
																		` and completed ${new Date(data?.fulfilledDate).toLocaleDateString()}`
																	: 
																		data?.startDate ? 
																				` and started ${new Date(data?.startDate).toLocaleDateString()}`
																			: 
																				` and is still pending` 
																}
															</p>
														</div>
													))}
												</div>
											}
											<div className="hist_box">
												<p>These users have a copy of this mail:</p>
												{historyData?.copies?.map((data, i) => (
													<div key={i} className="hist_box">
														<p><b>{data?.employeeName?.fullName}</b></p>
														<p>{data?.companyDetails?.department}</p>
														<p>	{data?.companyDetails?.address?.address1} {data?.companyDetails?.address?.city} {data?.companyDetails?.address?.state} {data?.companyDetails?.address?.zipcode} </p>
													</div>
												)
												)}
											</div>
										</>
									}
								</div>
							</Dropdown.Menu>
						</Dropdown >
					</div>
				</div>
				<div className="col-6 col-lg-auto order-lg-3">
					<div className="inbox_right_icon_link">
						<Dropdown>
							<div className="move_to">
								<Dropdown.Toggle className='text-center' id="dropdown-basic" as={'a'} variant="none" align="end">
									<img src="/assets/img/Move.png" alt="" className="mb-1" />
									<span>Move to…</span>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<ul aria-labelledby="dropdownMoveto">
										<li><h4 className="fnt_16 text_primary fnt_w_bold pt-4 folder_menu_header">Folders</h4></li>
										{
											folderList?.map((item, i) => 
											{
												return ( <li key={i} onClick={() => moveMail(item?.folderId)}>
															<a className="dropdown-item folder_menu_item">{item.name}</a>
														</li> )
											})
										}
									</ul>
								</Dropdown.Menu>
							</div>
						</Dropdown>
						<a onClick={() => deleteMail()}>
							<img src="/assets/img/Delete.png" alt="" />
							<span>Delete</span>
						</a>
					</div>
				</div>
			</div >
		</>
	)
}

export default Mailheader