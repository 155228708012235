import React from 'react'
import { useNavigate } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';

const MailNameListItem = ({ data }) => {

    const navigate = useNavigate()

    function navigateMail(data) {
        console.log(data)
        navigate(`/mail/${data?.folderId}/${data?.mailId}#${data?.textMatch?.page}`)
    }

    return (
        <>
			<div className='search_results_item'  id='mail_search_results'>
				<span className="d-flex">
					<p><b>From Your Mail</b></p>
				</span>
				{data?.map((data, i) => (
					<Dropdown.Item key={i} className="help_box" as={'div'} onClick={() => navigateMail(data)}>
						{ data?.sender && 
							<>
								<span className="d-flex">
									<p><b>{data?.sender}</b></p>
								</span>
								<p>{new Date(data?.received || data?.created).toLocaleDateString()} at {new Date(data?.received || data?.created).toLocaleTimeString()}</p>
								<p dangerouslySetInnerHTML={{ __html: data?.textMatch?.text }} ></p>
							</>
						}
						{ !data?.sender && 
							<>
								<span className="d-flex">
									<i className="bi bi-envelope-fill"></i>
									<p>{new Date(data?.received || data?.created).toLocaleDateString()} at {new Date(data?.received || data?.created).toLocaleTimeString()}</p>
								</span>
								<p dangerouslySetInnerHTML={{ __html: data?.textMatch?.text }} ></p>
							</>
						}
						
					</Dropdown.Item>
				))
				}
			</div>
        </>
    )
}

export default MailNameListItem