/*
This is a React component that implements a Home page for a web application.

It has the following features:

	Imports dependencies, including various layout components and functionalities such as:
		Header component to render the header of the Home page.
		FloatingSidebar component to render the floating sidebar.
		Outlet component from react-router-dom to handle dynamic routing of the components.
		DynamicSidebar component to render the dynamic sidebar.
		FloatingSidebarMob component to render the floating sidebar for mobile devices.
		SettingsModal component to render the settings modal.
		HelpCanvas component to render the help canvas.
		InactivityBanner component to render an inactivity banner.
		NewMailBanner component to render a new mail banner.

	Use of React hooks to handle state and side effects in the component.

	User session tracking to logout the user after a certain period of inactivity. The inactivity period is 60 seconds, 
	and a countdown of the session is displayed in a banner before logout.

	Handling of location changes in the application using useLocation and useNavigate hooks.

	Management of the user's folder mails using CommonState hook.

	Implementation of logout functionality by sending a logout request to the server and clearing all user-related data.

	A return statement that renders the header, floating sidebar, dynamic sidebar, and outlet components within a container.
*/

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "./Home.css";
import Header from '../../layout/Header/Header'
import FloatingSidebar from "../../layout/FloatingSidebar/FloatingSidebar";
import { Outlet } from 'react-router-dom'
import DynamicSidebar from "../../layout/DynamicSidebar/DynamicSidebar";
import FloatingSidebarMob from "../../layout/FloatingSidebar/FloatingSidebarMob";
import SettingsModal from "../dashboard/Settings/SettingsModal";
import HelpCanvas from "../../layout/Header/HelpCanvas";
import { removeAuthToken, removeRefreshToken } from "../../../services/tokenService";
import InactivityBanner from "./InactivityBanner";
import { logout_api } from "../../../services/AuthApi";
import { useNavigate, useLocation } from 'react-router-dom'
import { CommonState } from "../../../context/commonContext";
import NewMailBanner from "../../layout/DynamicSidebar/Sidebars/newMailBanner/NewMailBanner";
import { getService, putService } from "../../../services/commonApi";

const Home = () => {

	const location = useLocation()
	const navigate = useNavigate()

	const { trackFolderMails, setTrackFolderMails, hasNewMail, setHasNewMail, inactivityTimeout } = CommonState()

	let timeoutId;
	var intervalTimer
	// 30 minute or 1800 second time out for inactivity.
	const [inactivityTime, setInactivityTime] = useState(inactivityTimeout);
	const [showPopup,setShowPopup] = useState( false );
	const [updateModified,setUpdateModified] = useState( false )
	const [message,setMessage] = useState('')
	
	useEffect(() => 
	{
		const route = location.pathname.split('/')[1]
		if (route === 'mail') 
		{
			setTrackFolderMails(true)
		} 
		else 
		{
			setTrackFolderMails(false)
		}
	}, [location])

	function startTimeOut( )
	{
		return setTimeout(() => 
		{
			openInactive();
		}, inactivityTime * 1000);
	}

	// create side effect to watch for inactivity.  set a timer in here.  When timer goes off, show the 
	// inactivity popup.  If not acted on, then log out.
	useEffect(() => 
	{
		document.addEventListener("mousemove", resetTimer);
		document.addEventListener("keypress", resetTimer);

		// do a timer.  Show the popup if it timesout.
		timeoutId = startTimeOut();

		// Unload the timer.
		return () => {
			clearTimeout(timeoutId);
			document.removeEventListener("mousemove", resetTimer);
			document.removeEventListener("keypress", resetTimer);
		};
	}, [inactivityTime]);

	// reset the inactivity timer.
	const resetTimer = () => 
	{
		if( !showPopup )
		{
			clearTimeout(timeoutId);
			timeoutId = startTimeOut();
		}
	};

	const cancelLogout = () =>
	{
		setShowPopup( false );
		clearTimeout(timeoutId);
		timeoutId = startTimeOut();
	}

	function openInactive() 
	{
		if( !showPopup )
		{
			setShowPopup( true );
			document.getElementById('popInactivityBanner').click();
		}
	}

	const closeNewMailPopup = () =>
	{
		setHasNewMail( false );
	}

	function logout() {
		setShowPopup( false );
		clearInterval(intervalTimer)
		logout_api().then(() => 
		{
			localStorage.removeItem('user')
			removeAuthToken()
			removeRefreshToken()
			sessionStorage.clear()
			navigate('auth')
			window.location.reload();
		})
	}

	const modified = () =>
	{
		if( updateModified )
		{
			// set the flag that we are no longer looking to modify the user account.
			setUpdateModified( false )
			setMessage('')
			// update the modified flag at the backend regardless if it was already modified or not.
			putService('/mercury/employee/access/security/modified', {} );
		}
	}

	useEffect( () =>
	{
		// get the current user account to determine if we need to have them update their preferences
		getService('/mercury/employee/access/security').then((res) => 
		{
			const oneDay = 24 * 60 * 60 * 1000; 
			const now = new Date();
			let modified = ( res.data.employeeAccount.modified ? new Date(res.data.employeeAccount.modified) : null );
			// compare the date of last login with todays date.  Change every thirty days and first time logged in.
			if( modified == null || Math.round(Math.abs( (now - modified) / oneDay )) >= 30 )
			{
				if( modified == null )
				{
					setMessage( 'Since this is your first time logging in, please review your personal information. Ensure it is accurate and as complete as possible.' )
				}
				else
				{
					setMessage( 'Please review your personal information and ensure it is accurate and as thorough as possible.' )
				}
				// open the settings model and have it prompt the user to update.  Set the modified flag.
				setUpdateModified( true );
				document.getElementById("open-settings").click();
			}
		})
	}, [] )

	return (
		<>
			<Header />
			<main>
				<SettingsModal onClose={modified} message={message} />
				<PanelGroup autoSaveId="mercury" direction="horizontal" >
					<Panel defaultSize={25}>
						<div className="offcanvas_wrap d-none d-md-block">
							<FloatingSidebar />
							<DynamicSidebar />
						</div>
					</Panel>
					<PanelResizeHandle className="ResizeHandleOuter" >
						<div className="ResizeHandleInner">
						</div>
					</PanelResizeHandle>
					<Panel >
						<Outlet />
					</Panel>
				</PanelGroup>
				<a data-bs-toggle="offcanvas" aria-controls="offcanvasScrolling" data-bs-target="#inactivityBanner" id='popInactivityBanner' className='d-none'> </a>
				<a data-bs-toggle="offcanvas" aria-controls="offcanvasScrolling" data-bs-target="#newMailBanner" id='popNewMailAlert' className='d-none'> </a>
				<InactivityBanner showPopup={showPopup} seconds={60} reset={cancelLogout} logout={logout} />
				{ 
					hasNewMail && <NewMailBanner showPopup={hasNewMail} seconds={10} dismissCallback={closeNewMailPopup} />
				}
			</main>
		</>
	)
};
export default Home;

