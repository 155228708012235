/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { RouteState } from '../../../../context/routeContext'
import AccountAddEdit from './modals/AccountAddEdit'
import DeleteAccountType from './modals/DeleteAccountType'
import { getService } from '../../../../services/commonApi'
import { getCompanyIdToken } from '../../../../services/tokenService'

const AccountType = () => {
    const { systemRoute, setSystemRoute } = RouteState()

    const [accountTypeList, setAccountTypeList] = useState([])
    const [accountTypeId, setAccountTypeId] = useState('')

    var primaryUrl = `/mercury/company/${getCompanyIdToken()}/accounttypes`

    useEffect(() => {
        if (systemRoute === 'accountType') {
            getAccountTypeList()
        }
    }, [systemRoute])

    const getAccountTypeList = () => {
        getService(`${primaryUrl}/list`, true).then(
            (res) => {
                if (res.status === 200) {
                    setAccountTypeList(res?.data?.accountTypes)
                }
            }
        )
    }

    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-A" onClick={() => { setSystemRoute('accountType') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
                        Account types  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-A" className={`bgYellow collapse ${systemRoute === "accountType" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-A">
                <div>
                    <div className="head d-none d-lg-block">
                        <h4 className="heading_1 text_dark mb-0">Account types</h4>
                    </div>
                    <div className="p-3 my-4">
                        <p>Please enter all of your company’s account types. This list provides authorizations for user access to system functions.</p>
                    </div>
                    <div>
                        {/* <div  className=" col-xl-8"> */}
                        <div className=" col-xl-5">

                            <div className="table-responsive cst_table account_types">
                                <table className="table">
                                    {/* <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th></th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        {accountTypeList?.map((data, i) => (
                                            <tr key={i}>
                                                {/* <td>{data?.description}</td> */}
                                                <td>{data?.description}</td>
                                                <td className='d-flex justify-content-around'>
                                                    <a href="#" onClick={() => setAccountTypeId(data?.id)} className="me-2" data-bs-toggle="modal" data-bs-target="#edit_account_types"><img src="assets/img/Edit.svg" alt="" /></a>
                                                    <a href="#" onClick={() => setAccountTypeId(data?.id)} className="ms-2" data-bs-toggle="modal" data-bs-target="#delete_accountType_replace"><img src="assets/img/Delete.svg" alt="" /> </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="add_account mt-4">
                            <a data-bs-toggle="modal" onClick={() => setAccountTypeId('')} data-bs-target="#edit_account_types"><span className="fnt_14 text_dark">Add an account type</span>
                                <img className="ms-5" src="assets/img/add_icon.svg" alt="" /></a>
                        </div>
                    </div>
                </div>

                <AccountAddEdit getAccountTypeList={getAccountTypeList} url={primaryUrl} accountTypeId={accountTypeId} setAccountTypeId={setAccountTypeId} />
                <DeleteAccountType getAccountTypeList={getAccountTypeList} url={primaryUrl} accountTypeId={accountTypeId} setAccountTypeId={setAccountTypeId} accountTypeList={accountTypeList} />
            </div>
        </>
    )
}

export default AccountType