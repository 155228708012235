/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { getService } from '../../../../../services/commonApi'
import { getCompanyIdToken } from '../../../../../services/tokenService'

const AddAction = ({ operationType, url, actionInput, setActionInput }) => {

    var actionList = [
        { code: 'D', text: 'Set/Override Department Choice' },
        { code: 'P', text: 'Set/Override Position Choice' },
        { code: 'E', text: 'Set/Override Employee Choice' },
        { code: 'S', text: 'Set/Override Sender Choice' },
        { code: 'I', text: 'No Further Rule Actions' },
    ]

    const [element, setElement] = useState(actionList[0]?.code)

    const [textList, setTextList] = useState([])
    const [text, setText] = useState('')

    useEffect(() => {
        // console.log('change ', element)
        setText('No Value')
        fetchIdList(element)
    }, [element])



    async function fetchIdList(val) {
        var url
        setTextList([])
        switch (val) {
            case 'D':
                url = `/mercury/company/${getCompanyIdToken()}/department/list`
                var departments = await getListData(url)
                setTextList(departments?.list?.map((data) => ({ code: data?.departmentId, text: data?.label })))
                break;
            case 'P':
                url = `/mercury/company/${getCompanyIdToken()}/position/list`
                var positions = await getListData(url)
                setTextList(positions?.list?.map((data) => ({ code: data?.positionId, text: data?.label })))
                break;
            case 'E':
                url = `/mercury/employee/list`
                var employees = await getListData(url)
                setTextList(employees?.employees?.map((data) => ({ code: data?.employee?.employeeId, text: data?.employee?.employeeName?.firstName + ' ' + data?.employee?.employeeName?.lastName })))
                break;
            case 'S':
                url = `/mercury/sender/list`
                var senders = await getListData(url)
                // console.log(senders.senders)
                setTextList(senders?.senders?.map((data) => ({ code: data?.senderId, text: data?.label })))
                break;
            default:
                setTextList([])
                break;
        }

        // getService(url, true).then((res) => {
        //     if (res.status === 200) {
        //         setTextList(res.data)
        //     }
        // })
    }

    const getListData = async (url) => {
        var data = await getService(url, true)
        return data.data;
    }

    const setActionData = () => {
        // console.log({
        //     element, text
        // })
        setActionInput({
            element, text
        })
        document.getElementById('closeactionModal').click()
    }



    return (
        <div className="modal fade add_condition" id="add_action" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body p-4">
                        <h2 className="heading_1 mb-3">Add Action</h2>
                        <div className="row">
                            <div className="fm_grp col-md-12">
                                <label htmlFor="Firstname" className="form-label">* Element</label>
                                <select id="element" className="form-select" onChange={(e) => setElement(e.target.value)}>
                                    {actionList.map((data, i) => (
                                        <option key={i} value={data?.code}>{data?.text}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="fm_grp col-md-12">
                                <label htmlFor="inputState" className="form-label">* Value</label>
                                <select id="textList" className="form-select" onChange={(e) => setText(e.target.value)}>
                                    <option value="No Value">No Data set</option>
                                    {textList.map((data, i) => (
                                        <option key={i} value={data?.code}>{data?.text}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-end mt-3">
                                <div className="text-end">
                                    <button type="button" className="btn btn-link btn_link_primary" id="closeactionModal" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#add_rule">Cancel</button>
                                    <button type="button" className="btn btn_cstm_primary px-3" onClick={() => setActionData()}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddAction
