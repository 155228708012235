/**
 * 
 * All changes must adhere to the Authorization Code Flow with PKCE
 * Implicit flow is not allowed.
 * 
 * 		https://learn.microsoft.com/en-us/azure/active-directory/develop/tutorial-v2-javascript-auth-code
 * 
 * 
 * 
 */

import axios from "axios";
import { url, signOn, loginRequest } from "../environment/environment";
import { getLocalAuthToken, getRefreshToken, setAuthToken, removeAuthToken, removeRefreshToken, setExpTimestamp, validateExpTimestamp, setRefreshToken } from "./tokenService";
import history from "./history";
import { errorHandler } from "./ErrorHandler";

/**
 * Provide a sleep timer
 */
function sleep(ms) { return new Promise(resolve => setTimeout(resolve, ms)); }

/**
 * This function creates a function that returns a promise that will be called
 * when a valid bearer token is available for use.  The promise will return the bearer
 * token as the result or an error will occur if it was unable to retrieve it.
 * One of 3 promises might be returned.  The first is if a valid bearer token is 
 * immediately available in which case the promise just returns that value.
 * If the call is the first to retrieve the bearer token but it has expired, 
 * then the return is a promise that is refreshing the bearer token by calling the
 * refresh token API call.  If the call to the returned function is made while
 * the refresh is in progress, the promise that is returned will return when
 * the retrieve is complete and the new bearer token is available.  If it doesn't 
 * return in a set period of time, i.e. a timeout occurs, an error will be returned.
 * 
 * The default timeout is 10seconds.
 
 * @returns a function that returns a promise.
 */
function createBearerTokenRefresh( ) 
{
	let updating = null;
	// Handle embedded token.
	if( !signOn.isSSO )
	{
		// return a function that returns a promise.
		return async function getExpiringResource() 
		{
			// check to see if the bearer token has expired and if so if we are 
			// already in the midst of updating the bearer token
			if( !validateExpTimestamp() && !updating )
			{
				// create a promise that updates the bearer token and when it is done
				// updates the bearer token and passes the new bearer token 
				updating = new Promise((resolve,reject) => 
				{
					// get our local copy of the auth token.
					let local_token = getLocalAuthToken();
					const local_url = "/mercury/access/refresh";
					// create the headers for the call to refresh the bearer token.
					const headers = 
					{
						headers: 
						{
							Accept: "application/json",
							Authorization: "Bearer " + local_token,
						}
					};
					// make the post call to get an updated the bearer token.
					axios.post( `${url}${local_url}`, { refreshToken: getRefreshToken(), }, headers).then((res) => 
					{
						// set the value of the auth token.
						setAuthToken(res?.data?.accessToken);
						// if a new refresh token was also provided, then set that copy.
						if( res?.data?.refreshToken )
						{
							setRefreshToken( res?.data?.refreshToken );
						}
						// clear out update flag this will immediately cause the updating to use the race promise until this one resolves.
						updating=null;
						// update the timeout time this will immediately cause the validExpTimestamp to return true and now all will return local copy.
						setExpTimestamp(res?.data?.exp);
						// update the user info in the local storage.
						localStorage.setItem("user", JSON.stringify(res?.data));
						// pass the access token to the first caller.
						resolve( res?.data?.accessToken );
					})
					.catch((err) => 
					{
						// Call what we need to since we couldn't refresh the bearer token.
						errorHandler(err);
						localStorage.removeItem("user");
						removeAuthToken();
						removeRefreshToken();
						// navigation.push("/auth");
						history.push("/auth");
						window.location.reload();
						updating = null;
						reject( err );
					});
				});		
				return updating;
			}
			// The authentication bearer token is expired but a copy is being retrieved so return a promise 
			// that will call it when the bearer token is available.  A promise that is waiting on a race 
			// condition provides the ability to get the result from the first promise retrieving it.
			else if( updating && !validateExpTimestamp() ) 
			{
				// our timeout promise
				let secondPromise = new Promise((resolve, reject) => 
				{
					sleep(10000).then(() => {
						reject("Bearer Token Update Timeout");
					});
				});
				// return a promise that returns the results of the bearer token update or
				// the timeout condition.  
				return Promise.race([updating, secondPromise]);
			}
			// The authentication bearer token is not expired and is not being updated.  
			// return a promise that returns the local auth token copy.
			else
			{
				return new Promise((resolve) => 
				{
					resolve( getLocalAuthToken() );
				});
			}
		};
	}
	else if( signOn.isSSO && signOn.authentication === 'Azure' )
	{
		// check to see if the bearer token has expired and if so if we are 
		// already in the midst of updating the bearer token
		if( !validateExpTimestamp() && !updating )
		{
			// create a promise that updates the bearer token and when it is done
			// updates the bearer token and passes the new bearer token 
			updating = new Promise((resolve,reject) => 
			{
				const request = 
				{
					...loginRequest,
					account: signOn.accounts[0]
				};
				// Silently acquires an access token which is then attached to a request for Microsoft Graph data
				signOn.instance.acquireTokenSilent(request).then((res) => 
				{
					const idToken = res?.idToken
					const expiresOn = res?.expiresOn;
					setAuthToken(idToken);
					setRefreshToken( null );
					// set the timeout timestamp.
					setExpTimestamp( expiresOn );
					resolve( idToken )						
				})
				.catch((e) => 
				{
					signOn.instance.acquireTokenPopup( request ).then(( res ) => 
					{
						const idToken = res?.idToken
						const expiresOn = res?.expiresOn;
						setAuthToken(idToken);
						setRefreshToken( null );
						// set the timeout timestamp.
						setExpTimestamp( expiresOn );
						resolve( idToken )						
					});
				});
			});
			return updating;
		}
		// The authentication bearer token is expired but a copy is being retrieved so return a promise 
		// that will call it when the bearer token is available.  A promise that is waiting on a race 
		// condition provides the ability to get the result from the first promise retrieving it.
		else if( updating && !validateExpTimestamp() ) 
		{
			// our timeout promise
			let secondPromise = new Promise((resolve, reject) => 
			{
				sleep(10000).then(() => 
				{
					reject("Bearer Token Update Timeout");
				});
			});
			// return a promise that returns the results of the bearer token update or
			// the timeout condition.  
			return Promise.race([updating, secondPromise]);
		}
		// The authentication bearer token is not expired and is not being updated.  
		// return a promise that returns the local auth token copy.
		else
		{
			return new Promise((resolve) => 
			{
				resolve( getLocalAuthToken() );
			});
		}
	}
}

/**
 * Get the bearer token for use in calls to the API server.  Returns 
 * a promise that passes the bearer token.
 * 
 * Do not call getLocalAuthToken for the bearer token.  Calling this ensures
 * that it will always be valid or cannot be renewed or an error occurs while
 * renewing it.
 * 
 * Example:
 * 
		getAuthToken().then( ( token ) =>
		{
			console.log( "bearer token: "+token)
		})
		.catch( (err) =>
		{
			console.log( "error occurred: "+err )				
		});
 */
export const getAuthToken = createBearerTokenRefresh();
