import React, { useEffect, useState } from 'react'
import { forgot_password_challenge } from '../../services/AuthApi'

const EnterUserName = ({ setData }) => {
    const [username, setUsername] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        setError('')
    }, [])


    const getChallenge = () => {

        if (!username) {
            return setError('Please provide your username.')
        }
        forgot_password_challenge(username).then(
            (res) => {
                if (res.request.status === 200) {
                    // console.log('res', res)
                    setData(res.data)
                    resetForm()
                    document.getElementById('nextModal').click()
                } else {
                    setError('err', res.message)
                }
            }
        )
    }
    const resetForm = () => {
        setError('')
        setUsername('')
    }

    return (

        <div className="modal fade forget_password" id="forgotpassModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h5 className="title">Forgot Password </h5>
                        <button type="button" className="btn-close" onClick={resetForm} data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-lg-4">
                        <div className="mb-4">
                            <p><b>Step 1 : Identify Yourself</b></p>
                            <p className="fnt_14 ">In order to reset your password, we need to know who you are. Please provide the username created for your account</p>
                        </div>
                        <form>

                            {/* <!-- Without error input box  --> */}
                            <div className={`mb-3 ${error ? 'error_inupt' : ''}`}>
                                <label htmlFor="1username" className="form-label">Username</label>
                                <input type="text" className="form-control" id="1username" aria-describedby="emailHelp" onInput={(e) => setUsername(e.target.value)} value={username} />
                            </div>
                            {/* <!-- Without error input box  --> */}
                        </form>
                    </div>
                    <div className="modal-footer border-0">
                        <button type="button" className="btn btn-link btn_link_primary" onClick={resetForm} data-bs-dismiss="modal">Cancel</button>
                        <button className="btn  btn_cstm_primary px-4" onClick={() => getChallenge()}>Next</button>
                        <button className="btn  btn_cstm_primary px-4 d-none" id="nextModal" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" data-bs-dismiss="modal">Next</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default EnterUserName