import React, { useState } from 'react'
import { useEffect } from 'react'
import { CommonState } from '../../../../context/commonContext'
import { RouteState } from '../../../../context/routeContext'
import { getService } from '../../../../services/commonApi'

function DeliverTabSidebar() 
{
	const user = JSON.parse(localStorage?.getItem('user'))
	const { refreshMailStats } = CommonState()
	const { deliveryRoute, setDeliveryRoute } = RouteState()
	const [UnassignedMailCont, setUnassignedMailCont] = useState()
	const [RescanReqCount, setRescanReqCount] = useState()
	const [OriginalReqCount, setOriginalReqCount] = useState()
	const [wrongCompanyCount, setWrongCompanyCount] = useState()
	const [qualityControlCount, setQualityControlCount] = useState()

	const seekPermission = (link) => 
	{
		if (user?.entitlements?.includes(link)) 
		{
			return true
		} 
		else 
		{
			return false
		}
	}

	useEffect(() => 
	{
		getUnassignedMailData()
		getRescanReqData()
		getOriginalReqData()
		wrongCompanyDetails()
		qualityControlsDetails()
	}, [refreshMailStats])

	const getUnassignedMailData = () => 
	{
		getService('/mercury/mail/unassigned', true, "").then((res) => 
		{
			if (res.status === 200) 
			{
				setUnassignedMailCont(res?.data?.available)
			}
		})
	}

	// set the count of pending requests.  Not acted on yet.
	const getRescanReqData = () => 
	{
		getService('/mercury/mail/rescanrequests', true, "").then((res) => 
		{
			if (res.status === 200) 
			{
				setRescanReqCount( res?.data?.pending )
			}
		})
	}

	// set the count of unfulfilled requests.
	const getOriginalReqData = () => 
	{
		getService('/mercury/mail/originalrequests', true, "").then((res) => 
		{
			if (res.status === 200) 
			{
				setOriginalReqCount( res?.data?.unfulfilled )
			}
		})
	}

	const wrongCompanyDetails = () => {
		getService('/mercury/mail/wrongcompany', true, "").then(
			(res) => {
				if (res.status === 200) {
					setWrongCompanyCount(res?.data?.available)
				}
			}
		)
	}

	const qualityControlsDetails = () => {
		getService('/mercury/mail/qualitycontrol', true, "").then(
			(res) => {
				if (res.status === 200) {
					setQualityControlCount(res?.data?.available)
				}
			}
		)
	}



	return (
		<ul className="nav nav-tabs responsive_tabs ps-lg-3" role="tablist">
			<li className="nav-item">
				<a id="tab-A" href="#pane-A" data-bs-toggle="tab" role="tab" onClick={() => setDeliveryRoute("processing")} 
						className={`nav-link ${deliveryRoute === 'processing' ? 'active' : ''}`}>Processing Jobs </a>
			</li>

			<li className="nav-item">
				<a id="tab-B" href="#pane-B" data-bs-toggle="tab" role="tab" onClick={() => setDeliveryRoute("unassigned")} 
						className={`nav-link ${deliveryRoute === 'unassigned' ? 'active' : ''}`}>Unassigned Mail ({UnassignedMailCont})</a>
			</li>

			{seekPermission("request") &&
				<li className="nav-item">
					<a id="tab-C" href="#pane-C" data-bs-toggle="tab" role="tab" onClick={() => setDeliveryRoute("rescan")} 
							className={`nav-link ${deliveryRoute === 'rescan' ? 'active' : ''}`}>Rescan Requests ({RescanReqCount})</a>
				</li>}

			{seekPermission("request") &&
				<li className="nav-item">
					<a id="tab-C" href="#pane-C" data-bs-toggle="tab" role="tab" onClick={() => setDeliveryRoute("original")} 
							className={`nav-link ${deliveryRoute === 'original' ? 'active' : ''}`}>Original Requests ({OriginalReqCount})</a>
				</li>}

			<li className="nav-item">
				<a id="tab-D" href="#pane-D" data-bs-toggle="tab" role="tab" onClick={() => setDeliveryRoute("wrong")} 
						className={`nav-link ${deliveryRoute === 'wrong' ? 'active' : ''}`}>Wrong Company ({wrongCompanyCount})</a>
			</li>

			{seekPermission("quality") &&
				<li className="nav-item">
					<a id="tab-E" href="#pane-E" data-bs-toggle="tab" role="tab" onClick={() => setDeliveryRoute("quality")} 
							className={`nav-link ${deliveryRoute === 'quality' ? 'active' : ''}`}>Quality Control ({qualityControlCount})</a>
				</li>}
			<li className="nav-item">
				<a id="tab-F" href="#pane-F" data-bs-toggle="tab" role="tab" onClick={() => setDeliveryRoute("assignment")} 
						className={`nav-link ${deliveryRoute === 'assignment' ? 'active' : ''}`}>Assignment Rules</a>
			</li>
		</ul>
	)
}

export default DeliverTabSidebar