import axios from "axios";
import { url } from "../environment/environment";
import { getAuthToken } from "./refreshBearerToken"

/*===============================================================
            Common get API
==================================================================*/

export const getService = async (urlString, headers, params) => 
{
	return new Promise( ( resolve, reject ) =>
	{
		getAuthToken().then( token => 
		{
			const AxiosConfig = {
				headers: {
				  Accept: "application/json",
				  Authorization: "Bearer " + token,
				},
				...params,
			  };
			  // console.log({ Cheaders });
			axios.get(url + urlString, AxiosConfig, { validateStatus: status => { return status >= 200 && status < 300; } } ).then( resolve ).catch( reject );
		})
		.catch( reject );	
	});

};

/*===============================================================
            Common Post API for JSON post
==================================================================*/
//  notify(`${err?.response?.status} Something went wrong`);
export const postService = async (urlString, data, headers) => 
{
	return new Promise( ( resolve, reject ) =>
	{
		getAuthToken().then( token => 
		{
			const AxiosConfig = {
				headers: {
				  Accept: "application/json",
				  Authorization: "Bearer " + token,
				}
			};
			axios.post(url + urlString, data, AxiosConfig, { validateStatus: status => { return status >= 200 && status < 300; } } ).then( resolve ).catch( reject );
		})
		.catch( reject );	
	});

};

/*===============================================================
            Common Post API for FormData post
==================================================================*/

export const postFormData = async (urlString, data, headers) => 
{
	return new Promise( ( resolve, reject ) =>
	{
		getAuthToken().then( token => 
		{
			const AxiosConfig = {
				headers: {
				  Authorization: "Bearer " + token,
				}
			};
			axios.post(url + urlString, data, AxiosConfig, { validateStatus: status => { return status >= 200 && status < 300; } } ).then( resolve ).catch( reject );
		})
		.catch( reject );	
	});

};

export const putService = async (urlString, data, headers, params) => 
{
	return new Promise( ( resolve, reject ) =>
	{
		getAuthToken().then( token => 
		{
			const AxiosConfig = {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				...params,
			};
			axios.put(url + urlString, data, AxiosConfig, { validateStatus: status => { return status >= 200 && status < 300; } } ).then( resolve ).catch( reject );
		})
		.catch( reject );	
	});

};

export const putFormData = async (urlString, data, headers, params) => 
{
	return new Promise( ( resolve, reject ) =>
	{
		getAuthToken().then( token => 
		{
			const AxiosConfig = {
				headers: {
					Authorization: "Bearer " + token,
				},
				...params,
			};
			axios.put(url + urlString, data, AxiosConfig, { validateStatus: status => { return status >= 200 && status < 300; } } ).then( resolve ).catch( reject );
		})
		.catch( reject );	
	});


};

export const deleteService = async (urlString, data, headers, params) => 
{
	return new Promise( ( resolve, reject ) =>
	{
		getAuthToken().then( token => 
		{
			const AxiosConfig = {
				headers: {
					Accept: "application/json",
					Authorization: "Bearer " + token,
				},
				...params,
			};
			axios.delete(url + urlString, AxiosConfig, { validateStatus: status => { return status >= 200 && status < 300; } } ).then( resolve ).catch( reject );
		})
		.catch( reject );	
	});

};
