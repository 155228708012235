/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { AuthState } from '../../../../context/authContext'

import Accounts from './Accounts'
import AccountsRequest from './AccountsRequest'
import CompanyName from './CompanyName'
import Address from './Address'
import CostCenter from './CostCenter'
import Departments from './Departments'
import MailStops from './MailStops'
import Position from './Position'
import Senders from "./Senders"

const Database = () => {
    const { databaseRoute, setDatabaseRoute } = AuthState()

    const [activeTab, setActiveTab] = useState('accounts')

    useEffect(() => {
        setActiveTab(databaseRoute)
        // console.log(databaseRoute)
    }, [databaseRoute])


    return (
        //     <div id="content"  className="tab-content cst_tab" role="tablist">
        //         {activeTab === 'accounts' && <Accounts />}
        //         {activeTab === 'accountRequest' && <AccountsRequest />}
        //         {activeTab === 'address' && <Address />}
        //         {activeTab === 'companyName' && <CompanyName />}
        //     </div>
        <>
            <div id="content" className="tab-content cst_tab" role="tablist">

                <div id="pane-A" className={`tab-pane fade ${activeTab === 'accounts' ? 'show active' : 'fade'}`} role="tabpanel" aria-labelledby="tab-A">
                    <Accounts activeTab={activeTab} />
                </div>
                {/* <!-- End  Accounts-- >/ */}
                {/* < !--Start  Accounts Requested-- > */}
                <div id="pane-B" className={`tab-pane fade ${activeTab === 'accountRequest' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-B">
                    <AccountsRequest activeTab={activeTab} />
                </div>
                {/* <!--End  Accounts Requested-- > */}
                <div id="pane-C" className={`tab-pane fade ${activeTab === 'address' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-C">
                    <Address activeTab={activeTab} />
                </div>
                <div id="pane-D" className={`tab-pane fade ${activeTab === 'companyName' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-D">
                    <CompanyName activeTab={activeTab} />
                </div>
                <div id="pane-E" className={`tab-pane fade ${activeTab === 'costCenter' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-E">
                    <CostCenter activeTab={activeTab} />
                </div>

                <div id="pane-F" className={`tab-pane fade ${activeTab === 'departments' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-F">
                    <Departments activeTab={activeTab} />
                </div>

                <div id="pane-G" className={`tab-pane fade ${activeTab === 'mailStops' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-G">
                    <MailStops activeTab={activeTab} />
                </div>

                <div id="pane-H" className={`tab-pane fade ${activeTab === 'position' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-H">
                    <Position activeTab={activeTab} />
                </div>

                <div id="pane-I" className={`tab-pane fade ${activeTab === 'senders' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-I">
                    <Senders activeTab={activeTab} />
                </div>

            </div>
        </>
    )
}

export default Database