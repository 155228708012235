/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

const Contact = ({
	navLink,
	setNavLink,
	error,
	setError,
	contactInput,
	setContactInput,
	mode, resetData }) => 
{

	const [emailAddress1, setEmailAddress] = useState(contactInput?.emailAddress1 ? contactInput?.emailAddress1 : '')
	const [cellPhone, setCellPhone] = useState(contactInput?.cellPhone ? contactInput?.cellPhone : '')
	const [workPhone, setWorkPhone] = useState(contactInput?.workPhone ? contactInput?.workPhone : '')

	const [submit, setSubmit] = useState(false)
	const [validEmail, setValidEmail] = useState(true)

	useEffect(() => 
	{
		setContactInput({ emailAddress1, cellPhone, workPhone });
	}, [emailAddress1, cellPhone, workPhone])

	const setContactInputData = () => 
	{
		setSubmit(true)
		setContactInput({ emailAddress1, cellPhone, workPhone });
		if (!emailAddress1) 
		{
			return setError({ err:true, desc:"Email address is required field", emailAddress1:true });
		} 
		else if (emailValidation(emailAddress1)) 
		{
			setValidEmail(false)
			return setError({ err:true, desc:"Email address is not valid", emailAddress1:true });
		}
		setValidEmail(true)
		setError({ err:false, desc:"" });
		setNavLink("account")
	}

	function emailValidation(email) 
	{
		// eslint-disable-next-line
		const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		return !(!email || regex.test(email) === true);
	}

	return (
		<div className={`tab-pane fade ${navLink === "contact" ? "show active" : ""}`} aria-labelledby="v-pills-messages-tab" >
			<div className="col-lg-9 offset-lg-1 modalContentItems">
				{mode === 'request' && (<> <h2 className="heading_1">Account request information</h2>
					<p className="mb-0">
						Please review all the following information before process your request.
					</p>
					<p className="fnt_w_bold">Please be as thorough as possible.</p></>)}
				<form className="pt-4">
					<div className="row g-3 align-items-center">
						<div className="fm_grp col-md-6">
							<div className="error_inupt_box">
								<div className="error_input">
									<label htmlFor="inputEmail" className={`form-text ${error?.emailAddress1 && (!emailAddress1 || !validEmail) ? 'error_text' : ''}`}>
										* Email address {error?.emailAddress1 && !emailAddress1 && validEmail && <span>is required</span>}
												{error?.emailAddress1 && emailAddress1 && !validEmail && <span>is invalid</span>}
									</label>
									<input type="email"
										className={`form-control ${error?.emailAddress1 && (!emailAddress1 || !validEmail) ? 'error_input' : ''}`}
										id="inputEmail" placeholder="" onInput={(e) => setEmailAddress(e.target.value)} value={emailAddress1} />
								</div>
							</div>
						</div>
						<div className="fm_grp col-md-6">
							<label htmlFor="inputCell" className="form-label">
								Cell phone
							</label>
							<input type="text" className="form-control" id="inputCell" onInput={(e) => setCellPhone(e.target.value)} value={cellPhone} />
						</div>
						<div className="fm_grp col-md-6">
							<label htmlFor="inputWorkPhone" className="form-label">
								Work phone
							</label>
							<input type="text" className="form-control" id="inputWorkPhone" placeholder="" onInput={(e) => setWorkPhone(e.target.value)} value={workPhone}/>
						</div>
					</div>
				</form>
			</div>
			<div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
				<div> </div>
				<div className="p-md-4 text-end">
					<button type="button" className="btn btn-link  btn_link_primary me-3" data-bs-dismiss="modal" onClick={() => { setSubmit(false); setError(''); resetData() }} >
						Cancel
					</button>
					{(!mode || mode === 'request') && <button type="button" className="btn btn_cstm_primary px-4" onClick={() => setContactInputData()} >
						Next
					</button>}
					{mode === 'edit' && <button type="button" className="btn btn_cstm_primary px-4" onClick={() => setContactInputData()} >
						Save edits
					</button>}
				</div>
			</div>
		</div>
	)
}

export default Contact