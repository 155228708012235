import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';

const SenderNameListItem = ({ data }) => 
{
	return (
        <>
				<div className='search_results_item' id='sender_search_results'>
					<span className="d-flex">
						<p><b>Senders</b></p>
					</span>

					{data?.map((ele, i) => (
						<Dropdown.Item key={i} className="help_box" as={'div'}  >
							<span className="d-flex" >
								<b>{ele?.label.length ? ele?.label : `<i>-- Sender Name Missing --</i>`}</b>
							</span>
							<p><small>
								{ele?.address1 ?  ele?.address1+', ' : ''}
								{ele?.address2 ? ele?.address2+', ' : '' }
								{ele?.city} {ele?.state} {ele?.zipcode}
							</small></p>
						</Dropdown.Item>
					))}
				</div>
        </>
    )
}

export default SenderNameListItem