export const stringPipe = (string, limit) => {
	var transformedString =
	  string?.length > limit ? string.substring(0, limit - 3) + "..." : string;
	return transformedString;
  };
  
export const getFirstName = (string, index) => {
  var transformedString = "";
  var stringVal = string?.split(" ");
  if (stringVal?.length > 0) {
    for (let i = 0; i < index; i++) {
      transformedString = transformedString + stringVal[i] + " ";
    }
    return transformedString;
  } else {
    return string;
  }
};

export const checkForValue = (string, getReturn) => {
  return string ? string : getReturn ? "Not Identified" : "";
};
