/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import EditSenderName from './SenderEditComp/EditSenderName'
import EditSenderAddress from './SenderEditComp/EditSenderAddress'
import DeleteSenderAddress from './SenderEditComp/DeleteSenderAddress'
import DeleteSenderName from './SenderEditComp/DeleteSenderName'
import { getService } from '../../../../../../services/commonApi'
import { useRef } from 'react'

function SenderEdit({ data, setData, senderUrl, getSenderData }) 
{
	const [senderData, setSenderData] = useState()
	const [senderAddresses, setSenderAddresses] = useState([])
	const [senderNames, setSenderNames] = useState([])
	const [selectedName, setSelectedName] = useState(data?.name)  // this is the sendername object.
	const [selectedAddress, setSelectedAddress] = useState(data?.address)
	const [addressDelete, setAddressDelete] = useState()
	const [nameDelete, setNameDelete] = useState()
	const [nameType, setNameType] = useState('')
	const [addressType, setAddressType] = useState('')

	const url = useRef()
	useEffect(() => 
	{
		if( data && senderUrl ) 
		{
			console.log("SenderEdit: "+data)
			url.current = `${senderUrl}/${data.senderId}`
			getSenderDataByID()
		}
	}, [data,senderUrl])

	const getSenderDataByID = () => 
	{
		getService(url.current, true).then((res) => 
		{
			setSenderData(res?.data)
			setSenderAddresses(res?.data?.addresses)
			setSenderNames(res?.data?.senderNames)
			// get the primary and set it as the label
			res?.data?.senderNames?.forEach( (name) =>
			{
				if( name.primary )
				{
					setSelectedName(name)
				}
			})
		})
	}

	// Utility function for child object to call to obtain names.
	const getSenderNames = () => 
	{
		getService(`${url.current}/name/list`, true).then((res) => 
		{
			if (res?.status === 200) {
				setSenderNames(res.data)
			}
		})
	}

	// Utility function for child object to call to obtain addresses.
	const getSenderAddress = () => 
	{
		getService(`${url.current}/address/list`, true).then((res) => 
		{
			if (res?.status === 200) {
				setSenderAddresses(res.data)
			}
		})
	}

	useEffect(() => 
	{
		if( senderData ) 
		{
			if( senderNames?.length === 1 )
			{
				setSelectedName( senderNames[0] )
			}
			else
			{
				setSelectedName(...senderNames.filter(ele => ele.primary))
			}
			if( senderAddresses?.length === 1 )
			{
				setSelectedAddress(senderAddresses[0])
			}
			else
			{
				setSelectedAddress(...senderAddresses.filter(ele => ele.primary))
			}
		}
	}, [senderData, senderAddresses, senderNames])

	return (
		<>
			<div className="modal fade sender_name" id="sender_name" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
					<div className="modal-content">
						<div className="modal-body p-4">
							<div className=" col-lg-7">
								<div className="row g-0">
									<div className="col-lg-4">
										<h2 className="heading_1 mb-3">Edit Sender</h2>
									</div>
									<div className="col-lg-6">
										<p className="fnt_14">
											{selectedName?.name} <br/> 
											{selectedAddress?.address1} <br/> 
											{selectedAddress?.address2 && <>{selectedAddress?.address2}<br/> </>} 
											{selectedAddress?.city}, {selectedAddress?.state} {selectedAddress?.zip}
										</p>
									</div>
								</div>
							</div>
							<div className="row g-0">
								<div className="col-lg-5">
									<div className="sendar_box">
										<small>Sender names</small>
										<div className="sendar_box_inner">
											<ul>
												{
													senderNames && senderNames.map((item, i) => {
														return (
															<li key={i}>
																<div>{item?.name}</div>
																<div>
																	<a href="#" onClick={() => { setSelectedName(item); setNameType('edit'); }} data-bs-toggle="modal" data-bs-target="#add_sender_name" data-bs-dismiss="modal"><i className="bi bi-pencil-fill"></i></a>
																	{senderNames.length - 1 === i ? "" :
																		<a href="#" className="ms-2" onClick={() => setNameDelete(item)} data-bs-toggle="modal" data-bs-target="#delete_sender_name"><i className="bi bi-trash"></i> </a>
																	}
																</div>
															</li>
														)
													})
												}

											</ul>
										</div>
										<div className="add_account my-4">
											<a data-bs-toggle="modal" onClick={() => { setNameType(''); setSelectedName("") }} data-bs-target="#add_sender_name" data-bs-dismiss="modal">
												<img src="/assets/img/add_account.svg" alt="" />
												<span>Add new sender name</span>
											</a>
										</div>
									</div>
								</div>

								<div className="col-lg-5 offset-lg-1">
									<div className="sendar_box sendar_addresses">
										<small>Sender addresses</small>
										<div className="sendar_box_inner">
											<ul>
												{
													senderAddresses?.map((item, i) => {
														return (
															<li key={i}>
																<div>
																	<span>{item?.address1}</span>
																	<span>{item?.address2}</span>
																	<span>{item?.city}, {item?.state} {item?.zip}</span>
																</div>
																<div>
																	<a href="#" onClick={() => { setSelectedAddress(item); setAddressType('edit') }} data-bs-toggle="modal" data-bs-target="#do_edit_sender_address" data-bs-dismiss="modal"><i className="bi bi-pencil-fill"></i></a>
																	{senderAddresses.length - 1 === i ? "" :
																		<a href="#" className="ms-2" onClick={() => setAddressDelete(item)} data-bs-toggle="modal" data-bs-target="#delete_sender_address"><i className="bi bi-trash"></i> </a>
																	}
																</div>
															</li>
														)
													})
												}
											</ul>
										</div>
										<div className="add_account my-4">
											<a data-bs-toggle="modal" onClick={() => setAddressType('')} data-bs-target="#do_edit_sender_address" data-bs-dismiss="modal">
												<img src="/assets/img/add_account.svg" alt="" />
												<span>Add new address</span>
											</a>
										</div>
									</div>
								</div>

								<div className="d-flex flex-wrap align-items-center justify-content-between mt-3">
									<div> </div>
									<div className="text-end">
										<button type="button" className="btn btn-link  btn_link_primary me-3" data-bs-dismiss="modal">Close</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<EditSenderName data={selectedName} type={nameType} getSenderNames={getSenderNames} url={url.current} />
			<DeleteSenderName nameDelete={nameDelete} getSenderNames={getSenderNames} url={url.current} />
			<EditSenderAddress data={selectedAddress} setSelectedAddress={setSelectedAddress} type={addressType} getSenderAddress={getSenderAddress} url={url.current} />
			<DeleteSenderAddress addressDelete={addressDelete} setAddressDelete={setAddressDelete} getSenderAddress={getSenderAddress} url={url.current} />
		</>
	)
}

export default SenderEdit