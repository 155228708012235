/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { getService, deleteService } from '../../../../../../services/commonApi'
import { getCompanyIdToken } from '../../../../../../services/tokenService'

export default function DepartmentDelete({ data, url, getList, setDataToDelete }) {
    const [DepartmentList, setDepartmentList] = useState([])
    const [submit, setSubmit] = useState(false)
    const [replacementId, setReplacementId] = useState('')
    const [error, setError] = useState(false)
    useEffect(() => {
        if (data?.departmentId) {
            getDepartmentList()
        }
    }, [data?.departmentId])

    const getDepartmentList = () => {
        getService(`/mercury/company/${getCompanyIdToken()}/department/name/list?start=1&stop=30&primary=true`, true).then((res) => {
            if (res.request.status === 200) {
                setDepartmentList(res.data.list)
                setReplacementId(res.data.list[0].departmentId)
            } else {
                // console.log(res)
            }
        }, err => {
            // console.log(err)
        })
    }
    const resetModal = () => {
        setReplacementId('')
        setDataToDelete()
        setError('')
        setSubmit(false)
    }
    const deleteDepartment = () => {
        setSubmit(true)
        // console.log("first")
        if (!replacementId) {
            return setError('Replacement Id is required')
        } else {
            deleteService(url + '/' + data.departmentId, '', true, { params: { replacementId } })
                .then(
                    (res) => {
                        // console.log(res)
                        if (res?.request?.status === 200) {
                            getList()
                            document.getElementById('closeDeleteDepartmentModal').click()
                        } else {
                            setError(res?.response?.data?.error?.desc)
                        }
                    }, (err) => {
                        // console.log('err', err)
                        setError(err.response?.statusText)
                    }
                )

        }
    }



    return (
        <>
            <>
                {/* Modal Cost Centers Delete & replace  */}
                <div className="modal fade cost_centers" id="delete_department" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body py-4 px-lg-0">
                                <h2 className="heading_1 ps-lg-4">Delete Confirmation</h2>
                                {/*  Error alert box  */}
                                {/* <div  className="alert alert-danger alert_danger ps-lg-5" role="alert">
                          <span  className="me-3"><img src="/assests/img/error_icon_white.png"  className="img-fluid" alt="Image"></span>  A This company name already exists. Please provide another.
                      </div> */}
                                <div className="p-lg-5">
                                    <div className="col-xl-10 m-auto py-4">
                                        <h3 className="heading_2 mb-4">Delete "{data?.name}"</h3>
                                        <div className="fm_grp">
                                            <label htmlFor="inputState" className="form-label">* Replace with the following Department:</label>
                                            <select id="inputState" className="form-select" onChange={(e) => setReplacementId(e.target.value)} value={replacementId}>
                                                {
                                                    DepartmentList?.map((item, i) => {
                                                        return (<option key={i} value={item.departmentId}>{item.name}</option>)
                                                    })
                                                }
                                            </select>
                                        </div>
                                        {/*  Error alert input box  */}
                                        {/* <div  className="fm_grp">
                                  <div  className="error_inupt_box">
                                      <span  className="me-3"><img src="assests/img/error_icon.svg"  className="img-fluid" alt="Image"></span>
                                      <div  className="error_inupt">
                                          <label htmlFor="inputAddress"  className="form-label" required="">* Email address is a required field.</label>
                                          <input type="email"  className="form-control" id="inputAddress" placeholder="">
                                      </div>
                                  </div>
                              </div> */}
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 px-3">
                                    <div> </div>
                                    <div className=" text-end">
                                        <button id="closeDeleteDepartmentModal" onClick={() => resetModal()} type="button" className="btn btn-link  btn_link_primary" data-bs-dismiss="modal">Cancel</button>
                                        <button onClick={() => deleteDepartment()} type="button" className="btn btn_cstm_primary">Delete & replace</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Cost Centers Delete & replace*/}


            </>

        </>
    )
}
