/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { getService } from '../../../../../services/commonApi'
import { getCompanyIdToken } from '../../../../../services/tokenService'
import Select from 'react-select';

const AddCondition = ({ conditionInput, conditionForm, setConditionInput, operationType, Baseurl, setConditionForm }) => {

    var conditionList = [
        { code: 'c', text: 'Assignment confidence' },
        { code: 'z', text: 'Company name confidence' },
        { code: 'd', text: 'Department name confidence' },
        { code: 'e', text: 'Employee name confidence' },
        { code: 'm', text: 'Mail Stop confidence' },
        { code: 'p', text: 'Position Name confidence' },
        { code: 's', text: 'Sender Name confidence' },
        { code: 'B', text: 'Assignment Decision' },
        { code: 'X', text: 'Recipient Text' },
        { code: 'Y', text: 'Sender Text' },
        { code: 'V', text: 'Envelope Text' },
        { code: 'T', text: 'Letter Text' },
        { code: 'A', text: 'Company Address' },
        { code: 'Z', text: 'Company Name' },
        { code: 'M', text: 'Mail Stop' },
        { code: 'D', text: 'Department Name' },
        { code: 'P', text: 'Position Name' },
        { code: 'E', text: 'Employee Name' },
        { code: 'S', text: 'Sender' }
    ]

    const [element, setElement] = useState(conditionList[0]?.code)

    const [comparisonList, setComparisonList] = useState([])
    const [comparison, setComparison] = useState('')

    const [textList, setTextList] = useState([])
    const [textType, setTextType] = useState('number')
    const [text, setText] = useState('')

    useEffect(() => {
        console.log(conditionForm)
        if (operationType === 'edit') {
            setElement(conditionForm?.element)
            setComparison(conditionForm?.comparison)
            setText(conditionForm?.text?conditionForm?.text:'')
            if (conditionForm?.element === 'E' && conditionForm?.text) {
                searchEmployeeById(conditionForm?.text)
            }
        }
    }, [conditionForm, operationType])


    useEffect(() => {
        console.log('element===============>', element)
        triggerChange(element)
    }, [element])


    function triggerChange(val) {
        console.log("triggerChange value============================>", val)
        switch (val) {
            case 'c': case 'z': case 'd': case 'e': case 'm': case 'p': case 's':
                setComparisonList([
                    { code: '=', text: "Equals" },
                    { code: '<', text: "Less Than" },
                    { code: 'L', text: "Less Than Or Equal" },
                    { code: '>', text: "Greater Than" },
                    { code: 'G', text: "Greater Than Or Equal" },
                ])
                setTextType('number')
                break;
            case 'B':
                setComparisonList([
                    { code: '=', text: "Equals" },
                ])
                setTextType('select')
                setTextList([
                    { code: 'N', text: 'Insufficient match information(nomatch)' },
                    { code: 'D', text: 'Ambiguous choices available(duplicate)' },
                    { code: 'Z', text: 'No company addresses found' },
                    { code: 'I', text: 'Incorrect Assignment.Sent back for reassignment' },
                    { code: 'E', text: 'Match found but poor choice.' }
                ])
                break;
            case 'X': case 'Y': case 'V': case 'T':
                setComparisonList([
                    { code: 'C', text: ' Contains' },
                    { code: 'X', text: 'Does Not Contain' }
                ])
                setTextType('text')
                break;
            case 'A': case 'M': case 'D': case 'P':
                setComparisonList([
                    { code: '=', text: "Equals" },
                    { code: 'N', text: "No Value" },
                    { code: 'n', text: "Has A Value" },
                    { code: 'A', text: "Matches Recipients" },
                    { code: 'a', text: "Doesn't Match Recipients" },
                ])
                setTextType('select')
                fetchIdList(val)
                break;
            case 'E':
                setComparisonList([
                    { code: '=', text: "Equals" },
                    { code: 'N', text: "No Value" },
                    { code: 'n', text: "Has A Value" },

                ])
                setTextType('select')
                setTextList([])
                break;
            case 'Z': case 'S':
                setComparisonList([
                    { code: '=', text: "Equals" },
                    { code: 'N', text: "No Value" },
                    { code: 'n', text: "Has A Value" },
                ])
                setTextType('select')
                fetchIdList(val)
                break;
            default:
                setComparisonList([
                    { code: '=', text: "Equals" },
                    { code: '<', text: "Less Than" },
                    { code: 'L', text: "Less Than Or Equal" },
                    { code: '>', text: "Greater Than" },
                    { code: 'G', text: "Greater Than Or Equal" },
                ])
                setTextType('number')
                break;
        }
    }

    useEffect(() => {
        console.log('comparisonlist=========>', comparisonList)
        if (operationType !== 'edit' || !comparison) {
            setComparison(comparisonList[0]?.code)
        }
    }, [comparisonList])

    useEffect(() => {
        console.log('textList=========>', textList)
        if (operationType !== 'edit' || !text) {
            setText(textList?.length && textList[0]?.code ? textList[0]?.code:'')
        }
    }, [textList])

    const [showValue, setShowValue] = useState(true)

    useEffect(() => {
        console.log('element and comparison ======> ', element, comparison)
        if (['A', 'Z', 'M', 'D', 'P', 'E', 'S'].includes(element) && comparison !== '=') {
            console.log("false")
            setText('')
            setShowValue(false)
        } else {
            console.log("true")
            if (operationType !== 'edit' || !text) {
				setText(textList?.length && textList[0]?.code ? textList[0]?.code:'')
            }
            setShowValue(true)
        }
    }, [element, comparison])


    async function fetchIdList(val) {
        var url
        switch (val) {
            case 'A':
                url = `/mercury/company/${getCompanyIdToken()}/address/list`
                var addresses = await getListData(url)
                setTextList(addresses?.list?.map((data) => ({ code: data?.addressId, text: data?.address1 + ' ' + data?.city + ',' + data?.state + ' ' + data?.zipcode })))
                break;
            case 'M':
                url = `/mercury/company/${getCompanyIdToken()}/mailstop/list`
                var mailstops = await getListData(url)
                setTextList(mailstops?.list?.map((data) => ({ code: data?.mailStopId, text: data?.name })))
                break;
            case 'D':
                url = `/mercury/company/${getCompanyIdToken()}/department/list`
                var departments = await getListData(url)
                setTextList(departments?.list?.map((data) => ({ code: data?.departmentId, text: data?.label })))

                break;
            case 'P':
                url = `/mercury/company/${getCompanyIdToken()}/position/list`
                var positions = await getListData(url)
                setTextList(positions?.list?.map((data) => ({ code: data?.positionId, text: data?.label })))
                break;
            case 'Z':
                url = `/mercury/company/${getCompanyIdToken()}/names`
                var names = await getListData(url)
                setTextList(names?.companyNames?.map((data) => ({ code: data?.id, text: data?.name })))
                break;
            // case 'E':
            //     url = `/mercury/employee/list`
            //     var employees = await getListData(url)
            //     setTextList(employees?.employees?.map((data) => ({ code: data?.employee?.employeeId, text: data?.employee?.employeeName?.firstName + ' ' + data?.employee?.employeeName?.lastName })))
            //     break;
            case 'S':
                url = `/mercury/sender/list`
                var senders = await getListData(url)
                // console.log(senders.senders)
                setTextList(senders?.senders?.map((data) => ({ code: data?.senderId, text: data?.label })))
                break;
            default:
                break;
        }


        // getService(url, true).then((res) => {
        //     if (res.status === 200) {
        //         setTextList(res.data)
        //     }
        // })
    }

    function searchEmployee(name) {
        if (name?.length) {
            getService('/mercury/employee/search', true, {
                params: {
                    name
                }
            }).then((res) => {
                setTextList(res?.data?.employeeNames?.map((data) => ({ 'value': data?.employeeId, 'label': data?.fullName })))
            })
        }
        // const set = setTimeout(() => {

        // }, 1500);
        // return () => clearTimeout(set)
    }

    const searchEmployeeById = (id) => {
        getService(`/mercury/employee/${id}/name`, true).then(
            (res) => {
                setTextList([{
                    'label': res?.data?.employeeName?.fullName, 'value': res?.data?.employeeName?.employeeId
                }])
                // console.log(res)
                // console.log(res.searchResults)
            }
        )
    }


    const getListData = async (url) => {
        var data = await getService(url, true)
        return data?.data;
    }

    const [error, setError] = useState('')

    const setConditionData = () => {
        if (comparison === '=' && !text) {
            return setError('Value is required')
        }
        setError('')
        setConditionInput({
            element, comparison, text
        })
        // console.log({
        //     element, comparison, text
        // })
        document.getElementById('closeconditionmodal').click()
    }


    const resetForm = () => {
        setElement('c')
        setError('')
        setText('')
        setComparison('')
        setComparisonList([])
        setTextList([])
        setTextType('')
        setConditionForm({})
    }

    return (
        <div className="modal fade add_condition" id="add_condition" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body p-4">
                        <h2 className="heading_1 mb-3">{operationType === 'edit' ? 'Edit' : 'Add'} condition</h2>
                        {error &&
                            <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                                <span className="me-3">
                                    <img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" />
                                </span>
                                {error}
                            </div>
                        }
                        <div className="row">
                            <div className="fm_grp col-md-12">
                                <label htmlFor="element" className="form-label">* Element</label>
                                <select id="element" className="form-select" onChange={(e) => setElement(e.target.value)} value={element}>
                                    {conditionList?.map((data, i) => (
                                        <option key={i} value={data?.code}>{data?.text}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="fm_grp col-md-12">
                                <label htmlFor="comparison" className="form-label">* Comparison</label>
                                <select id="comparison" className="form-select" onChange={(e) => setComparison(e.target.value)} value={comparison}>
                                    {comparisonList?.map((data, i) => (
                                        <option key={i} value={data?.code}>{data?.text}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="fm_grp col-md-12">
                                <label htmlFor="textList" className="form-label">* Value</label>
                                {textType !== 'select' && <input type={textType} min="0" max="100" className="form-control" id="value" onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt?.key) && evt?.preventDefault()} onInput={(e) => setText(e?.target?.value)} value={text} />}
                                {textType === 'select' && (element !== 'E' ?
                                    (<select id="textList" className={`form-select ${error ? 'error_inupt' : ''}`} onChange={(e) => setText(e?.target?.value)} value={text}>
                                        {showValue ? textList?.map((data, i) => (
                                            <option key={i} value={data?.code}>{data?.text}</option>
                                        )) :
                                            <option value=''>No value is required</option>
                                        }
                                    </select>) : (
                                        <Select
                                            id='MAIL'
                                            onInputChange={(e) => searchEmployee(e)}
                                            className={`${error ? 'error_inupt' : ''}`}
                                            onChange={(e) => setText(e?.value)}
                                            isClearable={showValue}
                                            isSearchable={showValue}
                                            value={showValue ? textList.filter((ele) => ele.value === text) : ''}
                                            name="label"
                                            options={showValue ? textList : [{ value: '', label: 'No value is required' }]}
                                        />
                                    ))
                                }

                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-end mt-3">
                                <div className="text-end">
                                    <button type="button" className="btn btn-link btn_link_primary" onClick={() => resetForm()} data-bs-dismiss="modal" id='closeconditionmodal' data-bs-toggle="modal" data-bs-target="#add_rule">Cancel</button>
                                    <button type="button" className="btn btn_cstm_primary px-3" onClick={() => setConditionData()} >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AddCondition



// ====================~~~~~~~~~~~~~~~~data dump~~~~~~~~~~~~~~~~====================
// var comp1 = [
    //     { code: '=', text: "Equals" },
    //     { code: '<', text: "Less Than" },
    //     { code: 'L', text: "Less Than Or Equal" },
    //     { code: '>', text: "Greater Than" },
    //     { code: 'G', text: "Greater Than Or Equal" },
    // ]

    // var comp2 = [
    //     { code: 'C', text: ' Contains' },
    //     { code: 'X', text: 'Does Not Contain' }
    // ]

    // var comp3 = [
    //     { code: '=', text: "Equals" }
    // ]

    // var comp4 = [
    //     { code: '=', text: "Equals" },
    //     { code: 'N', text: "No Value" },
    //     { code: 'n', text: "Has A Value" },
    //     { code: 'A', text: "Matches Recipients" },
    //     { code: 'a', text: "Doesn't Match Recipients" },
    // ]

    // var comp4 = [
    //     { code: '=', text: "Equals" },
    //     { code: 'N', text: "No Value" },
    //     { code: 'n', text: "Has A Value" },
    // ]

    // var vals3 = [
    //     { code: 'N', text: 'Insufficient match information(nomatch)' },
    //     { code: 'D', text: 'Ambiguous choices available(duplicate)' },
    //     { code: 'Z', text: 'No company addresses found' },
    //     { code: 'I', text: 'Incorrect Assignment.Sent back for reassignment' },
    //     { code: 'E', text: 'Match found but poor choice.' },
    // ]