/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useRef } from 'react'
import { useParams } from 'react-router-dom'
import { getService } from '../../../../../services/commonApi'

export default function EmptyTrashModal({ onEmptyTrash }) {
    var params = useParams()
    var url = useRef()

    const [folderdetails, setFolderdetails] = useState()
    useEffect(() => 
	{
        if (params.id === 'trash') {
            url.current = `/mercury/mail/mailboxes/folders/${params.id}`
            getFolderDetails()
        }
    }, [params.id])

	const getFolderDetails = () => 
	{
		getService(url.current, true, "").then( (res) => 
		{
			// console.log(res)
			setFolderdetails(res?.data)
		})
	}

	function deleteAllMail() 
	{
		onEmptyTrash(url.current+'/mail').then((res) => 
		{
			document.getElementById('closeEmptyTrashModal').click()
		})
	}

	
    return (
        <div className="modal fade empty_trash" id="empty_trash" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body py-5">
                        <div className="px-lg-5">
                            <h2 className="heading_1">Empty trash</h2>
                            <p>You are about to permanently delete {folderdetails?.totalMail} items. Are you sure you want to empty the trash?</p>
                            <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 mt-5 pt-5">
                                <div> </div>
                                <div className=" text-end">
                                    <button type="button" className="btn btn-link  btn_link_primary" data-bs-dismiss="modal" id="closeEmptyTrashModal">Cancel</button>
                                    <button type="button" className="btn btn_cstm_primary" onClick={() => deleteAllMail()}>Empty trash</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
