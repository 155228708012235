	import Highcharts from "highcharts";
/**
 * 
 * @param 
 * 		{
 {
    "costcenters": [
        {
            "date": null,
            "totalPieces": 43,
            "totalImages": 176,
            "costCenterId": "costCenterPK",
            "costCenterName": "Data Processing",
            "data": [
                {
                    "date": "2022-11-29T00:00:00Z",
                    "totalPieces": 1,
                    "totalImages": 3
                },
                {
                    "date": "2022-11-02T00:00:00Z",
                    "totalPieces": 1,
                    "totalImages": 3
                },
            ]
        },
		
		* @returns 
 */
export const filterMailData = (chartdata) => 
{
	var options;
	if( chartdata ) 
	{
		let xvals = [];
		let xlabels = [];
		let series = [];
		chartdata.costcenters.forEach( (costcenter,i ) =>
		{
			let costcenter_data = [];
			costcenter?.data.forEach( (ele,j) =>
			{
				costcenter_data[j] =
				{
					name: ele?.date,
					y: ele?.totalPieces
				}
				// collect the labels for the bottom.
				xvals.push( ele?.date )
			});
			series[i] =
			{
				name: costcenter.costCenterName,
				showInLegend: true,
				data: costcenter_data // jun30, 15
			}
		});
		// sort and filter the labels so only unique values in sequence are present.
		xlabels = [...new Set(xvals)].sort(function(a, b)
		{
			const date1 = new Date(a)
			const date2 = new Date(b)
			return date1 - date2; 
		});
		// make sure all of the series have the same number of values.
		series.forEach( (series_ele,k) =>
		{
			let new_series_data = new Array(xlabels.length).fill(0).map( (ele,i) => ({ name:xlabels[i], y:0 }) )
			series_ele.data.forEach( (ele,i) =>
			{
				new_series_data[ xlabels.indexOf(ele.name) ].y = ele.y;
			})
			series_ele.data = new_series_data
		});		
		options = 
		{
			title: {
				text: "",
			},

			xAxis: 
			{
				categories: xlabels,
				labels: 
				{
					formatter: function () { return Highcharts.dateFormat("%e %b %Y", new Date(this.value)); },
				},
			},
			yAxis: {
				title: {
				useHTML: true,
				text: "Total Pieces",
				},
			},
			series: series,
			tooltip: 
			{
				headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
				pointFormat:
				'<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
				'<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
				footerFormat: "</table>",
				shared: true,
				useHTML: true,
			},
			plotOptions: 
			{
				column: 
				{
					pointPadding: 0,
					borderWidth: 0.2,
					colorByPoint: true,
				},
			},
		};
	} 
	else 
	{
		options = 
		{
			title: {
				text: "",
			},
			series: [
				{
					data: [],
				},
			],
			lang: {
				noData: "No data found",
			},
			noData: {
				style: {
				fontWeight: "bold",
				fontSize: "15px",
				},
			},
		};
	}
		return options;
	};
