import React from "react";
import ReactDOM from "react-dom/client";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./context/authContext";
import CommonProvider from "./context/commonContext";
import RouteProvider from "./context/routeContext";
import { SnackbarProvider } from "notistack";
import history from "./services/history";
import "bootstrap/dist/css/bootstrap.min.css";
import * as msal from "@azure/msal-browser";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
/*
 * The MsalConfig is placed into the runtime-config.js file.  It is loaded into the Window object and can be retrieved from
 * the environment object which provides a default value if the file is not present.
 */
import { msalConfig } from "./environment/environment"

const root = ReactDOM.createRoot(document.getElementById("root"));

const msalInstance = new msal.PublicClientApplication(msalConfig);
msalInstance.initialize();

root.render
(
	<BrowserRouter history={history}>
		<AuthProvider>
		<RouteProvider>
			<CommonProvider>
			<SnackbarProvider hideIconVariant>
				<App msalInstance={msalInstance} />
			</SnackbarProvider>
			</CommonProvider>
		</RouteProvider>
		</AuthProvider>
	</BrowserRouter>
);

