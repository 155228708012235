import Highcharts from "highcharts";

export const filterProdData = (chartdata) => 
{
	var options;
	if(chartdata) 
	{
		let xvals = [];
		let xlabels = [];
		let series = [];
		chartdata.reasons.forEach( (reason,i ) =>
		{
			let reason_data = [];
			reason?.data.forEach( (ele,j) =>
			{
				reason_data[j] =
				{
					name: ele?.date,
					y: ele?.totalPieces
				}
				// collect the labels for the bottom.
				xvals.push( ele?.date )
			});
			series[i] =
			{
				name: reason.reason,
				showInLegend: true,
				data: reason_data // jun30, 15
			}
		});
		// sort and filter the labels so only unique values in sequence are present.
		xlabels = [...new Set(xvals)].sort(function(a, b)
		{
			const date1 = new Date(a)
			const date2 = new Date(b)
			return date1 - date2; 
		});
		// make sure all of the series have the same number of values.
		series.forEach( (series_ele,k) =>
		{
			let new_series_data = new Array(xlabels.length).fill(0).map( (ele,i) => ({ name:xlabels[i], y:0 }) )
			series_ele.data.forEach( (ele,i) =>
			{
				new_series_data[ xlabels.indexOf(ele.name) ].y = ele.y;
			})
			series_ele.data = new_series_data
		});		
		
		options = 
		{
			title: 
			{
				text: "",
			},

			yAxis: 
			{
				title: 
				{
					text: "Total Pieces",
				},
			},

			xAxis: 
			{
				categories: xlabels,
				labels: { formatter: function () { return Highcharts.dateFormat("%e %b %Y", new Date(this.value)); }, }
			},
			series: series,
			responsive: 
			{
				rules: 
				[
					{
						condition: 
						{
							maxWidth: 500,
							maxHeight: 500,
						},
						chartOptions: 
						{
							legend: 
							{
								layout: "horizontal",
								align: "center",
								verticalAlign: "bottom",
							},
						},
					},
				],
			},
		};
	} else {
		options = {
		title: {
			text: "",
		},
		series: [
			{
			data: [],
			},
		],
		lang: {
			noData: "No data found",
		},
		noData: {
			style: {
			fontWeight: "bold",
			fontSize: "15px",
			},
		},
		};
	}
	return options;
	};
