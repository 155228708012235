/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select';
import { getService } from '../../../../../services/commonApi';
import { getCompanyIdToken } from '../../../../../services/tokenService';
import { filterSearch } from '../../deliverModule/UnassignedMailSearchListLogic';

const SearchField = ({ index, inputData, dynamicForm, setDynamicForm }) => 
{
	const [searchList, setSearchList] = useState([])
	const [dynamicSearch, setDynamicSearch] = useState(false)
	const [searchKey, setSearchKey] = useState("")
	const [selection,setSelection] = useState()
	const [error,setError] = useState(true)
	const url = useRef()

	useEffect(() => 
	{
		const search = setTimeout(() => 
		{
			if( searchKey ) 
			{
				findMatchingSources()
			}
		}, 500);
		return () => clearTimeout(search)
	}, [searchKey])

	useEffect(() => 
	{
		if (!['employee', 'sender'].includes(inputData?.inputtype)) 
		{
			setDynamicSearch(false)
			if (inputData?.inputtype === 'workstation') 
			{ 
				url.current = '/mercury/system/workstations' 
			}
			else 
			{ 
				url.current = `/mercury/company/${getCompanyIdToken()}/${inputData?.inputtype !== 'company' ? inputData?.inputtype + '/list' : 'names'}` 
			}
			findMatchingSources()
		} 
		else 
		{
			url.current = '/mercury/search/resources'
			setDynamicSearch(true)
		}
		setError( inputData?.has_error )
	}, [inputData])

	const handleInput = (index, value) => 
	{
		console.log(index, value)
		dynamicForm[index] = { ...dynamicForm[index], value }
		setDynamicForm([...dynamicForm])
	}

	// matchSourcesurl = '/mercury/search/resources'
	function capitalizeFirstLetter(string) 
	{
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const findMatchingSources = () => 
	{
		let params = {}
		if( searchKey ) 
		{
			params = { filter: searchKey, include_type: inputData.inputtype }
		}
		else 
		{
			params = { include_type: inputData.inputtype }
		}
		getService(url.current, true, dynamicSearch && { params }).then( res => 
		{
			let list = []
			if( dynamicSearch )
			{
				list = filterSearch( { filter: '', include_type: inputData.inputtype,  ...res?.data } )
			}
			else
			{
				list = filterSearch( { matches: [ { recordType: capitalizeFirstLetter( inputData?.inputtype ), ...res?.data } ], filter: inputData.inputtype, include_type: inputData.inputtype } );
			}
			setSearchList( list )
			if( list && list.length )
			{
				setSelection( list[0].id )
				dynamicForm[index] = { ...dynamicForm[index], 'value': list[0].id }
			}
		})
	}

	return (
		<div className="fm_grp col-md-12">
			<label htmlFor={`field${index}`} className="form-label">{inputData?.inputlabel}</label>
			{
				!dynamicSearch ? <select className={`form-control ${error ? 'report_error_input' : ''}`} id={`field${index}`} onChange={(e) => handleInput(index, e.target.value)} value={selection}>
				{
					searchList?.map((ele) => 
					(
						<option key={ele?.id} value={ele?.id}>{ele?.label}</option>
					))
				}
				</select> : <Select id='MAIL' className={`${error ? 'report_error_input' : ''}`} onInputChange={(e) => setSearchKey(e)} onChange={(e) => handleInput(index, e?.value)} 
							isClearable={true} isSearchable={true} name="label" defaultValue={selection} options={ searchList?.map((data) => 
					(
						{ 'key': data?.id, 'value': data?.id, 'label': data?.label }
					))}
				/>
			}
		</div>
	)
}

export default SearchField
