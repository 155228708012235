/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getconfiguration } from "../../../services/AuthApi";
import { setCompanyIdToken } from "../../../services/tokenService";
// import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";
// import Select from 'react-select';

const NameInfo = ({
  navLink,
  setNavLink,
  error,
  setError,
  nameInput,
  setNameInput,
}) => {
  const [firstname, setfirstname] = useState(
    nameInput ? nameInput.firstname : ""
  );
  const [title, setTitle] = useState(nameInput ? nameInput.title : "");
  const [middlename, setmiddlename] = useState(
    nameInput ? nameInput.middlename : ""
  );
  const [lastname, setlastname] = useState(nameInput ? nameInput.lastname : "");
  const [maiden_name, setMaiden_name] = useState(
    nameInput ? nameInput.maiden_name : ""
  );
  const [suffix, setSuffix] = useState(nameInput ? nameInput.suffix : "");
  const [nickNames, setAlternate] = useState(
    nameInput ? nameInput.nickNames.join("\n") : ""
  );

  const [submit, setSubmit] = useState(false);

  const [titlesList, setTitlesList] = useState([]);
  const [suffixList, setSuffixList] = useState([]);

  const getConfigurationData = () => {
    // console.log('calling')
    getconfiguration().then((result) => {
      // console.log(result)
      setCompanyIdToken(result?.companyId);
      setTitlesList(result?.displaySettings?.titles);
      setSuffixList(result?.displaySettings?.suffixes);
    });
  };

  // useEffect(() => {
  //   setfirstName(nameInput.firstName)
  //   setTitle(nameInput.title);
  //   setmiddleName(nameInput.middleName);
  //   setlastName(nameInput.lastName);
  // }, [nameInput])

  useEffect(() => {
    getConfigurationData();
  }, []);

  useEffect(() => {
    setNameInput({
      firstname,
      title,
      middlename,
      lastname,
      maiden_name,
      suffix,
      nickNames: nickNames.split("\n"),
    });
    // console.log({
    //   firstname,
    //   title,
    //   middlename,
    //   lastname,
    //   maiden_name,
    //   suffix,
    //   'nickNames': nickNames.split('\n'),
    // })
  }, [firstname, middlename, maiden_name, lastname, nickNames, title, suffix]);

  const setNameInputData = () => {
    setSubmit(true);
    setNameInput({
      firstname,
      title,
      middlename,
      lastname,
      maiden_name,
      suffix,
      nickNames: nickNames.split("\n"),
    });

    if (!firstname || !lastname) {
      if (!firstname && lastname) {
        return setError("First name is required");
      } else if (!lastname && firstname) {
        return setError("Last name is required");
      }
      return setError("Required fields are missing ");
    }

    setError("");
    setNavLink("company");
  };

  return (
    <div
      className={`tab-pane fade ${navLink === "name" ? "show active" : ""}`}
      aria-labelledby="v-pills-home-tab"
    >
      <div className="col-lg-9 offset-lg-1 modalContentItems">
        <h2 className="heading_1">Let’s create your account</h2>
        <p className="mb-0">
          In order to create your account, we will need certain information from
          you. Please provide all the following information so that we can
          process your request.
        </p>
        <p className="fnt_w_bold">Please be as thorough as possible.</p>
        <form className=" pt-4">
          <div className="row g-3">
            <div className="fm_grp col-md-6">
              <label
                htmlFor="Firstname"
                className={`form-label ${
                  error && !firstname ? "error_text" : ""
                }`}
              >
                * First name {error && !firstname && <span>is required</span>}
              </label>
              <input
                type="text"
                className={`form-control ${
                  error && !firstname ? "error_input" : ""
                }`}
                id="Firstname"
                onInput={(e) => setfirstname(e.target.value)}
                value={firstname}
              />
            </div>

            <div className="fm_grp col-md-6">
              <label htmlFor="inputTitle" className="form-label">
                Title
              </label>

              <Combobox
                value={title}
                className="form-control"
                id="inputTitle"
                onChange={(e) => {
                  setTitle(e);
                }}
                // onInput={(e) => console.log(e)}
                data={titlesList}
              />

              {/* 
              {addNewTitle &&
                <div className='d-flex align-items-center'>
                  <input type="text" className='form-control' onInput={(e) => setTitle(e.target.value)} value={title} />
                  <span  className="btn-close" onClick={() => { setAddNewTitle(false) }}></span>
                </div>
              }
              {!addNewTitle && <select
                id="inputTitle"
                 className="form-select"
                onInput={(e) => {
                  if (e.target.value === 'addNew') { return setAddNewTitle(true) }
                  else {
                    setTitle(e.target.value)
                  }
                }}
                value={title}
              >
                {titlesList?.map((data, i) => {
                  return (<option key={i} value={data}>{data}</option>)
                })
                }
                <option value='addNew'>Add New</option>
              </select>} */}
            </div>

            <div className="fm_grp col-md-6">
              <div className="mb-3">
                <label htmlFor="Middlename" className="form-label">
                  Middle name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Middlename"
                  onInput={(e) => setmiddlename(e.target.value)}
                  value={middlename}
                />
              </div>

              <div>
                <label
                  htmlFor="inputLastName"
                  className={`form-label ${
                    error && !lastname ? "error_text" : ""
                  }`}
                >
                  * Last name {error && !lastname && <span>is required</span>}
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    error && !lastname ? "error_input" : ""
                  }`}
                  id="inputLastName"
                  onInput={(e) => setlastname(e.target.value)}
                  value={lastname}
                />
              </div>
            </div>

            <div className="fm_grp col-md-6">
              <label htmlFor="inputAltFirstName" className="form-label">
                Alternate first names (1 per line)
              </label>
              <textarea
                className="form-control"
                id="inputAltFirstName"
                rows="5"
                onInput={(e) => setAlternate(e.target.value)}
                value={nickNames}
              ></textarea>
            </div>

            <div className="fm_grp col-md-6">
              <label htmlFor="inputMaiden" className="form-label">
                Previous Last Name
              </label>
              <input
                type="text"
                className="form-control"
                id="inputMaiden"
                onInput={(e) => setMaiden_name(e.target.value)}
                value={maiden_name}
              />
            </div>

            <div className="fm_grp col-md-6">
              <label htmlFor="inputSuffix" className="form-label">
                Suffix
              </label>

              <Combobox
                value={suffix}
                id="inputSuffix"
                className="form-control"
                onChange={(e) => {
                  setSuffix(e);
                }}
                data={suffixList}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1">
        <div className="">* Required field</div>
        <div className="p-md-4 text-end">
          <button
            type="button"
            className="btn btn-link  btn_link_primary me-3"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn_cstm_primary px-4"
            onClick={() => setNameInputData()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default NameInfo;
