import React, { useState } from 'react'
import { postService } from '../../../../../../services/commonApi'
import { getCompanyIdToken } from '../../../../../../services/tokenService'
const CompanyAdd = ({ getList }) => {

    const [companyname, setCompanyname] = useState('')
    // const [submit, setSubmit] = useState(false)
    const [error, setError] = useState('')

    const addCompany = () => {
        // setSubmit(true)
        if (!companyname) {
            return setError('Company name is required')
        }
        addNewCompanyAPIFunc()
        setError('')
    }

    const addNewCompanyAPIFunc = () => {
        const url = `/mercury/company/${getCompanyIdToken()}/names`
        let data = {
            "name": companyname,
            "primary": true
        }
        postService(url, data, "").then(
            (res) => {
                if (res?.status === 200) {
                    getList()
                    document.getElementById("CloseCompanyAddModal").click()
                } else {
                    setError(res?.response?.data?.error?.desc)
                }
            }, (err) =>
            setError(err?.response?.data?.error?.desc)
        )
    }

    const resetForm = () => {
        setCompanyname("")
        setError("")
    }

    return (
        <div className="modal fade add_company" id="add_company" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body py-4 px-lg-0">
                        <h2 className="heading_1 ps-lg-4">Add Company Name</h2>

                        {/* <!--  Error alert box  --> */}
                        {error && <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                            <span className="me-3"><img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" /></span>{error}
                        </div>}
                        {/* <!--  Error alert box  --> */}

                        <div className="p-lg-5">
                            <div className="d-flex col-xl-12 m-auto py-4">
                                <span className="me-3 d-flex align-items-center" style={{ 'visibility': error ? 'visible' : 'hidden' }}><img src="assets/img/error_icon.svg" className="img-fluid" alt="" /></span>
                                <div className="fm_grp">
                                    <label htmlFor="companyName" className="form-label">Add the following Company Name:</label>
                                    <input type="text" id="companyName" onInput={(e) => setCompanyname(e.target.value)} value={companyname} style={{ 'width': '350px' }} className={`form-control  ${error ? 'error_input' : ''}`} />
                                </div>

                            </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 px-3">
                            <div> </div>
                            <div className=" text-end">
                                <button type="button" className="btn btn-link  btn_link_primary me3" data-bs-dismiss="modal" id="CloseCompanyAddModal" onClick={resetForm}>Cancel</button>
                                <button type="button" className="btn btn_cstm_primary px-4" onClick={() => addCompany()}>Add Company Name</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CompanyAdd