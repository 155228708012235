/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { RouteState } from '../../../../context/routeContext'

import AccountType from './AccountType'
import Settings from './Settings'
import WorkStation from './WorkStation'


const System = () => {

    const { systemRoute, setSystemRoute } = RouteState()

    const [activeTab, setActiveTab] = useState('accountType')

    useEffect(() => {
        setActiveTab(systemRoute)
        // console.log(systemRoute)
    }, [systemRoute])

    return (
        <>
            <div id="content" className="tab-content cst_tab" role="tablist">

                <div id="pane-A" className={`tab-pane fade ${activeTab === 'accountType' ? 'show active' : 'fade'}`} role="tabpanel" aria-labelledby="tab-A">
                    <AccountType activeTab={activeTab} />
                </div>
                {/* <!-- End  Accounts-- >/ */}
                {/* < !--Start  Accounts Requested-- > */}
                <div id="pane-B" className={`tab-pane fade ${activeTab === 'workstation' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-B">
                    <WorkStation activeTab={activeTab} />
                </div>
                {/* <!--End  Accounts Requested-- > */}
                <div id="pane-C" className={`tab-pane fade ${activeTab === 'setting' ? 'show active' : ''}`} role="tabpanel" aria-labelledby="tab-C">
                    <Settings activeTab={activeTab} />
                </div>

            </div>
        </>
    )
}

export default System