/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { CommonState } from '../../../../../context/commonContext'

const DeleteFolder = ( {onDeleteFolder} ) => {

    const [submit,setSubmit] = useState(false)
    const [replacementId, setReplacementId] = useState('inbox')
    const [error,setError] = useState('')
	const { actionFolder, folderList } = CommonState()

    const deleteFolder = () => 
	{
		onDeleteFolder(actionFolder.folderId,replacementId).then((res) => 
		{
			document.getElementById('closedeletefolderModal').click()
		})
		.catch( err =>
		{
			console.log(err)
			setError( 'Folder not deleted.' )
		})
    }

    const resetModal = () => 
	{
        setReplacementId('')
        setError('')
        setSubmit(false)
    }

    return (
        <div className="modal fade cost_centers" id="delete_folder" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body py-4 px-lg-0">
                        <h2 className="heading_1 ps-lg-4">Delete & Move</h2>
                        <div className="p-lg-5">
                            <div className="col-xl-9 m-auto py-4">
                                <h3 className="heading_2 mb-4">Delete "{actionFolder?.name}"</h3>
                                <div className="fm_grp">
                                    <label htmlFor="inputState" className="form-label">* Move mail to the following folder:</label>
                                    <select id="inputState" className="form-select" onChange={(e) => setReplacementId(e?.target?.value)} value={replacementId}>
                                        {
                                            folderList?.map((item, i) => 
											{
                                                return ((item.folderId !== actionFolder?.folderId) && <option key={i} value={item?.folderId}>{item?.name}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 px-3">
                            <div> </div>
                            <div className=" text-end">
                                <button type="button" id="closedeletefolderModal" className="btn btn-link  btn_link_primary" onClick={() => resetModal()} data-bs-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn_cstm_primary" onClick={() => deleteFolder() }>Delete Folder</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteFolder
