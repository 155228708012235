/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { RouteState } from '../../../../context/routeContext'

import DeleteBanner from './modals/DeleteBanner'
import { deleteService, getService, putService } from '../../../../services/commonApi'
import { ProgressBar } from 'react-bootstrap'
import { url as MainUrl } from '../../../../environment/environment'
import { stringPipe } from '../../../../pipes/stringPipes'
import WrongCompanyBanner from './modals/WrongCompanyBanner'
import ImageGallery from './ImageGallery'
import { useSnackbar } from 'notistack'
import { CommonState } from '../../../../context/commonContext'
import { getAuthToken } from '../../../../services/refreshBearerToken';
import { errorHandler } from "../../../../services/ErrorHandler";

var axios = require('axios');

const WrongCompany = () => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    var imageRef = useRef()
//    var url = useRef()
	const [url,setUrl] = useState('')
    var PrimaryUrl = '/mercury/mail/wrongcompany'
    const { deliveryRoute, setDeliveryRoute } = RouteState()
    const [close, setClose] = useState(false)
    const [mailList, setMailList] = useState([])
    const [mailDetail, setMailDetail] = useState({})

    const [mailImagesList, setMailImagesList] = useState([])

    const [skippedDetails, setSkippedDetails] = useState([])

    const [currentIndex, setCurrentIndex] = useState(0)

    const [loader, setLoader] = useState(false)
    const [text, setText] = useState('')

    const { refreshMailStats, setRefreshMailStats } = CommonState()


    useEffect(() => {
        if (deliveryRoute === 'wrong') {
            getWrongMailList()
        }
    }, [deliveryRoute])

    const getWrongMailList = () => {
        getService(PrimaryUrl, true, "").then(
            (res) => {
                // console.log(res);
                if (res.status === 200) {
                    setMailList(res?.data?.messages)
                }
            }
        )
    }

    useEffect(() => {
        if (mailList.length > 0) {
            getMailDetail(currentIndex)
        }
    }, [mailList, currentIndex])

	const getMailDetail = (index) => 
	{ 
//		url.current = `${PrimaryUrl}/${mailList[index]?.assignId}`

		let curr_url = `${PrimaryUrl}/${mailList[index]?.assignId}`
		setUrl( `${PrimaryUrl}/${mailList[index]?.assignId}` )

		getService( curr_url, true, "" ).then((res) => 
		{
					setMailDetail(res.data)
					setMailImagesList(res.data.mailParts)
		})
	}

    function setSkipstate() {
        skippedDetails[currentIndex] = mailDetail
    }

	const reassignMail = () => 
	{
		putService(url).then( res => 
		{
			document.getElementById('popReassignCanvas').click()
			autocloseBox()
			resetMailScreen()
			getWrongMailList()
			setRefreshMailStats(!refreshMailStats)
			setCurrentIndex(currentIndex < mailList.length - 1 ? currentIndex : currentIndex - 1)
		})
	}

	const deleteMail = () => 
	{
		deleteService(`${url}`, '', true).then((res) => 
		{
					setClose(true)
					resetMailScreen()
					setRefreshMailStats(!refreshMailStats)
					getWrongMailList()
		})
	}
	
	const saveMail = async () => 
	{
		getAuthToken().then( token =>
		{
			var config = {
				method: 'get',
				url: `${MainUrl}${url}/pdf`,
				headers: {
					'Authorization': 'Bearer ' + token,
				},
				responseType: 'arraybuffer'
			}
			axios(config).then(res => 
			{
				var file = new Blob([res.data], { type: 'application/pdf' });
				var fileUrl = URL.createObjectURL(file);
				var fileName = "wrongCompany.pdf";
				var a = document.createElement("a");
				document.body.appendChild(a);
				a.href = fileUrl;
				a.download = fileName;
				a.click();
				//open it directly 
				window.open(fileUrl);
			})
		})
		.catch( err => 
		{
			errorHandler( err );
		})
    }

    function showAlertBox(msg) {

        const action = snackbarId => (
            <>
                <button className="btn close_btn" style={{ 'color': '#3D75B1', 'font-size': '25px' }} onClick={() => { closeSnackbar(snackbarId) }}>
                    <i className="bi bi-x"></i>
                </button>
            </>
        );
        enqueueSnackbar(msg, {
            variant: 'warning',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            },
            autoHideDuration: 10000,
            action,
            preventDuplicate: true
        }
        )
    }

    const resetMailScreen = () => {
        setMailDetail({})
        setMailImagesList([])
    }

    function autocloseBox() {
        setTimeout(() => {
            setClose(true)
        }, 2000);
    }



    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-D" onClick={() => { setDeliveryRoute('wrong') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-D" aria-expanded="true" aria-controls="collapse-D">
                        Wrong Company  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-D" className={`bgYellow collapse ${deliveryRoute === "wrong" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-D">
                <div className={`row g-1 align-items-center ${mailList?.length < 1 && 'mailHeadDisable'}`}>
                    <div className="col-lg-9">
                        {/* <div  className="head inbox_head inbox_head_left py_19">
                            <p  className="text_dark mb-0">#2 of 12 <img  className="ms-3" src="assets/img/range.svg" alt="" /></p>
                        </div> */}

                        {mailList?.length < 1 ?
                            <div className="head mt-2 mt-lg-0 row">
                                <p className="flex-grow-1 fnt_14 mb-0 mt-2">No mail left</p>
                            </div> :
                            <div className="head mt-2 mt-lg-0 row">
                                <div className="col-auto">
                                    <p className="text_dark mb-0">#{currentIndex + 1} of {mailList?.length}</p>
                                </div>
                                <div className="col-11 col-md-7 col-sm-12 align-self-center">
                                    <ProgressBar variant="info" now={((currentIndex + 1) / mailList?.length) * 100} />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-3 col-lg-1">
                        <div className="inbox_right_icon_link">
                            <a onClick={() => saveMail()}>
                                <img src="assets/img/Print.png" alt="" />
                                <span>Print </span>
                            </a>
                        </div>
                    </div>
                    <div className="col-3 col-lg-1">
                        <div className="inbox_right_icon_link">
                            <a onClick={() => { setCurrentIndex(currentIndex + 1 < mailList?.length ? currentIndex + 1 : 0) }}>
                                <img src="assets/img/Forward.png" alt="" />
                                <span>Skip</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-3 col-lg-1 order-lg-3">
                        <div className="inbox_right_icon_link">
                            <a onClick={() => reassignMail()} >
                                <img src="assets/img/Notmine.png" alt="" />
                                <span>Reassign</span>
                            </a>
                        </div>
                    </div>
                    {/* <div  className="col-3 col-lg-1 order-lg-3">
                        <div  className="inbox_right_icon_link">
                            <a onClick={() => deleteMail()} data-bs-toggle="modal" data-bs-target="#delete_banner">
                                <img src="assets/img/Delete.png" alt="" />
                                <span>Delete</span>
                            </a>
                        </div>
                    </div> */}
                </div>

                <div className="p-2 mt-4">
                    <div className="row">
                        <div className="col-lg-9">
                            {/* <!-- Add doc sheet area --> */}
                            <ImageGallery imageList={mailImagesList} mailUrl={url} />
                            {/* <!-- Add doc sheet area --> */}
                        </div>

                        <div className="col-lg-3">
                            <div className="right_box mt-4 pt-1">
                                <p className="mb-1 pb-1 bdr_b"><b>Batch information </b></p>
                                <p className="mb-0">Batch: <b> {stringPipe(mailDetail?.batchId, 15)}</b></p>
                                <p className="mb-0">Position: <b>{mailDetail?.batchSequence} </b></p>
                                <p className="mb-0">Tracking ID: <b>{stringPipe(mailDetail?.externalTrackingId, 15)}</b></p>
                            </div>

                            <div className="right_box mt-5 pt-1 pb-lg-5">
                                <p className="mb-1 pb-1 bdr_b"><b> Mail information </b></p>
                                <p className="mb-0">Digitized: <b> {new Date(mailDetail?.digitizedDate).toLocaleDateString()}</b></p>
                                <p className="mb-0">Number of pages: <b>{mailDetail?.mailParts?.length} </b></p>
                                <p className="mb-0">Copies Circulating: <b>{mailDetail?.accessCount}</b></p>
                                {/* <p className="mb-0">Assigned: <b>{new Date(mailDetail?.assignedDate).toLocaleDateString()}</b></p> */}
                            </div>

                            <div className="text-end mt-5 pt-lg-5">
                                <button type="button" className="btn btn_cstm_primary" data-bs-toggle="offcanvas" data-bs-target="#delete_banner" disabled={mailList?.length < 1}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
                <a data-bs-toggle="offcanvas" aria-controls="offcanvasScrolling" data-bs-target="#reassignCanvas" id='popReassignCanvas' className='d-none'>o</a>

                <DeleteBanner callBackFunction={deleteMail} close={close} setClose={setClose} />
                <WrongCompanyBanner close={close} setClose={setClose} />
            </div>
        </>
    )
}

export default WrongCompany