/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { postService, putService } from '../../../../../../../services/commonApi'

function EditSenderName({ data, type, url, getSenderNames }) {

    const [name, setName] = useState('')
    const [primary, setPrimary] = useState()

    const [error, setError] = useState('')

    useEffect(() => {
        if (type === 'edit') {
            setName(data?.name)
            setPrimary(data?.primary)
        } else {
            setName('')
        }
    }, [type, data?.nameId])

    const submitSenderName = () => {

        if (!name) {
            return setError('Name is required field')
        }

        type ?
            putService(`${url}/name/${data?.nameId}`, { nameId: data?.nameId, name, primary }).then(
                (res) => {
                    // console.log('res', res)
                    if (res?.request?.status === 200) {
                        getSenderNames()
                        document.getElementById('editsenderClose').click()
                    } else {
                        return setError(res?.request.statusText)
                    }
                }, 
				err => 
				{ 
					return setError( err?.response?.data?.error?.desc )
				}
            ) :
            postService(`${url}/name`, { name, primary: false }).then(
                (res) => {
                    // console.log('res', res)
                    if (res?.request?.status === 200) {
                        getSenderNames()
                        document.getElementById('editsenderClose').click()
                    }
                    else {
                        return setError(res?.request?.statusText)
                    }
                }, err => { return setError(err?.request?.statusText) })


    }

	const resetForm = () => {
        setError('')
        setName('')
		setPrimary( false )
    }

    return (
        <div className="modal fade add_sender_name" id="add_sender_name" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-body py-4 px-lg-0">
                        <h2 className="heading_1 ps-lg-4 text-capitalize">{type ? 'Edit' : 'Add'} sender name</h2>
                        {error && <div className="alert alert-danger alert_danger ps-lg-5" role="alert">
                            <span className="me-3"><img src="/assets/img/error_icon_white.png" className="img-fluid" alt="" /></span>{error}
                        </div>}
                        <div className="p-lg-5">
                            <div className="fm_grp">
                                <label htmlFor="inputAddress" className="form-label" required="">Sender’s full name</label>
                                <input type="text" className="form-control" onInput={(e) => setName(e.target.value)} value={name} id="inputAddress" placeholder="" />
                            </div>
                            <div className="fm_grp form-check">
                                <input type="checkbox" className="form-check-input" onChange={(e) => setPrimary(e.target.checked)} checked={primary ? true : false} id="isprimary" placeholder="" />
                                <label htmlFor="isprimary" className="form-check-label" required="">Is primary</label>
                            </div>

                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-between offset-md-1 pe-3">
                            <div> </div>
                            <div className=" text-end">
                                <button type="button" id='editsenderClose' onClick={resetForm} className="btn btn-link  btn_link_primary me-3" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#sender_name">Cancel</button>
                                <button type="button" className="btn btn_cstm_primary px-4" onClick={() => submitSenderName()}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditSenderName