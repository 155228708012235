/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { RouteState } from '../../../../context/routeContext'
import DeleteWorkstation from './modals/DeleteWorkstation'
import { getService } from '../../../../services/commonApi'
import AddWorkstation from './modals/AddWorkstation'
import './WorkStation.css'


const WorkStation = () => {
    const { systemRoute, setSystemRoute } = RouteState()

    const [workstationList, setWorkstationList] = useState([])
    const [workstationId, setWorkstationId] = useState('')

    var primaryUrl = "/mercury/system/workstations"

    useEffect(() => {
        systemRoute === "workstation" && getWorkstationList()
    }, [systemRoute])

    const getWorkstationList = () => {
        getService(primaryUrl, true, "").then(
            (res) => {
                if (res.status === 200) {
                    setWorkstationList(res?.data?.workstations)
                } else {

                    // console.log(res)
                }
            }, err => { }
        )
    }


    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-B" onClick={() => { setSystemRoute('workstation') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-B" aria-expanded="true" aria-controls="collapse-B">
                        Workstations  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-B" className={`bgYellow collapse ${systemRoute === "workstation" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-B">
                <div className="head d-none d-lg-block">
                    <h4 className="heading_1 text_dark mb-0">Workstations</h4>
                </div>

                <div className="p-3 my-4">
                    <p>Please enter all of your company’s scanner workstations. This list provides authorizations for scanning mail.</p>
                </div>

                <div className=" col-xl-3">
                    <div className="table-responsive cst_table account_types">
                        <table className="table">
                            <tbody>
                                {workstationList?.map((data, i) => (
                                    <tr key={i}>
                                        <td>{data?.workstationName}</td>
                                        {/* <td>*/}
                                        <td className="d-flex justify-content-end">
                                            <a href="#" className="ms-2" data-bs-toggle="modal" onClick={() => setWorkstationId(data?.workstationId)} data-bs-target="#delete_workstation_replace"><img src="assets/img/Delete.svg" alt="" /> </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="add_account mt-4">
                        <a href="#" data-bs-toggle="modal" data-bs-target="#add_Workstation"> <img src="assets/img/add_account.svg" alt="" /><span>Add a Workstation</span></a>
                    </div>


                </div>
                <DeleteWorkstation url={primaryUrl} workstationId={workstationId} workstationList={workstationList} setWorkstationId={setWorkstationId} getWorkstationList={getWorkstationList} />
                <AddWorkstation url={primaryUrl} getWorkstationList={getWorkstationList} />
            </div>

        </>
    )
}

export default WorkStation