/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'



import Name from './Name'
import Company from './Company'
import Contact from './Contact'
import Account from './Account'
import { getService, postService, putService } from '../../../../../../../services/commonApi'

const AccountAdd = ({ userID, setuserID, getAccountList }) => {

	var addressURL = "/mercury/employee"

	const [navLink, setNavLink] = useState("name");
	const [nameInput, setNameInput] = useState();
	const [nameInputError, setNameInputError] = useState();

	const [companyInput, setCompanyInput] = useState();
	const [companyInputError, setCompanyInputError] = useState();

	const [contactInput, setContactInput] = useState();
	const [contactInputError, setContactInputError] = useState();

	const [accountInput, setAccountInput] = useState();
	const [accountInputError, setAccountInputError] = useState();

	const [error, setError] = useState({ err:true, desc:'', name:{err:false,desc:""}, company:{err:false,desc:""}, contact:{err:false,desc:""}, account:{err:false,desc:""} });
	const [mode, setMode] = useState('')

	useEffect(() => 
	{
		if (userID) {
			getAccount(userID)
			setMode('edit')
		} else {
			setMode('')
			setNameInput({});
			setAccountInput({});
			setCompanyInput({});
			setContactInput({});
		}
	}, [userID])

	const getAccount = (id) => 
	{
		getService(`${addressURL}/${id}`, true).then((res) => 
		{
			if (res?.status === 200) 
			{
				var userdata = res.data
				setNameInput(userdata?.employee?.employeeName);
				setAccountInput(userdata?.employeeAccount);
				setCompanyInput(userdata?.employee?.companyDetails);
				setContactInput(userdata?.employee?.contactDetails);
			}
		})
	}

	const onError = ( err ) =>
	{
		let error = { err:true, desc:err?.desc + ":", name:{desc:""}, company:{desc:""}, contact:{desc:""}, account:{desc:""} }
		err.fields.forEach( (elem, idx ) =>
		{
			error.desc += "<br>" + err.info[idx]
			switch( elem )
			{
				case "firstName":
				case "title":
				case "middleName":
				case "lastName":
				case "maidenName":
				case "suffix":
				case "nickNames":
					error['name']['err'] = true;
					error['name'][elem] = true;
					error['name']['desc'] = err.info[idx]
					break;
				case "addressId":
				case "mailStopId":
				case "departmentId":
				case "employeeNumber":
				case "position":
				case "costCenterId":
					error['company']['err'] = true;
					error['company'][elem] = true;
					error['company']['desc'] = err.info[idx]
					break;
				case "emailAddress1":
				case "cellPhone":
				case "workPhone":
					error['contact']['err'] = true;
					error['contact'][elem] = true;
					error['contact']['desc'] = err.info[idx]
					break;
				case "userName":
				case "notificationMode":
				case "accountTypeId":
				case "notify":					
					error['account']['err'] = true;
					error['account'][elem] = true;
					error['account']['desc'] = err.info[idx]
					break;
				default:
					break;
			}
		})
		if( error?.name?.err )
		{
			setNameInputError( error.name )
			setNavLink('name')
		}
		if( error?.company?.err )
		{
			setCompanyInputError( error.company );
			setNavLink('company')
		}
		if( error?.contact?.err )
		{
			setContactInputError( error.contact )
			setNavLink('contact')
		}
		if( error?.account?.err )
		{
			setAccountInputError( error.account )
			setNavLink('account')
		}
		setError( error )
	}

	const resetData = () => 
	{
		setNameInput('');
		setNameInputError({err:false,desc:""})
		setAccountInput('');
		setAccountInputError({err:false,desc:""});
		setCompanyInput('');
		setCompanyInputError({err:false,desc:""})
		setContactInput('');
		setContactInputError({err:false,desc:""})
		setError( { err:false, desc:"", name:{err:false,desc:""}, company:{err:false,desc:""}, contact:{err:false,desc:""}, account:{err:false,desc:""} } )
		setuserID('')
		setNavLink('name')
	}

	const addAccount = (accountInput) => 
	{
		if ((nameInput && !nameInputError?.err) && (companyInput && !companyInputError?.err) && (contactInput && !contactInputError?.err) && (accountInput && !accountInputError?.err)) 
		{
			const data = ({ ...nameInput, ...companyInput, ...contactInput, ...accountInput })
			postService(addressURL, data, true).then((res) => 
			{
				setAccountInput({});
				getAccountList()
				document.getElementById('closeAccReqButton').click()
			})
			.catch( err =>
			{
				onError( err?.response?.data?.error )	
			})
	
		}
	}

	const editAccount = (accountInput) => 
	{
		// console.log('edit')
		if ((nameInput && !nameInputError?.err) && (companyInput && !companyInputError?.err) && (contactInput && !contactInputError?.err) && accountInput) 
		{
			const data = ({ ...nameInput, ...companyInput, ...contactInput, ...accountInput })
			// some elements that were loaded and passed back should be deleted since not part of api.
			data['mailStopId'] = data['primaryMailStopId'] ? data['primaryMailStopId'] : companyInput['mailStopId']
			delete data['primaryMailStop']
			delete data['primaryMailStopId']
			delete data['secondaryMailStopId']
			delete data['address']
			delete data['employeeId']
			delete data['companyId']
			delete data['fullName']
			delete data['positionNames']
			delete data['departmentContact']
			delete data['homePhone']
			delete data['faxPhone']
			delete data['emailAddress2']
			delete data['costCenter']
			delete data['department']
			// update the data.
			putService(`${addressURL}/${userID}`, data, true).then((res) => 
			{
				setAccountInput({});
				getAccountList()
				document.getElementById('closeAccReqButton').click()
			})
			.catch( err =>
			{
				onError( err?.response?.data?.error )	
			})
		}
	}

	useEffect(() => 
	{
		switch (navLink) {
			case 'name':
				setError({ ...error, desc:nameInputError?.desc, err:nameInputError?.err, name: nameInputError } )
				break;
			case 'company':
				setError({ ...error, desc:companyInputError?.desc, err:companyInputError?.err, company: companyInputError })
				break;
			case 'contact':
				setError({ ...error, desc:contactInputError?.desc, err:contactInputError?.err, contact: contactInputError })
				break;
			default:
				setError({ ...error, desc:accountInputError?.desc, err:accountInputError?.err, account: accountInputError })
				break;
		}
	}, [navLink, nameInputError, companyInputError, contactInputError, accountInputError])

	return (
		<>

			<div
				className="modal fade"
				id="staticBackdrop"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
					<div className="modal-content">
						<div className="mdl_head">
							<div className="row gx-0">
								<div className="col-12 col-lg-3 col-xl-2">
									<div className="bg_primary">
										<div className="pstn_relative">
											<h5 className="modal-title p-4" id="staticBackdropLabel">
												{mode ? 'Edit' : 'Add'} account
											</h5>
											<button
												type="button"
												id="closeAccReqButton"
												className="btn-close p-4 d-block d-lg-none sm_btn-close"
												data-bs-dismiss="modal"
												onClick={() => resetData()}
												aria-label="Close"
											></button>
										</div>
									</div>
									{/* start responsive */}
									{error?.err && (
										<div
											className="alert alert-danger sm_ctm_alert pl-4 d-block d-lg-none"
											role="alert"
										>
											<span>
												<img
													src="/assets/img/error_icon_white.png"
													className="img-fluid"
													alt=""
												/>
											</span>{" "}
											{error.desc}
										</div>
									)}
									{/* end responsive */}
								</div>

								<div className="col-12 col-lg-9 col-xl-10 text-end">
									<div className="pstn_relative">
										<button
											type="button"
											className="btn-close btnClose p-2 d-none d-lg-block ms-auto"
											data-bs-dismiss="modal"
											aria-label="Close"
										><i className="bi bi-x d-none"></i></button>
										{/* start desktop */}
										{error?.err && (
											<div
												className="alert alert-danger ctm_alert d-none d-lg-block"
												role="alert"
											>
												<span className="me-3">
													<img
														src="/assets/img/error_icon_white.png"
														className="img-fluid"
														alt=""
													/>
												</span>{" "}
												{error.desc}
											</div>
										)}
										{/* end desktop */}
									</div>
								</div>
							</div>
						</div>
						<div className="modal-body p-lg-0">
							<div className="row gx-0">
								{/* <!-- Start Left nav-link --> */}
								<div className="col-lg-3 col-xl-2 bg_primary mb-4 mb-lg-0">
									<div
										className="nav flex-column nav-pills"
										aria-orientation="vertical">
										<button
											onClick={() => setNavLink("name")}
											className={`nav-link ${navLink === "name" ? "active" : ""
												}`}
											id="v-pills-home-tab"
											type="button"
										>
											Names
											{nameInputError?.err && <span className="ms-3">
												<img
													src="assets/img/error_icon.svg"
													className="img-fluid"
													alt=""
												/>
											</span>}
										</button>
										<button
											onClick={() => setNavLink("company")}
											className={`nav-link ${navLink === "company" ? "active" : ""
												}`}
											id="v-pills-profile-tab"
											type="button"
										>
											Company info
											{companyInputError?.err && <span className="ms-3">
												<img
													src="assets/img/error_icon.svg"
													className="img-fluid"
													alt=""
												/>
											</span>}
										</button>
										<button
											onClick={() => setNavLink("contact")}
											className={`nav-link ${navLink === "contact" ? "active" : ""
												}`}
											id="v-pills-messages-tab"
											type="button"
										>
											Contact info
											{contactInputError?.err && <span className="ms-3">
												<img
													src="assets/img/error_icon.svg"
													className="img-fluid"
													alt=""
												/>
											</span>}
										</button>
										<button
											onClick={() => setNavLink("account")}
											className={`nav-link ${navLink === "account" ? "active" : ""
												}`}
											id="v-pills-settings-tab"
											type="button"
										>
											Account info
											{accountInputError?.err && <span className="ms-3">
												<img
													src="assets/img/error_icon.svg"
													className="img-fluid"
													alt=""
												/>
											</span>}
										</button>
									</div>
								</div>

								{/* <!-- Start right Tab-content --> */}
								<div className="col-lg-9 col-xl-10">
									<div className="tab-content">
										{/* <!-- Start Name Tab-pane--> */}
										{navLink === "name" && (
											<Name
												navLink={navLink}
												setNavLink={setNavLink}
												error={nameInputError}
												setError={setNameInputError}
												nameInput={nameInput}
												setNameInput={setNameInput}
												mode={mode}
												resetData={resetData}
											// editAccount={editAccount}

											/>
										)}
										{/* <!-- End Name Tab-pane--> */}

										{/* <!-- Start Comany Info Tab-pane--> */}
										{navLink === "company" && (
											<Company
												navLink={navLink}
												setNavLink={setNavLink}
												error={companyInputError}
												setError={setCompanyInputError}
												companyInput={companyInput}
												setCompanyInput={setCompanyInput}
												mode={mode}
												resetData={resetData}
											// editAccount={editAccount}
											/>
										)}
										{/* <!-- End Comany Info Tab-pane--> */}

										{/* <!-- End Contact Info Tab-pane--> */}
										{navLink === "contact" && (
											<Contact
												navLink={navLink}
												setNavLink={setNavLink}
												error={contactInputError}
												setError={setContactInputError}
												contactInput={contactInput}
												setContactInput={setContactInput}
												mode={mode}
												resetData={resetData}
											// editAccount={editAccount}
											/>
										)}
										{/* <!-- End Contact Info Tab-pane--> */}

										{/* <!-- End Account Info Tab-pane--> */}
										{navLink === "account" && (
											<Account
												navLink={navLink}
												setNavLink={setNavLink}
												error={accountInputError}
												setError={setAccountInputError}
												accountInput={accountInput}
												setAccountInput={setAccountInput}
												callbackfunction={mode === 'edit' ? editAccount : addAccount}
												mode={mode}
												resetData={resetData}
											// editAccount={editAccount}
											/>
										)}
										{/* <!-- End Account Info Tab-pane--> */}
									</div>
								</div>
								{/* <!-- End right Tab-content --> */}

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AccountAdd