/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { stringPipe } from '../../../../../pipes/stringPipes'
import { getService, putService } from '../../../../../services/commonApi'
import { url as MainUrl } from '../../../../../environment/environment'
import { getAuthToken } from '../../../../../services/refreshBearerToken';
import { errorHandler } from "../../../../../services/ErrorHandler";

var axios = require('axios');

const RescanRequestDetails = ({ refresh, getRescanReqList, url, data, type }) => 
{
	const [reqDetails, setReqDetails] = useState({})

	useEffect(() => 
	{
		if (data && type) 
		{
			getRequestDetails()
		}
	}, [data, type])

	const getRequestDetails = () => 
	{
		getService(`${url}/${data}`, true).then( (res) => 
		{
			setReqDetails(res?.data)
		})
	}

	const fullfillrequest = () => 
	{
		putService(`${url}/${data}`, '', true, { params: { 'response': 'inprogress' } }).then( (res) => 
		{
			if (res.status === 200) 
			{
				refresh()
				document.getElementById('dismissreqModal').click()
			}
		})
	}

	const printRequest = async () => 
	{
		getAuthToken().then( token =>
		{
			var config = 
			{
				method: 'get',
				url: `${MainUrl}${url}/${data}/pdf`,
				headers: 
				{
					'Authorization': 'Bearer ' + token,
				},
				responseType: 'arraybuffer'
			}
			axios(config).then(res => 
			{
				var file = new Blob([res.data], { type: 'application/pdf' });
				var fileUrl = URL.createObjectURL(file);
				var fileName = "download.pdf";
				var a = document.createElement("a");
				document.body.appendChild(a);
				a.href = fileUrl;
				a.download = fileName;
				a.click();
				window.open(fileUrl);
			})
		})
		.catch( err => 
		{
			errorHandler( err );
		})
	}

    return (
        <div className="modal fade rescanrequest_details" id="rescanrequest_details" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body py-4">
                        <div className="row align-items-center">
                            <div className="col-lg-11 m-auto">
                                <h2 className="heading_1 mb-4">Request details</h2>
                                <p>{type === 'pending' && <>The following user requested that this piece of mail be rescanned. 
											Please print this request's cover sheet and rescan the mail using it.</>}
                                </p>
                                <div className="my-4 information">
                                    <p className="mb-1"><b>Requesting Account</b></p>
                                    <p className="mb-1">{reqDetails?.employeeName}</p>
                                    <p className="mb-1">{reqDetails?.employee?.companyDetails?.address?.address1}</p>
                                    <p className="mb-1">{reqDetails?.employee?.contactDetails?.emailAddress1}</p>
                                    {reqDetails?.employee?.companyDetails?.department && <p>{reqDetails?.employee?.companyDetails?.department}</p>}
                                </div>

                                <div className="my-4 information">
									<p className="mb-1"><b>Rescan Mail Details</b></p>
									<p className="mb-1">Digitized: <b>{new Date(reqDetails?.batchDate).toLocaleDateString()}</b></p>
									<p className="mb-1">Date Requested: <b>{new Date(reqDetails?.requestDate).toLocaleDateString()}</b></p>
									{ reqDetails?.startDate && <p className="mb-1">Date Started: <b>{new Date(reqDetails?.startDate).toLocaleDateString()}</b></p> }
									{ reqDetails?.fulfilledDate && <p className="mb-1">Date Fulfilled: <b>{ new Date(reqDetails?.fulfilledDate).toLocaleDateString()}</b></p> }
									<p className="mb-1"><b>{`This piece of mail is ${ !reqDetails?.available ? 'not available' : 'available' } to be rescanned`}</b></p>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center justify-content-between mt-4">
                                <div className="ms-lg-4">
                                    <a onClick={() => printRequest()} className="text_dark">Print</a>
                                </div>
                                {type === "pending" && reqDetails?.available && <div className="p-md-4 text-end">
                                    <button type="button" className="btn btn-link btn_link_primary" onClick={() => getRescanReqList()} id="dismissreqModal" data-bs-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn_cstm_primary px-4" onClick={() => fullfillrequest()}>Start</button>
                                </div>}
                                { (type !== "pending" || !reqDetails?.available) && <div className="p-md-4 text-end">
                                    <button type="button" className="btn btn_cstm_primary px-4" data-bs-dismiss="modal">Close</button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default RescanRequestDetails