/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef  } from 'react'
import { Dropdown } from 'react-bootstrap'
import GridLayout from "react-grid-layout";
import { RouteState } from '../../../../context/routeContext'
import { deleteService, getService, putService } from '../../../../services/commonApi'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { filterSysData } from './chartLogics/SystemActivity'
import { filterMailData } from './chartLogics/MailVolume'
import { filterProdData } from './chartLogics/Productivity'
import { filterQualData } from './chartLogics/QualityCheck'
import { filterOriginalData } from './chartLogics/OriginalRequests'
import { url as MainUrl } from '../../../../environment/environment'
import { getAuthToken } from '../../../../services/refreshBearerToken';
import { errorHandler } from "../../../../services/ErrorHandler";
import { getCompanyIdToken } from '../../../../services/tokenService'

var axios = require('axios');

// const options = {
//     chart: {
//         type: "pie"
//     },
//     series: [{
//         data: [1, 2, 3]
//     }]
// }

const Dashboard = () => {
    const { dashboardRoute, setDashboardRoute, } = RouteState()

    const [systemActivity, setSystemActivity] = useState([])
    const [systemActivityParam, setSystemActivityParam] = useState({
        days: '30', filter: '', file: false, workstationId: ''
    })

    const [mailVolume, setMailVolume] = useState([])
    const [mailVolumeParam, setMailVolumeParam] = useState({
        days: '30', filter: '', file: false
    })

    const [productivity, setProductivity] = useState([])
    const [productivityParam, setProductivityParam] = useState({
        days: '30', filter: '', file: false
    })

    const [qualityCheck, setQualityCheck] = useState([])
    const [qualityCheckParam, setQualityCheckParam] = useState({
        days: '30', file: false
    })

    const [originalRequest, setOriginalRequest] = useState([])
    const [originalRequestParam, setOriginalRequestParam] = useState({
        days: '30', file: false
    })


    const [operatorList, setOperatorList] = useState([])
    const [costCenterList, setCostCenterList] = useState([])
    const [workStationList, setWorkStationList] = useState([])
    const [workstationId, setWorkstationId] = useState('')
    var daysFilter = [
        { "value": "1", "text": "1 Day" },
        { "value": "2", "text": "2 Days" },
        { "value": "3", "text": "3 Days" },
        { "value": "7", "text": "1 Week" },
        { "value": "30", "text": "1 Month" },
        { "value": "60", "text": "2 Months" },
        { "value": "90", "text": "3 Months" },
        { "value": "365", "text": "1 Year" }
    ]

    var productivityReason = [
        { text: "All", value: "", },
        { text: "Automatic Assignment", value: "automatic" },
        { text: "Manual Assignment", value: "manual" },
        { text: "Sent Back", value: "misassignment" },
        { text: "Forwarded", value: "forwarded" },
        { text: "Wrong Company", value: "wrongcompany" }
    ]

	const [layout, setLayout] = useState([
		{ i: "a", x: 0, y: 0, w: 4, h: 7 },
		{ i: "b", x: 4, y: 0, w: 4, h: 7 },
		{ i: "c", x: 8, y: 0, w: 4, h: 7 },
		{ i: "d", x: 0, y: 7, w: 4, h: 7 },
		{ i: "e", x: 4, y: 7, w: 4, h: 7 }
	]);
	// Create an object to store references to HighchartsReact components  Use the layout id.
	const chartRefs = {};
	chartRefs['a'] = useRef(null);
	chartRefs['b'] = useRef(null);
	chartRefs['c'] = useRef(null);
	chartRefs['d'] = useRef(null);
	chartRefs['e'] = useRef(null);

	useEffect(() =>
	{
		if( dashboardRoute === 'dashboard' ) 
		{
			loadLayout();
			loadSystemActivityParam()
			loadMailVolumeParam()
			loadProductivityParam()
			loadQualityCheckParam()
			loadOriginalRequestParam();
		}
	}, [] );

	useEffect(() => {
        if(dashboardRoute === 'dashboard') {
            getWorkStationList();
            systemActivityParam?.days && getSystemActivity(systemActivityParam);
            mailVolumeParam?.days && getMailVolume(mailVolumeParam);
            productivityParam?.days && getProductivity(productivityParam);
            qualityCheckParam?.days && getQualityCheck(qualityCheckParam);
            originalRequestParam?.days && getOriginalRequest(originalRequestParam);
            getCostCenterList();
            getOperatorList();
        }
    }, [dashboardRoute])

	useEffect(() => 
	{
		if( dashboardRoute === 'dashboard' ) 
		{
			getSystemActivity(systemActivityParam)
			storeSystemActivityParam()
		}
	}, [systemActivityParam ])

	useEffect(() => 
	{
		if( dashboardRoute === 'dashboard' )
		{
			getMailVolume(mailVolumeParam)
			storeMailVolumeParam()
		}
	}, [mailVolumeParam])

	useEffect(() => 
	{
		if( dashboardRoute === 'dashboard' )
		{
			getProductivity(productivityParam);
			storeProductivityParam()
		}
	}, [productivityParam])

	useEffect(() => 
	{
		if( dashboardRoute === 'dashboard' )
		{
			getQualityCheck(qualityCheckParam);
			storeQualityCheckParam()
		}
	}, [qualityCheckParam])

	useEffect(() => 
	{
		if( dashboardRoute === 'dashboard' )
		{
			getOriginalRequest(originalRequestParam);
			storeOriginalRequestParam()
		}
	}, [originalRequestParam])

	const storeLayout = () =>
	{
		const param = JSON.stringify( layout );
		localStorage.setItem( "report-dashboard-layout", param )
	}

	const loadLayout = () =>
	{
		let param = localStorage.getItem("report-dashboard-layout" )
		if( param && param !== "undefined" )
		{
			const updatedLayout = JSON.parse(param);
			setLayout(updatedLayout);
		}
	}

	const storeSystemActivityParam = () =>
	{
		localStorage.setItem("system-activity-chart", JSON.stringify( systemActivityParam ));
	}

	const loadSystemActivityParam = () =>
	{
		let param = localStorage.getItem( "system-activity-chart" )
		if( param )
		{
			setSystemActivityParam( JSON.parse( param ) );
		}
	}

	const storeMailVolumeParam = () =>
	{	
		localStorage.setItem("mail-volume-chart", JSON.stringify( mailVolumeParam ));
	}
	const loadMailVolumeParam = () =>
	{
		let param = localStorage.getItem( "mail-volume-chart" )
		if( param )
		{
			setMailVolumeParam( JSON.parse( param ) );
		}
	}
	const storeProductivityParam = () =>
	{	
		localStorage.setItem("productivity-chart", JSON.stringify( productivityParam ));
	}
	const loadProductivityParam = () =>
	{
		let param = localStorage.getItem( "productivity-chart" )
		if( param )
		{
			setProductivityParam( JSON.parse( param ) );
		}
	}
	const storeQualityCheckParam = () =>
	{
		localStorage.setItem("quality-check-chart", JSON.stringify( qualityCheckParam ));
	}
	const loadQualityCheckParam = () =>
	{
		let param = localStorage.getItem( "quality-check-chart" )
		if( param )
		{
			setQualityCheckParam( JSON.parse( param ) );
		}
	}
	const storeOriginalRequestParam = () =>
	{
		localStorage.setItem("original-request-chart", JSON.stringify( originalRequestParam ));

	}
	const loadOriginalRequestParam = () =>
	{
		let param = localStorage.getItem( "original-request-chart" )
		if( param )
		{
			setOriginalRequestParam( JSON.parse( param ) );
		}
	}

	const downloadSystemActivity = () =>
	{
		let url = MainUrl+"/mercury/reports/system/activity"
		if( systemActivityParam?.workstationId !== '' )
			url += "/"+systemActivityParam.workstationId
		url += '?days='+systemActivityParam?.days+'&filter='+systemActivityParam?.filter+'&file=true'
		downloadFile( url )
	}

	const getSystemActivity = (params) => 
	{
		let url = "/mercury/reports/system/activity"
		if( params?.workstationId )
			url += "/"+params.workstationId
		getService(url, true, { params }).then(( res => 
		{
			setSystemActivity( filterSysData(res.data) )
		}))
	}

	const getMailVolume = (params) => 
	{
		getService(`/mercury/reports/system/mailvolume`, true, { params }).then((res => 
		{
			setMailVolume(filterMailData( res.data ))
		}))
	}

	const getProductivity = (params) => 
	{
		getService(`/mercury/reports/system/productivity`, true, { params }).then(( res => 
		{
			setProductivity( filterProdData( res.data ) )
		}))
	}

    const getQualityCheck = (params) => {
        getService(`/mercury/reports/system/qualitycontrol`, true, { params }).then((
            res => {
                if (res.status === 200) {
                    setQualityCheck(res.data)
                }
            }
        ))
    }

	const getOriginalRequest = (params) => 
	{
		getService(`/mercury/reports/system/originalrequest`, true, { params }).then((res => 
		{
			setOriginalRequest( res.data )
		}))
	}

    const getOperatorList = () => {
        getService('/mercury/system/operators', true).then(
            (res) => {
                if (res.status === 200) {
                    setOperatorList(res.data?.operators)
                }
            })
    }

    const getCostCenterList = () => {
        getService(`/mercury/company/${getCompanyIdToken()}/costcenter/list`, true).then(
            (res) => {
                if (res.status === 200) {
                    setCostCenterList(res.data?.list)
                }
            }
        )
    }

	const getWorkStationList = () => 
	{
		getService(`/mercury/system/workstations`, true).then( (res) => 
		{
			if (res.status === 200) {
				setWorkStationList(res?.data?.workstations)
			}
		})
	}

    const downloadFile = async (url) => 
	{
		getAuthToken().then( token =>
		{	
			var config = {
				method: 'get',
				url: url,
				headers: { 'Authorization': 'Bearer ' + token, },
				responseType: 'arraybuffer'
			}
			axios(config).then(res => 
			{
				var file = new Blob([res.data], { type: 'text/csv' });
				var fileUrl = URL.createObjectURL(file);
				var fileName = "downloads.csv";
				var a = document.createElement("a");
				document.body.appendChild(a);
				a.href = fileUrl;
				a.download = fileName;
				a.click();
				//open it directly 
				window.open(fileUrl);
			})
		})		
		.catch( err => 
		{
			errorHandler( err );
		})
    }

	// Layout is an array of object with the format:
	// {x: number, y: number, w: number, h: number}
	const itemResize = ( layout, oldItem, newItem, placeholder, e, element ) =>
	{
		const updatedLayout = [...layout];
		const updatedItem = { ...newItem };
		updatedLayout[layout.findIndex((item) => item.i === newItem.i)] = updatedItem;
		setLayout(updatedLayout);
		storeLayout()
		// Access the Highcharts chart instance and call reflow
		if (chartRefs[newItem.i]) 
		{
			chartRefs[newItem.i].current.chart.reflow();
		}
	}

    return (
		<>
			<div className="card-header d-md-none" role="tab" id="heading-A" onClick={() => { setDashboardRoute('dashboard') }}>
				<h5 className="mb-0">
					<a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
						Dashboard  <span> <i className="bi bi-chevron-down"></i></span>
					</a>
				</h5>
			</div>
			<div id="collapse-A" className={`bgYellow collapse ${dashboardRoute === "dashboard" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
				aria-labelledby="heading-A">
				<div className="dashboard_page p-lg-5">
					<GridLayout className="layout" layout={layout} cols={15} rowHeight={50} width={1600} onResize={itemResize} >

						<div key="a">
							<div className="card d-flex align-items-center">
								<div className="card_head  d-flex align-items-center">
									<h5 className="card-title">System Activity</h5>
									<span className='d-flex align-items-center'>
										{/* <a href="#"> <img src="assets/img/filter.svg" alt="" /> </a> */}
										<Dropdown className='filter_down' autoClose="outside">
											<Dropdown.Toggle as={'a'} align="end"
												variant="none" className="cbtn" id="dropdown-basic">
												<img src="assets/img/filter.svg" alt="" />
											</Dropdown.Toggle>

											<Dropdown.Menu as={'ul'} style={{ 'backgroundColor': '#fff', 'width': 'max-content' }}>
												<li><Dropdown.Item ><span>Days
													<select className='float-end' style={{ 'width': '5rem' }} id=""
														onChange={(e) => setSystemActivityParam({
															days: e.target.value,
															filter: systemActivityParam.filter,
															file: systemActivityParam.file,
															workstationId: systemActivityParam.workstationId
														})}
														value={systemActivityParam.days}
													>
														{daysFilter?.map((data, i) => (
															<option key={i} value={data?.value}>{data?.text}</option>
														))
														}
													</select>
												</span></Dropdown.Item></li>
												<li><Dropdown.Item ><span >Operator
													<select className='float-end' style={{ 'width': '5rem' }} id=""
														onChange={(e) => setSystemActivityParam({
															days: systemActivityParam.days,
															filter: e.target.value,
															file: systemActivityParam.file,
															workstationId: systemActivityParam.workstationId
														})}
														value={systemActivityParam.filter}
													>
														<option value={''}>All Operators</option>
														{operatorList?.map((data, i) => (
															<option key={i} value={data}>{data}</option>
														))}
													</select>
												</span></Dropdown.Item></li>

												<li><Dropdown.Item ><span >Workstation
													<select className='float-end' style={{ 'width': '5rem' }} id=""
														onChange={(e) => setSystemActivityParam({
															days: systemActivityParam.days,
															filter: systemActivityParam.filter,
															file: systemActivityParam.file,
															workstationId: e.target.value
														})}
														value={systemActivityParam.workstationId}
													>
														<option value=''>All Workstation</option>
														{workStationList?.map((data, i) => (
															<option key={i} value={data?.workstationId}>{data?.workstationName}</option>
														))}
													</select>
												</span></Dropdown.Item></li>
											</Dropdown.Menu>
										</Dropdown>

										<a
											// onClick={() =>
											// setSystemActivityParam({
											//     days: systemActivityParam?.days,
											//     filter: systemActivityParam?.filter,
											//     file: true
											// })}

											onClick={downloadSystemActivity} download >
											<img src="assets/img/download.svg" alt="" /> </a>
									</span>
								</div>
								<div className="card-body" style={{
												width: `${layout.find((item) => item.i === 'a').w * 100}px`, // Adjust the width as needed
												height: `${layout.find((item) => item.i === 'a').h * 10}px`, // Adjust the height as needed
										}}>
									<HighchartsReact className="d-flex align-items-center" highcharts={Highcharts} options={ systemActivity } 
											allowChartUpdate={true} shrinkHeight={true}
											containerProps={{ style: {
												width: '100%',
												height: '100%',
												display: 'flex',
												flexDirection: 'column',							  
											}}}
											ref={chartRefs['a']} // Store a ref to HighchartsReact components
									/>
								</div>
							</div>
						</div>

						<div key="b">
							<div className="card  d-flex align-items-center">
								<div className="card_head  d-flex align-items-center">
									<h5 className="card-title">Mail Volume
										{/* <span className="sm_text">in thousands</span> */}
									</h5>
									<span className='d-flex align-items-center'>
										{/* <a href="#"> <img src="assets/img/filter.svg" alt="" /> </a> */}
										<Dropdown className='filter_down' autoClose="outside">
											<Dropdown.Toggle as={'a'} align="end"
												variant="none" className="cbtn" id="dropdown-basic">
												<img src="assets/img/filter.svg" alt="" />
											</Dropdown.Toggle>

											<Dropdown.Menu as={'ul'} style={{ 'backgroundColor': '#fff', 'width': '195px' }}>
												<li><Dropdown.Item ><span>Days
													<select className='float-end' style={{ 'width': '5rem' }} id=""
														onChange={(e) => setMailVolumeParam({
															days: e.target.value,
															filter: mailVolumeParam?.filter,
															file: mailVolumeParam?.file
														})}
														value={mailVolumeParam.days}>
														{daysFilter?.map((data, i) => (
															<option key={i} value={data?.value}>{data?.text}</option>
														))
														}
													</select>
												</span></Dropdown.Item></li>
												<li><Dropdown.Item ><span >Cost center
													<select className='float-end' style={{ 'width': '5rem' }} id=""
														onChange={(e) => setMailVolumeParam({
															days: mailVolumeParam?.days,
															filter: e.target.value,
															file: mailVolumeParam?.file
														})}
													>
														<option value={''}>All Costcenter</option>
														{costCenterList?.map((data, i) => (
															<option key={i} value={data?.costCenterId}>{data?.name}</option>
														))}
													</select>
												</span></Dropdown.Item></li>
											</Dropdown.Menu>
										</Dropdown>
										<a
											// onClick={() =>
											// setMailVolumeParam({
											//     days: mailVolumeParam?.days,
											//     filter: mailVolumeParam?.filter,
											//     file: true
											// })}

											onClick={() => downloadFile(`${MainUrl}/mercury/reports/system/mailvolume?days=${mailVolumeParam?.days}&filter=${mailVolumeParam?.filter}&file=true`)} download >
											<img src="assets/img/download.svg" alt="" /> </a>
									</span>
								</div>
								<div className="card-body" style={{
												width: `${layout.find((item) => item.i === 'b').w * 100}px`, // Adjust the width as needed
												height: `${layout.find((item) => item.i === 'b').h * 10}px`, // Adjust the height as needed
										}}>
									<HighchartsReact className="d-flex align-items-center" highcharts={Highcharts} options={mailVolume}
											allowChartUpdate={true} shrinkHeight={true}
											containerProps={{ style: {
												width: '100%',
												height: '100%',
												display: 'flex',
												flexDirection: 'column',							  
											}}}
											ref={chartRefs['b']} // Store a ref to HighchartsReact components
									/> 
								</div>
							</div>
						</div>

						<div key="c">
							<div className="card  d-flex align-items-center">
								<div className="card_head  d-flex align-items-center">
									<h5 className="card-title">Productivity</h5>
									<span className='d-flex align-items-center'>
										{/* <a href="#"> <img src="assets/img/filter.svg" alt="" /> </a> */}
										<Dropdown className='filter_down' autoClose="outside">
											<Dropdown.Toggle as={'a'}
												variant="none" className="cbtn" id="dropdown-basic">
												<img src="assets/img/filter.svg" alt="" />
											</Dropdown.Toggle>

											<Dropdown.Menu as={'ul'} style={{ 'backgroundColor': '#fff', 'width': '175px' }}>
												<li><Dropdown.Item ><span>Days
													<select className='float-end' style={{ 'width': '5rem' }} id=""
														onChange={(e) => setProductivityParam({
															days: e.target.value,
															filter: productivityParam?.filter,
															file: productivityParam?.file
														})}
														value={productivityParam.days}
													>
														{daysFilter?.map((data, i) => (
															<option key={i} value={data?.value}>{data?.text}</option>
														))
														}
													</select>
												</span></Dropdown.Item></li>
												<li><Dropdown.Item ><span >Reason
													<select className='float-end' style={{ 'width': '5rem' }} id=""
														onChange={(e) => setProductivityParam({
															days: productivityParam?.days,
															filter: e.target.value,
															file: productivityParam?.file
														})}
													>
														{productivityReason?.map((data, i) => (
															<option key={i} value={data?.value}>{data?.text}</option>
														))}
													</select>
												</span></Dropdown.Item></li>
											</Dropdown.Menu>
										</Dropdown>
										<a
											// onClick={() =>
											// setProductivityParam({
											//     days: productivityParam?.days,
											//     filter: productivityParam?.filter,
											//     file: true
											// })}
											onClick={() => downloadFile(`${MainUrl}/mercury/reports/system/productivity?days=${productivityParam?.days}&filter=${productivityParam?.filter}&file=true`)} download
										> <img src="assets/img/download.svg" alt="" /> </a>
									</span>
								</div>
								<div className="card-body" style={{
												width: `${layout.find((item) => item.i === 'c').w * 100}px`, // Adjust the width as needed
												height: `${layout.find((item) => item.i === 'c').h * 10}px`, // Adjust the height as needed
										}}>
									<HighchartsReact className="d-flex align-items-center"  highcharts={Highcharts} options={productivity}
											allowChartUpdate={true} shrinkHeight={true}
											containerProps={{ style: {
												width: '100%',
												height: '100%',
												display: 'flex',
												flexDirection: 'column',							  
											}}}
											ref={chartRefs['c']} // Store a ref to HighchartsReact components
									/> 
								</div>
							</div>
						</div>

						<div key="d">
							<div className="card  d-flex align-items-center">
								<div className="card_head  d-flex align-items-center">
									<h5 className="card-title">Quality Check</h5>
									<span className='d-flex align-items-center'>
										{/* <a href="#"> <img src="assets/img/filter.svg" alt="" /> </a> */}
										<Dropdown className='filter_down' autoClose="outside">
											<Dropdown.Toggle as={'a'} align="end"
												variant="none" className="cbtn" id="dropdown-basic">
												<img src="assets/img/filter.svg" alt="" />
											</Dropdown.Toggle>

											<Dropdown.Menu as={'ul'} style={{ 'backgroundColor': '#fff', 'width': '175px' }}>
												<li><Dropdown.Item ><span>Days
													<select className='float-end' style={{ 'width': '5rem' }} id=""
														onChange={(e) => setQualityCheckParam({
															days: e.target.value,
															file: qualityCheckParam?.file
														})}
														value={qualityCheckParam.days}
													>
														{daysFilter?.map((data, i) => (
															<option key={i} value={data?.value}>{data?.text}</option>
														))
														}
													</select>
												</span></Dropdown.Item></li>
											</Dropdown.Menu>
										</Dropdown>
										<a
											// onClick={() =>
											// setQualityCheckParam({
											//     days: qualityCheckParam?.days,
											//     file: true
											// })}
											onClick={() => downloadFile(`${MainUrl}/mercury/reports/system/qualitycontrol?days=${qualityCheckParam?.days}&file=true`)} download
										> <img src="assets/img/download.svg" alt="" /> </a>
									</span>
								</div>
								<div className="card-body" style={{
												width: `${layout.find((item) => item.i === 'd').w * 100}px`, // Adjust the width as needed
												height: `${layout.find((item) => item.i === 'd').h * 10}px`, // Adjust the height as needed
										}}>
									<HighchartsReact className="d-flex align-items-center"  highcharts={Highcharts} options={filterQualData(qualityCheck)}
											allowChartUpdate={true} shrinkHeight={true}
											containerProps={{ style: {
												width: '100%',
												height: '100%',
												display: 'flex',
												flexDirection: 'column',							  
											}}}
											ref={chartRefs['d']} // Store a ref to HighchartsReact components
									/> 
								</div>
							</div>
						</div>

						<div key="e">
							<div className="card  d-flex align-items-center">
								<div className="card_head  d-flex align-items-center">
									<h5 className="card-title">Original Request</h5>
									<span className='d-flex align-items-center'>
										{/* <a href="#"> <img src="assets/img/filter.svg" alt="" /> </a> */}
										<Dropdown className='filter_down' autoClose="outside">
											<Dropdown.Toggle as={'a'} align="end"
												variant="none" className="cbtn" id="dropdown-basic">
												<img src="assets/img/filter.svg" alt="" />
											</Dropdown.Toggle>

											<Dropdown.Menu as={'ul'} style={{ 'backgroundColor': '#fff', 'width': '175px' }}>
												<li><Dropdown.Item ><span>Days
													<select className='float-end' style={{ 'width': '5rem' }} id=""
														onChange={(e) => setOriginalRequestParam({
															days: e.target.value,
															file: originalRequestParam?.file
														})}
														value={originalRequestParam.days}
													>
														{daysFilter?.map((data, i) => (
															<option key={i} value={data?.value}>{data?.text}</option>
														))
														}
													</select>
												</span></Dropdown.Item></li>

											</Dropdown.Menu>
										</Dropdown>
										<a
											// onClick={() =>
											// setOriginalRequestParam({
											//     days: originalRequestParam?.days,
											//     file: true
											// })}
											onClick={() => downloadFile(`${MainUrl}/mercury/reports/system/originalrequest?days=${originalRequestParam?.days}&file=true`)} download
										> <img src="assets/img/download.svg" alt="" /> </a>
									</span>
								</div>
								<div className="card-body" style={{
												width: `${layout.find((item) => item.i === 'e').w * 100}px`, // Adjust the width as needed
												height: `${layout.find((item) => item.i === 'e').h * 10}px`, // Adjust the height as needed
										}}>
									<HighchartsReact className="d-flex align-items-center"  highcharts={Highcharts} options={filterOriginalData(originalRequest)} 
											allowChartUpdate={true} shrinkHeight={true}
											containerProps={{ style: {
												width: '100%',
												height: '100%',
												display: 'flex',
												flexDirection: 'column',							  
											}}}
											ref={chartRefs['e']} // Store a ref to HighchartsReact components
									/> 
								</div>
							</div>
						</div>

                    </GridLayout>

                </div>
            </div>
        </>
    )
}

export default Dashboard