
export function rolesToEntitlements( roles )
{
	let retval = [];
	roles.forEach( (role) =>
	{
		switch( role )
		{
			case 'SuperUser' :
				retval.push( 'basic','assign','route','quality','account','reassign','request','settings','report' )
				break;
			case 'Administrator' : 
				retval.push( 'basic','account','reassign','request','settings','report' )
				break;
			case 'Manager' :
				retval.push( 'basic','assign','route','quality' )
				break;
			case 'User' :
				retval.push( 'basic' )
				break;
			default:
				break;
		}
	});
	return retval;
}

export function getLocalAuthToken() {
  let token = localStorage.getItem("authToken");
  return token;
}

export function setAuthToken(auth) {
	localStorage.setItem("authToken", auth);
	return;
  }
  
export function removeAuthToken() {
  localStorage.removeItem("authToken");
  return;
}

export function getCompanyIdToken() {
  let token = localStorage.getItem("CompanyIdToken");
  return token;
}

export function setCompanyIdToken(CompanyId) {
  localStorage.setItem("CompanyIdToken", CompanyId);
  return;
}

export function removeCompanyIdToken() {
  localStorage.removeItem("CompanyIdToken");
  return;
}

export function getRefreshToken() {
  let token = localStorage.getItem("refreshToken");
  return token;
}

export function setRefreshToken(token) {
  // console.log(token);
  localStorage.setItem("refreshToken", token);
  return;
}

export function removeRefreshToken() {
  localStorage.removeItem("refreshToken");
  return;
}

// Set the expiration time stamp as a string.
// convert the expiration time stamp to a difference in minutes
// between now and the expiration time.
// store the number of minutes before the current expiration time.
export function setExpTimestamp( exp_str ) 
{
	const exp = new Date( exp_str );
	const now = new Date();
	const diff_millis = (exp - now);
	// convert the millis seconds expiration time to minutes.
	const numOfMinutes = ( diff_millis/1000/60 );  
	let date =  new Date();
	const time = date.setMinutes( now.getMinutes() + numOfMinutes );
  	sessionStorage.setItem("expTime", time);
}

export function validateExpTimestamp() {
  const CurrentTime = new Date().getTime();
  const ExpTime = sessionStorage.getItem("expTime");

  const diff = ExpTime - CurrentTime;
  // console.log(diff);
  if (diff < 60000) {
    return false;
  } else {
    return true;
  }
}

export function setLoginTimestamp( )
{
	const now = new Date();
	sessionStorage.setItem("loginTime", now.toISOString() );
}

export function getLoginTimestamp()
{
	let loginTime = sessionStorage.getItem("loginTime");
	return new Date(loginTime);
}

/**
 * Returns true if the date passed in occurred after the login timestamp.
 * Returns false if the date passed in occurred on or before the login timestamp.
 * 
 * @param {date} when 
 */
export function isAfterLogin( when )
{
	let loginTime = sessionStorage.getItem("loginTime");
	const diff = when - loginTime;
	return diff > 0;
}

// function addMinutes(numOfMinutes, date = new Date()) {
//   date.setMinutes(date.getMinutes() + numOfMinutes);

//   return date;
// }

// // 👇️ Add 10 minutes to current Date
// const result = addMinutes(10);

// // 👇️ Add 20 minutes to other Date
// const date = new Date('2022-03-14T09:25:30.820');

// // 👇️ Mon Mar 14 2022 09:45:30
// console.log(addMinutes(20, date));
