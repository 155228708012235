/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { RouteState } from '../../../../context/routeContext'

import AddRule from './modals/AddRule'

import AddCondition from './modals/AddCondition'
import { getService } from '../../../../services/commonApi'
import { useRef } from 'react'
import AddAction from './modals/AddAction'
import DeleteRule from './modals/DeleteRule'

const AssignmentRules = () => {
    const { deliveryRoute, setDeliveryRoute } = RouteState()

    const [name, setName] = useState('')

    const [assignRulesList, setAssignRulesList] = useState([])
    const [ruleID, setRuleID] = useState('')
    const [operationType, setOperationType] = useState('add')
    const [sort, setSort] = useState('up')
    const [pageCount, setPageCount] = useState({
        start: 1, stop: 30
    })

    const [filter, setFilter] = useState('')

    const [ruleInput, setRuleInput] = useState({})

    const [conditionInput, setConditionInput] = useState({})
    const [actionInput, setActionInput] = useState({})

    var url = useRef()
    var PrimaryUrl = '/mercury/mail/assignmentrules'

    useEffect(() => {
        if (deliveryRoute === 'assignment') {
            getAssignedRulesTab()
        }
    }, [deliveryRoute])

    useEffect(() => {
        const search = setTimeout(() => {
            if (deliveryRoute === 'assignment') {
                getAssignedRulesTab()
            }
        }, 1500);
        return () => clearTimeout(search)
    }, [filter, sort])


    const getAssignedRulesTab = () => {
        getService(PrimaryUrl, true, {
            params: {
                sort, ...pageCount, filter
            }
        }).then(
            (res) => {
                if (res.status === 200) {
                    setAssignRulesList(res?.data?.rules)
                }
            }
        )
    }

    // const getReason = (value) => {
    //     var reason
    //     switch (value) {
    //         case 'N':
    //             reason = 'Insufficient match information (nomatch)'
    //             break;
    //         case 'D':
    //             reason = 'Ambiguous choices available (duplicate)'
    //             break;
    //         case 'Z':
    //             reason = 'No company addresses found'
    //             break;
    //         case 'I':
    //             reason = 'Incorrect Assignment. Sent back for reassignment'
    //             break;
    //         case 'E':
    //             reason = 'Match found but poor choice'
    //             break;
    //         default:
    //             break;
    //     }
    //     return reason
    // }



    return (
        <>
            <div className="card-header d-md-none" role="tab" id="heading-F" onClick={() => { setDeliveryRoute('assignment') }}>
                <h5 className="mb-0">
                    <a className="heading_1 text_dark" data-bs-toggle="collapse" href="#collapse-F" aria-expanded="true" aria-controls="collapse-F">
                        Assignment Rules  <span> <i className="bi bi-chevron-down"></i></span>
                    </a>
                </h5>
            </div>
            <div id="collapse-F" className={`bgYellow collapse ${deliveryRoute === "assignment" ? 'show' : ''}`} data-bs-parent="#content" role="tabpanel"
                aria-labelledby="heading-F">
                <div className="head d-none d-lg-block">
                    <h4 className="heading_1 text_dark mb-0">Assignment Rules</h4>
                </div>
                <div className="p-3">
                    <p>
                        Please enter all of your company’s Rules. Rules can be used to assign mail to employees when an employee has not been identified in mail, or to change which employee mail is assigned to.
                    </p>
                </div>
                <div className="p-3 pt-0 col-lg-12">
                    {/* <!-- Start search--> */}
                    <div className="row mb-3">
                        <div className="col-md-6 col-xl-4">
                            <div className="serach_box">
                                <input className="form-control" type="text" aria-label="Search" placeholder="Search Rules" onChange={(e) => setFilter(e.target.value)} value={filter} />
                                <a href="#">
                                    <span className="search_cion"><img src="assets/img/search_primary.svg" alt="" /></span>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-2 offset-xl-6 align-self-end sort">
                            <select className="form-select form-select-sm w_lg_75 ms-auto select_primary" onChange={(e) => setSort(e.target.value)} >
                                <option value="up">Up</option>
                                <option value="down">Down</option>
                            </select>
                        </div>
                    </div>
                    {/* <!-- Start search --> */}

                    {/* <!-- Start Table --> */}
                    <div className="table-responsive cst_table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Rule name</th>
                                    <th>Description</th>
                                    <th>Type</th>
                                    <th>Position</th>
                                    <th>Date created</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {assignRulesList?.map((data, i) => (
                                    <tr key={i}>
                                        <td>{data?.name}</td>
                                        <td>{data?.description}</td>
                                        <td>{data?.type}</td>
                                        <td>{data?.position}</td>
                                        <td>{new Date(data?.created).toLocaleDateString()}</td>
                                        <td className="acction_icon">
                                            <a href="" onClick={() => { setRuleID(data?.id); setOperationType('edit') }} data-bs-toggle="modal" data-bs-target="#add_rule">
                                                <img src="assets/img/Edit.svg" alt="" />
                                            </a>
                                            <a href="" data-bs-toggle="modal" data-bs-target="#delete_rule_modal" onClick={() => {
                                                setName(data?.name); setRuleID(data?.id)
                                            }}>
                                                <img src="assets/img/Delete.svg" alt="" />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* <!-- End Table --> */}
                    {/* <!-- Add an Account --> */}
                    <div className="add_account mt-4">
                        <a href="#" onClick={() => setOperationType('add')} data-bs-toggle="modal" data-bs-target="#add_rule"> <img src="assets/img/add_account.svg" alt="" /><span>Add a Rule</span></a>
                    </div>
                    {/* <!-- Add an Account --> */}

                    {/* <div>

                    </div> */}
                </div>

            </div>
            <AddRule operationType={operationType} url={PrimaryUrl} ruleID={ruleID} setRuleID={setRuleID} getList={getAssignedRulesTab} />
            <DeleteRule url={PrimaryUrl} name={name} getList={getAssignedRulesTab} id={ruleID} setId={setRuleID} />
        </>
    )
}

export default AssignmentRules