/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { url as MainUrl } from '../../../../environment/environment';
import { BarLoader } from 'react-spinners';
import { errorHandler } from "../../../../services/ErrorHandler";
import { getAuthToken } from '../../../../services/refreshBearerToken';
import './imagegallery.css'

var axios = require('axios');

const ImageGallery = ({ imageList, mailUrl, region, showWrong, callBackFunc, setPropCallback }) => {

	var observer = null;
	var loadCount = 0;

	const [scale, setScale] = useState([]);
	const [rotation, setRotation] = useState([]);

	// Image list contains an array of elements where each contains one element from the mailParts element
	// from the Get Mail Details call.  Additionally, when loaded, the src property is added that contains the 
	// in memory image source data.
    var [imagelist, setImagelist] = useState(imageList)
	var [totalPages,setTotalPages] = useState(0)
    const [page, setPage] = useState(0)
    const [loadImages, setLoadImages] = useState(false)
	const [isLoaded,setIsLoaded] = useState(false)
	const [loader, setLoader] = useState(false)
    const [hashPage, setHashPage] = useState(0)
	const slideGallery = useRef(null);
	const containerRef = useRef(null);
	const elementsRef = useRef([]);
	const [topEntry, setTopEntry] = useState();
	const [highlightPage,setHighlightPage] = useState()
	const [isHovering,setIsHovering] = useState();
	const [rectangleStyle,setRectangleStyle] = useState(null)
	const [tooltipStyle,setTooltipStyle] = useState(null)
	const [tooltip,setTooltip] = useState([])
	const [currentScale,setCurrentScale] = useState(1)
	const [currentRotation,setCurrentRotation] = useState(0)

	const onImageLoaded = (index) =>
	{
		loadCount++;
		if( loadCount === imagelist?.length )
		{
			setIsLoaded( true );
		}
	}

	const handleImageControl = (imageid, rotate, scale) => 
	{
		if( setPropCallback )
		{
			let deg = Math.round((( rotate % 360 ) + 360 ) %360 / 90 ) * 90;
			setPropCallback( imageid, deg, Math.floor(scale*100) )
		}
	}


	const handleScaleChange = (newScale,index) =>
	{
		setScale((prevScale) => (
		{
			...prevScale,
			[index] : newScale,
		}));
		setCurrentScale(newScale);
		handleImageControl( imagelist[index].imageId, rotation[index] , newScale )
	}

	const handleRotationChange = (newRotation,index) =>
	{
		setRotation((prevRotation) => (
		{
			...prevRotation,
			[index] : newRotation,
		}));
		setCurrentRotation(newRotation)
		handleImageControl( imagelist[index].imageId, newRotation , scale[index] )
	}

	useEffect(() =>
	{
		loadCount = 0;
		setPage(0)
		setTotalPages(0)
		setScale([])
		setRotation([])
		setHighlightPage(null)
		setRectangleStyle(null)
		setTooltipStyle(null)
		setTooltip('')
		setIsHovering(false)
	}, [] )

	useEffect(() =>
	{
		if( region  )
		{
			let x = 0;
			let y = 0;
			let width = 0;
			let height = 0;
			let children = slideGallery.current.childNodes;
			const rect = children[ region.page ].getBoundingClientRect();
			const divWidth =rect.width;
			const divHeight = rect.height;

			const imageWidth = imageList[ region.page ].width 
			const imageHeight = imageList[ region.page ].height
			const scaleX = divWidth /imageWidth;
			const scaleY = divHeight / imageHeight;
			const image_scale = Math.min( scaleX, scaleY );

			if( rotation[region.page] % 360 === 270 )
			{
				const imageWidth = imageList[ region.page ].height 
				const imageHeight = imageList[ region.page ].width
				const scaleX = divWidth /imageWidth;
				const scaleY = divHeight / imageHeight;
				const image_scale = Math.min( scaleX, scaleY );
				height = Math.abs( region.topleft.x-region.bottomright.x ) * image_scale;
				width = Math.abs( region.topleft.y - region.bottomright.y ) * image_scale;
				x = (divWidth-(divWidth-region.topleft.y)) * image_scale * scale[ region.page ]
				y = ((divHeight-(region.topleft.x * image_scale)) * scale[ region.page ])-height
			}
			else if( rotation[ region.page ] % 360 === 180 )
			{
				const imageWidth = imageList[ region.page ].width 
				const imageHeight = imageList[ region.page ].height
				const scaleX = divWidth /imageWidth;
				const scaleY = divHeight / imageHeight;
				const image_scale = Math.min( scaleX, scaleY );
				width = Math.abs( region.topleft.x-region.bottomright.x ) * image_scale;
				height = Math.abs( region.topleft.y - region.bottomright.y ) * image_scale;
				x = (divWidth-(region.topleft.x * image_scale * scale[ region.page ]))-width
				y = (divHeight-(region.topleft.y * image_scale * scale[ region.page ]))-height
			}
			else if( rotation[region.page] % 360 === 90 )
			{
				const imageWidth = imageList[ region.page ].height 
				const imageHeight = imageList[ region.page ].width
				const scaleX = divWidth /imageWidth;
				const scaleY = divHeight / imageHeight;
				const image_scale = Math.min( scaleX, scaleY );
				height = Math.abs( region.topleft.x-region.bottomright.x ) * image_scale;
				width = Math.abs( region.topleft.y - region.bottomright.y ) * image_scale;
				x = ((divWidth-(region.topleft.y * image_scale)) * scale[ region.page ]) - width
				y = (divHeight-((imageWidth-region.topleft.x ) * image_scale)) * scale[ region.page ]
			}
			else
			{
				const imageWidth = imageList[ region.page ].width 
				const imageHeight = imageList[ region.page ].height
				const scaleX = divWidth /imageWidth;
				const scaleY = divHeight / imageHeight;
				const image_scale = Math.min( scaleX, scaleY );
				x = (divWidth - ( divWidth - ( region.topleft.x * scaleX ))) * scale[ region.page ]
				y = (divHeight-(divHeight-region.topleft.y)) * image_scale * scale[ region.page ]
				width = Math.abs( region.topleft.x-region.bottomright.x ) * image_scale * scale[ region.page ]
				height = Math.abs( region.topleft.y - region.bottomright.y ) * image_scale * scale[ region.page ]
			}
			setHighlightPage( region.page );
			
			setRectangleStyle({
				position: 'absolute',
				left: `${x}px`,
				top: `${y}px`,
				width: `${width}px`,
				height: `${height}px`,
				border: '2px solid blue',
				zIndex: 1,
			})

			setTooltip( region.text );

			setTooltipStyle({
				left: `${x}px`,
				top: `${y}px`
			});
			scrollToHighlight( region.page, x );
		}
		else
		{
			setHighlightPage(null)
			setRectangleStyle(null)
			setTooltipStyle(null)
			setTooltip('')
		}
	}, [region,currentRotation,currentScale]);

	const handleMouseEnter = () =>
	{
		setRectangleStyle(
			{
				...rectangleStyle,
				position: 'absolute',
				border: '0px solid blue'
			}
		)
		setIsHovering(true);
	}
	const handleMouseLeave = () =>
	{
		setRectangleStyle(
			{
				...rectangleStyle,
				position: 'absolute',
				border: '2px solid blue',
			}
		)
		setIsHovering(false);
	}
	/**
	 * Retrieve the images for a piece of mail.  Retrieve the images using a promise
	 * so that all of them can be retrieved and when they have been, a final scroll
	 * operation can take place as neecessary.
	 */
	useEffect(() => 
	{
		getAuthToken().then( token =>
		{
			let page_count = 0;
			let image_list = [];
			let scale_list = [];
			let rotation_list = [];
			loadCount = 0;
			setIsLoaded( false );
			// increase the array to match the number of images.
			elementsRef.current = new Array(imageList?.length).fill(null);
			setImagelist([]);
			setLoadImages(true)
			setScale([])
			setRotation([])
			setTotalPages( 0 )
			// get the images in a promise that will call our function when all loaded.
			Promise.all
			(
				// Array is the MailParts from the API call.
				imageList.map( async (mail_page, index) => 
				{
					let image_url = `${MainUrl}${mailUrl}/images/${mail_page.imageId}?format=JPEG`;
					var config = 
					{
						method: 'get',
						url: image_url,
						headers: {
							'Accept': 'image/jpeg',
							'Authorization': 'Bearer ' + token,
						},
						responseType: 'arraybuffer'
					}
					await axios(config).then(res => 
					{
						var binary = '';
						page_count++;
						let data = new Uint8Array(res.data);
						var len = data.byteLength;
						for (var i = 0; i < len; i++) 
						{
							binary += String.fromCharCode(data[i]);
						}
						let base64 = window.btoa(binary);
						let src = "data:image/jpeg;base64," + base64;
						image_list[mail_page.position] = { image_url, src, ...mail_page }
						scale_list[mail_page.position] = ( mail_page.scale <= 0 ? 1 : mail_page.scale/100 )
						rotation_list[mail_page.position] = mail_page.rotation
					})
				} )
			).then(( ) => 
			{
				setPage(0)
				setTotalPages( page_count )
				if( page_count === 1 && image_list.length > 1 )
				{
					setScale( [ scale_list[ scale_list.length-1 ] ] );
					setRotation( [ rotation_list[ rotation_list.length-1 ] ] )
					setImagelist( [ image_list[ image_list.length-1 ]] )
				}
				else
				{
					setScale( scale_list );
					setRotation( rotation_list )
					setImagelist( image_list )
				}
				setRectangleStyle(null)
				setTooltipStyle(null)
				setTooltip('')
			})
			.catch( ( err ) =>
			{
				errorHandler( err );
			})		
		})
		.catch( err => 
		{
			errorHandler( err );
		})
	}, [MainUrl,mailUrl,imageList])

	/**
	 * When the elements are first displayed, determine which one is at the top and will
	 * be the element that is manipulated by the controls.
	 */
	 useEffect(() => 
	 {
		/**
		 * As the area is scrolled, different elements come into view 
		 * as they come into view others leave view or start to diminish in the 
		 * amount of area displayed.  Determine the area required and specify which one 
		 * is the top one as it shifts up and down.
		 * @param {array of observer elements} entries 
		 */
		const observerCallback = (entries) => 
		{
			entries.forEach((entry) => 
			{
				const the_div = entry.target;
				// if this entry is insersecting and the previous one isn't
				// then we are at the very top.
				// if the previous one has moved up so less than 1/2 is visible but
				// the top of the element is out of view, then the next one is
				// the top element.
				if (entry.isIntersecting && !topEntry) 
				{
					setTopEntry(entry);

					let index = parseInt( entry.target.getAttribute("index") );
					setPage(index)
				} 
				else if (entry.isIntersecting) 
				{
					const mid_point = the_div.parentElement.offsetHeight / 2;
					const offset = the_div.offsetTop;
					if( the_div.offsetTop < mid_point && topEntry?.target.offsetTop < 0 && topEntry.target.intersectionRatio < 0.5 ) 
					{
						setTopEntry(entry);

						let index = parseInt( entry.target.getAttribute("index") );
						setPage(index)
					}
				}
			});
		};
		setTopEntry(null);
		observer = new IntersectionObserver(observerCallback, 
		{
			root: null,
			rootMargin: "0px",
			threshold: 0.15
		});
		if( imagelist?.length > 0 && isLoaded === true )
		{
			for( let x = 0; x < elementsRef?.current.length && elementsRef?.current[x] != null; x++ )
			{
				observer.observe( elementsRef?.current[x] );
			};
		}
		// if hash set, then it is the mail_page to scroll to.
		scrollToPage( hashPage );
		return () => 
		{
			observer.disconnect();
		};
	 }, [ imagelist, containerRef, isLoaded ]);
	 
	// scroll to a page number.
	const scrollToPage = (el) => 
	{
		let page_top = getPageTop( el );
		slideGallery.current.scrollTo(0, page_top + 16);
	};

	// scroll to a page number.
	const scrollToHighlight = (el,height) => 
	{
		let page_top = getPageTop( el );
		slideGallery.current.scrollTo(0, page_top + 16 + height);
	};

	
	const getPageTop = ( index ) =>
	{
		let page_top = 0;
		let children = slideGallery.current.childNodes;
		for( let x = 0; x < children?.length && x < index; x++ )
		{
			page_top += children[ x ].clientHeight;
		}
		return page_top;
	}

	/*
	 * increase the scale value.  
	*/
	const new_handleZoomIn = () => 
	{
		const index = parseInt( topEntry?.target.getAttribute('index') );
		handleScaleChange( scale[index]+0.2, index );
	};
	
	/*
	 * increase the scale value.  
	*/
	const new_handleZoomOut = () => {
		const index = parseInt( topEntry?.target.getAttribute('index') );
		handleScaleChange( scale[index]-0.2, index );
	};
	/*
	 * rotate the image.  
	*/
	const new_handleRotation = () => {
		const index = parseInt( topEntry?.target.getAttribute('index') );
		handleRotationChange( rotation[index]+90, index );
	};

	
    return (
        <>
            <div className="pe-lg-5" style={{ backgroundColor: '#f9f8f1' }}>
                <div className="row pagination gx-0 mt-1">
                    {loader && <BarLoader width={'100vw'} color={'#0D4A8B'} />}
                    {loadImages && totalPages > 0 && <>
                        <div className="d-flex col-10 col-md-7 col-lg-7 col-xl-4 pg_left">
                            <span className='flex-grow-1'>Page <span className="num">{+page + 1}</span> of <span>{totalPages}</span></span>
                            <span>
                                <a onClick={ () => new_handleZoomIn() }> <img src="/assets/img/zoom-in.png" alt="" /> </a>
                                <a onClick={ () => new_handleZoomOut() }> <img src="/assets/img/zoom-out.png" alt="" /> </a>
                                <a onClick={ () => new_handleRotation() }> <img src="/assets/img/rotate-ccw.png" alt="" /> </a>
                            </span>
                        </div>
						{showWrong && <div className="col-12 col-md-5 col-lg-5 col-xl-3 offset-xl-4 text-md-end">
							<div className="btn_link py-2 py-lg-0">
								<a target="_blank" onClick={() => callBackFunc()} className="fnt_16"> Wrong company </a>
							</div>
						</div>}
						{!showWrong && 
							<div className="col-2 col-md-5 col-lg-5 col-xl-8 pg_right align-self-center">
								<div className="d-flex justify-content-end ">
									{page > 0 && 
										<div className="next_link ps-4">
											<a onClick={() => { scrollToPage(page - 1); setPage(page - 1) }} style={{ cursor: 'pointer' }}> 
												<img src="/assets/img/arrow-right.png" style={{ rotate: '180deg' }} alt="" /> 
												{page + 1 === imagelist?.length && <span className="d-none d-md-block">&nbsp;Previous page</span>} 
											</a>
										</div>
									}
									{page + 1 < imagelist?.length && 
										<div className="next_link ps-4">
											<a id="clicknext" onClick={() => { scrollToPage(page + 1); setPage(page + 1) }} style={{ cursor: 'pointer' }}> 
												{page === 0 && <span className="d-none d-md-block">Next page</span>} 
												<img src="/assets/img/arrow-right.png" alt="" /> 
											</a>
										</div>
									}
								</div>
								<div>
								</div>
							</div>
						}
                    </>}
                </div>
                <div className="gallery-container" ref={containerRef} >

					{ /* Change scale when the current page is the same as index.  
						change the rotation when the current page is the same as the index.
					*/}
					{ <div id="top_img_div" style={{ display: 'flex', 'overflow': 'auto','flexDirection': 'column', 'alignItems': 'center', 'position':'relative' }} ref={ slideGallery } >
                        {imagelist.length > 0 && imagelist?.map((data, i) => (
							<div key={i} style={{ 'width':`${scale[i]*100}%`,  'position' : 'relative ' }} >
								<img key={i} index={i} id={"mid"+i} src={data?.src} 
									style={{ 'width':'100%', 'transform': `rotate(-${rotation[i]}deg)` }} 
									onLoad={()=>onImageLoaded(i)} 
									ref={ el => ( elementsRef.current[i] = el )}
									alt="Mail Page" 
									/>
								{ region && rectangleStyle && highlightPage===i && <div key={`highlight_${i}`} id={`hid_${i}`} className="addr-border" style={rectangleStyle} onMouseEnter={(e)=> handleMouseEnter() } onMouseLeave={(e)=> handleMouseLeave() } /> }
								{ isHovering && <div key={`tooltop_${i}`} className="addr-tooltip" style={tooltipStyle} > 
										{ tooltip.length && tooltip?.map((text,j) =>
										(
											<div key={`text_${j}`}>{text}</div>
										))}
									</div> 
								}
							</div>
                        ))}
                    </div>}
                </div>
            </div >
        </>
    )
}

export default ImageGallery