/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { reset_password } from '../../services/AuthApi'

const SolveChallenge = ({ data, setData }) => {

    const userData = data
    const [answer, setAnswer] = useState('')
    const [error, setError] = useState('')

    const submitAnswer = () => {
        if (!answer) {
            return setError('Please provide an answer.')
        }
        const Cdata = {
            username: userData?.data?.username,
			question: userData?.data?.challenge,
            answer
        }

        // console.log(userData)
        reset_password(Cdata).then(
            (res) => {
                // console.log(res)
                if (res.request.status === 200) {
                    // console.log('res', res)
                    setData(res.data)
                    resetForm()
                    document.getElementById('nextModal3').click()
                } else {
                    setError('err', res.message)
                }
            }
        )
    }

    const resetForm = () => {
        setError('')
        setAnswer('')
    }

    return (
        <div className="modal fade forget_password" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h5 className="title">Forgot Password </h5>
                    </div>
                    <div className="modal-body p-lg-4">

                        <div className="mb-4">
                            <p><b>Step 2 : Security Question</b></p>
                            <p className="fnt_14"> Before we can reset your password, you must answer following the question  </p>
                        </div>

                        <form>
                            <div className={`mb-3 ${error ? 'error_inupt' : ''}`}>
                                <label htmlFor="emailAnswer" className="form-label">{data?.data?.challenge}</label>
                                <input type="text" className="form-control" id="emailAnswer" aria-describedby="emailHelp" onInput={(e) => setAnswer(e.target.value)} value={answer} />
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer border-0">
                        <button type="button" className="btn btn-link btn_link_primary" onClick={resetForm} data-bs-dismiss="modal">Cancel</button>
                        <button className="btn  btn_cstm_primary px-4" onClick={() => submitAnswer()}>Next</button>
                        <button className="btn btn_cstm_primary px-4 d-none" id="nextModal3" data-bs-target="#exampleModalToggle3" data-bs-toggle="modal" data-bs-dismiss="modal">Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SolveChallenge