/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import React, { useEffect, useState } from 'react'
import './DynamicSidebar.css'
import { useLocation } from 'react-router-dom'
import DatabaseTabSidebar from './Sidebars/DatabaseTabSidebar'
import DeliverTabSidebar from './Sidebars/DeliverTabSidebar'
import MailTabSidebar from './Sidebars/MailTabSidebar'
import ReportTabSidebar from './Sidebars/ReportTabSidebar'
import SystemTabSidebar from './Sidebars/SystemTabSidebar'

const DynamicSidebar = () => {

    const location = useLocation();
    const [activeLink, setActiveLink] = useState('mail');

    useEffect(() => {
        const route = location.pathname;

        setActiveLink(route.split("/")[1]);

    }, [location])


    return (
        <>

            <div className="sidebar ps-5">
                {
                    (() => {
                        switch (activeLink) {
                            case "database": return <DatabaseTabSidebar />
                            case "deliver": return <DeliverTabSidebar />
                            case "mail": return <MailTabSidebar />
                            case "reports": return <ReportTabSidebar />
                            case "system": return <SystemTabSidebar />
                        }
                    })()
                }
            </div>
        </>
    )
}

export default DynamicSidebar