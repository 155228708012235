/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { CommonState } from "../../../context/commonContext";
import "./Header.css";
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { removeAuthToken, removeRefreshToken } from "../../../services/tokenService";
import { AuthState } from "../../../context/authContext";
import { logout_api } from "../../../services/AuthApi";
import { getService, putService } from "../../../services/commonApi";
import Dropdown from 'react-bootstrap/Dropdown';
// import SenderEdit from '../..//databaseModule/Modals/SenderModal/SenderEdit';
import { FaStickyNote } from 'react-icons/fa'
import SenderEdit from "../../pages/dashboard/databaseModule/Modals/SenderModal/SenderEdit";
import Avatar from 'react-avatar';
import EmployeeNameListItem from "./SearchBoxItems/EmployeeNameListItem";
import MailNameListItem from "./SearchBoxItems/MailNameListItem";
import AddressListItem from './SearchBoxItems/AddressListItem'
import HelpItem from "./SearchBoxItems/HelpItem";
import SenderNameListItem from "./SearchBoxItems/SenderNameListItem";
import PositionItem from "./SearchBoxItems/PositionItem";
import DepartmentListItem from "./SearchBoxItems/DepartmentListItem";
import CompanyNameItem from "./SearchBoxItems/CompanyNameItem";
import HelpCanvas from "./HelpCanvas";
import ClickOutsideEvent from "../../ClickOutsideEvent";

const Header = () => {

	var params = useParams();
	const helpRef = useRef();
	const location = useLocation();
	const navigate = useNavigate()
	const { toggleMailMobilebar, setToggleMailMobilebar, currentFolder, refreshFolderData, helpUrl } = CommonState()
	const { user, activeLink } = AuthState()
	const [headerLink, setHeaderLink] = useState('')
	const [filter, setFilter] = useState('')
	const [include_type, setInclude_type] = useState('companyname,department,position,employee,address,sender,mail,help')
	const [searchData, setSearchData] = useState({})
	const [url, setUrl] = useState('')
	const [searchResults,setSearchResults] = useState()
	const [showSearch,setShowSearch] = useState(false)

	useEffect(() => 
	{
		console.log("active line or current folder "+activeLink+"  "+params?.id)
		if (activeLink === 'mail' && params.id) 
		{
			let txt = currentFolder?.unreadMail ? currentFolder?.name+ ' ('+currentFolder?.unreadMail+')' : currentFolder?.name
			setHeaderLink( txt )
		} 
		else 	
		{
			setHeaderLink(activeLink)
		}
	}, [activeLink, params.id, currentFolder])

	useEffect(() =>
	{
		if( refreshFolderData )
		{
			let txt = currentFolder?.unreadMail ? currentFolder?.name+ ' ('+currentFolder?.unreadMail+')' : currentFolder?.name
			setHeaderLink( txt )
		}
	}, [ refreshFolderData ] )

	const logout = () => 
	{
		logout_api().then(() => {
		localStorage.removeItem('user')
		sessionStorage.clear()
		removeAuthToken()
		removeRefreshToken()
		navigate('auth')
		window.location.reload();
		})
	}

	useEffect(() => 
	{
		if( !filter )
		{
			setSearchResults( {} )
			setSearchData([])
		}
		const search = setTimeout(() => 
		{
			if (filter) 
			{
				setSearchResults( {} )
				setSearchData([])
				searchMail()
				setShowSearch( true );
			}
		}, 1500);
		return () => clearTimeout(search)
	}, [filter])

	const searchMail = () => 
	{
		getService('/mercury/search/resources', true, { params: { filter, include_type } }).then( (res) => 
		{
			setSearchResults( res?.data?.available )
			setSearchData(res?.data?.matches)
		})
	}

	const handleClickScroll = ( ele ) => {
		const element = document.getElementById( ele );
		if (element) 
		{
			// 👇 Will scroll smoothly to the top of the next section
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};
	
	const clickOutsideHelp = () =>
	{
		document.getElementById('helpcanvas_close').click()
		setUrl("");
	}

	const clickHelpIndex = () =>
	{
		setUrl(helpUrl);
	}

	return (
		<>
		<header className="navbar navbar-dark sticky-top text-white bg_dark_blue_2 px-3 cst_conaitner">
			<div className="col-sm-12 col-lg-3 col-xl-2 navbar-logo">
				<a className="navbar-brand col-md-3 col-lg-3" href="#">
					<img src="/assets/img/logo.png" className="img-fluid" alt="" />
				</a>
				<button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="offcanvas" 
							data-bs-target="#sidebarCanvasMob" aria-controls="offcanvasExample">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="col-5 col-sm-3 col-lg-3 col-xl-3 navbar-title">
					<h4 className="heading_1 text-white mb-0 text-capitalize">{headerLink}</h4>
				</div>
			</div>
			<div className="right_head_link">
				<div className="row align-items-center mt-3 mt-lg-0">
					<div className="navbar-search">
						<Dropdown open={ showSearch }>
							<Dropdown.Toggle className='serach_box' as={'div'} variant="none" align="end">
								<input className="form-control" type="text" aria-label="Search" onInput={(e) => setFilter(e.target.value)} value={filter} />
								<a href="#"><span className="search_cion"><img src="/assets/img/Icon_map-search.png" alt="" /></span></a>
							</Dropdown.Toggle>
							<Dropdown.Menu className='search_results'>
								{ searchData?.length > 0 ?
									<div className="help_modal_body" aria-labelledby="dropdownMenuButton2">
										<div className='help-left-side-list'>
											<ul className='no-bullets'>
												<li><div onClick={() => handleClickScroll('mail_search_results')} >Mail ({searchResults?.mail ? searchResults?.mail : '0' })</div></li>
												<li><div onClick={() => handleClickScroll('sender_search_results')} >Senders ({searchResults?.sender ? searchResults?.sender : '0'})</div></li>
												<li><div onClick={() => handleClickScroll('employee_search_results')} >Employees ({searchResults?.employee ? searchResults?.employee : '0'})</div></li>
												<li><div onClick={() => handleClickScroll('position_search_results')} >Positions ({searchResults?.position ? searchResults?.position : '0'})</div></li>
												<li><div onClick={() => handleClickScroll('department_search_results')} >Departments ({searchResults?.department ? searchResults?.department : '0'})</div></li>
												<li><div onClick={() => handleClickScroll('companyname_search_results')} >Company Names ({searchResults?.companyname ? searchResults?.companyname : '0'})</div></li>
												<li><div onClick={() => handleClickScroll('address_search_results')} >Company Addresses ({searchResults?.address ? searchResults?.address : '0'})</div></li>
												<li><div onClick={() => handleClickScroll('help_search_results')} >Help Topics ({searchResults?.help ? searchResults?.help : '0'})</div></li>
											</ul>
										</div>
										<div className='help-right-side'>
											<div className='help-right-side-label'>{ filter ? `Search Results for '${filter}'"` : "" }</div>
											<div className='help-right-side-list'>
											{
												searchData?.length && searchData?.map((data, i) => 
												{
													switch (data?.recordType) 
													{
														case 'Mail':
															return <MailNameListItem key={i} data={data?.list} />
														case 'Help':
															return <HelpItem key={i} data={data?.list} setUrl={setUrl} />
														case 'Sender':
															return <SenderNameListItem key={i} data={data?.list}/>
														case 'Employee':
															return <EmployeeNameListItem key={i} data={data?.list}/>
														case 'Position':
															return <PositionItem key={i} data={data?.list} />
														case 'Department':
															return <DepartmentListItem key={i} data={data?.list} />
														case 'CompanyName':
															return <CompanyNameItem key={i} data={data?.list} />
														case 'Address':
															return <AddressListItem key={i} data={data?.list} />
														default:
															break
													}
												})
											}
											</div>
										</div>
									</div>
									:
									<div className="help_modal_body" aria-labelledby="dropdownMenuButton2">
										<div className='help-left-side-list'>
											<ul className='no-bullets'>
												<li>Mail (0)</li>
												<li>Senders (0)</li>
												<li>Employees (0)</li>
												<li>Positions (0)</li>
												<li>Departments (0)</li>
												<li>Company Names (0)</li>
												<li>Company Addresses (0)</li>
												<li>Help Topics (1)</li>
											</ul>
										</div>
										<div className='help-right-side'>
											<div className='help-right-side-label'>
											{ filter?.length > 0 &&
												<span className="d-flex" >
													<b>Your search results for '{filter}' did not match any results.  Try different search terms.</b>
												</span>
											}
											</div>
											<div className='help-right-side-list'>
												<div className='search_results_item'>
													<span className="d-flex">
														<p><b>Help Topics</b></p>
													</span>
													<Dropdown.Item className="help_box" as={'div'} onClick={clickHelpIndex} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" >
														<span className="d-flex" >
															<b>Using the Mercury application</b>
														</span>
														<p>Read an introduction about the mercury application and how it works.</p>
													</Dropdown.Item>
												</div>
											</div>
										</div>											
									</div>
								}
							</Dropdown.Menu>
						</Dropdown >
					</div>
					<div className="navbar-menu col-12 col-sm-5 col-lg-5 col-xl-3 ">
						<div className="navbar-menu-items">
							{activeLink === 'mail' && !toggleMailMobilebar && <button onClick={() => setToggleMailMobilebar(true)} className="d-sm-block d-md-none back_btn">back</button>}
							<div className="prf_box navbar-menu-link">
								<Dropdown>
									<Dropdown.Toggle id="dropdown-custom-1" variant="none" className="text-white d-flex align-items-center">
									<a className="link-secondary" href="#" aria-label="Add a new report">
										<span data-feather="plus-circle">
										{/* <img src={user?.picture}  className="img-fluid" alt={user?.name} /> */}
										<Avatar size="40" src={user?.picture} name={user?.userName} />
										</span>
									</a>
									<span className="ms-2">{user?.userName}</span>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdownLogout">
									<Dropdown.Item  onClick={() => logout()}>Logout</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="settings">
								<a id="open-settings" className="text-center" href="#" data-bs-toggle="modal" data-bs-target="#settings">
									<img src="/assets/img/icon-settings.png" alt="" />
									<span>Settings</span> 
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		<ClickOutsideEvent onClickOutside={ clickOutsideHelp }>
			<HelpCanvas url={url} />
		</ClickOutsideEvent>
		</>

	)

};

export default Header;
